import React from 'react';
import {Box} from "@mui/material";
import {formatNumber} from "../../../../fonctions/formatNumber"
function Index(props) {
    const { data, data2,data3,tva, agence } = props;
    const cv_d = data2.reduce((accumulator, currentDevise) => {
        return accumulator + parseFloat(currentDevise.total_cv_v);
    }, 0);

    const b_v_d = data2.reduce((accumulator, currentDevise) => {
        return accumulator + parseFloat(currentDevise.benefice_vente?currentDevise.benefice_vente:0);
    }, 0);
    const b_c_d = data2.reduce((accumulator, currentDevise) => {
        return accumulator + parseFloat(currentDevise.benefice_client?currentDevise.benefice_client:0);
    }, 0);

    const t_b=(b_c_d+b_v_d).toFixed(2);
    const c_t=(t_b*parseFloat(tva))/100;
    const total=t_b-c_t;
    const renderTableRows = () => {
        if (data3.etat === "Détaillé") {
            return (
                <tr>
                    <th>Date</th>
                    <th >N° bordereau</th>
                    <th> Devise</th>
                    <th> Montant</th>
                    <th >Cours Achat</th>
                    <th >Cours Vente</th>
                    <th >Contre Valeur</th>
                    <th >Benéfice vente</th>
                    <th >Benéfice vente client</th>
                </tr>
            );

        }else{
            return (
                <tr >
                    <td>Devise</td>
                    <td>Montant</td>
                    <td>Contre valeur</td>
                    <td>Benéfice vente banque</td>
                    <td>Benéfice vente client</td>

                </tr>
            );
        }}


    const renderTableBody = () => {

        if (data3.etat === "Détaillé") {
            return (

                <>
                    {data2.map((row) => (
                        <tr  >
                            <td>{row.date}</td>
                            <td>{row.num_bvc}</td>
                            <td>{row.nom_devise}</td>
                            <td align='right'>{formatNumber(row.total_mt_vc)}</td>

                            <td align='right'>{parseFloat(row.cours_ac).toFixed(4)}</td>
                            <td align='right'>{parseFloat(row.cours_vc).toFixed(4)}</td>
                            <td align='right'>{formatNumber(row.total_cv_v)}</td>
                            <td align='right'>{formatNumber(row.benefice_vente?row.benefice_vente:"00.00")}</td>
                            <td align='right'>{formatNumber(row.benefice_client?row.benefice_client:"00.00")}</td>


                        </tr>
                    ))}
                    <tr  >
                        <th colspan="6">Total</th>
                        <th align='right'>{formatNumber(cv_d)}</th>
                        <th align='right'>{formatNumber(b_v_d)}</th>
                        <th align='right'>{formatNumber(b_c_d)}</th>
                    </tr>
                </>
            );}else{
            return (
                <>
                    {data.map((row) => (
                        <tr>
                            <td >{row.nom_devise}</td>
                            <td align='right'>{formatNumber(row.mt_ac_sum)}</td>
                            <td align='right'>{formatNumber(row.cv_ac_sum)}</td>
                            <td align='right'>{formatNumber(row.b_v_sum?row.b_v_sum:"00.00")}</td>
                            <td align='right'>{formatNumber(row.b_c_sum?row.b_c_sum:"00.00")}</td>



                        </tr>
                    ))}
                    <tr  >
                        <th colspan="2">Total</th>
                        <th>{formatNumber(cv_d)}</th>
                        <th>{formatNumber(b_v_d)}</th>
                        <th>{formatNumber(b_c_d)}</th>
                    </tr>
                </>
            );
        }
    };
    return (
        <div>
            {/* Agency information */}
            <Box sx={{
                borderBottom: '1px solid black',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%'
            }}>
                <h5 className="h5">{agence.nom_bureau}</h5>
                <h5 className="h5">{agence.num_auto}</h5>
            </Box>

            {/* Header */}
            <Box
                sx={{
                    border: '1px solid black',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '70%',
                    marginLeft: '15%',
                    marginY: '4vh',
                    borderRadius: '8px',
                    backgroundColor: '#ea5a27', // Change the background color here
                }}
            >
                {data3.etat === "Détaillé" ? <h2 className="h2">ÉTAT DE DÉTAIL DES BÉNÉFICES</h2> :
                    <h2 className="h2">ÉTAT DES BÉNÉFICES GLOBAUX</h2>}
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <Box sx={{marginRight: '5vw'}}>
                    <h5 className="h5">Du : {data3.du}</h5>
                </Box>
                <Box sx={{}}>
                    <h5 className="h5">Au : {data3.au}</h5>
                </Box>
            </Box>

            <Box className="tableau" sx={{width: '100%'}}>
                <table className="print-table">
                    {renderTableRows()}
                    {renderTableBody()}
                </table>


            </Box>
            <Box className="tableau" sx={{
                marginLeft: '25%',
                width: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <table className="print-table">
                    <thead>
                    <tr>
                        <th>Total Benefice</th>
                        <th>Taux TVA</th>
                        <th>Dont TVA</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{formatNumber(t_b)}</td>
                        <td>{parseFloat(tva).toFixed(2)} %</td>
                        <td>{formatNumber(c_t)}</td>
                    </tr>
                    <tr>
                        <td colSpan="3">net : {formatNumber(total)}</td>
                    </tr>
                    </tbody>
                </table>
            </Box>


            {/* Additional CSS styles for printing */}
            <style>
                {`
            .print-table {
                margin-left:1%;
                width: 98%;
              border-collapse: collapse;
              z-index: 9;
            }
            .footer {
                margin-top: 4vh;
                border: 2px dotted #73AD21;
                align-items: center;
                width: 100%;
                justify-content: center;
                z-index: 9;
              }
            .print-table th,
            .print-table td {
              font-size: 12px;
              border: 1px solid black;
              padding: 8px;
              z-index: 9;
              text-align: right;
            }

            /* Additional styles specific for printing */
            @media print {
              
              .print-table {
                z-index: 9;
              }
              
              .print-table th,
              .print-table td {
                page-break-inside: avoid;
                z-index: 9;
              }
              /* Ensure the footer appears on each page */
              .footer {
                page-break-inside: avoid;
                z-index: 9;

              }
              .tableau{
                margin-bottom: 2vh; /* Add margin at the bottom of the table */

                z-index: 9;

              }
            }
          `}
            </style>


            {/* Footer */}
            <Box className="footer" sx={{paddingTop: '1vh'}}>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <h5 className="h5">Adresse : {agence.adresse}</h5>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Box sx={{marginRight: '3vh'}}>
                        <h5 className="h5">Tél : {agence.tel}</h5>
                    </Box>
                    <Box>
                        <h5 className="h5">Email : {agence.email}</h5>
                    </Box>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Box sx={{marginRight: '3vh'}}>
                        <h5 className="h5">TP : {agence.tp}</h5>
                    </Box>
                    <Box sx={{marginRight: '3vh'}}>
                        <h5 className="h5">IF : {agence.idfis}</h5>
                    </Box>
                    <Box>
                        <h5 className="h5">RC : {agence.rc}</h5>
                    </Box>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <h5 className="h5">ICE : {agence.ice}</h5>
                </Box>
            </Box>
        </div>
    );
}

export default Index;