import { Box } from "@mui/material";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import Autocomplete from '@mui/material/Autocomplete';
import {useEffect, useState} from 'react';
import {Button} from "@mui/material";
import swal from "sweetalert";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import axios from "axios";
import { useReactToPrint } from "react-to-print";
import React, { useRef } from 'react';
import Logo from './../../data/bg-05.gif'
const formatNumber = (number) => {
  // Convert the number to a string with 2 decimal places
const formattedNumber = Number(number).toFixed(2);

// Split the number into parts before and after the decimal point
const [integerPart, decimalPart] = formattedNumber.split('.');

// Add thousands separators
const integerWithSeparators = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

// Combine the formatted parts
return `${integerWithSeparators}.${decimalPart}`;

};
class PDFContent extends React.Component {
  render() {
    const { data,agence,data2,cours } = this.props;
    const montant = data.reduce((accumulator, currentDevise) => {
      return accumulator +  currentDevise.montant;
    }, 0);
    //hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh
    const sumcontrevaleur = () => {
      let sum = 0;
      data.map((op) => {
          sum += (op.montant)*(op.coursVente);
      });
      return sum;
    };
    const sumCommission2 = () => {
      let sum = 0;
      data.map((op2) => {
        sum+=sumCommission(op2.devise);
      return sum;
    })
      return sum;
    };
    const sumCommission = (devise) => {
      let sum = 0;
      let courV;
      let prix_par;
      data.map((op2) => {
        if(devise==op2.devise){
        courV=op2.coursVente;
        prix_par=op2.prix_par;
        }
      });
      cours.map((op2) => {
        if(devise==op2.devise){
        sum+=((op2.montant)*(courV)-(op2.montant)*(op2.cours_ac))/prix_par;
        
      }
    })
      return sum;
    };
    const pageCount = 2;
    return (
      <div  >
        
        <Box sx={{ borderBottom: '1px solid black', display: 'flex', flexDirection: 'column', alignItems: 'center' ,width:"100vw"}}> 
            <h5 className="h5">{agence.nom_bureau}</h5>
            <h5 className="h5">{agence.num_auto}</h5>
        </Box>
        <Box
          sx={{
            border: '1px solid black',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '50vw',
            marginLeft: '25vw',
            marginY: '4vh',
            borderRadius: '8px',
            backgroundColor: '#ea5a27', // Change the background color here
          }}
        >
          <h2 className="h2">ETAT DE VENTE BANQUE</h2>
        </Box>
        <Box sx={{width:'80vw',marginBottom:'2vh',marginLeft:"7.5vw",marginRight:"7.5vw"}}>
          <h5 className="h5">Date Vente : {data2.date} </h5>
          <h5 className="h5">N° Vente : {data2.num}</h5>
          <h5 className="h5">Banque: {data2.banque}</h5>
        </Box>
        <Box sx={{ width: '90vw',marginLeft:"5vw",marginRight:"5vw" }}>
      <table className="print-table">
        <tr>
          <th>Devise</th>
          <th>Montant en devise</th>
          <th>Cours Vente</th>
          <th>Contre valeur en DHS</th>
          <th>Commission</th>
        </tr>
        {data.map((row) => (
          <tr key={row.devise}> {/* Assuming each row has a unique identifier (e.g., 'id') */}
            <td>{row.devise}</td>
            <td align="right">{formatNumber(row.montant)}</td>
            <td align="right">{parseFloat(row.coursVente).toFixed(4)}</td>
            <td align="right">{formatNumber(row.contreValeur)}</td>
            <td align="right">{formatNumber(sumCommission(row.devise))}</td>
          </tr>
        ))}
      </table>
      <Box sx={{ display: 'flex',marginLeft:"30vw",width:"65vw",marginRight:"5vw",alignItems: 'center',marginTop:"2vh"}}>
        <Box sx={{ borderBottom: '1px dotted black', display: 'flex', flexDirection: 'column', alignItems: 'center' ,width:"16vw",marginX:"2vw"}}><h3>Total</h3></Box><Box  sx={{ borderBottom: '1px dotted black', display: 'flex', flexDirection: 'column', alignItems: 'center' ,width:"16vw",marginX:"2vw"}}><h3>{formatNumber(sumcontrevaleur())}</h3></Box><Box sx={{ borderBottom: '1px dotted black', display: 'flex', flexDirection: 'column', alignItems: 'center' ,width:"16vw",marginX:"2vw"}}><h3>{formatNumber(sumCommission2())}</h3></Box>
        
      </Box>
      
      {/* Additional CSS styles for printing */}
      <style>
        {`
          .print-table {
            width: 100%;
            border-collapse: collapse;
          }

          .print-table th, .print-table td {
            border: 1px solid black;
            padding: 8px;
          }

          /* Additional styles specific for printing */
          @media print {
            .print-table {
              page-break-inside: auto;
            }
            .footer{
              position: fixed;
              bottom: 0;
              border-top: 2px solid #73AD21;
              align-items: center;
              width:100vw;
              justify-content:center;
            }
            .print-table th, .print-table td {
              page-break-inside: avoid;
            }
          }
        `}
      </style>
    </Box>
    <Box className="footer" sx={{paddingTop:'1vh'}} >
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}><h5 className="h5">Adresse : {agence.adresse}</h5></Box>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}><Box sx={{marginRight:"3vh"}}><h5 className="h5">Tél : {agence.tel}</h5></Box><Box ><h5 className="h5">Email : {agence.email}</h5></Box></Box>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}><Box sx={{marginRight:"3vh"}}><h5 className="h5">TP : {agence.tp}</h5></Box><Box sx={{marginRight:"3vh"}}><h5 className="h5">IF : {agence.idfis}</h5></Box><Box sx={{marginRight:"3vh"}}><h5 className="h5">RC : {agence.rc}</h5></Box>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}><h5 className="h5">ICE : {agence.ice}</h5></Box></Box>
      </Box>   
      
      </div>
    );
  }
}


const Achat = () => {
    const history=useHistory();
    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
    const [currentDate, setCurrentDate] = useState(date );
    const [devise,setDevise]=useState([]);
    const [devise2,setDevise2]=useState([]);
    const [banque,setBanque]=useState([]);
    const [agence,setAgence]=useState([]);
    const [coursVente,setCoursVente]=useState();
    const [coursac,setCoursac]=useState([]);
    let numBvc=' ';
    const [inputColor, setInputColor] = useState("#000000de"); // Initial color is black
    let selb="BANQUE";
    const [selectedbanque,setSelectedbanque]=useState([]);
    const [valider, setValider] = useState(true);

    const [submitValue,setSubmitValue]=useState({
      date:currentDate,
      banque:'d',
      num:numBvc,
    })
    const [selectedQC,setSelectedQC]=useState([]);
    const [pays,setPays]=useState([]);
    const [listdevise,setlistdevise]=useState([]);
    const handleInput2=(e)=>{
      e.persist();
      setSubmitValue({...submitValue, [e.target.name]: e.target.value})
    }   
    const handleInput3 = (e, newValue) => {
      setSubmitValue({ ...submitValue, code_nop: newValue }); // Set the selected option directly
    }; 
    const handleInput4=(e,newValue)=>{
      setSubmitValue({ ...submitValue, nationalite: newValue });
       
     } 
     const theme = useTheme();
     const colors = tokens(theme.palette.mode);
  
    
    const handleInput = (event, newValue) => {
      setSelectedbanque(newValue);
      selb=newValue;
      
    };
    
  const [rows, setRows] = useState([]);
  
  const [coursAchat, setCoursAchat] = useState('');
  const [montant, setMontant] = useState('');
  const [contreValeur, setContreValeur] = useState('');
  const handleInsert = () => {
    if(selectedDevise  && montant!=0 && coursVente!= 0){
    if (selectedDevise) {
      const Devi=devise.find((row)=>row.nom_devise===selectedDevise.nom_devise);
      const existingDevise=rows.find((row)=>row.devise===selectedDevise.nom_devise);
      if(!existingDevise ){
        if( parseFloat(montant)<=parseFloat(Devi.montant) ){

        const cv = devise2.find((op) => op.nom_devise === selectedDevise.nom_devise);
        const nbr=montant/cv.copure;
        if(Number.isInteger(nbr)){
        const newRow = {
          id_devise:cv.id_devise,
          devise: selectedDevise.nom_devise,
          montant: montant,
          coursVente: coursVente,
          contreValeur: contreValeurs,
          prix_par:cv.prix_par,
        };
        setRows((prevRows) => [...prevRows, newRow]);
        setMontant('0')
      }else{
        swal('',"Veuillez respecter les coupures.",'warning');
      }
    }else{
      swal('',"Le montant en caisse est insuffisant.",'warning');
    }
    }else{
      swal('',"Devise déja inséré.",'warning');
    }
    }
  }
  };
  
  const [selectedRows, setSelectedRows] = useState([]);
  const handleRowSelection = (rowName) => {
    const selectedIndex = selectedRows.indexOf(rowName);
    let newSelectedRows = [];

    if (selectedIndex === -1) {
      newSelectedRows = [...selectedRows, rowName];
    } else {
      newSelectedRows = selectedRows.filter((name) => name !== rowName);
    }

    setSelectedRows(newSelectedRows);
  };
  const handleDeleteRows = () => {
    if (selectedRow !== null) {
      swal({
        title: '',
        text: 'Voulez-vous vraiment supprimer cette ligne ?',
        icon: 'warning',
        buttons: ['Annuler', 'Oui'],
        dangerMode: true,
      }).then((confirmed) => {
        if (confirmed) {
          const updatedRows = rows.filter((row) => row.devise !== selectedRow.devise);
          const existingDevise = listdevise.find((row) => row.nom_devise === selectedRow.devise);
          setSelectedDevise(existingDevise);
          setCoursVente(parseFloat(selectedRow.coursVente).toFixed(4));
          setMontant(selectedRow.montant);
          setContreValeur(selectedRow.contreValeur); // Assuming setContreValeur is a state setter function
          setSelectedRow(null);
          setRows(updatedRows);
        }
      });
    }
  };
  
  const [selectedDevise,setSelectedDevise]=useState({
    id_devise: "",
    nom_devise: "EUR",
    designation: "",
    prix_par: "",
    copure: "",
    compte: "",
    tauxA: "",
    tauxVC: "",
    tauxVB: ""
});

  const sumCvAc = devise.reduce((accumulator, currentDevise) => {
    if(currentDevise.nom_devise !== "DHS"){
    return accumulator + currentDevise.cv_ac;
  }else{
    return accumulator;
  }
  }, 0);
  const sumCvAcWithTwoDecimals = sumCvAc.toFixed(2);
  const sumCv = rows.reduce((accumulator, currentDevise) => {
    return accumulator +  currentDevise.contreValeur;
  }, 0);
  const submitall = (e) => {
    e.preventDefault();
    axios.get(`api/info-agence`).then((res) => {
      if (res.data.status === 200) {
        setAgence(res.data.message);
      }
    });
  
    const data = {
      id_client: selectedbanque.id,
      TypeV: "B",
      rows: rows.map((row) => ({
        devise: row.devise,
        montant: row.montant,
        coursVente: row.coursVente,
        coursAchat: row.coursAchat,
        contreValeur: row.contreValeur,
        prix_par:row.prix_par,

      })),
    };
    console.log(data);
    swal({
      title: "",
      text: "Voulez vous vraiment valider ce bordereau ?",
      icon: "warning",
      buttons: ["Annuler", "Oui"],
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        // User clicked "Oui," proceed with form submission
        axios
          .post("api/vente-client", data)
          .then((res) => {
            // ... Handle the response from the server ...
            if (res.data.status === 200) {
              const numBvc = res.data.num;
              setCoursac(res.data.cours_ac);

              // Update the state with the new values in one call
              setSubmitValue((prevState) => ({
                ...prevState,
                num: res.data.num,
                banque: selectedbanque.designation,
              }));
            } else if (res.data.status === 410) {
              swal(
                "",
                "Pour enregistrer cette opération, il est nécessaire de saisir les informations du banque.",
                "warning"
              );
            } else if (res.data.status === 400) {
              swal(
                "",
                "Pour enregistrer cette opération, il est nécessaire de saisir les informations de l'opération de vente.",
                "warning"
              );
            } else {
              swal("", "Erreur.", "warning");
            }
          })
          .catch((error) => {
            // Handle any error that occurred during the request
            console.error("Error submitting form:", error);
            swal("", "Une erreur est survenue lors de l'enregistrement.", "error");
          });
      }
    });
  };
  useEffect(() => {
    // Check if the status is 200 and perform the actions
    if ( submitValue.banque === selectedbanque.designation) {
      
      handlePrint(); // Call handlePrint() after the state is updated
        setRows([]);
        setValider(true);
    }
  }, [submitValue.banque, selectedbanque.designation]);
  
const [infodevise,setInfodevise]=useState([]);
useEffect(()=>{axios.get(`api/info-devise`).then(res=>
  {
    if(res.data.status===200){
      setInfodevise(res.data.message);
      
    }
  }
  );},[]);
  


  
  const [selectedDevQC] = useState(null);
  
  const [de,setDe]=useState([]);

  // Handler to update the selectedDevise state when a devise is selected from Autocomplete
  const handleCoursChange = (event) => {
      if (/^\d*$/.test(event.target.value)) {
          setCoursVente(event.target.value);
      }
  }
  const handleDeviseChange = (event, newValue) => {
    setSelectedDevise(newValue); // Update selectedDevise directly
    setInputColor("#000000de")
    if (newValue === null) {
      changeCoursVente2();
    } else {
      
      changeCoursVente(newValue);
    }
  };

  const changeCoursVente2 = () => {
    setCoursVente(0);
    setDe([]);
  };

  const changeCoursVente = (selectedDevise) => {
    const cv = devise2.find((op) => op.nom_devise === selectedDevise.nom_devise);
    setDe(cv);
    const cours = cv ? cv.tauxVB : 0;
    setCoursVente(parseFloat(cours).toFixed(4));
  };
  
  // Handler to update the Montant state when the value changes in the "Montant" TextField
  const handleMontantChange = (event) => {
    const inputValue = event.target.value;
    if (/^\d+$/.test(inputValue) || inputValue === '') {
      setMontant(inputValue);
    }
  };
  const [sumsByIdDevise, setSumsByIdDevise] = useState({});

  // Calculate Contre Valeur based on the selectedDevise's tauxA and the Montant
   useEffect(() => {
    const groupedData = coursac.reduce((result, current) => {
      const { id_devise, montant, cours_ac } = current;
      const existingData = result.find(item => item.id_devise === id_devise);

      if (existingData) {
        existingData.montantSum += montant;
        existingData.cv_aSum += montant * cours_ac;
      } else {
        result.push({
          id_devise,
          montantSum: montant,
          cv_aSum: montant * cours_ac,
        });
      }

      return result;
    }, []);

  }, [coursac]);
  
  const [selectedRow, setSelectedRow] = useState(null);
  const handleRowClick = (row) => {
    setSelectedRow(row);
  };
  const [selectedRow2, setSelectedRow2] = useState(null);
  const handleRowClick2 = (row) => {
    setSelectedRow2(row);
  };
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Print",
    pageStyle: `
      @page {
        size: A4;
        margin: 10mm;
      }
      /* Additional styles for the printed page can be added here */
      /* Remove the pagination and URL styles */
      @media print {
        .MuiDataGrid-footerContainer {
          display: none !important;
        }
        a {
          display: none !important;
        }
      }
    `,
    header: null, // Set header to null to remove it from the PDF
    footer: null,
     // Set footer to null to remove it from the PDF
  });
  
  
  
  
/*******************************************************************************APIS************************** */
const [loading, setLoading] = useState(true);

useEffect(() => {
    if(valider){
    axios.get(`api/detail-caisse`).then(res => {
      if (res.data.status === 200) {
          setValider(false);
        setDevise(res.data.message);
        setDevise2(res.data.devise);
        setSelectedQC(res.data.selectedQC);
        const dhs = "DHS";
        const ldevise = (res.data.message).filter((de) => de.nom_devise !== dhs);
        setlistdevise(ldevise);
        const rt=res.data.message;
      const sd=res.data.message?rt[0]:null;
     
      setSelectedDevise(sd);
      setDe((res.data.devise)[0]);
      setCoursVente(parseFloat(res.data.devise?(res.data.devise)[0].tauxVC:null).toFixed(4));
      setLoading(false);

      }
    });
    }
  }, [valider]);
  
    
    useEffect(()=>{
      axios.get(`api/op-v-b`).then(res=>{
        if(res.data.status===200){
          setBanque(res.data.banques);
          const sl=res.data.selectedBanque;
          setSelectedbanque(sl)
        }
      })},[]);
      const contreValeurs = de.length!==0 ? (coursVente * montant) / de.prix_par : 0;
      if (loading) {
        return <div ><div style={{marginLeft:'37.5%',marginTop:"30vh"}}>
                    <img style={{width:"25%",height:"40vh"}} src={Logo}/>
          
        </div></div>;
      }
  return (
    
    <Box className="type2" sx={{
      marginTop:"3vh"
    }}>


      <Box className="type3">
        <Box  className="form1"
         sx={{
            "& .MuiOutlinedInput-input":{
            heigh:"0.5rem"}
        }}
         >
          
            <Box >
                <h2  className= "h2">Informations vente banque</h2>
                <Box><TextField disabled size="small" name="n_pid"  value={currentDate} onChange={handleInput2} sx={{marginTop:`8px` ,width:`50%`}}  label="Date Vente" variant="outlined" /></Box>
                <Autocomplete
                    onChange={handleInput}
                    value={selectedbanque}
                    size="small"
                    id="combo-box-demo"
                    options={banque}
                    getOptionLabel={(option) => option.designation} // Use the 'designation' property as the label
                    sx={{ marginTop: `8px` }}
                    renderInput={(params) => <TextField {...params} label="Banque" />}
                    disableClearable={true}

                    />
                </Box>
            <Box sx={{marginY:"1.5rem"}}>
                <h2  className="h2">Opération de vente</h2>
                <Box  display="flex" sx={{marginY:"1.5rem",
             "& .MuiInputBase-root":{
                color: inputColor
             }
            }}>
                <Autocomplete
                    size="small"
                    value={selectedDevise}
                    options={listdevise}
                    getOptionLabel={(option) => option.nom_devise}
                    sx={{ marginRight: "0.5rem", width: "9rem" }}
                    renderInput={(params) => <TextField {...params}  label="Devise" />}
                    onChange={handleDeviseChange}
                    disableClearable={true}

                    />
                  <Box sx={{marginX:'0.5rem'}}>
                      <img src={require(`./../drapeaux/${selectedDevise.nom_devise?selectedDevise.nom_devise:"EUR"}.png`)} style={{ width: "4rem", height: '2.5rem' }} alt="Flag" />
                  </Box>

                    <TextField className="cv"
                    size="small"
                    sx={{ width: "10rem", marginRight: "0.5rem" }}
                    label="Cours Vente"
                    value={coursVente}
                    onChange={handleCoursChange}
                    type='number'
                    />
                  <TextField
                    size="small"
                    sx={{ width: "10rem", marginRight: "0.5rem" }}
                    label="Montant"
                    value={montant}
                    onChange={handleMontantChange}
                    type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    inputProps={{ style: { color: inputColor } }}
                  />
                  <TextField
                    size="small"
                    sx={{ width: "12rem", marginRight: "0.5rem" }}
                    label="Contre Valeur"
                    value={formatNumber(contreValeurs)}

                    disabled
                    />
 
                </Box>
                <Box display="flex" justifyContent="space-between" sx={{
                      "& .MuiButtonBase-root" : {
                          color: colors.grey[200],
                          backgroundColor : colors.primary[400],
                          marginY: "0.5em",
                          fontSize: "0.8em"
                      },
                      "& .MuiButtonBase-root:hover" : {
                          color: colors.primary[400],
                          backgroundColor : colors.grey[200],
                          
                          marginY: "0.5em",
                          fontSize: "0.9em"
                      },
                      "& .span1" : {
                          
                          
                          
                          
                      }}}>
                  <Box sx={{width:"82%"}}>
                    <TableContainer component={Paper} >
                        <Table >
                            <TableHead>
                            <TableRow>
                                
                                <TableCell>Devise</TableCell>
                                <TableCell align="right">Montant en devise</TableCell>
                                <TableCell align="right">Cours Apliqué</TableCell>
                                <TableCell align="right">Contre valeur en DHS</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                
                                key={row.devise}
                                onClick={()=>handleRowClick(row)}
                                sx={{
                                  backgroundColor:
                                    selectedRow && selectedRow.devise === row.devise ? '#ccc' : 'inherit',
                                }}
                                >
                                
                                <TableCell >
                                    {row.devise}
                                </TableCell>
                                <TableCell align="right">{formatNumber(row.montant)}</TableCell>
                                <TableCell align="right">{parseFloat(row.coursVente).toFixed(4)}</TableCell>
                                <TableCell align="right">{formatNumber(row.contreValeur)}</TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                        </TableContainer>
                      </Box>
                  <Box sx={{width:"18%"}}>
                    <Box><Button onClick={handleInsert}><AddIcon/><span className="span1">Insérer</span></Button></Box>
                    <Box><Button onClick={handleDeleteRows}><CancelIcon/><span className="span1">Annuler</span></Button></Box>
                    <Box><Button  onClick={submitall}><CheckCircleOutlineIcon/><span className="span1">Valider</span></Button></Box>
                  </Box>
                  
                </Box>
                <Box  display="flex"
            sx={{justifyContent:'end',width:'82%',
                "& .contrevaleur":{
                    color: colors.grey[100],
                },
                "& .contrevv":{
                    backgroundColor:colors.primary[800],
                    color: colors.grey[100],
                }      
            }} 
            ><span className="contrevaleur" >Contre valeur en DHS</span> <span className="contrevv"  >{formatNumber(sumCv)} </span></Box>
            
            </Box>
            
        </Box>
        <Box className="table1"
            sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .name-column--cell": {
                  color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${colors.grey[100]} !important`,
                },
              }}
        >
            <h2   className="h2">Caisse en Devise</h2>
            <TableContainer component={Paper} >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Devise</TableCell>
            <TableCell>Montant</TableCell>
          </TableRow>
        </TableHead>
      </Table>
      <div style={{ overflowY: 'auto', maxHeight: '35vh' }}>
        <Table aria-label="simple table">
          <TableBody>
            {devise.map((row, index) => (
              <TableRow
                key={index}
                onClick={() => handleRowClick2(row)}
                style={{
                  backgroundColor:
                    selectedRow2 && selectedRow2.nom_devise === row.nom_devise ? '#ccc' : 'inherit',
                }}
              >
                <TableCell>{row.nom_devise}</TableCell>
                <TableCell align="right">{formatNumber(row.montant)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </TableContainer>
            <Box display="flex" justifyContent="space-between" >
     
            <Box 
            sx={{width:'100%',justifyContent: 'center', alignItems: 'center',justifyItems:'center',
                "& .contrevaleur":{
                    color: colors.grey[100],
                    fontSize: "1rem",
                    marginTop: "1rem",
                    padding:"0.5rem",
                },
                "& .contrev":{
                    backgroundColor:colors.primary[800],
                    color: colors.grey[100],
                    fontSize: "1.1rem",
                    marginTop: "1rem",
                    marginLeft: "2rem",
                    marginRight : "3rem",
                    marginBottom: "5rem",
                    paddingX:"2.5rem",
                    paddingY:"0.5rem",
                }      
            }} 
            ><Box sx={{ marginY: '2vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <span className="contrevaleur" stylle={{width:'100%', textAlign:'center'}}>Contre valeur de devises conservées</span>
          </Box >
          <Box  sx={{display: 'flex', justifyContent: 'center', alignItems: 'center' }}><span className="contrev"  >{formatNumber(sumCvAc)} </span></Box></Box>
          </Box>
        </Box>
       
      </Box>
      <div style={{ display: 'none' }}>
        <PDFContent data={rows} agence={agence} data2={submitValue} cours={coursac} ref={componentRef} />
      </div>
      </Box>
       
      
    
  );
};

export default Achat;
