import { Box } from "@mui/material";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import {Button} from "@mui/material";
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import * as React from 'react';
import axios from "axios";
import {useEffect, useState,useRef} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import { useReactToPrint } from "react-to-print";
import PDFRecherche from '../PDFContent/Recherche/index'
class PDFContent extends React.Component {
    render() {
        // Extracting props for easier access
        const { data, data2,data3, agence } = this.props;

        return (
            <PDFRecherche data={data} data2={data2} data3={data3} agence={agence}/>
        );
    }
}
const RapportJ = () => {
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedDevise, setSelectedDevise] = useState(null);
    const [selectedDuDate, setSelectedDuDate] = useState(dayjs());
    const [selectedAuDate, setSelectedAuDate] = useState(dayjs());
    const [selectedEtat, setSelectedEtat] = useState("Détaillé");
    const [shouldPrint, setShouldPrint] = useState(false);
    const [dataToRender, setDataToRender] = useState([]);
    const [dataToRender2, setDataToRender2] = useState([]);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [bordereaux,setBordereaux]=useState([]);
    const [user,setuser]=useState([]);
    const [users,setusers]=useState([]);
    const [agence,setAgence]=useState({});
    const [rows, setRows] = useState([]);
    const [data2, setData2] = useState([]);
    const componentRef = useRef();
    const [selectedRow, setSelectedRow] = useState(null);
    const [info, setInfo] = useState({
        du:'',
        au:'',
        utilisateur:'',
        devise:'',
        etat:'',
        user:'',
    });
    const [bool,setBool]=useState(false);
    const [selectedType,setSelectedType]=useState({  name: 'Nom' });
    const type = [
        {  name: 'Nom' },
        {name: "Prenom" },
        {name: 'CIN' },
        { name: "N° Bordreau Achat" },
        { name: "N° Borderau Vente" },
    ];
    const [selectedValue, setSelectedValue] = useState(' ');

    const handleValueChange = (event) => {
        setSelectedValue(event.target.value);
        setDataToRender([]);
        setDataToRender2([]);
        setClients([]);
        if((event.target.value)!=''){

            setBool(true);
        }
    };
    /****************************************************************functions************************************************** */
    const handlePrintev = (e) => {
        e.preventDefault();
        axios.get(`api/info-agence`).then((res) => {
            if (res.data.status === 200) {
                setAgence(res.data.message);
                setShouldPrint(true);
            }
        });

        // Add checks to ensure variables are defined
        const du = convertDateFormat(selectedDuDate);
        const au = convertDateFormat(selectedAuDate);
        const utilisateur = selectedUser ? selectedUser.name : '--all--';
        const devise = selectedDevise ? selectedDevise : '--all--';
        const etat = selectedEtat ? selectedEtat : '';

        setInfo((prevInfo) => ({
            ...prevInfo,
            du: du,
            au: au,
            utilisateur: utilisateur,
            devise: devise,
            etat: etat,
            user:user,
        }));
    };
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "Print",
        pageStyle: `
    @page {
      size: A4;
      margin: 10mm;
    }
    /* Additional styles for the printed page can be added here */
    /* Remove the pagination and URL styles */
    @media print {
      .MuiDataGrid-footerContainer {
        display: none !important;
      }
      a {
        display: none !important;
      }
    }
  `,
        header: null, // Set header to null to remove it from the PDF
        footer: null,
        // Set footer to null to remove it from the PDF
    });
    const handleRowClick = (row) => {

        setSelectedRow(row);
        let filteredItems;
        filteredItems = dataToRender2.filter((item) =>
            item.nom === row.nom && item.prenom === row.prenom
        );

        setDataToRender(filteredItems);
    };
    useEffect(() => {
        if (shouldPrint) {
            // Call handlePrint() when shouldPrint becomes true
            handlePrint(); // This will trigger the printing action
            setShouldPrint(false); // Reset shouldPrint back to false
        }
    }, [shouldPrint]);
    /*************************************************************end functions********************************************** */
    /********************************************************************************UseEffect********************************** */
    const [clients,setClients]=useState([]);
    useEffect(() => {
        setSelectedRow(null);
        if(bool){
            const te=selectedType.name;
            const vr=selectedValue;
            setDataToRender([]);
            setClients([]);

            const fetchData = async () => {
                try {
                    const response = await axios.get(`api/recherche?type=${te}&valeur=${vr}`);
                    if (response.data.status === 200) {
                        const { message ,client} = response.data;
                        setDataToRender(message);
                        setDataToRender2(message);
                        setClients(client);
                        setBool(false);
                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            };

            fetchData();
        }
    }, [bool]);
    const [rrows,setRrows]=useState(1);
    const [devise2, setDevise2] = useState([]);

    useEffect(() => {
        const result = dataToRender.reduce((acc, item) => {
            const { date_achat, nom_devise, mt_ac, cv_ac } = item;
            const key = `${date_achat}-${nom_devise}`;

            if (acc[key]) {
                acc[key].cv_ac_sum += parseFloat(cv_ac);
                acc[key].mt_ac_sum += parseFloat(mt_ac);
            } else {
                acc[key] = {
                    date_achat,
                    nom_devise,
                    mt_ac_sum: parseFloat(mt_ac),
                    cv_ac_sum: parseFloat(cv_ac),
                };
            }

            return acc;
        }, {});

        const formattedResult = Object.values(result);

        // Update the state with the processed data
        setDevise2(formattedResult);
    }, [dataToRender]);

    /****************************************************************************end UseEffect********************************** */
    const handleTypeChange = (event, newValue) => {
        setSelectedType(newValue);
        setBool(true);

    }


    const renderInputs = () => {
        return (
            <>

                <Box display="flex" justifyContent="space-between"
                     sx={{
                         "& .MuiFormControl-root" : {
                             marginRight:"1rem",
                             width:"20vw",

                         },
                         "& .MuiAutocomplete-root":{
                             marginTop:"8px"
                         },
                         "& .buttons" : {
                             color: colors.grey[200],
                             backgroundColor : colors.primary[400],
                         },
                         "& .buttons:hover" : {
                             color: colors.primary[400],
                             backgroundColor : colors.grey[200]
                         },
                         "& .span1" : {


                             marginLeft: "0.5rem",

                         }
                     }}
                >
                    <Box display="flex">

                        <Autocomplete className="Autocomplete"
                                        size="small"
                                      id="combo-box-demo"
                                      value={selectedType}
                                      options={type}
                                      onChange={handleTypeChange}
                                      getOptionLabel={(option) => option.name}
                                      renderInput={(params) => <TextField {...params} label="Type de recherche" />}
                                      disableClearable={true}
                        />




                        <TextField size="small" sx={{marginTop:"0.5rem"}} id="outlined-basic" label="" variant="outlined" value={selectedValue} onChange={handleValueChange} />

                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            justifyItems: 'center',
                            '& .buttons': {
                                color: colors.grey[200],
                                backgroundColor: colors.primary[400],
                                marginTop:'0.5rem',
                                '&:hover': {
                                    color: colors.primary[400],
                                    backgroundColor: colors.grey[200],
                                },
                            },
                        }}
                    >
                        <Button className="buttons" onClick={handlePrintev}>
                            <FindInPageOutlinedIcon />
                            <span className="span1">Aperçu</span>
                        </Button>
                    </Box>

                </Box></>
        );
    };


    const convertDateFormat = (dateString) => {

        const parsedDate = dayjs(dateString, 'MM/DD/YYYY'); // Parse the date with the original format
        return parsedDate.format('YYYY/MM/DD'); // Format the date in the desired format
    };

    return (
        <Box  sx={{width: "95%",marginX: "2.5%",
            "& .bartitle":{
                marginY : "1.5rem",
            }
        }}>

            {renderInputs()}
            <Box>
                <h2 className="h2" style={{margin:'1vh'}}>Client</h2>

                <Paper >
                    <TableContainer  sx={{height:"35vh",backgroundColor:"#f2f0f0"}} >
                        <Table stickyHeader aria-label="sticky table" >
                            <TableHead sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>
                                <TableRow>
                                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Nom</TableCell>
                                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Prenom</TableCell>
                                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Adresse</TableCell>

                                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Nationalité</TableCell>
                                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>N° PID</TableCell>

                                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>N° PASS</TableCell>
                                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Date Passport</TableCell>

                                </TableRow>

                            </TableHead>
                            <TableBody>
                                {clients.map((row) => (
                                    <TableRow   onClick={() => handleRowClick(row)}
                                                sx={{
                                                    backgroundColor:
                                                        selectedRow && selectedRow.id === row.id ? '#ccc' : 'inherit',
                                                }}>
                                        <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{row.nom?row.nom:''}</TableCell>
                                        <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{row.prenom?row.prenom:''}</TableCell>
                                        <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{row.adresse?row.adresse:''}</TableCell>
                                        <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{row.nationalite?row.nationalite:''}</TableCell>
                                        <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{row.n_pid?row.n_pid:''}</TableCell>
                                        <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{row.num_cni?row.num_cni:''}</TableCell>
                                        <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{row.date_pass?rows.date_pass:''}</TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </Paper>
                <h2 className="h2" style={{margin:'1vh'}}>Opérations client</h2>
                <Paper >
                    <TableContainer  sx={{height:"65vh",backgroundColor:"#f2f0f0"}} >
                        <Table stickyHeader aria-label="sticky table" >
                            <TableHead sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>
                                <TableRow>
                                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>N° Bordereau</TableCell>
                                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Utilisateur</TableCell>
                                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Client</TableCell>
                                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Date</TableCell>

                                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Heure</TableCell>
                                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Qualité client</TableCell>

                                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Code NOP</TableCell>
                                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Devise</TableCell>
                                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Montant</TableCell>
                                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Cours</TableCell>
                                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Ctr valeur</TableCell>

                                </TableRow>

                            </TableHead>
                            <TableBody>
                                {dataToRender.map((row) => (
                                    <TableRow  key={row.id} >
                                        <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{row.num_b}</TableCell>
                                        <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{row.name?row.name:''}</TableCell>
                                        <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{row.nom?row.nom:''} {row.prenom?row.prenom:''}</TableCell>
                                        <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{row.date?row.date:''}</TableCell>
                                        <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{row.heure?row.heure:''}</TableCell>
                                        <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{row.qualite_cl?row.qualite_cl:''}</TableCell>
                                        <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{row.code_nop?row.code_nop:''}</TableCell>
                                        <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{row.nom_devise?row.nom_devise:''}</TableCell>
                                        <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{row.mt?row.mt:''}</TableCell>
                                        <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{row.cours?row.cours:''}</TableCell>
                                        <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{row.cv?row.cv:''}</TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </Paper>
            </Box>

            <div style={{ display: 'none' }}>
                <PDFContent data={selectedRow} agence={agence} data2={dataToRender} data3={info} ref={componentRef} />
            </div>
        </Box>
    );
};

export default RapportJ;