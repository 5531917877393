import { Box } from "@mui/material";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import {Button} from "@mui/material";
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useState,useEffect ,useRef} from "react";
import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import axios from "axios";
import { useReactToPrint } from "react-to-print";
import { CSVLink } from "react-csv";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import swal from "sweetalert";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Table2 from "../../../table";

import {formatNumber } from "../../../fonctions/formatNumber"
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
class PDFContent extends React.Component {
    render() {
        // Extracting props for easier access
        const {  data2,data3, agence } = this.props;
        const renderTableRows = () => {
            return (
                <tr>
                    {data3.user===1?<th>Utilisateur</th>:null}
                    <th >Date</th>
                    <th> Heure</th>
                    <th> Devise</th>
                    <th >Montant</th>
                    <th >Caisse</th>
                    <th >Bénéficiaire</th>
                    <th>Observation</th>
                </tr>
            );
        }


        const renderTableBody = () => {

            return (

                <>
                    {data2.map((row) => (
                        <tr  >
                            {data3.user === 1 ? <td>{row.user_name}</td> : null}
                            <td>{row.date_operation}</td>
                            <td>{row.heure_operation}</td>
                            <td>{row.nom_devise}</td>
                            <td>{formatNumber(row.montant)}</td>
                            <td>{row.caisse}</td>
                            <td>{row.beneficiaire}</td>
                            <td>{row.observation?row.observation:''}</td>


                        </tr>
                    ))}
                </>
            );
        };
        return (
            <div>
                {/* Agency information */}
                <Box sx={{ borderBottom: '1px solid black', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                    <h5 className="h5">{agence.nom_bureau}</h5>
                    <h5 className="h5">{agence.num_auto}</h5>
                </Box>

                {/* Header */}
                <Box
                    sx={{
                        border: '1px solid black',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '70%',
                        marginLeft: '15%',
                        marginY: '4vh',
                        borderRadius: '8px',
                        backgroundColor: '#ea5a27', // Change the background color here
                    }}
                >
                    <h2 className="h2">PASSAGE DE DEVISES</h2>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Box sx={{ marginRight: '5vw' }}>
                        <h5 className="h5">Du : {data3.du}</h5>
                    </Box>
                    <Box sx={{ }}>
                        <h5 className="h5">Au : {data3.au}</h5>
                    </Box>
                </Box>


                {/* Currency exchange data */}
                <Box className="tableau" sx={{ width: '100%' }}>
                    <table className="print-table">
                        {renderTableRows()}
                        {renderTableBody()}
                    </table>



                </Box>

                {/* Additional CSS styles for printing */}
                <style>
                    {`
            .print-table {
              width: 100%;
              border-collapse: collapse;
              margin-bottom: 10vh;
              z-index: 9;
            }
            .footer {
                margin-top: 4vh;
                border: 2px dotted #73AD21;
                align-items: center;
                width: 100%;
                justify-content: center;
                z-index: 9;
              }
            .print-table th,
            .print-table td {
              border: 1px solid black;
              padding: 8px;
              z-index: 9;
            }

            /* Additional styles specific for printing */
            @media print {
              
              .print-table {
                z-index: 9;
              }
              
              .print-table th,
              .print-table td {
                page-break-inside: avoid;
                z-index: 9;
              }
              /* Ensure the footer appears on each page */
              .footer {
                page-break-inside: avoid;
                z-index: 9;

              }
              .tableau{
                margin-bottom: 10vh; /* Add margin at the bottom of the table */

                z-index: 9;

              }
            }
          `}
                </style>


                {/* Footer */}
                <Box className="footer" sx={{ paddingTop: '1vh' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <h5 className="h5">Adresse : {agence.adresse}</h5>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Box sx={{ marginRight: '3vh' }}>
                            <h5 className="h5">Tél : {agence.tel}</h5>
                        </Box>
                        <Box>
                            <h5 className="h5">Email : {agence.email}</h5>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Box sx={{ marginRight: '3vh' }}>
                            <h5 className="h5">TP : {agence.tp}</h5>
                        </Box>
                        <Box sx={{ marginRight: '3vh' }}>
                            <h5 className="h5">IF : {agence.idfis}</h5>
                        </Box>
                        <Box>
                            <h5 className="h5">RC : {agence.rc}</h5>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <h5 className="h5">ICE : {agence.ice}</h5>
                    </Box>
                </Box>
            </div>
        );
    }
}
const Consultation = () => {
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const users=["user1","user2"];
    const [dataToRender,setDataToRender]=useState([]);
    const [dataToRender2,setDataToRender2]=useState([]);
    const [user,setUser]=useState([]);
    const [caisses,setCaisses]=useState([]);
    const [selectedRow,setSelectedRow]=useState(null);

    const [page, setPage] =useState(0);
    const [rowsPerPage, setRowsPerPage] =useState(10);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedDevise, setSelectedDevise] = useState(null);
    const [selectedDevise2, setSelectedDevise2] = useState(null);
    const [selectedDuDate, setSelectedDuDate] = useState(dayjs());
    const [selectedAuDate, setSelectedAuDate] = useState(dayjs());
    const [listcaisse,setlistcaisse]=useState([]);
    const componentRef = useRef();
    const [agence,setAgence]=useState({});
    const [shouldPrint, setShouldPrint] = useState(false);
    const [info, setInfo] = useState({
        du:'',
        au:'',
        user:'',
    });
    const [rows, setRows] = useState([]);
    const [caisses1,setCaisses1]=useState([]);
    const [caisses2,setCaisses2]=useState([]);
    const [rows2,setRows2]=useState([]);
    const [rows3,setRows3]=useState([]);
    const [rows4,setRows4]=useState([]);
    const [selectedDu, setSelectedDu] = useState(null);
    const [selectedAu, setSelectedAu] = useState(null);
    const [selectedRow1, setSelectedRow1] = useState([]);
    const [selectedRow2, setSelectedRow2] = useState([]);
    const [observation,setObservation]=useState();
    const [montant,setMontant]=useState();
    /****************************************************************functions ************************************************************************ */
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handleRowClick = (row) => {
        setSelectedRow(row);
    };
    const handlePrintev = (e) => {
        e.preventDefault();
        axios.get(`api/info-agence`).then((res) => {
            if (res.data.status === 200) {
                setAgence(res.data.message);
                setShouldPrint(true);
            }
        });

        // Add checks to ensure variables are defined
        const du = convertDateFormat(selectedDuDate);
        const au = convertDateFormat(selectedAuDate);

        setInfo((prevInfo) => ({
            ...prevInfo,
            du: du,
            au: au,
            user:user,
        }));
    };
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "Print",
        pageStyle: `
    @page {
      size: A4;
      margin: 10mm;
    }
    /* Additional styles for the printed page can be added here */
    /* Remove the pagination and URL styles */
    @media print {
      .MuiDataGrid-footerContainer {
        display: none !important;
      }
      a {
        display: none !important;
      }
    }
  `,
        header: null, // Set header to null to remove it from the PDF
        footer: null,
        // Set footer to null to remove it from the PDF
    });

    useEffect(() => {
        if (shouldPrint) {
            // Call handlePrint() when shouldPrint becomes true
            handlePrint(); // This will trigger the printing action
            setShouldPrint(false); // Reset shouldPrint back to false
        }
    }, [shouldPrint]);
    /********************************************************************************UseEffect********************************** */

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`api/passage-devise`);
                if (response.data.status === 200) {
                    const { message, user,caisses } = response.data;
                    const bor=handleDuDateChange2(selectedDuDate,message);
                    const  bor2=handleAuDateChange2(selectedAuDate,bor);
                    const msg = bor.map((op) => {
                        return {
                            'Utilisateur': op.name,
                            'Date': op.date_operation,
                            'Heure': op.heure_operation,
                            'Devise': op.nom_devise,
                            'Montant': op.montant,
                            'Caisse': op.caisse,
                            'Bénéficiaire': op.beneficiaire,
                            'Observation': op.Observation?op.Observation:'',
                        };
                    });
                    setRows(msg);
                    setDataToRender(bor2);
                    setDataToRender2(message);
                    setlistcaisse(caisses);
                    setUser(user);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();

    }, [open]);
    /*****************************************************filtre functions************************************************ */
//user
    const handleUserFilterChange = (event, newValue) => {
        setSelectedUser(newValue);
        let bor=dataToRender2;
        bor=handleUserFilterChange2( (newValue? newValue.name:''),bor);
        bor=handleDuDateChange2(selectedDuDate,bor);
        bor=handleAuDateChange2(selectedAuDate,bor);
        bor=handleDeviseFilterChange2( selectedDevise,bor);
        setDataToRender(bor);
    }
    const handleUserFilterChange2 = (newValue,bor) => {
        let filteredItems;
        if(newValue!=''){
            filteredItems = bor.filter((item) =>
                item.name===newValue
            );
        }else{
            filteredItems = dataToRender2;
        }
        return filteredItems;
    };


//du date filtre
    const convertDateFormat = (dateString) => {

        const parsedDate = dayjs(dateString, 'MM/DD/YYYY'); // Parse the date with the original format
        return parsedDate.format('YYYY/MM/DD'); // Format the date in the desired format
    };
    const convertDateFormat2 = (dateString) => {
        const parsedDate = dayjs(dateString, 'DD/MM/YYYY'); // Parse the date with the original format
        return parsedDate.format('YYYY/MM/DD'); // Format the date in the desired format
    };
    const convertDateFormat3 = (dateString) => {

        return  dayjs(dateString, 'MM/DD/YYYY'); // Parse the date with the original format
    };

    const handleDuDateChange = (newValue) => {
        console.log(newValue);
        setSelectedDuDate(newValue);
        let bor=dataToRender2;
        bor=handleUserFilterChange2( (selectedUser? selectedUser.name:''),bor);
        bor=handleDuDateChange2(newValue,bor);
        bor=handleAuDateChange2(selectedAuDate,bor);
        bor=handleDeviseFilterChange2( selectedDevise,bor);
        setDataToRender(bor);
        const msg = bor.map((op) => {
            return {
                'Utilisateur': op.name,
                'Date': op.date_operation,
                'Heure': op.heure_operation,
                'Devise': op.nom_devise,
                'Montant': op.montant,
                'Caisse': op.caisse,
                'Bénéficiaire': op.beneficiaire,
                'Observation': op.Observation?op.Observation:'',
            };
        });
        setRows(msg);
    };
    const handleDuDateChange2 = (newValue,bor) => {
        if(newValue){
            const newV=convertDateFormat(newValue);
            const filteredItems = bor.filter((item) => {
                const parsedDate = convertDateFormat2(item.date_operation);
                return new Date(parsedDate) >= new Date(newV);
            });
            return filteredItems;
        }else{

            return bor;
        }
    }
// au date functions

    const handleAuDateChange = (newValue) => {
        setSelectedAuDate(newValue);
        let bor=dataToRender2;
        bor=handleUserFilterChange2( (selectedUser? selectedUser.name:''),bor);
        bor=handleAuDateChange2(newValue,bor);
        bor=handleDuDateChange2(selectedDuDate,bor);
        bor=handleDeviseFilterChange2( selectedDevise,bor);
        setDataToRender(bor);
        const msg = bor.map((op) => {
            return {
                'Utilisateur': op.name,
                'Date': op.date_operation,
                'Heure': op.heure_operation,
                'Devise': op.nom_devise,
                'Montant': op.montant,
                'Caisse': op.caisse,
                'Bénéficiaire': op.beneficiaire,
                'Observation': op.Observation?op.Observation:'',
            };
        });
        setRows(msg);
    };
    const handleAuDateChange2 = (newValue,bor) => {
        if(newValue){
            const newV=convertDateFormat(newValue);
            const filteredItems = bor.filter((item) => {
                const parsedDate = convertDateFormat2(item.date_operation);
                return new Date(parsedDate) <= new Date(newV);
            });
            return filteredItems;
        }else{

            return bor;
        }
    }
// devise filtre functions
    const handleDeviseFilterChange = (event, newValue) => {
        setSelectedDevise(newValue);
        let bor=dataToRender2;
        bor=handleAuDateChange2(selectedAuDate,bor);
        bor=handleDuDateChange2(selectedDuDate,bor);
        bor=handleDeviseFilterChange2( newValue,bor);
        bor=handleDeviseFilterChange3( selectedDevise2,bor);

        setDataToRender(bor);
        const msg = bor.map((op) => {
            return {
                'Utilisateur': op.name,
                'Date': op.date_operation,
                'Heure': op.heure_operation,
                'Devise': op.nom_devise,
                'Montant': op.montant,
                'Caisse': op.caisse,
                'Bénéficiaire': op.beneficiaire,
                'Observation': op.Observation?op.Observation:'',
            };
        });
        setRows(msg);
    };
    const handleDeviseFilterChangee = (event, newValue) => {
        setSelectedDevise2(newValue);
        let bor=dataToRender2;
        bor=handleAuDateChange2(selectedAuDate,bor);
        bor=handleDuDateChange2(selectedDuDate,bor);
        bor=handleDeviseFilterChange3( newValue,bor);
        bor=handleDeviseFilterChange2( selectedDevise,bor);

        setDataToRender(bor);
        const msg = bor.map((op) => {
            return {
                'Utilisateur': op.name,
                'Date': op.date_operation,
                'Heure': op.heure_operation,
                'Devise': op.nom_devise,
                'Montant': op.montant,
                'Caisse': op.caisse,
                'Bénéficiaire': op.beneficiaire,
                'Observation': op.Observation?op.Observation:'',
            };
        });
        setRows(msg);
    };

    const handleDeviseFilterChange2 = ( newValue,bor) => {
        if(newValue){
            const filteredItems = bor.filter((item) =>
                item.caisse===newValue.designation
            );

            return filteredItems;
        }else{

            return bor;
        }


    }
    const handleDeviseFilterChange3 = ( newValue,bor) => {
        if(newValue){
            const filteredItems = bor.filter((item) =>
                item.beneficiaire===newValue.designation
            );

            return filteredItems;
        }else{

            return bor;
        }


    }
    /**********************************END FILTRE FUNCTIONS*************************************** */
    /***************************************************operatios ********************************* */
    const handleDuChange = (event, newValue) => {
        setSelectedDu(newValue);

    };
    const handleAuChange = (event, newValue) => {
        setSelectedAu(newValue);

    };
    const handleRowClick1 = (row) => {
        setMontant(row.montant);
        setSelectedRow1(row);
    };

    const handleRowClick2 = (row) => {
        setMontant(row.montant);
        setSelectedRow2(row);
    };
    const handleObservationChange = (event) => {
        const newValue = event.target.value;
        setObservation(newValue);
    };
    const handleMontantChange = (event) => {
        const newValue = event.target.value;
        setMontant(newValue);
    };
    const handleBLeft = () => {
        if(montant){
            if(selectedAu){
                if(selectedRow2){
                    if(selectedRow2.montant>=montant){
                        const da={
                            'id_devise':selectedRow2.id_devise,
                            'montant':parseFloat(montant),
                            'cv_ac':(parseFloat(selectedRow2.id_devise)/parseFloat(selectedRow2.montant))*parseFloat(montant),
                            'nom_devise':selectedRow2.nom_devise,
                        };
                        const rw=mergeRowsByDevise(rows2, da);
                        setRows2(rw);
                        const updatedStat = rows3.map((item) => {
                            if (item.id_devise === selectedRow2.id_devise) {
                                // If the id matches, create a new object with the updated value
                                return { ...item, montant:parseFloat(selectedRow2.montant)-parseFloat(montant),
                                    cv_ac:parseFloat(selectedRow2.id_devise)-(parseFloat(selectedRow2.id_devise)/parseFloat(selectedRow2.montant))*parseFloat(montant), };
                            } else {
                                // For other rows, keep the existing object as-is
                                return item;
                            }
                        });
                        const filteredStat = updatedStat.filter((item) => item.montant !== 0);
                        setSelectedRow2([]);
                        setMontant("");
                        setRows3(filteredStat);
                    }else{
                        swal('',"Opération interdit.",'warning');
                    }

                }else{
                    swal('',"Opération interdit.",'warning');
                }
            }else{
                swal('',"Opération interdit.",'warning');
            }
        }else{
            swal('',"Opération interdit.",'warning');
        }
    };
    const handleBRight = () => {
        if(montant){
            if(selectedAu){
                if(selectedRow1){
                    if(selectedRow1.montant>=montant){
                        const da={
                            'id_devise':selectedRow1.id_devise,
                            'montant':parseFloat(montant),
                            'cv_ac':(parseFloat(selectedRow1.id_devise)/parseFloat(selectedRow1.montant))*parseFloat(montant),
                            'nom_devise':selectedRow1.nom_devise,
                        };
                        const rw=mergeRowsByDevise(rows3, da);
                        setRows3(rw);
                        const updatedStat = rows2.map((item) => {
                            if (item.id_devise === selectedRow1.id_devise) {
                                // If the id matches, create a new object with the updated value
                                return { ...item, montant:parseFloat(selectedRow1.montant)-parseFloat(montant),
                                    cv_ac:parseFloat(selectedRow1.id_devise)-(parseFloat(selectedRow1.id_devise)/parseFloat(selectedRow1.montant))*parseFloat(montant), };
                            } else {
                                // For other rows, keep the existing object as-is
                                return item;
                            }
                        });
                        const filteredStat = updatedStat.filter((item) => item.montant !== 0);
                        setSelectedRow1([]);
                        setMontant("");
                        setRows2(filteredStat);
                    }else{
                        swal('',"Opération interdit.",'warning');
                    }
                }else{
                    swal('',"Opération interdit.",'warning');
                }
            }else{
                swal('',"Opération interdit.",'warning');
            }
        }else{
            swal('',"Opération interdit.",'warning');
        }
    };
    const submit=(e)=>{

        e.preventDefault();


        const data={
            idCaisse: selectedDu.id,
            id_beneficiaire: selectedAu.id,
            observation:observation,
            rows: rows3.map((row) => ({
                id_devise: row.id_devise,
                montant: row.montant,
                contre_valeur: row.cv_ac,

            })),

        }
        swal({
            title: '',
            text: 'Voulez vous vraiment valider cette operation ?',
            icon: 'warning',
            buttons: ['Annuler', 'Oui'],
            dangerMode: true,
        }).then((confirmed) => {
            if (confirmed) {
                // User clicked "Oui," proceed with form submission
                axios
                    .post('api/passage-devise', data)
                    .then((res) => {
                        // ... Handle the response from the server ...
                        if (res.data.status === 200) {
                            setOpen(false);
                            setRows3([]);
                            selectedAu(null);
                            setObservation(null);
                            setMontant(null);
                        } else if (res.data.status === 400) {
                            swal(
                                '',
                                "Veuillez respecter les coupures.",
                                'warning'
                            );
                        }
                    })
                    .catch((error) => {
                        // Handle any error that occurred during the request
                        console.error('Error submitting form:', error);
                    });
            }
        });




    }
    /***********************************************useeffect****************************************************** */
    function mergeRowsByDevise(rows, newDa) {
        const updatedRows = rows.map(row => {
            if (row.id_devise === newDa.id_devise) {
                // If the id_devise already exists, update the row with the new data
                return {
                    ...row,
                    montant: row.montant + newDa.montant,
                    cv_ac: (parseFloat(row.id_devise) / parseFloat(row.montant)) * (row.montant + newDa.montant)
                };
            } else {
                // If the id_devise doesn't exist, keep the original row
                return row;
            }
        });

        // If the id_devise is not found in the existing rows, add a new entry
        const foundIndex = updatedRows.findIndex(row => row.id_devise === newDa.id_devise);
        if (foundIndex === -1) {
            updatedRows.push(newDa);
        }

        return updatedRows;
    }


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`api/detail-caisse`);
                if (response.data.status === 200) {
                    const { caisse,message,caisses } = response.data;
                    setSelectedDu(caisse);
                    setCaisses1((cai)=>[...cai,caisse]);
                    const cai=caisse;

                    setRows(message);
                    setRows2(message);
                    setCaisses2(caisses);

                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();

    }, [open]);


    useEffect(() => {
// Calculate the sum of cv_ac for each unique date_achat and nom_devise
        const result = rows4.reduce((acc, item) => {
            const { montant,nom_devise } = item;
            const key = `${nom_devise}`;

            if (acc[key]) {
                acc[key] += montant;
            } else {
                acc[key] = montant;
            }

            return acc;
        }, {});

// Format the data into an array of objects with date_achat, nom_devise, and cv_ac_sum
        const formattedResult = Object.keys(result).map((key) => {
            const [nom_devise] = key.split("-");
            return {
                nom_devise,
                montant: result[key],
            };
        });

// Update the state with the processed data
        setRows4(formattedResult);
    }, [rows4]);
    /**************************************end operation******************************************* */
    const nomFichier=`PASSAGE_DE_DEVISES-Du_${convertDateFormat(selectedDuDate)}-Au_${convertDateFormat(selectedAuDate)}.csv`
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setRows3([]);
        setSelectedAu(null);
        setObservation(null);
        setMontant(null);
    };
    const colonnesEntete = [
        { id: 'date_operation', label: "Date", align: 'left' },
        { id: 'heure_operation', label: 'Heure', align: 'left' },
        { id: 'nom_devise', label: 'Devise', align: 'left' },
        { id: 'montant', label: 'Montant', align: 'right' },
        { id: 'observation', label: 'Observation', align: 'center' },
        { id: 'user_name', label: 'Utilisateur', align: 'left' },
        { id: 'caisse', label: 'Caisse', align: 'left' },
        { id: 'beneficiaire', label: 'Bénéficiaire', align: 'left' },
    ];
    const colsToShow=[1,2, 3, 4, 5,6,9,10];
    return (
        <Box m="20px" sx={{
            "& .bartitle":{
                marginY : "1.5rem",
            }
        }}>

            <Box display="flex" sx={{
                "& .MuiFormControl-root" : {
                    marginRight:"0.5vw",
                    width:"14vw",

                }}}>
                <Box>
                    <Autocomplete className="Autocomplete"
                                  size="small"
                                  id="combo-box-demo"
                                  value={selectedDevise}
                                  getOptionLabel={(option) => option.designation}
                                  options={listcaisse}
                                  onChange={handleDeviseFilterChange}
                                  renderInput={(params) => <TextField {...params} label="Depuis" />}
                    />
                </Box>
                <Box>
                    <Autocomplete className="Autocomplete"
                                  size="small"
                                  id="combo-box-demo"
                                  value={selectedDevise2}
                                  options={listcaisse}
                                  onChange={handleDeviseFilterChangee}
                                  getOptionLabel={(option) => option.designation}
                                  renderInput={(params) => <TextField {...params} label="Vers" />}
                    />
                </Box>
            </Box>
            <Box display="flex" justifyContent="space-between"
                 sx={{
                     "& .MuiFormControl-root" : {
                         marginRight:"0.5vw",
                         width:"14vw",

                     },
                     "& .MuiAutocomplete-root":{
                         marginTop:"8px"
                     },
                     "& .buttons" : {
                         color: colors.grey[200],
                         backgroundColor : colors.primary[400],
                         margin: "0.5rem 0.5rem -0.2rem 0.5rem",
                         fontSize: "0.7rem",
                         height: "2.5rem",
                         paddingX: "1.2rem",
                         paddingY: "0.2rem"
                     },
                     "& .buttons:hover" : {
                         color: colors.primary[400],
                         backgroundColor : colors.grey[200],

                     },
                     "& .span1" : {


                         marginLeft: "0.5vw",

                     },
                     "& .csss" : {


                         minWidth:'14vw',

                     }
                 }}
            >
                <Box display="flex">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                            <DatePicker
                                label="DU"
                                value={selectedDuDate}
                                onChange={handleDuDateChange}
                                format="DD/MM/YYYY"
                                className='csss'
                                sx={{
                                    '& .MuiInputBase-input': {
                                        padding: '10px 14px',
                                        fontSize: '0.875rem',
                                    },
                                }}
                            />
                        </DemoContainer>
                    </LocalizationProvider>




                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                        <DemoContainer components={['DatePicker']}>
                            <DatePicker label="AU"
                                        value={selectedAuDate}
                                        onChange={handleAuDateChange}
                                        format="DD/MM/YYYY"
                                        className='csss'
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                padding: '10px 14px',
                                                fontSize: '0.875rem',
                                            },
                                        }}
                            />
                        </DemoContainer>
                    </LocalizationProvider>

                </Box>
                < Box>
                    <Button className="buttons" onClick={handleClickOpen}><AddCircleOutlinedIcon/><span className="span1">Nouveau</span> </Button>
                    <Button className="buttons" onClick={handlePrintev}><FindInPageOutlinedIcon/><span className="span1">Aperçu</span> </Button>
                    <CSVLink data={rows} filename={nomFichier}><Button className="buttons"><FileDownloadIcon/><span className="span1">Exporter</span></Button></CSVLink>

                </Box>

            </Box>
            <Box>
                <Table2 dataToRender={dataToRender} headCells={colonnesEntete} colsToShow={colsToShow} cols={"num_bac"}/>


            </Box>
            <Box display="flex" justifyContent="space-between" >
                <Box display="flex"></Box>

            </Box>
            <div style={{ display: 'none' }}>
                <PDFContent  agence={agence} data2={dataToRender} data3={info}  ref={componentRef} />
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
                PaperProps={{
                    style: {
                        width: '70vw',
                        maxWidth: '70vw',
                        height:'90vh',
                        maxHeight:'90vh',
                    },
                }}
            >
                <AppBar sx={{ position: 'relative', bgcolor: '#0f2479' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            PASSAGE DEVISE
                        </Typography>

                    </Toolbar>
                </AppBar>
                <List sx={{marginX:'2vw'}}>
                    <Box display="flex" justifyContent="space-between"

                    >
                        <Box width="35vw">
                            <Box
                                marginTop="2rem"
                                height="75vh"
                                sx={{
                                    "& .MuiDataGrid-root": {
                                        border: "none",
                                    },
                                    "& .MuiDataGrid-cell": {
                                        borderBottom: "none",
                                    },
                                    "& .name-column--cell": {
                                        color: colors.greenAccent[300],
                                    },
                                    "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: colors.blueAccent[700],
                                        borderBottom: "none",
                                    },
                                    "& .MuiDataGrid-virtualScroller": {
                                        backgroundColor: colors.primary[400],
                                    },
                                    "& .MuiDataGrid-footerContainer": {
                                        borderTop: "none",
                                        backgroundColor: colors.blueAccent[700],
                                    },
                                    "& .MuiCheckbox-root": {
                                        color: `${colors.greenAccent[200]} !important`,
                                    },
                                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                        color: `${colors.grey[100]} !important`,
                                    },
                                }}
                            >
                                <Autocomplete
                                    disabled
                                    size="small"
                                    id="combo-box-demo"
                                    options={caisses1}
                                    value={selectedDu}
                                    onChange={handleDuChange} // Update this line
                                    getOptionLabel={(option) => option.designation}
                                    renderInput={(params) => <TextField {...params} label="Du" />}
                                />
                                <Paper >
                                    <TableContainer  sx={{height:"65vh",marginTop:"3vh",backgroundColor:"#f2f0f0"}} >
                                        <Table stickyHeader aria-label="sticky table" >
                                            <TableHead sx={{backgroundColor:"#a4a9fc"}}>
                                                <TableRow >
                                                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Devise</TableCell>
                                                    <TableCell align='right' sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Montant</TableCell>
                                                </TableRow>

                                            </TableHead>
                                            <TableBody>
                                                {rows2.map((row) => (
                                                    <TableRow
                                                        key={row.id_devise}
                                                        onClick={()=>handleRowClick1(row)}
                                                        sx={{
                                                            backgroundColor:
                                                                selectedRow1 && selectedRow1.id_devise === row.id_devise ? '#ccc' : 'inherit',
                                                        }}
                                                    >
                                                        <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{row.nom_devise}</TableCell>
                                                        <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}} align='right'>{formatNumber(row.montant)}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                </Paper>


                            </Box>
                        </Box>
                        <Box width="20vw" sx={{display: "flex",
                            justifyContent: "center",
                            alignItems: "center"}}>
                            <Box>
                                <Box sx={{display: "flex",
                                    justifyContent: "center",marginBottom:"2vh"
                                }}>
                                    <TextField
                                        whidth="80%"
                                        id="outlined-multiline-static"
                                        label="Observation"
                                        multiline
                                        rows={4}
                                        value={observation}
                                        onChange={handleObservationChange}
                                    />
                                </Box>
                                <Box sx={{display:"flex",justifyContent:"center"}}>
                                    <Button className="buttons"  onClick={handleBLeft} ><ArrowBackIcon sx={{whidth:"25%"}}/></Button>
                                    <TextField
                                        sx={{whidth:"50%"}}
                                        id="filled-multiline-flexible"
                                        label=""
                                        variant="filled"
                                        value={montant}
                                        onChange={handleMontantChange}
                                        type="number"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                    <Button onClick={handleBRight} className="buttons"><ArrowForwardIcon sx={{whidth:"25%"}}/></Button>
                                </Box>
                                <Box sx={{display:"flex",justifyContent:"center",marginTop:"10vh","& .MuiFormControl-root" : {
                                        marginRight:"1rem",
                                        width:"6rem",

                                    },
                                    "& .MuiAutocomplete-root":{
                                        marginTop:"8px"
                                    },
                                    "& .buttons" : {
                                        color: colors.grey[200],
                                        backgroundColor : colors.primary[700],
                                        margin: "0.5rem",
                                        marginTop:"1rem",
                                        fontSize: "0.8rem",
                                        height: "3.4rem" ,
                                        paddingX:"1.5rem"
                                    },
                                    "& .buttons:hover" : {
                                        color: colors.primary[400],
                                        backgroundColor : colors.grey[200],

                                        margin: "0.5rem",
                                        marginTop:"1rem",
                                        fontSize: "0.9rem"
                                    },
                                    "& .span1" : {



                                    }}}>
                                    <Button className="buttons" onClick={submit}><span className="span1">Valider</span> </Button>
                                </Box>
                            </Box>
                        </Box>
                        <Box
                            width="35vw">
                            <Box
                                marginTop="2rem"
                                height="75vh"
                                sx={{
                                    "& .MuiDataGrid-root": {
                                        border: "none",
                                    },
                                    "& .MuiDataGrid-cell": {
                                        borderBottom: "none",
                                    },
                                    "& .name-column--cell": {
                                        color: colors.greenAccent[300],
                                    },
                                    "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: colors.blueAccent[700],
                                        borderBottom: "none",
                                    },
                                    "& .MuiDataGrid-virtualScroller": {
                                        backgroundColor: colors.primary[400],
                                    },
                                    "& .MuiDataGrid-footerContainer": {
                                        borderTop: "none",
                                        backgroundColor: colors.blueAccent[700],
                                    },
                                    "& .MuiCheckbox-root": {
                                        color: `${colors.greenAccent[200]} !important`,
                                    },
                                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                        color: `${colors.grey[100]} !important`,
                                    },
                                }}
                            >
                                <Autocomplete
                                    size="small"
                                    id="combo-box-demo"
                                    options={caisses2}
                                    value={selectedAu}
                                    onChange={handleAuChange} // Update this line
                                    getOptionLabel={(option) => option.designation}
                                    renderInput={(params) => <TextField {...params} label="Au" />}
                                />
                                <Paper >
                                    <TableContainer  sx={{height:"65vh",marginTop:"3vh",backgroundColor:"#f2f0f0"}} >
                                        <Table stickyHeader aria-label="sticky table" >
                                            <TableHead sx={{backgroundColor:"#a4a9fc"}}>
                                                <TableRow >
                                                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Devise</TableCell>
                                                    <TableCell align='right' sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Montant</TableCell>
                                                </TableRow>

                                            </TableHead>
                                            <TableBody>
                                                {rows3.map((row) => (
                                                    <TableRow  key={row.id_devise}
                                                               onClick={()=>handleRowClick2(row)}
                                                               sx={{
                                                                   backgroundColor:
                                                                       selectedRow2 && selectedRow2.id_devise === row.id_devise ? '#ccc' : 'inherit',
                                                               }} >
                                                        <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{row.nom_devise}</TableCell>
                                                        <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}} align='right'>{formatNumber(row.montant)}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                </Paper>




                            </Box>
                        </Box>

                    </Box>
                </List>
            </Dialog>
        </Box>
    );
};

export default Consultation;
