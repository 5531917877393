import * as React from 'react';
import { Box,  useTheme } from "@mui/material";

import { tokens } from "../../theme";
import Header from "../../components/Header";

import {Tabs,Tab,AppBar} from "@mui/material";
import Achat from "../../components/GestionAchat/AchatClient/index";
import Consultation from "../../components/GestionAchat/ConsultationAchats/index";
import Bordereauxerrones from "../../components/GestionAchat/BordereauxErrones/index";
import Statistiques from "../../components/GestionAchat/Statistique/index";
import "../index.css";
const Achatclients = () => {
  const theme = useTheme();
  
  const [value,setValue]=React.useState(0);
  const handleTabs=(r,val)=>{
    console.warn(val)
    setValue(val)
  }
  return (
    <Box className="gestion-devise-container">
      <Header title="ACHATS CLIENTS"  />
      <Box>
        <AppBar position='static'   >
          <Tabs value={value} onChange={handleTabs} >
            <Tab label="Achat" />
            <Tab label="Consultation"/>
            <Tab label="Bordereaux erronés"/>
            <Tab label="Statistiques"/>
          </Tabs>
        </AppBar>
        
      </Box>
        
      <Box className="gestion-devise-content">
         
      <TabPanel value={value} index={0}><Achat/></TabPanel>
      <TabPanel value={value} index={1}><Consultation/></TabPanel>
      <TabPanel value={value} index={2}><Bordereauxerrones/></TabPanel>
      <TabPanel value={value} index={3}><Statistiques/></TabPanel>
      </Box>
      
    </Box>
  );
  
};
const TabPanel = (props) => {
  const {children,value,index}=props;
  return (
    
      value===index && (
        <div>{children}</div>
      )
      
    
  );
};
export default Achatclients;
