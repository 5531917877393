import { Box } from "@mui/material";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import {Button} from "@mui/material";
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import * as React from 'react';
import axios from "axios";
import {useEffect, useState,useRef} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import { useReactToPrint } from "react-to-print";
import {formatNumber} from '../../../fonctions/formatNumber'
import PDFDotation from '../PDFContent/Dotation/index'
class PDFContent extends React.Component {
    render() {
        // Extracting props for easier access
        const { data, data2,data3, agence,selectedDotation,mois,annee } = this.props;
        return (
            <PDFDotation data={data} data2={data2} data3={data3} agence={agence} selectedDotation={selectedDotation} mois={mois} annee={annee}/>
        );
    }
}
const RapportJ = () => {

    const [page, setPage] =useState(0);
    const [rowsPerPage, setRowsPerPage] =useState(10);
    const [sumCv, setSumCv] = useState();
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedDevise, setSelectedDevise] = useState(null);
    const [selectedDuDate, setSelectedDuDate] = useState(dayjs());
    const [selectedAuDate, setSelectedAuDate] = useState(dayjs());
    const [shouldPrint, setShouldPrint] = useState(false);
    const [dataToRender, setDataToRender] = useState([]);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const email=localStorage.getItem("auth_email");
    const [user,setuser]=useState([]);
    const [agence,setAgence]=useState({});
    const componentRef = useRef();
    const [selectedRow, setSelectedRow] = useState(null);
    const [info, setInfo] = useState({
        du:'',
        au:'',
        utilisateur:'',
        devise:'',
        etat:'',
        user:'',
    });

    const [bool,setBool]=useState(true);
    const [selectedDate, setSelectedDate] = useState(new Date().getFullYear());
    const [selectedMonth, setSelectedMonth] = useState('01');
    const startYear = 2020;
    const endYear = new Date().getFullYear();
    const years = Array.from({ length: endYear - startYear + 1 }, (_, index) => endYear - index);
    const mois = [
        { index: '01', name: 'Janvier' },
        { index: '02', name: 'Février' },
        { index: '03', name: 'Mars' },
        { index: '04', name: 'Avril' },
        { index: '05', name: 'Mai' },
        { index: '06', name: 'Juin' },
        { index: '07', name: 'Juillet' },
        { index: '08', name: 'Août' },
        { index: '09', name: 'Septembre' },
        { index: '10', name: 'Octobre' },
        { index: '11', name: 'Novembre' },
        { index: '12', name: 'Décembre' },
    ];
    const getCurrentMonth = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        return `${year}-${month}`;
    };
    const defaultValue = getCurrentMonth(); // Get the current month in "YYYY-MM" format

    const releve = [
        { index: '1', name: 'Dotation pour voyages personnels' },
        { index: '2', name: "Dotation pour émigration à l'étranger" },
        { index: '3', name: 'Dotation Départ scolarité' },
        { index: '4', name: "Allocation pour mission et stage  à l'étranger" },
    ];
    const [selectedDotation,setSelectedDotation]=useState({ index: '1', name: 'Dotation pour voyages personnels' });
    const etat = [
        { index: '1', name: 'Par Devise' },
        { index: '2', name: 'Par Contre Valeur' },
    ];
    const [selectedEtat,setSelectedEtat]=useState({ index: '1', name: 'Par Devise' });
    /****************************************************************functions************************************************** */
    const handlePrintev = (e) => {
        e.preventDefault();
        axios.get(`api/info-agence`).then((res) => {
            if (res.data.status === 200) {
                setAgence(res.data.message);
                setShouldPrint(true);
            }
        });

        // Add checks to ensure variables are defined
        const du = convertDateFormat(selectedDuDate);
        const au = convertDateFormat(selectedAuDate);
        const utilisateur = selectedUser ? selectedUser.name : '--all--';
        const devise = selectedDevise ? selectedDevise : '--all--';
        const etat = selectedEtat ? selectedEtat : '';

        setInfo((prevInfo) => ({
            ...prevInfo,
            du: du,
            au: au,
            utilisateur: utilisateur,
            devise: devise,
            etat: etat,
            user:user,
        }));
    };
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "Print",
        pageStyle: `
    @page {
      size: A4;
      margin: 10mm;
    }
    /* Additional styles for the printed page can be added here */
    /* Remove the pagination and URL styles */
    @media print {
      .MuiDataGrid-footerContainer {
        display: none !important;
      }
      a {
        display: none !important;
      }
    }
  `,
        header: null, // Set header to null to remove it from the PDF
        footer: null,
        // Set footer to null to remove it from the PDF
    });
    const handleRowClick = (row) => {
        setSelectedRow(row);
    };
    useEffect(() => {
        if (shouldPrint) {
            // Call handlePrint() when shouldPrint becomes true
            handlePrint(); // This will trigger the printing action
            setShouldPrint(false); // Reset shouldPrint back to false
        }
    }, [shouldPrint]);
    const handleEtatChange = (event, newValue) => {
        setSelectedEtat(newValue);
    }
    const handleReleveChange = (event, newValue) => {
        setSelectedDotation(newValue);
        console.log(newValue);

        setBool(true);
    }
    /*************************************************************end functions********************************************** */
    /********************************************************************************UseEffect********************************** */
    useEffect(() => {
        if(bool){
            const an=selectedDate;
            const mo=selectedMonth;
            const re=selectedDotation.name;

            const fetchData = async () => {
                try {
                    const response = await axios.get(`api/dotation?annee=${an}&mois=${mo}&&dotation=${re}`);
                    if (response.data.status === 200) {
                        const { message } = response.data;
                        console.log(message);
                        setDataToRender(message);
                        setBool(false);
                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            };

            fetchData();
        }
    }, [bool]);


    /****************************************************************************end UseEffect********************************** */

    const renderTableRows = () => {
        if(selectedDotation.index==='1'){
            return (
                <TableRow>
                    <TableCell  sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Identité du bénéficiaire</TableCell>
                    <TableCell  sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Numéro de la CNI</TableCell>
                    <TableCell  sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Numéro du Passport</TableCell>

                    <TableCell  sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }} align='right'>Montant accordé</TableCell>
                    <TableCell  sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Enfant Mineur</TableCell>

                    <TableCell  sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Date d'actrois de la dotation</TableCell>


                </TableRow>
            );
        }else if(selectedDotation.index==='2'){
            return (
                <TableRow>
                    <TableCell  sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Identité du bénéficiaire</TableCell>
                    <TableCell  sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Numéro de la CNI</TableCell>
                    <TableCell  sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }} align='right'>Montant accordé</TableCell>
                    <TableCell  sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Date d'actroi</TableCell>
                </TableRow>
            );
        }else if(selectedDotation.index==='3'){
            return (
                <TableRow>
                    <TableCell  sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Nom et prénom de l'étudiant</TableCell>
                    <TableCell  sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Numéro du Passport</TableCell>
                    <TableCell  sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Numéro de la CNI</TableCell>
                    <TableCell  sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }} align='right'>Montant accordé</TableCell>
                    <TableCell  sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Date d'actroi</TableCell>
                </TableRow>
            );
        }else if(selectedDotation.index==='4'){
            return (
                <TableRow>
                    <TableCell  sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Identité du bénéficiaire</TableCell>
                    <TableCell  sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Numéro de la CNI</TableCell>
                    <TableCell  sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Numéro du Passport</TableCell>
                    <TableCell  sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }} align='right'>Montant accordé</TableCell>
                    <TableCell  sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Enfant Mineur</TableCell>
                    <TableCell  sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Date d'actrois de la dotation</TableCell>

                </TableRow>
            );
        }
    }


    const renderTableBody = () => {
        if(selectedDotation.index==='1'){
            return (

                <TableBody>
                    {dataToRender.map((row) => (
                        <TableRow   >
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{row.nom} {row.prenom}</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{row.num_cni}</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{row.n_pid}</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}} align='right'>{formatNumber(row.mt_ac)}</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{row.mineur==1?'Oui':'Non'}</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{row.date_vc}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            );
        }else if(selectedDotation.index==='2'){
            return (

                <TableBody>
                    {dataToRender.map((row) => (
                        <TableRow   >
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{row.nom} {row.prenom}</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{row.n_pid}</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}} align='right'>{formatNumber(row.mt_ac)}</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{row.date_vc}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            );
        }else if(selectedDotation.index==='3'){
            return (

                <TableBody>
                    {dataToRender.map((row) => (
                        <TableRow   >
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{row.nom} {row.prenom}</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{row.n_pid}</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{row.num_cni}</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}} align='right'>{formatNumber(row.mt_ac)}</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{row.date_vc}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            );
        }else{
            return (

                <TableBody>
                    {dataToRender.map((row) => (
                        <TableRow   >
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{row.nom} {row.prenom}</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{row.num_cni}</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{row.n_pid}</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}} align='right'>{formatNumber(row.mt_ac)}</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{row.mineur==1?'Oui':'Non'}</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{row.date_vc}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            );
        }
    };
    const renderInputs = () => {
        return (
            <>

                <Box display="flex" justifyContent="space-between"
                     sx={{
                         "& .MuiFormControl-root" : {
                             marginRight:"1rem",
                             width:"20vw",

                         },
                         "& .MuiAutocomplete-root":{
                             marginTop:"8px"
                         },
                         "& .buttons" : {
                             color: colors.grey[200],
                             backgroundColor : colors.primary[400],

                         },
                         "& .buttons:hover" : {
                             color: colors.primary[400],
                             backgroundColor : colors.grey[200],

                         },
                         "& .span1" : {


                             marginLeft: "0.5rem",

                         }
                     }}
                >
                    <Box display="flex">

                        <TextField
                            size="small"
                            style={{ marginTop: '0.5rem', width: '10vw' }}
                            type="year"
                            id="start"
                            name="start"
                            onChange={handleDateFilterChange}
                            select
                            SelectProps={{
                                native: true,
                            }}
                        >
                            {years.map((year) => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            ))}
                        </TextField>

                        <TextField
                            size="small"
                            style={{ marginTop: '0.5rem', width: '12vw' }}
                            type="month"
                            id="start"
                            onChange={handleMonthFilterChange}
                            name="start"
                            select
                            SelectProps={{
                                native: true,
                            }}
                        >
                            {mois.map((moisName, index) => (
                                <option key={moisName.name} value={index + 1}>
                                    {moisName.name}
                                </option>
                            ))}
                        </TextField>
                        <Autocomplete className="Autocomplete"
                                      size="small"
                                      id="combo-box-demo"
                                      value={selectedDotation}
                                      options={releve}
                                      onChange={handleReleveChange}
                                      getOptionLabel={(option) => option.name}
                                      renderInput={(params) => <TextField {...params} label="Les dotations délévrées" />}
                                      disableClearable={true}
                        />

                    </Box>

                    <Box
                        sx={{display:"flex",justifyContent:'center',justifyItems:'center',"& .buttons" : {
                                color: colors.grey[200],
                                backgroundColor : colors.primary[400],
                                marginTop:"0.5rem"                            },
                            "& .buttons:hover" : {
                                color: colors.primary[400],
                                backgroundColor : colors.grey[200],

                            },}}
                    >       <Button className="buttons" onClick={handlePrintev}><FindInPageOutlinedIcon/><span className="span1">Aperçu</span> </Button>
                    </Box>

                </Box></>
        );
    };

    const [defaultMonth, setDefaultMonth] = useState('');

    useEffect(() => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so we add 1 and pad with '0' if necessary
        setDefaultMonth(`${year}-${month}`);
    }, []);
    /*****************************************************filtre functions************************************************ */
    const convertDateFormat = (dateString) => {

        const parsedDate = dayjs(dateString, 'MM/DD/YYYY'); // Parse the date with the original format
        return parsedDate.format('YYYY/MM/DD'); // Format the date in the desired format
    };
    const convertDateFormat2 = (dateString) => {
        const parsedDate = dayjs(dateString, 'DD/MM/YYYY'); // Parse the date with the original format
        return parsedDate.format('YYYY/MM/DD'); // Format the date in the desired format
    };
    const handleMonthFilterChange = (event) => {
        let cc=event.target.value;
        setSelectedMonth(cc);

        setBool(true);
    };

    const handleDateFilterChange = (event) => {
        setSelectedDate(event.target.value);
        setBool(true);


    };

    /**********************************END FILTRE FUNCTIONS*************************************** */









    return (
        <Box  sx={{width: "95%",marginX: "2.5%",
            "& .bartitle":{
                marginY : "1.5rem",
            }
        }}>

            {renderInputs()}
            <Box zIndex="1"
                 height="75vh"

            >
                <Paper sx={{width: "100%"}}>
                    <TableContainer  sx={{height:"65vh",marginTop:"3vh",backgroundColor:"#f2f0f0",overflowX: "auto"}} >
                        <Table stickyHeader aria-label="sticky table" >
                            <TableHead  sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>
                                {renderTableRows()}

                            </TableHead>
                            {renderTableBody()}
                        </Table>
                    </TableContainer>

                </Paper>
            </Box>

            <div style={{ display: 'none' }}>
                <PDFContent data={dataToRender} agence={agence} data2={dataToRender} data3={info} selectedDotation={selectedDotation} ref={componentRef} mois={selectedMonth} annee={selectedDate}/>
            </div>
        </Box>
    );
};

export default RapportJ;