import { Box } from "@mui/material";
import { DataGrid} from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataContacts } from "../../data/mockData";
import Header from "../Header";
import { useTheme } from "@mui/material";
import {Button} from "@mui/material";
import { hover } from "@testing-library/user-event/dist/hover";
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import { Padding } from "@mui/icons-material";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useState } from "react";
import * as React from 'react';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import SystemUpdateAltOutlinedIcon from '@mui/icons-material/SystemUpdateAltOutlined';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {useEffect,useRef} from 'react';
import swal from 'sweetalert';
import axios from "axios";
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { useReactToPrint } from "react-to-print";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
const Statistiques = () => {
    const componentRef = useRef();
    const [agence,setAgence]=useState({});
    const [shouldPrint, setShouldPrint] = useState(false);
  const history=useHistory();
  const [banques,setBanques]=useState([]);
  const [dataToRender,setDataToRender]=useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [infoAgence,setinfoAgence]=useState({
    nom_bureau:'',
    num_auto:'',
    adresse:'',
    tel:'',
    ville:'',
    idfis:'',
    tp:'',
    email:'',
    cnss:'',
    ice:'',
    rc:'',
    CcompteAchat:'',
    CcompteVente:'',
    logo:'',
    confirme:'',
    nom:'',
    motdpasse:'',
    email2:'',
  })
  const [infoAgence2,setinfoAgence2]=useState({
    id: "",
    nom_bureau:'',
    num_auto:'',
    adresse:'',
    tel:'',
    ville:'',
    idfis:'',
    tp:'',
    email:'',
    cnss:'',
    ice:'',
    rc:'',
    CcompteAchat:'',
    CcompteVente:'',
    logo:'',
  });
  const [bool,setBool]=useState(1);
  /********************************************functions*********************************************** */
  const handlePrintev = (e) => {
    e.preventDefault();
    axios.get(`api/info-agence`).then((res) => {
      if (res.data.status === 200) {
        setAgence(res.data.message);
        setShouldPrint(true);
      }
    });
  
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Print",
    pageStyle: `
      @page {
        size: A4;
        margin: 10mm;
      }
      /* Additional styles for the printed page can be added here */
      /* Remove the pagination and URL styles */
      @media print {
        .MuiDataGrid-footerContainer {
          display: none !important;
        }
        a {
          display: none !important;
        }
      }
    `,
    header: null, // Set header to null to remove it from the PDF
    footer: null,
     // Set footer to null to remove it from the PDF
  });
  const handleRowClick = (row) => {
    setSelectedRow(row);
  };
  const handleerror=(e)=>{
    e.preventDefault();
    const idb=selectedRow.id;
    
        history.push(`/admin/modifier_compte/${idb}`);
      
  }
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setinfoAgence({
        nom_bureau:'',
        num_auto:'',
        adresse:'',
        tel:'',
        ville:'',
        idfis:'',
        tp:'',
        email:'',
        cnss:'',
        ice:'',
        rc:'',
        CcompteAchat:'',
        CcompteVente:'',
        logo:'',
        confirme:'',
        nom:'',
        motdpasse:'',
        email2:'',
      });
  };
  const handleInput = (e) => {
    const { name, value } = e.target;
    setinfoAgence((previnfo) => ({
      ...previnfo,
      [name]: value,
    }));
}
const handleInput2 = (e) => {
    const { name, value } = e.target;
    setinfoAgence2((previnfo) => ({
      ...previnfo,
      [name]: value,
    }));
}
const [open2, setOpen2] = React.useState(false);

  const handleClickOpen2 = () => {
    if(selectedRow){
    setOpen2(true);
    const sr=selectedRow;
    setinfoAgence2(sr);
    }
  };

  const handleClose2 = () => {
    
        setOpen2(false);
        
    
  };
 
const submit=(e)=>{
    e.preventDefault();
    console.log(infoAgence);
      swal({
        title: '',
        text: 'Voulez-vous vraiment ajouter une nouvelle agence ?',
        icon: 'warning',
        buttons: ['Annuler', 'Oui'],
        dangerMode: true,
      }).then((confirmed) => {
        if (confirmed) {
            if(infoAgence.motdpasse===infoAgence.confirme){
                axios
                    .post('api/ajouter-agence', infoAgence)
                    .then((res) => {
                    // ... Handle the response from the server ...
                    if (res.data.status === 200) {
                        setBool(1);
                        setOpen(false);
                        setinfoAgence({
                          nom_bureau:'',
                          num_auto:'',
                          adresse:'',
                          tel:'',
                          ville:'',
                          idfis:'',
                          tp:'',
                          email:'',
                          cnss:'',
                          ice:'',
                          rc:'',
                          CcompteAchat:'',
                          CcompteVente:'',
                          logo:'',
                          confirme:'',
                          nom:'',
                          motdpasse:'',
                          email2:'',
                        });
                        
                    }
                    else if (res.data.status === 400){
                        const firstErrorMessage = Object.values(res.data.validationerror)[0][0];
                        swal(
                            '',
                            firstErrorMessage || "Pour enregistrer cette opération, il est nécessaire de saisir toutes les informations.",
                            'warning'
                        );
                    } else if(res.data.status === 450) {
                      swal(
                            '',
                            "Utilisateur ID de l'administrateur existe déjà.",
                            'warning'
                          );
                  } 
                    })
                    .catch((error) => {
                    // Handle any error that occurred during the request
                    console.error('Error submitting form:', error);
                    });
                
                }
            else{
                swal(
                  '',
                  "Les mots de passe saisis ne correspandent pas.",
                  'warning'
                );
              }
            }
      });
  }
  const submit2=(e)=>{
    e.preventDefault();
    
      swal({
        title: '',
        text: 'Voulez vous vraiment valider cette opération ?',
        icon: 'warning',
        buttons: ['Annuler', 'Oui'],
        dangerMode: true,
      }).then((confirmed) => {
        if (confirmed) {
            if(infoAgence2.motdpasse===infoAgence2.confirme){

                axios
                    .post('api/modifier-agence', infoAgence2)
                    .then((res) => {
                    // ... Handle the response from the server ...
                    if (res.data.status === 200) {
                        setBool(1);
                        setOpen2(false);
                    } 
                    else if (res.data.status === 400){
                      swal(
                          '',
                          "Pour enregistrer cette opération, il est nécessaire de remplir tous les champs obligatoires.",
                          'warning'
                        );
                  } 
                    })
                    .catch((error) => {
                    // Handle any error that occurred during the request
                    console.error('Error submitting form:', error);
                    });
                
                }
            }else{
                swal(
                  '',
                  "Les mots de passe saisis ne correspandent pas.",
                  'warning'
                );
              }
      });
      
      
  

  }


  const [search, setSearch] = useState(null);

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearch(query);

    // Filter the array based on the search query
    const filteredResults = banques.filter((item) =>
      item.nom_bureau.toLowerCase().includes(query.toLowerCase())
    );

    setDataToRender(filteredResults);
  };
/***************************************************end functions******************************************* */
useEffect(() => {
    if (shouldPrint) {
      // Call handlePrint() when shouldPrint becomes true
      handlePrint(); // This will trigger the printing action
      setShouldPrint(false); // Reset shouldPrint back to false
    }
  }, [shouldPrint]);
useEffect(() => {
    if(bool){
  const fetchData = async () => {
    try {
      
      const response = await axios.get(`api/liste-agence`);
        setBanques(response.data.message);
        setDataToRender(response.data.message);
      
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  fetchData();
  setBool(0);
}
  }, [bool]);
  return (
    <Box m="20px" sx={{
      "& .bartitle":{
        marginY : "1.5rem",
      }
    }}>
        
      <Box className="bartitle" textAlign="center" marginTop="0" backgroundColor={colors.primary[400]} sx={{
        "& .h1":{
            color: colors.grey[500],
        }
      }}><h1 className="h1" ></h1></Box>
      
      <Box display="flex" justifyContent="center" marginBottom="3rem"
      sx={{
        "& .MuiFormControl-root" : {
          marginRight:"1rem",
          width:"11vw",
          
        },
        "& .MuiAutocomplete-root":{
          marginTop:"8px"
        },
        "& .buttons" : {
          color: colors.grey[200],
          backgroundColor : colors.primary[400],
          marginRight: "1rem",
          fontSize: "0.8rem",
          height: "3.4rem" ,
          paddingX:"1.5rem"
      },
      "& .buttons:hover" : {
          color: colors.primary[400],
          backgroundColor : colors.grey[200],
          
          
          fontSize: "0.9rem"
      },
      "& .span1" : {
          
          
          marginLeft: "0.5rem",
          
      },
      
      }}
      >
     
      
     <TextField id="outlined-basic" label="Agence" variant="outlined"
     
          value={search}
          onChange={handleSearchChange}
     /> 
    <Button className="buttons" onClick={handleClickOpen2} ><DriveFileRenameOutlineOutlinedIcon/><span className="span1">Modifier</span> </Button>
    <Button className="buttons" onClick={handleClickOpen}><AddCircleOutlinedIcon/><span className="span1">Ajouter</span> </Button>

      
       
        
      </Box>
      <Box display="flex" justifyContent="center"
        sx={{"& .solde":{
          marginTop:"20vh"
        }}}
      >
        <Box 
              width="100%" marginBottom="4vh">
          <Box  
              marginTop="25rem"
              height="75vh"
              marginBottom='3vh'
              sx={{
              "& .MuiDataGrid-root": {
                  border: "none",
              },
              "& .MuiDataGrid-cell": {
                  borderBottom: "none",
              },
              "& .name-column--cell": {
                  color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${colors.grey[100]} !important`,
              },
              }}
          >
              <h2>Les bureaux de change</h2>
              <Paper >
              <TableContainer sx={{ height: '70vh', marginTop: '3vh',marginBottom:'3vh',width:'100%' }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead sx={{backgroundColor:"#a4a9fc"}}>
                      <TableRow>
                        <TableCell sx={{backgroundColor:"#a4a9fc"}}>Nom bureau</TableCell>
                        <TableCell sx={{backgroundColor:"#a4a9fc"}}>N° d'autorisation</TableCell>
                        <TableCell sx={{backgroundColor:"#a4a9fc"}}>Adresse</TableCell>
                        <TableCell sx={{backgroundColor:"#a4a9fc"}}>Ville</TableCell>
                        <TableCell sx={{backgroundColor:"#a4a9fc"}}>Tél</TableCell>
                        <TableCell sx={{backgroundColor:"#a4a9fc"}}>Identifiant fiscal</TableCell>
                        <TableCell sx={{backgroundColor:"#a4a9fc"}}>N° TP</TableCell>
                        <TableCell sx={{backgroundColor:"#a4a9fc"}}>Email</TableCell>

                        <TableCell sx={{backgroundColor:"#a4a9fc"}}>CNSS</TableCell>
                        <TableCell sx={{backgroundColor:"#a4a9fc"}}>ICE</TableCell>
                        <TableCell sx={{backgroundColor:"#a4a9fc"}}>RC</TableCell>
                        <TableCell sx={{backgroundColor:"#a4a9fc"}}>Code journal d'achat</TableCell>
                        <TableCell sx={{backgroundColor:"#a4a9fc"}}>Code journal de vente</TableCell>


                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dataToRender.map((row) => (
                        <TableRow key={row.id} onClick={() => handleRowClick(row)}
                          sx={{
                            backgroundColor:
                              selectedRow && selectedRow.id === row.id ? '#ccc' : 'inherit',
                          }}
                        >
                          <TableCell>{row.nom_bureau}</TableCell>
                          <TableCell>{row.num_auto}</TableCell>
                          <TableCell>{row.adresse}</TableCell>
                          <TableCell>{row.ville}</TableCell>
                          <TableCell>{row.tel}</TableCell>
                          <TableCell>{row.idfis}</TableCell>
                          <TableCell>{row.tp}</TableCell>
                          <TableCell>{row.email}</TableCell>
                          <TableCell>{row.cnss}</TableCell>
                          <TableCell>{row.ice}</TableCell>
                          <TableCell>{row.rc}</TableCell>
                          <TableCell>{row.CcompteAchat}</TableCell>
                          <TableCell>{row.CcompteVente}</TableCell>

                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                
              </Paper>
      
        
        
      <Box display="flex" justifyContent="center"
          sx={{
              "& .MuiFormControl-root" : {
                marginRight:"1rem",
                width:"6rem",
                
              },
              "& .MuiAutocomplete-root":{
                marginTop:"8px"
              },
              "& .buttons" : {
                color: colors.grey[200],
                backgroundColor : colors.primary[700],
                margin: "0.5rem",
                marginTop:"1rem",
                fontSize: "0.8rem",
                height: "3.4rem" ,
                paddingX:"1.5rem"
            },
            "& .buttons:hover" : {
                color: colors.primary[400],
                backgroundColor : colors.grey[200],
                
                margin: "0.5rem",
                marginTop:"1rem",
                fontSize: "0.9rem"
            },
            "& .span1" : {
                
                
                marginLeft: "0.5rem",
                
            }
            }}
      >
      
      </Box>
    </Box>
    </Box>
      
      
        
      </Box>
      <Box>
      <Dialog
          PaperProps={{
              style: {
                  width: '80vw',
                  maxWidth: '80vw',
              },
          }}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative', bgcolor: '#0f2479' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Ajouter une agence
            </Typography>
            <Button autoFocus color="inherit" onClick={submit}>
              Enregistrer
            </Button>
          </Toolbar>
        </AppBar>
        <List sx={{paddingX:"2vw"}}>
    <Box  justifyContent="center" sx={{
        "& .bartitle":{
            marginY : "0.75rem",
        }
        }}>
      <Box className="bartitle"  marginTop="0"  sx={{
        "& .h1":{
            color: colors.grey[500],
        }
      }}><h3 className="h3" >Les informations du bureau de change</h3></Box>

        <Box display="flex" justifyContent="space-between">
            <Box width="40vw">
                <Box display="flex" justifyContent="space-between"
                sx={{
                    "& .MuiFormControl-root" : {
                    width:"40vw",
                    marginBottom:"0.5rem"
                    },
                    "& .MuiAutocomplete-root":{
                    marginTop:"8px"
                    },
                    "& .buttons" : {
                    color: colors.grey[200],
                    backgroundColor : colors.primary[400],
                    margin: "0.5rem",
                    fontSize: "0.8rem",
                    height: "3.4rem" ,
                    paddingX:"1.5rem"
                },
                "& .buttons:hover" : {
                    color: colors.primary[400],
                    backgroundColor : colors.grey[200],
                    
                    margin: "0.5rem",
                    fontSize: "0.9rem"
                },
                "& .span1" : {
                    
                    
                    marginLeft: "0.5rem",
                    
                }
                }}
                >
                    <Box display="flex">
                        
                    
                        <TextField id="outlined-basic" size="small"  name="nom_bureau" label="Raison sociale *" value={infoAgence.nom_bureau} onChange={handleInput}  variant="outlined" />

                    </Box> 
                </Box>

                <Box display="flex" justifyContent="space-between"
                    sx={{
                        "& .MuiFormControl-root" : {
                        width:"30vw",
                        marginBottom:"0.5rem"
                        
                        },
                        "& .MuiAutocomplete-root":{
                        marginTop:"8px"
                        },
                        "& .buttons" : {
                        color: colors.grey[200],
                        backgroundColor : colors.primary[400],
                        margin: "0.5rem",
                        fontSize: "0.8rem",
                        height: "3.4rem" ,
                        paddingX:"1.5rem"
                    },
                    "& .buttons:hover" : {
                        color: colors.primary[400],
                        backgroundColor : colors.grey[200],
                        
                        margin: "0.5rem",
                        fontSize: "0.9rem"
                    },
                    "& .span1" : {
                        
                        
                        marginLeft: "0.5rem",
                        
                    }
                    }}
                    >
                    <Box display="flex">
                        
                        
                        <TextField id="outlined-basic" size="small" name="num_auto" label="N° d'autorisation *" value={infoAgence.num_auto} onChange={handleInput}  variant="outlined" />

                    </Box> 
                    </Box>

                    <Box display="flex" justifyContent="space-between"
                        sx={{
                            "& .MuiFormControl-root" : {
                            width:"40vw",
                            marginBottom:"0.5rem"
                            
                            },
                            "& .MuiAutocomplete-root":{
                            marginTop:"8px"
                            },
                            "& .buttons" : {
                            color: colors.grey[200],
                            backgroundColor : colors.primary[400],
                            margin: "0.5rem",
                            fontSize: "0.8rem",
                            height: "3.4rem" ,
                            paddingX:"1.5rem"
                        },
                        "& .buttons:hover" : {
                            color: colors.primary[400],
                            backgroundColor : colors.grey[200],
                            
                            margin: "0.5rem",
                            fontSize: "0.9rem"
                        },
                        "& .span1" : {
                            
                            
                            marginLeft: "0.5rem",
                            
                        }
                        }}
                        >
                        <Box display="flex">
                        
                            <TextField id="outlined-basic" size="small" name="adresse" label="Adresse *" value={infoAgence.adresse} onChange={handleInput}  variant="outlined" />
                        </Box> 
                        </Box>


                        <Box display="flex" 
                            sx={{
                                "& .MuiFormControl-root" : {
                                
                                width:"19vw",
                                marginBottom:"0.5rem"

                                
                                },
                                "& .MuiAutocomplete-root":{
                                marginTop:"8px"
                                },
                                "& .buttons" : {
                                color: colors.grey[200],
                                backgroundColor : colors.primary[400],
                                margin: "0.5rem",
                                fontSize: "0.8rem",
                                height: "3.4rem" ,
                                paddingX:"1.5rem"

                            },
                            "& .buttons:hover" : {
                                color: colors.primary[400],
                                backgroundColor : colors.grey[200],
                                
                                margin: "0.5rem",
                                fontSize: "0.9rem"
                            },
                            "& .span1" : {
                                
                                
                                marginLeft: "0.5rem",
                                
                            }
                            }}
                            >
                            <Box display="flex">
                                
                                <TextField id="outlined-basic" size="small" sx={{marginRight:"2vw"}}  name="ville" label="Ville *" value={infoAgence.ville} onChange={handleInput}  variant="outlined" />

                            </Box>
                            <Box display="flex">
                                <TextField id="outlined-basic" size="small" name="tel" label="Télephone *" value={infoAgence.tel} onChange={handleInput}  variant="outlined" />

                            </Box> 
                            </Box>

                            <Box display="flex" 
                                    sx={{
                                        "& .MuiFormControl-root" : {
                                        width:"19vw",
                                        marginBottom:"0.5rem"

                                        
                                        },
                                        "& .MuiAutocomplete-root":{
                                        marginTop:"8px"
                                        },
                                        "& .buttons" : {
                                        color: colors.grey[200],
                                        backgroundColor : colors.primary[400],
                                        margin: "0.5rem",
                                        fontSize: "0.8rem",
                                        height: "3.4rem" ,
                                        paddingX:"1.5rem"

                                    },
                                    "& .buttons:hover" : {
                                        color: colors.primary[400],
                                        backgroundColor : colors.grey[200],
                                        
                                        margin: "0.5rem",
                                        fontSize: "0.9rem"
                                    },
                                    "& .span1" : {
                                        
                                        
                                        marginLeft: "0.5rem",
                                        
                                    }
                                    }}
                                    >
                                    <Box display="flex">
                                        <TextField id="outlined-basic" size="small" sx={{marginRight:"2vw"}}  name="email" label="Email" value={infoAgence.email} onChange={handleInput}  variant="outlined" />

                                    </Box>
                                    <Box display="flex">
                                        <TextField id="outlined-basic" size="small"  name="cnss" label="CNSS" value={infoAgence.cnss} onChange={handleInput}  variant="outlined" />

                                    </Box> 
                                    </Box>
                                    <Box display="flex" 
                                    sx={{
                                        "& .MuiFormControl-root" : {
                                        width:"19vw",
                                        marginBottom:"0.5rem"

                                        
                                        },
                                        "& .MuiAutocomplete-root":{
                                        marginTop:"8px"
                                        },
                                        "& .buttons" : {
                                        color: colors.grey[200],
                                        backgroundColor : colors.primary[400],
                                        margin: "0.5rem",
                                        fontSize: "0.8rem",
                                        height: "3.4rem" ,
                                        paddingX:"1.5rem"

                                    },
                                    "& .buttons:hover" : {
                                        color: colors.primary[400],
                                        backgroundColor : colors.grey[200],
                                        
                                        margin: "0.5rem",
                                        fontSize: "0.9rem"
                                    },
                                    "& .span1" : {
                                        
                                        
                                        marginLeft: "0.5rem",
                                        
                                    }
                                    }}
                                    >
                                    <Box display="flex">
                                        <TextField id="outlined-basic"  size="small" sx={{marginRight:"2vw"}}  name="idfis" label="Identifiant fiscale" value={infoAgence.idfis} onChange={handleInput}  variant="outlined" />

                                    </Box>
                                    <Box display="flex">
                                        <TextField id="outlined-basic" size="small"  name="tp" label="N° TP" value={infoAgence.tp} onChange={handleInput}  variant="outlined" />

                                    </Box> 
                                    </Box>
                                  

                                    <Box display="flex" 
                                        sx={{
                                            "& .MuiFormControl-root" : {
                                            width:"19vw",
                                            marginBottom:"0.5rem"

                                            
                                            },
                                            "& .MuiAutocomplete-root":{
                                            marginTop:"8px"
                                            },
                                            "& .buttons" : {
                                            color: colors.grey[200],
                                            backgroundColor : colors.primary[400],
                                            margin: "0.5rem",
                                            fontSize: "0.8rem",
                                            height: "3.4rem" ,
                                            paddingX:"1.5rem"

                                        },
                                        "& .buttons:hover" : {
                                            color: colors.primary[400],
                                            backgroundColor : colors.grey[200],
                                            
                                            margin: "0.5rem",
                                            fontSize: "0.9rem"
                                        },
                                        "& .span1" : {
                                            
                                            
                                            marginLeft: "0.5rem",
                                            
                                        }
                                        }}
                                        >
                                        <Box display="flex">
                                            
                                            <TextField id="outlined-basic" size="small" sx={{marginRight:"2vw"}} name="ice" label="ICE *" value={infoAgence.ice} onChange={handleInput}  variant="outlined" />
                                        </Box>
                                        <Box display="flex">
                                            <TextField id="outlined-basic" size="small"  name="rc" label="RC" value={infoAgence.rc} onChange={handleInput}  variant="outlined" />

                                        </Box> 
                                        </Box>

                                        <Box display="flex" 
                                            sx={{
                                                "& .MuiFormControl-root" : {
                                                width:"40vw",
                                                marginBottom:"0.5rem"

                                                
                                                },
                                                "& .MuiAutocomplete-root":{
                                                marginTop:"8px"
                                                },
                                                "& .buttons" : {
                                                color: colors.grey[200],
                                                backgroundColor : colors.primary[400],
                                                margin: "0.5rem",
                                                fontSize: "0.8rem",
                                                height: "3.4rem" ,
                                                paddingX:"1.5rem"

                                            },
                                            "& .buttons:hover" : {
                                                color: colors.primary[400],
                                                backgroundColor : colors.grey[200],
                                                
                                                margin: "0.5rem",
                                                fontSize: "0.9rem"
                                            },
                                            "& .span1" : {
                                                
                                                
                                                marginLeft: "0.5rem",
                                                
                                            }
                                            }}
                                            >
                                            <Box display="flex">
                                                <TextField id="outlined-basic" size="small"  name="CcompteAchat" label="Code Journal d'achat" value={infoAgence.CcompteAchat} onChange={handleInput}  variant="outlined" />

                                            </Box>
                                            
                                            </Box>

                                            <Box display="flex" 
                                            sx={{
                                                "& .MuiFormControl-root" : {
                                                width:"40vw",
                                                marginBottom:"0.5rem"

                                                
                                                },
                                                "& .MuiAutocomplete-root":{
                                                marginTop:"8px"
                                                },
                                                "& .buttons" : {
                                                color: colors.grey[200],
                                                backgroundColor : colors.primary[400],
                                                margin: "0.5rem",
                                                fontSize: "0.8rem",
                                                height: "3.4rem" ,
                                                paddingX:"1.5rem"

                                            },
                                            "& .buttons:hover" : {
                                                color: colors.primary[400],
                                                backgroundColor : colors.grey[200],
                                                
                                                margin: "0.5rem",
                                                fontSize: "0.9rem"
                                            },
                                            "& .span1" : {
                                                
                                                
                                                marginLeft: "0.5rem",
                                                
                                            }
                                            }}
                                            >
                                            <Box display="flex">
                                                <TextField id="outlined-basic" size="small"  name="CcompteVente" label="Code Journal de vente" value={infoAgence.CcompteVente} onChange={handleInput}  variant="outlined" />

                                            </Box>
                                            
                                            </Box>
      
            </Box>
            <Box width="30vw">
                <Box className="bartitle"  marginTop="0"  sx={{
                    "& .h1":{
                        color: colors.grey[500],
                    }
                }}><h3 className="h3" >Les informations de l'administrateur</h3></Box>
                <Box >
                    <Box sx={{marginBottom:'0.5rem'}}><TextField size="small" sx={{width:"30vw"}} id="outlined-basic"  name="nom" label="Nom  complet *" value={infoAgence.nom} onChange={handleInput}  variant="outlined" /></Box>
                    <Box sx={{marginBottom:'0.5rem'}}><TextField size="small" sx={{width:"30vw"}} id="outlined-basic"  name="email2" label="Utilisateur ID *" value={infoAgence.email2} onChange={handleInput}  variant="outlined" /></Box>
                    <Box sx={{marginBottom:'0.5rem'}}><TextField size="small" sx={{width:"30vw"}} type='password' id="outlined-basic"  name="motdpasse" label="Mot de passe (8 caractères, maj., min., spéciaux) * " value={infoAgence.motdpasse} onChange={handleInput}  variant="outlined" /></Box>
                    <Box sx={{marginBottom:'0.5rem'}}><TextField size="small" sx={{width:"30vw"}} type='password' id="outlined-basic"  name="confirme" label="Confirmer mot de passe *" value={infoAgence.confirme} onChange={handleInput}  variant="outlined" /></Box>


                </Box>
            </Box>
          
    </Box>
    <Box>

    </Box>
    </Box>


        </List>
      </Dialog>
      </Box>
      <Box>
      <Dialog
          PaperProps={{
              style: {
                  width: '60vw',
                  maxWidth: '60vw',
              },
          }}
        open={open2}
        onClose={handleClose2}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative', bgcolor: '#0f2479' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose2}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Modifier une agence
            </Typography>
            <Button autoFocus color="inherit" onClick={submit2}>
              Enregistrer
            </Button>
          </Toolbar>
        </AppBar>
        <List sx={{paddingX:"2vw"}}>
    <Box  justifyContent="center" sx={{
        "& .bartitle":{
            marginY : "0.75rem",
        }
        }}>
      <Box className="bartitle"  marginTop="0"  sx={{
        "& .h1":{
            color: colors.grey[500],
        }
      }}><h3 className="h3" >Les informations de bureau de change</h3></Box>

        <Box display="flex" justifyContent="space-between">
            <Box width="56vw">
                <Box display="flex" justifyContent="space-between"
                sx={{
                    "& .MuiFormControl-root" : {
                    width:"56vw",
                    marginBottom:"0.5rem"
                    },
                    "& .MuiAutocomplete-root":{
                    marginTop:"8px"
                    },
                    "& .buttons" : {
                    color: colors.grey[200],
                    backgroundColor : colors.primary[400],
                    margin: "0.5rem",
                    fontSize: "0.8rem",
                    height: "3.4rem" ,
                    paddingX:"1.5rem"
                },
                "& .buttons:hover" : {
                    color: colors.primary[400],
                    backgroundColor : colors.grey[200],
                    
                    margin: "0.5rem",
                    fontSize: "0.9rem"
                },
                "& .span1" : {
                    
                    
                    marginLeft: "0.5rem",
                    
                }
                }}
                >
                    <Box display="flex">
                        
                    
                        <TextField size="small" id="outlined-basic"  name="nom_bureau" label="Raison sociale *" value={infoAgence2.nom_bureau} onChange={handleInput2}  variant="outlined" />

                    </Box> 
                </Box>

                <Box display="flex" justifyContent="space-between"
                    sx={{
                        "& .MuiFormControl-root" : {
                        width:"40vw",
                        marginBottom:"0.5rem"
                        
                        },
                        "& .MuiAutocomplete-root":{
                        marginTop:"8px"
                        },
                        "& .buttons" : {
                        color: colors.grey[200],
                        backgroundColor : colors.primary[400],
                        margin: "0.5rem",
                        fontSize: "0.8rem",
                        height: "3.4rem" ,
                        paddingX:"1.5rem"
                    },
                    "& .buttons:hover" : {
                        color: colors.primary[400],
                        backgroundColor : colors.grey[200],
                        
                        margin: "0.5rem",
                        fontSize: "0.9rem"
                    },
                    "& .span1" : {
                        
                        
                        marginLeft: "0.5rem",
                        
                    }
                    }}
                    >
                    <Box display="flex">
                        
                        
                        <TextField size="small" id="outlined-basic"  name="num_auto" label="N° d'autorisation *" value={infoAgence2.num_auto} onChange={handleInput2}  variant="outlined" />

                    </Box> 
                    </Box>

                    <Box display="flex" justifyContent="space-between"
                        sx={{
                            "& .MuiFormControl-root" : {
                            width:"56vw",
                            marginBottom:"0.5rem"
                            
                            },
                            "& .MuiAutocomplete-root":{
                            marginTop:"8px"
                            },
                            "& .buttons" : {
                            color: colors.grey[200],
                            backgroundColor : colors.primary[400],
                            margin: "0.5rem",
                            fontSize: "0.8rem",
                            height: "3.4rem" ,
                            paddingX:"1.5rem"
                        },
                        "& .buttons:hover" : {
                            color: colors.primary[400],
                            backgroundColor : colors.grey[200],
                            
                            margin: "0.5rem",
                            fontSize: "0.9rem"
                        },
                        "& .span1" : {
                            
                            
                            marginLeft: "0.5rem",
                            
                        }
                        }}
                        >
                        <Box display="flex">
                        
                            <TextField size="small" id="outlined-basic"  name="adresse" label="Adresse *" value={infoAgence2.adresse} onChange={handleInput2}  variant="outlined" />
                        </Box> 
                        </Box>


                        <Box display="flex" 
                            sx={{
                                "& .MuiFormControl-root" : {
                                
                                width:"27vw",
                                marginBottom:"0.5rem"

                                
                                },
                                "& .MuiAutocomplete-root":{
                                marginTop:"8px"
                                },
                                "& .buttons" : {
                                color: colors.grey[200],
                                backgroundColor : colors.primary[400],
                                margin: "0.5rem",
                                fontSize: "0.8rem",
                                height: "3.4rem" ,
                                paddingX:"1.5rem"

                            },
                            "& .buttons:hover" : {
                                color: colors.primary[400],
                                backgroundColor : colors.grey[200],
                                
                                margin: "0.5rem",
                                fontSize: "0.9rem"
                            },
                            "& .span1" : {
                                
                                
                                marginLeft: "0.5rem",
                                
                            }
                            }}
                            >
                            <Box display="flex">
                                
                                <TextField size="small" id="outlined-basic" sx={{marginRight:"2vw"}}  name="ville *" label="Ville" value={infoAgence2.ville} onChange={handleInput2}  variant="outlined" />

                            </Box>
                            <Box display="flex">
                                <TextField size="small" id="outlined-basic"  name="tel" label="Télephone *" value={infoAgence2.tel} onChange={handleInput2}  variant="outlined" />

                            </Box> 
                            </Box>

                            <Box display="flex" 
                                    sx={{
                                        "& .MuiFormControl-root" : {
                                        width:"27vw",
                                        marginBottom:"0.5rem"

                                        
                                        },
                                        "& .MuiAutocomplete-root":{
                                        marginTop:"8px"
                                        },
                                        "& .buttons" : {
                                        color: colors.grey[200],
                                        backgroundColor : colors.primary[400],
                                        margin: "0.5rem",
                                        fontSize: "0.8rem",
                                        height: "3.4rem" ,
                                        paddingX:"1.5rem"

                                    },
                                    "& .buttons:hover" : {
                                        color: colors.primary[400],
                                        backgroundColor : colors.grey[200],
                                        
                                        margin: "0.5rem",
                                        fontSize: "0.9rem"
                                    },
                                    "& .span1" : {
                                        
                                        
                                        marginLeft: "0.5rem",
                                        
                                    }
                                    }}
                                    >
                                    <Box display="flex">
                                        <TextField size="small" id="outlined-basic" sx={{marginRight:"2vw"}}  name="email" label="Email" value={infoAgence2.email} onChange={handleInput2}  variant="outlined" />

                                    </Box>
                                    <Box display="flex">
                                        <TextField size="small" id="outlined-basic"  name="cnss" label="CNSS" value={infoAgence2.cnss} onChange={handleInput2}  variant="outlined" />

                                    </Box> 
                                    </Box>
                                    <Box display="flex" 
                                    sx={{
                                        "& .MuiFormControl-root" : {
                                        width:"27vw",
                                        marginBottom:"0.5rem"

                                        
                                        },
                                        "& .MuiAutocomplete-root":{
                                        marginTop:"8px"
                                        },
                                        "& .buttons" : {
                                        color: colors.grey[200],
                                        backgroundColor : colors.primary[400],
                                        margin: "0.5rem",
                                        fontSize: "0.8rem",
                                        height: "3.4rem" ,
                                        paddingX:"1.5rem"

                                    },
                                    "& .buttons:hover" : {
                                        color: colors.primary[400],
                                        backgroundColor : colors.grey[200],
                                        
                                        margin: "0.5rem",
                                        fontSize: "0.9rem"
                                    },
                                    "& .span1" : {
                                        
                                        
                                        marginLeft: "0.5rem",
                                        
                                    }
                                    }}
                                    >
                                    <Box display="flex">
                                        <TextField size='small' id="outlined-basic" sx={{marginRight:"2vw"}}  name="idfis" label="Identifiant fiscale" value={infoAgence2.idfis} onChange={handleInput2}  variant="outlined" />

                                    </Box>
                                    <Box display="flex">
                                        <TextField size='small' id="outlined-basic"  name="tp" label="N° TP" value={infoAgence2.tp} onChange={handleInput2}  variant="outlined" />

                                    </Box> 
                                    </Box>
                                  

                                    <Box display="flex" 
                                        sx={{
                                            "& .MuiFormControl-root" : {
                                            width:"27vw",
                                            marginBottom:"0.5rem"

                                            
                                            },
                                            "& .MuiAutocomplete-root":{
                                            marginTop:"8px"
                                            },
                                            "& .buttons" : {
                                            color: colors.grey[200],
                                            backgroundColor : colors.primary[400],
                                            margin: "0.5rem",
                                            fontSize: "0.8rem",
                                            height: "3.4rem" ,
                                            paddingX:"1.5rem"

                                        },
                                        "& .buttons:hover" : {
                                            color: colors.primary[400],
                                            backgroundColor : colors.grey[200],
                                            
                                            margin: "0.5rem",
                                            fontSize: "0.9rem"
                                        },
                                        "& .span1" : {
                                            
                                            
                                            marginLeft: "0.5rem",
                                            
                                        }
                                        }}
                                        >
                                        <Box display="flex">
                                            
                                            <TextField size="small" id="outlined-basic" sx={{marginRight:"2vw"}} name="ice" label="ICE *" value={infoAgence2.ice} onChange={handleInput2}  variant="outlined" />
                                        </Box>
                                        <Box display="flex">
                                            <TextField size="small" id="outlined-basic"  name="rc" label="RC" value={infoAgence2.rc} onChange={handleInput2}  variant="outlined" />

                                        </Box> 
                                        </Box>

                                        <Box display="flex" 
                                            sx={{
                                                "& .MuiFormControl-root" : {
                                                width:"56vw",
                                                marginBottom:"0.5rem"

                                                
                                                },
                                                "& .MuiAutocomplete-root":{
                                                marginTop:"8px"
                                                },
                                                "& .buttons" : {
                                                color: colors.grey[200],
                                                backgroundColor : colors.primary[400],
                                                margin: "0.5rem",
                                                fontSize: "0.8rem",
                                                height: "3.4rem" ,
                                                paddingX:"1.5rem"

                                            },
                                            "& .buttons:hover" : {
                                                color: colors.primary[400],
                                                backgroundColor : colors.grey[200],
                                                
                                                margin: "0.5rem",
                                                fontSize: "0.9rem"
                                            },
                                            "& .span1" : {
                                                
                                                
                                                marginLeft: "0.5rem",
                                                
                                            }
                                            }}
                                            >
                                            <Box display="flex">
                                                <TextField size="small" id="outlined-basic"  name="CcompteAchat" label="Code Journal d'achat" value={infoAgence2.CcompteAchat} onChange={handleInput2}  variant="outlined" />

                                            </Box>
                                            
                                            </Box>

                                            <Box display="flex" 
                                            sx={{
                                                "& .MuiFormControl-root" : {
                                                width:"56vw",
                                                marginBottom:"0.5rem"

                                                
                                                },
                                                "& .MuiAutocomplete-root":{
                                                marginTop:"8px"
                                                },
                                                "& .buttons" : {
                                                color: colors.grey[200],
                                                backgroundColor : colors.primary[400],
                                                margin: "0.5rem",
                                                fontSize: "0.8rem",
                                                height: "3.4rem" ,
                                                paddingX:"1.5rem"

                                            },
                                            "& .buttons:hover" : {
                                                color: colors.primary[400],
                                                backgroundColor : colors.grey[200],
                                                
                                                margin: "0.5rem",
                                                fontSize: "0.9rem"
                                            },
                                            "& .span1" : {
                                                
                                                
                                                marginLeft: "0.5rem",
                                                
                                            }
                                            }}
                                            >
                                            <Box display="flex">
                                                <TextField size="small" id="outlined-basic"  name="CcompteVente" label="Code Journal de vente" value={infoAgence2.CcompteVente} onChange={handleInput2}  variant="outlined" />

                                            </Box>
                                            
                                            </Box>
      
            </Box>

          
    </Box>
    
    </Box>


        </List>
      </Dialog>
      </Box>
    </Box>
  );
};

export default Statistiques;
