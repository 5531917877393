import { Box } from "@mui/material";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import {Button} from "@mui/material";
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import TextField from '@mui/material/TextField';
import { useState } from "react";
import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {useEffect,useRef} from 'react';
import swal from 'sweetalert';
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { useReactToPrint } from "react-to-print";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
class PDFContent extends React.Component {
    render() {
        // Extracting props for easier access
        const { data, agence } = this.props;
        const renderTableRows = () => {

            return (
                <tr>
                    <th>Etablissement de crédit</th>
                    <th >Code</th>
                    <th> Adresse</th>
                    <th> Tél</th>
                    <th >Compte Comptable</th>
                    <th >Code Comptable</th>
                </tr>
            );

        }


        const renderTableBody = () => {

            return (

                <>
                    {data.map((row) => (
                        <tr  >
                            <td>{row.designation?row.designation:''}</td>
                            <td>{row.code?row.code:''}</td>
                            <td>{row.adresse?row.adresse:''}</td>
                            <td>{row.tel?row.tel:''}</td>

                            <td>{row.compte_comptable?row.compte_comptable:''}</td>
                            <td>{row.code_comptable?row.code_comptable:''}</td>


                        </tr>
                    ))}
                </>
            );
        };
        return (
            <div>
                {/* Agency information */}
                <Box sx={{ borderBottom: '1px solid black', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                    <h5 className="h5">{agence.nom_bureau}</h5>
                    <h5 className="h5">{agence.num_auto}</h5>
                </Box>

                {/* Header */}
                <Box
                    sx={{
                        border: '1px solid black',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '70%',
                        marginLeft: '15%',
                        marginY: '4vh',
                        borderRadius: '8px',
                        backgroundColor: '#ea5a27', // Change the background color here
                    }}
                >
                    <h2 className="h2">Liste des banques</h2>
                </Box>


                {/* Currency exchange data */}
                <Box className="tableau" sx={{ width: '100%' }}>
                    <table className="print-table">
                        {renderTableRows()}
                        {renderTableBody()}
                    </table>



                </Box>

                {/* Additional CSS styles for printing */}
                <style>
                    {`
              .print-table {
              margin-left:1%;
                width: 98%;
                border-collapse: collapse;
                margin-bottom: 10vh;
                z-index: 9;
              }
              .footer {
                  margin-top: 4vh;
                  border: 2px dotted #73AD21;
                  align-items: center;
                  width: 100%;
                  justify-content: center;
                  z-index: 9;
                }
              .print-table th,
              .print-table td {
                border: 1px solid black;
                padding: 8px;
                z-index: 9;
              }
  
              /* Additional styles specific for printing */
              @media print {
                
                .print-table {
                  z-index: 9;
                }
                
                .print-table th,
                .print-table td {
                  page-break-inside: avoid;
                  z-index: 9;
                }
                /* Ensure the footer appears on each page */
                .footer {
                  page-break-inside: avoid;
                  z-index: 9;
  
                }
                .tableau{
                  margin-bottom: 10vh; /* Add margin at the bottom of the table */
  
                  z-index: 9;
  
                }
              }
            `}
                </style>


                {/* Footer */}
                <Box className="footer" sx={{ paddingTop: '1vh' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <h5 className="h5">Adresse : {agence.adresse}</h5>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Box sx={{ marginRight: '3vh' }}>
                            <h5 className="h5">Tél : {agence.tel}</h5>
                        </Box>
                        <Box>
                            <h5 className="h5">Email : {agence.email}</h5>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Box sx={{ marginRight: '3vh' }}>
                            <h5 className="h5">TP : {agence.tp}</h5>
                        </Box>
                        <Box sx={{ marginRight: '3vh' }}>
                            <h5 className="h5">IF : {agence.idfis}</h5>
                        </Box>
                        <Box>
                            <h5 className="h5">RC : {agence.rc}</h5>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <h5 className="h5">ICE : {agence.ice}</h5>
                    </Box>
                </Box>
            </div>
        );
    }
}
const Statistiques = () => {
    const componentRef = useRef();
    const [agence,setAgence]=useState({});
    const [shouldPrint, setShouldPrint] = useState(false);
    const history=useHistory();
    const [banques,setBanques]=useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [infoBanque,setInfoBanque]=useState({
        nom_banque:'',
        code_banque:'',
        adresse:'',
        tel:'',
        compte_c:'',
        code_c:'',
    })
    const [infoBanque2,setInfoBanque2]=useState({
        id: "",
        designation: "",
        code: "",
        adresse: "",
        tel: "",
        compte_comptable: "",
        code_comptable: "",
        id_agence: "",
        created_at: "",
        updated_at: "",
    });
    const [bool,setBool]=useState(1);
    /********************************************functions*********************************************** */
    const handlePrintev = (e) => {
        e.preventDefault();
        axios.get(`api/info-agence`).then((res) => {
            if (res.data.status === 200) {
                setAgence(res.data.message);
                setShouldPrint(true);
            }
        });

    };
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "Print",
        pageStyle: `
      @page {
        size: A4;
        margin: 10mm;
      }
      /* Additional styles for the printed page can be added here */
      /* Remove the pagination and URL styles */
      @media print {
        .MuiDataGrid-footerContainer {
          display: none !important;
        }
        a {
          display: none !important;
        }
      }
    `,
        header: null, // Set header to null to remove it from the PDF
        footer: null,
        // Set footer to null to remove it from the PDF
    });
    const handleRowClick = (row) => {
        setSelectedRow(row);
    };
    const handleerror=(e)=>{
        e.preventDefault();
        const idb=selectedRow.id;

        history.push(`/espace-magchange/modifier-compte/${idb}`);

    }
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setInfoBanque({
            nom_banque:'',
            code_banque:'',
            adresse:'',
            tel:'',
            compte_c:'',
            code_c:'',
        });
    };
    const handleInput = (e) => {
        const { name, value } = e.target;
        setInfoBanque((previnfo) => ({
            ...previnfo,
            [name]: value,
        }));
    }
    const handleInput2 = (e) => {
        const { name, value } = e.target;
        setInfoBanque2((previnfo) => ({
            ...previnfo,
            [name]: value,
        }));
    }
    const [open2, setOpen2] = React.useState(false);

    const handleClickOpen2 = () => {
        if(selectedRow){
            setOpen2(true);
            const sr=selectedRow;
            setInfoBanque2(sr);
            console.log(infoBanque2);
        }
    };

    const handleClose2 = () => {

        setOpen2(false);

    };

    const submit=(e)=>{
        e.preventDefault();

        swal({
            title: '',
            text: 'Voulez-vous vraiment ajouter une nouvelle banque ?',
            icon: 'warning',
            buttons: ['Annuler', 'Oui'],
            dangerMode: true,
        }).then((confirmed) => {
            if (confirmed) {
                axios
                    .post('api/ajouter-banque', infoBanque)
                    .then((res) => {
                        // ... Handle the response from the server ...
                        if (res.data.status === 200) {
                            setBool(1);
                            setOpen(false);
                            setInfoBanque({
                                nom_banque:'',
                                code_banque:'',
                                adresse:'',
                                tel:'',
                                compte_c:'',
                                code_c:'',
                            });
                        }
                    })
                    .catch((error) => {
                        // Handle any error that occurred during the request
                        console.error('Error submitting form:', error);
                    });

            }
        });
    }
    const submit2=(e)=>{
        e.preventDefault();

        swal({
            title: '',
            text: 'Voulez-vous vraiment modifier les informations de cette banque ?',
            icon: 'warning',
            buttons: ['Annuler', 'Oui'],
            dangerMode: true,
        }).then((confirmed) => {
            if (confirmed) {
                axios
                    .post('api/modifier-banque', infoBanque2)
                    .then((res) => {
                        // ... Handle the response from the server ...
                        if (res.data.status === 200) {
                            setBool(1);
                            setOpen2(false);
                        }
                    })
                    .catch((error) => {
                        // Handle any error that occurred during the request
                        console.error('Error submitting form:', error);
                    });
            }
        });




    }
    /***************************************************end functions******************************************* */
    useEffect(() => {
        if (shouldPrint) {
            // Call handlePrint() when shouldPrint becomes true
            handlePrint(); // This will trigger the printing action
            setShouldPrint(false); // Reset shouldPrint back to false
        }
    }, [shouldPrint]);
    useEffect(() => {
        if(bool){
            const fetchData = async () => {
                try {

                    const response = await axios.get(`api/liste-banques`);
                    setBanques(response.data.banques);

                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            };
            fetchData();
            setBool(0);
        }
    }, [bool]);
    return (
        <Box m="20px" sx={{
            "& .bartitle":{
                marginY : "1.5rem",
            }
        }}>

            <Box display="flex" justifyContent="center" marginBottom="3rem"
                 sx={{
                     "& .MuiFormControl-root" : {
                         marginRight:"1rem",
                         width:"11rem",

                     },
                     "& .MuiAutocomplete-root":{
                         marginTop:"0px"
                     },
                     "& .buttons" : {
                         color: colors.grey[200],
                         backgroundColor : colors.primary[400],
                         margin: "0rem 0.5rem -0.2rem 0.5rem",
                         fontSize: "0.7rem",
                         height: "2.5rem",
                         paddingX: "1.2rem",
                         paddingY: "0.2rem"
                     },
                     "& .buttons:hover" : {
                         color: colors.primary[400],
                         backgroundColor : colors.grey[200],
                     },
                     "& .span1" : {


                         marginLeft: "0.5rem",

                     },

                 }}
            >


                <TextField size="small" id="outlined-basic" label="Banque" variant="outlined" />
                <Button className="buttons" onClick={handleClickOpen2} ><DriveFileRenameOutlineOutlinedIcon/><span className="span1">Modifier</span> </Button>
                <Button className="buttons" onClick={handleClickOpen}><AddCircleOutlinedIcon/><span className="span1">Ajouter</span> </Button>
                <Button className="buttons" onClick={handlePrintev}><FindInPageOutlinedIcon/><span className="span1">Aperçu</span> </Button>




            </Box>
            <Box display="flex" justifyContent="center"
                 sx={{"& .solde":{
                         marginTop:"0vh"
                     }}}
            >
                <Box
                    width="85%">
                    <Box
                        sx={{
                            "& .MuiDataGrid-root": {
                                border: "none",
                            },
                            "& .MuiDataGrid-cell": {
                                borderBottom: "none",
                            },
                            "& .name-column--cell": {
                                color: colors.greenAccent[300],
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                backgroundColor: colors.blueAccent[700],
                                borderBottom: "none",
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                backgroundColor: colors.primary[400],
                            },
                            "& .MuiDataGrid-footerContainer": {
                                borderTop: "none",
                                backgroundColor: colors.blueAccent[700],
                            },
                            "& .MuiCheckbox-root": {
                                color: `${colors.greenAccent[200]} !important`,
                            },
                            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                color: `${colors.grey[100]} !important`,
                            },
                        }}
                    >
                        <Paper >
                            <TableContainer sx={{ height: '65vh' }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead sx={{backgroundColor:"#a4a9fc"}}>
                                        <TableRow>
                                            <TableCell sx={{backgroundColor:"#a4a9fc"}}>Etablissement de crédit</TableCell>
                                            <TableCell sx={{backgroundColor:"#a4a9fc"}}>Code</TableCell>
                                            <TableCell sx={{backgroundColor:"#a4a9fc"}}>Adresse</TableCell>
                                            <TableCell sx={{backgroundColor:"#a4a9fc"}}>Tél</TableCell>
                                            <TableCell sx={{backgroundColor:"#a4a9fc"}}>Compte Comptable</TableCell>
                                            <TableCell sx={{backgroundColor:"#a4a9fc"}}>Code Comptable</TableCell>


                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {banques.map((row) => (
                                            <TableRow key={row.id} onClick={() => handleRowClick(row)}
                                                      sx={{
                                                          backgroundColor:
                                                              selectedRow && selectedRow.id === row.id ? '#ccc' : 'inherit',
                                                      }}
                                            >
                                                <TableCell>{row.designation}</TableCell>
                                                <TableCell>{row.code}</TableCell>
                                                <TableCell>{row.adresse}</TableCell>
                                                <TableCell>{row.tel}</TableCell>
                                                <TableCell>{row.compte_comptable}</TableCell>
                                                <TableCell>{row.code_comptable}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </Paper>



                        <Box display="flex" justifyContent="center"
                             sx={{
                                 "& .MuiFormControl-root" : {
                                     marginRight:"1rem",
                                     width:"6rem",

                                 },
                                 "& .MuiAutocomplete-root":{
                                     marginTop:"8px"
                                 },
                                 "& .buttons" : {
                                     color: colors.grey[200],
                                     backgroundColor : colors.primary[700],
                                     margin: "0.5rem",
                                     marginTop:"1rem",
                                     fontSize: "0.8rem",
                                     height: "3.4rem" ,
                                     paddingX:"1.5rem"
                                 },
                                 "& .buttons:hover" : {
                                     color: colors.primary[400],
                                     backgroundColor : colors.grey[200],

                                     margin: "0.5rem",
                                     marginTop:"1rem",
                                     fontSize: "0.9rem"
                                 },
                                 "& .span1" : {


                                     marginLeft: "0.5rem",

                                 }
                             }}
                        >

                        </Box>
                    </Box>
                </Box>



            </Box>
            <Box>
                <Dialog
                    PaperProps={{
                        style: {
                            width: '60vw',
                            maxWidth: '60vw',
                        },
                    }}
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                >
                    <AppBar sx={{ position: 'relative', bgcolor: '#0f2479' }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                GESTION BANQUE
                            </Typography>
                            <Button autoFocus color="inherit" onClick={submit}>
                                Enregistrer
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <List sx={{paddingX:"5vw",paddingY:"5vh"}}>
                        <Box sx={{marginY:'4vh'}}><TextField size="small" name="nom_banque" value={infoBanque.nom_banque} onChange={handleInput}  sx={{width:'100%'}}  label="Nom du Banque" variant="outlined" /></Box>
                        <Box sx={{marginY:'4vh'}}><TextField size="small" name="code_banque" value={infoBanque.code_banque} onChange={handleInput}  sx={{width:'100%'}}  label="Code Banque" variant="outlined" /></Box>
                        <Box sx={{marginY:'4vh'}}><TextField size="small" name="adresse" value={infoBanque.adresse} onChange={handleInput}  sx={{width:'100%'}}  label="Adresse" variant="outlined" /></Box>
                        <Box sx={{marginY:'4vh'}}><TextField size="small" name="tel" value={infoBanque.tel} onChange={handleInput}  sx={{width:'100%'}}  label="Tél" variant="outlined" /></Box>
                        <Box sx={{marginY:'4vh'}}><TextField size="small" name="compte_c" value={infoBanque.compte_c} onChange={handleInput}  sx={{width:'100%'}}  label="Compte comptable" variant="outlined" /></Box>
                        <Box sx={{marginY:'4vh'}}><TextField size="small" name="code_c" value={infoBanque.code_c} onChange={handleInput}  sx={{width:'100%'}}  label="Code comptable" variant="outlined" /></Box>



                    </List>
                </Dialog>
            </Box>
            <Box>
                <Dialog
                    PaperProps={{
                        style: {
                            width: '60vw',
                            maxWidth: '60vw',
                        },
                    }}
                    open={open2}
                    onClose={handleClose2}
                    TransitionComponent={Transition}
                >
                    <AppBar sx={{ position: 'relative', bgcolor: '#0f2479' }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose2}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                GESTION BANQUE
                            </Typography>
                            <Button autoFocus color="inherit" onClick={submit2}>
                                Enregistrer
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <List sx={{paddingX:"5vw",paddingY:"5vh"}}>
                        <Box sx={{marginY:'4vh'}}><TextField size="small" name="designation" value={infoBanque2.designation} onChange={handleInput2}  sx={{width:'100%'}}  label="Nom du Banque" variant="outlined" /></Box>
                        <Box sx={{marginY:'4vh'}}><TextField size="small" name="code" value={infoBanque2.code} onChange={handleInput2}  sx={{width:'100%'}}  label="Code Banque" variant="outlined" /></Box>
                        <Box sx={{marginY:'4vh'}}><TextField size="small" name="adresse" value={infoBanque2.adresse} onChange={handleInput2}  sx={{width:'100%'}}  label="Adresse" variant="outlined" /></Box>
                        <Box sx={{marginY:'4vh'}}><TextField size="small" name="tel" value={infoBanque2.tel} onChange={handleInput2}  sx={{width:'100%'}}  label="Tél" variant="outlined" /></Box>
                        <Box sx={{marginY:'4vh'}}><TextField size="small" name="compte_comptable" value={infoBanque2.compte_comptable} onChange={handleInput2}  sx={{width:'100%'}}  label="Compte comptable" variant="outlined" /></Box>
                        <Box sx={{marginY:'4vh'}}><TextField size="small" name="code_comptable" value={infoBanque2.code_comptable} onChange={handleInput2}  sx={{width:'100%'}}  label="Code comptable" variant="outlined" /></Box>




                    </List>
                </Dialog>
            </Box>
            <div style={{ display: 'none' }}>
                <PDFContent data={banques} agence={agence}  ref={componentRef} />
            </div>
        </Box>
    );
};

export default Statistiques;
