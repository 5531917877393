import React from 'react';
import {Box} from "@mui/material";
import {formatNumber} from "../../../../fonctions/formatNumber";

function Index(props) {
    const { rows2,rows3, data2, agence,selected ,user,caisses} = props;
    const renderTableRows = () => {
        if (selected.type === "Détaillé") {
            return (
                <tr>
                    <th >Devise</th>
                    <th align="right">Montant</th>
                    <th align="right">Cours</th>
                    <th align="right">Contre valeur</th>
                </tr>
            );

        }else{
            return (
                <tr >
                    <th >Devise</th>
                    <th align="right">Montant</th>
                </tr>
            );
        }}


    const renderTableBody = () => {
        if (selected.type === "Détaillé") {
            return (

                <>
                    {rows2.map((row) => (
                        row.montant !== 0 && row.nom_devise !== 'DHS' ? (
                            <tr >
                                <td>{row.nom_devise}</td>
                                <td align="right">{formatNumber(row.montant)}</td>
                                <td align="right">{parseFloat(row.cours_ac).toFixed(4)}</td>
                                <td align="right">{formatNumber(row.cv)}</td>
                            </tr>
                        ) : null
                    ))}

                </>
            );}else{
            return (

                <>
                    {rows3.map((row) => (
                        row.montant !== 0 && row.nom_devise !== 'DHS'  ? (
                            <tr  >
                                <td>{row.nom_devise}</td>
                                <td align="right">{formatNumber(row.montant)}</td>
                            </tr>
                        ) : null
                    ))}
                </>
            );
        }
    };
    return (
        <div>
            {/* Agency information */}
            <Box sx={{
                borderBottom: '1px solid black',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100vw'
            }}>
                <h5 className="h5">{agence.nom_bureau}</h5>
                <h5 className="h5">{agence.num_auto}</h5>
            </Box>

            {/* Header */}
            <Box
                sx={{
                    border: '1px solid black',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '50vw',
                    marginLeft: '25vw',
                    marginY: '4vh',
                    borderRadius: '8px',
                    backgroundColor: '#ea5a27', // Change the background color here
                }}
            >
                <h2 className="h2">CAISSE ACTUELLE EN DEVISE</h2>
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <Box sx={{marginRight: '5vh'}}>
                    {user === 1 ? (
                        <h5 className="h5">Caisse : {data2.caisse}</h5>
                    ) : (
                        caisses.length > 0 && (
                            <h5 className="h5">Caisse : {caisses[0].designation}</h5>
                        )
                    )}
                </Box>
            </Box>
            {/* Date and time */}
            <Box sx={{marginY: '4vh'}}>
                <Box sx={{marginRight: '5vh'}}>
                    <h5 className="h5">Date : {data2.date}</h5>
                </Box>
                <Box sx={{marginRight: '5vh'}}>
                    <h5 className="h5">Heure: {new Date().getHours()}:{new Date().getSeconds()}:{new Date().getMilliseconds()}</h5>
                </Box>
            </Box>

            {/* Currency exchange data */}
            <Box sx={{width: '90vw', marginLeft: '5vw', marginRight: '5vw'}}>
                <table className="print-table">
                    {renderTableRows()}
                    {renderTableBody()}
                </table>


            </Box>

            {/* Additional CSS styles for printing */}
            <style>
                {`
          .print-table {
            width: 100%;
            border-collapse: collapse;
          }

          .print-table th, .print-table td {
            border: 1px solid black;
            padding: 8px;
          }

          /* Additional styles specific for printing */
          @media print {
            .page{
              page-break-inside: avoid;
            }
            .print-table {
              page-break-inside: auto;
            }
            .footer{
              position: fixed;
              bottom: 0;
              border-top: 2px solid #73AD21;
              align-items: center;
              width:100vw;
              justify-content:center;
            }
            .print-table th, .print-table td {
              page-break-inside: avoid;
            }
          }
        `}
            </style>

            {/* Footer */}
            <Box className="footer" sx={{paddingTop: '1vh'}}>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <h5 className="h5">Adresse : {agence.adresse}</h5>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Box sx={{marginRight: '3vh'}}>
                        <h5 className="h5">Tél : {agence.tel}</h5>
                    </Box>
                    <Box>
                        <h5 className="h5">Email : {agence.email}</h5>
                    </Box>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Box sx={{marginRight: '3vh'}}>
                        <h5 className="h5">TP : {agence.tp}</h5>
                    </Box>
                    <Box sx={{marginRight: '3vh'}}>
                        <h5 className="h5">IF : {agence.idfis}</h5>
                    </Box>
                    <Box>
                        <h5 className="h5">RC : {agence.rc}</h5>
                    </Box>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <h5 className="h5">ICE : {agence.ice}</h5>
                </Box>
            </Box>
        </div>
    );
}

export default Index;