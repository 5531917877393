import React from "react";
import { Box } from "@mui/material";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import { Button } from "@mui/material";
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import axios from "axios";
import { useEffect, useState,useRef } from 'react';
import Table from '../../../table/index';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useReactToPrint } from "react-to-print";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PDFDenominationDevises from '../PDFContent/DenominationDevises/index';
import {useSelectedRow} from "../../../context/SelectedRowContext";

class PDFContent extends React.Component {
    render() {
        // Extracting props for easier access
        const currentDate = new Date();
        const { data, agence } = this.props;
        // Format the date as "dd/mm/yyyy"
        const formattedDate = currentDate.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
        return (
            <PDFDenominationDevises data={data} agence={agence}/>
        );
    }
}
const colonnesEntete = [
    { id: 'nom_devise', label: 'Devise', align: 'left' },
    { id: 'designation', label: 'Désignation', align: 'left' },
    { id: 'prix_par', label: 'Unité', align: 'right' },
    { id: 'copure', label: 'Copure', align: 'right' },
    { id: 'compte', label: 'Compte Général', align: 'right' },
];

const Achat = () => {
    const history=useHistory();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedUser, setSelectedUser] = useState(null);
    const [bordereaux, setBordereaux] = useState([]);
    const [user, setUser] = useState();
    const [agences, setAgences] = useState([]);
    const [sumCv, setSumCv] = useState(0);
    const [dataToRender, setDataToRender] = useState([]);
    const { selectedRow, updateSelectedRow } = useSelectedRow();
    const componentRef = useRef();
    const [agence, setAgence] = useState([]);
    const [rows, setRows] = useState([]);
    const [rows2, setRows2] = useState([]);
    const [rows3, setRows3] = useState([]);
    const [shouldPrint, setShouldPrint] = useState(false);
    const [search, setSearch] = useState(null);
    const [submitValue,setSubmitValue]=useState({
        devise:'',
        designation:'',
        unite:'',
        copure:'',
        compte:'',
    });
    const [bool,setBool]=useState(1);
    const [devise, setDevise] =useState(null);

    const [open, setOpen] = React.useState(false);
    const handleSearchChange = (event) => {
        const query = event.target.value;
        setSearch(query);

        // Filter the array based on the search query
        const filteredResults = bordereaux.filter((item) =>
            item.nom_devise.toLowerCase().includes(query.toLowerCase())
        );

        setDataToRender(filteredResults);
    };

    //handles
    const handleerror=(e)=>{
        e.preventDefault();
        const idb=selectedRow.id;
        axios.get(`api/if-bordereauer?id=${idb}`).then(res=>{
            if(res.data.status===200){
                history.push(`/admin/gestionerreurachat/${idb}`);
            }else{

            }
        });
    }
    const handleUserFilterChange = (event, newValue) => {
        setSelectedUser(newValue);
        const filterValue = newValue ? newValue.name : '';
        const filteredItems = bordereaux.filter((item) =>
            item.name.toLowerCase().includes(filterValue.toLowerCase())
        );
        setDataToRender(filteredItems);
        const newSumCv = filteredItems.reduce((accumulator, currentDevise) => {
            return accumulator + currentDevise.cv_ac;
        }, 0);
        setSumCv(newSumCv);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const email = localStorage.getItem("auth_email");
    const [ag,setag]=useState([]);
    useEffect(() => {
        if(bool){
            const fetchData = async () => {
                try {
                    const response = await axios.get(`api/cours-devises`);
                    if (response.data.status === 200) {
                        setBordereaux(response.data.cours_devises);
                        setDataToRender(response.data.cours_devises);

                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            };
            fetchData();
            setBool(0);
        }

    }, [bool]);
    const calculateSumCv = (rows) => {
        const totalCv = rows.reduce((accumulator, currentDevise) => {
            return accumulator + currentDevise.cv_ac;
        }, 0);
        return totalCv.toFixed(2);
    };
    const printEv = async (e) => {
        e.preventDefault();
        e.preventDefault();
        axios.get(`api/info-agence`).then((res) => {
            if (res.data.status === 200) {
                setAgence(res.data.message);
                setShouldPrint(true);
            }
        });
    };
    useEffect(() => {
        if (shouldPrint) {
            // Call handlePrint() when shouldPrint becomes true
            handlePrint(); // This will trigger the printing action
            setShouldPrint(false); // Reset shouldPrint back to false

        }
    }, [shouldPrint]);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "numBvc",
        pageStyle: `
      @page {
        size: A4;
        margin: 10mm;
      }
      /* Additional styles for the printed page can be added here */
      /* Remove the pagination and URL styles */
      @media print {
        .MuiDataGrid-footerContainer {
          display: none !important;
        }
        a {
          display: none !important;
        }
      }
    `,
        header: null, // Set header to null to remove it from the PDF
        footer: null,
        // Set footer to null to remove it from the PDF
    });
    const handleClickOpen = () => {
        console.log(selectedRow)
        if(selectedRow){
            setOpen(true);
        }
    };

    const handleClose = () => {
        if(selectedRow){
            let idd=devise.id ;
            const up={
                id:idd,
                designation:submitValue.designation,
                unite:submitValue.unite,
                copure:submitValue.copure,
                compte:submitValue.compte,
            };
            const rs = rows.filter((op) => op.id !== idd);
            rs.push(up);
            setRows(rs);
            const data={
                rows: rs.map((row) => ({
                    id: row.id,
                    designation:row.designation,
                    prixpar:row.unite,
                    copure:row.copure,
                    compte:row.compte,
                })),

            }
            console.log(data);
            axios.post("api/devise-update2",data).then((res) => {
                // ... Handle the response from the server ...
                if (res.data.status === 200) {
                    setBool(1)
                }
                setOpen(false);
            })
        }else{
            setOpen(false);
        }
    }
    const handleValide = () => {
        let idd=devise.id ;
        const up={
            id:idd,
            designation:submitValue.designation,
            unite:submitValue.unite,
            copure:submitValue.copure,
            compte:submitValue.compte,
        };
        const rs = rows.filter((op) => op.id !== idd);
        rs.push(up);
        setRows(rs);
        if(idd===16){
            idd=1;
        }else{
            idd++;
        }
        const row=dataToRender.find((op)=>(op.id===idd));
        setDevise(row);
        setSubmitValue({
            devise:row.nom_devise,
            designation:row.designation,
            unite:row.prix_par,
            copure:row.copure,
            compte:row.compte,
        })
    };
    const handleInput=(e)=>{
        e.persist();
        setSubmitValue({...submitValue, [e.target.name]: e.target.value})
    };
    const handleInput2=(e,newValue)=>{
        setDevise(newValue);
        setSubmitValue({
            devise:newValue.nom_devise,
            designation:newValue.designation,
            unite:newValue.prix_par,
            copure:newValue.copure,
            compte:newValue.compte,

        })
    }

    return (
        <Box  className="type2" >



            <Box sx={{display:'flex',justifyContent:'space-between',marginRight: "4%",marginLeft: "2%", width: "92%",}}>
                <Box sx={{
                    display:'flex',justifyContent:'flex-end',justifyItems:'flex-end',
                    "& .MuiButtonBase-root": {
                        color: colors.grey[200],
                        backgroundColor: colors.primary[400],

                    },
                    "& .MuiButtonBase-root:hover": {
                        color: colors.primary[400],
                        backgroundColor: colors.grey[200],

                    },

                }}>

                    <Button onClick={handleClickOpen}>
                        <DriveFileRenameOutlineOutlinedIcon /><span className="span1">Modifier</span>
                    </Button>
                    <Button onClick={printEv}><FindInPageOutlinedIcon /><span className="span1">Aperçu</span></Button>

                </Box>
                <Box sx={{}}>
                    <TextField
                        id="search-bar"
                        className="text"
                        label="Nom de devise"
                        value={search}
                        onChange={handleSearchChange}
                        variant="outlined"
                        placeholder="Search..."
                        size="small"
                    />
                </Box>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Dénominations des devises</DialogTitle>
                    <DialogContent>
                        <Box sx={{marginX:'3vh',marginBottom:'6vh'}}><Box sx={{display:'flex'}}>
                            <Box sx={{marginTop: `8px`,width:'68%'}}>

                                <Autocomplete

                                    onChange={handleInput2}
                                    value={devise}
                                    size="small"
                                    id="combo-box-demo"
                                    options={dataToRender}
                                    getOptionLabel={(option) => option.nom_devise} // Use the 'designation' property as the label
                                    sx={{ width:'100%' }}
                                    renderInput={(params) => <TextField {...params} label="Code devise" />}
                                    disableClearable={true}
                                />
                            </Box>
                            <Box sx={{marginTop: `8px`,width:'28%',marginLeft:'4%'}}>

                                <img src={require(`./../../drapeaux/${devise?devise.nom_devise:'EUR'}.png`)} style={{ width: "100%", height: '40px' }} alt="Flag" />
                            </Box>
                        </Box>
                        </Box><Box sx={{marginX:'6vh',marginY:'4vh'}}>
                        <TextField
                            id="filled-number"
                            label="Désignation"
                            name="designation"
                            onChange={handleInput}
                            value={submitValue.designation}                      InputLabelProps={{
                            shrink: true,
                        }}
                            variant="filled"
                        /></Box>
                        <Box sx={{marginX:'6vh',marginY:'4vh'}}><TextField
                            id="filled-number"
                            label="Unité"
                            type="number"
                            name="unite"
                            onChange={handleInput}
                            value={submitValue.unite}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="filled"
                        /></Box>
                        <Box sx={{marginX:'6vh',marginY:'4vh'}}><TextField
                            id="filled-number"
                            label="Copure"
                            type="number"
                            name="copure"
                            onChange={handleInput}
                            value={submitValue.copure}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="filled"
                        /></Box>
                        <Box sx={{marginX:'6vh',marginY:'4vh'}}><TextField
                            id="filled-number"
                            label="Compte général"
                            type="number"
                            name="compte"
                            onChange={handleInput}
                            value={submitValue.compte}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="filled"
                        /></Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Annuler</Button>
                        <Button onClick={handleValide}>Valider</Button>
                    </DialogActions>
                </Dialog>
            </Box>
            <Box sx={{margin:'0 20px 0 20px'}} >
                <Table dataToRender={dataToRender} headCells={colonnesEntete} colsToShow={[1, 2, 3, 4,5]} />
            </Box>

            <div style={{ display: 'none' }}>
                <PDFContent data={dataToRender} data2={rows2} data3={rows3} agence={agence} ref={componentRef} />
            </div>
        </Box>
    );
};

export default Achat;
