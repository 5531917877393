import React, { useState, useEffect } from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, Autocomplete } from '@mui/material';

const EditDialog = ({ open, handleClose, handleValide, dataToRender, selectedRow, updateSelectedRow }) => {
    const [submitValue, setSubmitValue] = useState({
        tauxA: '',
        tauxVC: '',
        tauxVB: '',
    });
    const [devise, setDevise] = useState(null);

    useEffect(() => {
        if (selectedRow) {
            setDevise(selectedRow);
            setSubmitValue({
                tauxA: selectedRow.tauxA,
                tauxVC: selectedRow.tauxVC,
                tauxVB: selectedRow.tauxVB,
            });
        }
    }, [selectedRow]);

    const handleInput = (e) => {
        e.persist();
        let inputValue = e.target.value;

        // Limiter à 3 chiffres avant la virgule et 4 après la virgule
        if (/^\d{0,3}(\.\d{0,4})?$/.test(inputValue)) {
            // Mettre à jour l'état avec la valeur formatée
            setSubmitValue({ ...submitValue, [e.target.name]: inputValue });
        }
    };


    const handleInput2 = (e, newValue) => {
        setDevise(newValue);
        setSubmitValue({
            tauxA: newValue.tauxA,
            tauxVC: newValue.tauxVC,
            tauxVB: newValue.tauxVB,
        });
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Création de cours des devises</DialogTitle>
            <DialogContent>
                <Box sx={{ marginX: '3vh', marginBottom: '6vh' }}>
                    <Box sx={{ display: 'flex' }}>
                        <Box sx={{ marginTop: `8px`, width: '68%' }}>
                            <Autocomplete
                                onChange={handleInput2}
                                value={devise}
                                size="small"
                                id="combo-box-demo"
                                options={dataToRender}
                                getOptionLabel={(option) => option.nom_devise}
                                sx={{ width: '100%' }}
                                renderInput={(params) => <TextField {...params} label="Code devise" />}
                                disableClearable={true}
                            />
                        </Box>
                        <Box sx={{ marginTop: `8px`, width: '28%', marginLeft: '4%' }}>
                            <img src={require(`./../../../drapeaux/${devise ? devise.nom_devise : 'EUR'}.png`)} style={{ width: "100%", height: '40px' }} alt="Flag" />
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ marginX: '6vh', marginY: '4vh' }}>
                    <TextField
                        id="filled-number"
                        label="Taux d'achat"
                        type="number"
                        name="tauxA"
                        onChange={handleInput}
                        value={submitValue.tauxA}
                        InputLabelProps={{ shrink: true }}
                        variant="filled"
                    />
                </Box>
                <Box sx={{ marginX: '6vh', marginY: '4vh' }}>
                    <TextField
                        id="filled-number"
                        label="Taux de vente client"
                        type="number"
                        name="tauxVC"
                        onChange={handleInput}
                        value={submitValue.tauxVC}
                        InputLabelProps={{ shrink: true }}
                        variant="filled"
                    />
                </Box>
                <Box sx={{ marginX: '6vh', marginY: '4vh' }}>
                    <TextField
                        id="filled-number"
                        label="Taux de vente banque"
                        type="number"
                        name="tauxVB"
                        onChange={handleInput}
                        value={submitValue.tauxVB}
                        InputLabelProps={{ shrink: true }}
                        variant="filled"
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Annuler</Button>
                <Button onClick={() => handleValide(devise, submitValue)}>Valider</Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditDialog;