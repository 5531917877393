import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import {SelectedRowProvider} from './context/SelectedRowContext';
import {OrderProvider} from './context/OrderContext';


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <OrderProvider>
    <SelectedRowProvider>
      <React.StrictMode>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </React.StrictMode>
    </SelectedRowProvider>
    </OrderProvider>
);
