import React, {useEffect} from 'react';
import {Box} from "@mui/material";
import {formatNumber} from "../../../../fonctions/formatNumber";

function Index(props) {
    const { data,agence,data2,data3,sumCV } = props;
    const pageCount = 2;

    return (
        <div>
            {Array.from({length: pageCount}).map((_, index) => (
                <div className='page' key={index}>
                    <Box sx={{
                        borderBottom: '1px solid black',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: "100vw"
                    }}>
                        <Box sx={{alignItems: 'center', justifyContent: 'center', display: "flex"}}><h3
                            className="h3">BORDEREAU DE VENTE DE BILLETS DE BANQUE ETRANGERS</h3></Box>
                        <Box sx={{alignItems: 'center', justifyContent: 'center', display: "flex"}}><h3
                            className="h3">ET DE CHEQUE DE VOYAGE</h3></Box>
                        <Box sx={{alignItems: 'center', justifyContent: 'center', display: "flex"}}><p
                            className="p2"> (Instruction Générale des Opérations de Change 3 Janvier 2022 - Articles 34
                            et 208)</p></Box>
                    </Box>

                    <Box sx={{marginTop: "2vh"}}>
                        <Box sx={{marginBottom: "1vh", display: "flex"}}>
                            <Box><h3 className="h3">INFORMATIONS POINT DE CHANGE</h3></Box>

                        </Box>
                        <Box sx={{marginLeft: "5vw"}}><p className="p2">Dénomination : {agence.nom_bureau}</p></Box>
                        <Box sx={{marginLeft: "5vw"}}><p className="p2">Identifiant : {agence.num_auto}</p></Box>
                    </Box>


                    <Box sx={{marginTop: "2vh"}}>
                        <Box sx={{marginBottom: "1vh", display: 'flex'}}>
                            <Box><h3 className="h3"> REFERENCE BORDEREAU</h3></Box>
                            <Box sx={{ border: '1px solid black', padding:"1% 4%",marginLeft:'10%',marginTop:"-1%"}}><h3 className="h3">VENTE</h3></Box>
                        </Box>
                        <Box sx={{marginBottom: "1vh", display: 'flex'}}>
                            <Box sx={{marginLeft: "5vw"}}><p className="p2">Numéro : {data2.num_bvc}</p></Box>
                            <Box sx={{marginLeft: "5vw"}}><p className="p2">Date
                                : {new Date().getDate() < 10 ? "0" + (new Date().getDate()) : (new Date().getDate())}/{new Date().getMonth() + 1 < 10 ? "0" + (new Date().getMonth() + 1) : new Date().getMonth() + 1}/{new Date().getFullYear()}</p>
                            </Box>
                            <Box sx={{marginLeft: "5vw"}}><p className="p2">Heure
                                : {new Date().getHours()}:{new Date().getMinutes()}:{new Date().getMilliseconds()}</p>
                            </Box>
                        </Box>
                    </Box>


                    <Box sx={{marginTop: "2vh"}}>
                        <Box sx={{marginBottom: "1vh", display: 'flex'}}>
                            <Box><h3 className="h3">INFORMATIONS ET IDENTIFICATION CLIENT</h3></Box>
                        </Box>
                        <Box sx={{marginBottom: "1vh"}}>
                            <Box sx={{marginX: "5vw", display: "flex", justifyContent: "space-between"}}>
                                <Box sx={{marginLeft: "5vw"}}><h4 className="h4">Personne physique</h4></Box>
                                <Box sx={{marginLeft: "5vw"}}><h4 className="h4">Personne morale</h4></Box>
                            </Box>
                            <Box sx={{marginLeft: "5vw"}}><p className="p2">Qualité du cédant : {data3}</p></Box>
                            <Box sx={{marginLeft: "5vw"}}><p className="p2">Nom et Prénom ou Raison Sociale
                                : {data2.prenom ? data2.prenom : ""} {data2.nom ? data2.nom : ""}</p></Box>
                            <Box sx={{marginLeft: "5vw"}}><p className="p2">Adresse
                                : {data2.adresse ? data2.adresse : ""}</p></Box>
                            <Box sx={{marginY: "1vh", display: 'flex'}}>
                                <Box><h3 className="h3">INFORMATIONS OPERATION</h3></Box>
                            </Box>
                            <Box sx={{marginLeft: "5vw"}}><p className="p2">Nature de l'Opération : {data2.natured}</p>
                            </Box>
                            <Box sx={{marginLeft: "5vw"}}><p className="p2">Justificatif du Rachat
                                : {data2.jusrechat ? data2.jusrechat : ''}</p></Box>
                            <Box sx={{marginLeft: "5vw"}}><p className="p2">Numéro du justificatif
                                : {data2.numjus ? data2.numjus : ''}</p></Box>

                        </Box>
                    </Box>


                    <Box sx={{width: '90vw', marginLeft: "5vw", marginRight: "5vw"}}>
                        <table className="print-table">
                            <tr>
                                <th>Dénomination des devises</th>
                                <th>Montant en devises</th>
                                <th>Cours appliqués</th>
                                <th>Contre Valeur en DHS</th>
                            </tr>
                            {data.map((row) => (
                                <tr key={row.devise}> {/* Assuming each row has a unique identifier (e.g., 'id') */}
                                    <td>{row.devise}</td>
                                    <td align="right">{formatNumber(row.montant)}</td>
                                    <td align="right">{parseFloat(row.coursVente).toFixed(4)}</td>
                                    <td align="right">{formatNumber(row.contreValeur)}</td>

                                </tr>
                            ))}
                        </table>
                        <Box sx={{
                            display: 'flex',
                            marginRight: "5vw",
                            alignItems: 'center',
                            marginTop: "2vh",
                            justifyContent: "end"
                        }}>
                            <Box sx={{
                                borderBottom: '1px dotted black',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                width: "16vw",
                                marginX: "2vw"
                            }}><h3>Total</h3></Box><Box sx={{
                            borderBottom: '1px dotted black',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: "16vw",
                            marginX: "2vw"
                        }}><h3>{sumCV}</h3></Box>

                        </Box>

                        {/* Additional CSS styles for printing */}
                        <style>
                            {`
          .print-table {
            width: 100%;
            border-collapse: collapse;
          }

          .print-table th, .print-table td {
            border: 1px solid black;
            padding: 8px;
          }

          /* Additional styles specific for printing */
          @media print {
            .page{
              page-break-inside: avoid;
            }
            .print-table {
              page-break-inside: auto;
            }
            .footer{
              position: fixed;
              bottom: 0;
              border-top: 2px solid #73AD21;
              align-items: center;
              width:100vw;
              justify-content:center;
            }
            .print-table th, .print-table td {
              page-break-inside: avoid;
            }
            .p2{
              font-size:12px;
              color:black;
                                }
          }
        `}
                        </style>
                        <Box sx={{
                            marginY: "2vh", display: "flex", justifyContent: "space-between",
                            ".p": {
                                fontSize: '16px',
                                color: 'black',
                            }
                        }}>
                            <Box sx={{marginLeft: "6vw"}}><p className="p">Signature du client</p></Box>
                            <Box sx={{marginLeft: "6vw"}}><p className="p">Cachet et signnature du point de change</p>
                            </Box>
                        </Box>
                    </Box>
                    <Box className="footer" sx={{paddingTop: '1vh'}}>
                        <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}><p
                            className="p2">Adresse : {agence.adresse}</p></Box>
                        <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}><Box
                            sx={{marginRight: "3vh"}}><p className="p2">Tél : {agence.tel}</p></Box><Box><p
                            className="p2">Email : {agence.email}</p></Box></Box>
                        <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}><Box
                            sx={{marginRight: "3vh"}}><p className="p2">TP : {agence.tp}</p></Box><Box
                            sx={{marginRight: "3vh"}}><p className="p2">IF : {agence.idfis}</p></Box><Box
                            sx={{marginRight: "3vh"}}><p className="p2">RC : {agence.rc}</p></Box>
                            <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}><p
                                className="p2">ICE : {agence.ice}</p></Box></Box>
                    </Box>
                </div>
            ))}
        </div>
    );
}

export default Index;