import { Box } from "@mui/material";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import {Button} from "@mui/material";
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import axios from "axios";
import {useEffect, useState,useRef} from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/fr'; // Assurez-vous d'importer la locale française si nécessaire
import { useReactToPrint } from "react-to-print";
import {formatNumber} from '../../../fonctions/formatNumber';
// import PDFConsultation from '../PDFContent/Consultation/index'
import {CSVLink} from "react-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Table from "../../../table";
import PDFConsultation from '../PDFContent/ConsultationVentes'
class PDFContent extends React.Component {
    render() {
        const { data, data2,data3, agence,user } = this.props;

        return (
            <PDFConsultation data={data} data2={data2} data3={data3} agence={agence} user={user}/>
        );
    }
}
const Consultation = () => {
    const today = new Date();

    // Format the date as "dd/mm/yyyy"
    const formattedDate = `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`;

    const [page, setPage] =useState(0);
    const [rowsPerPage, setRowsPerPage] =useState(10);
    const [sumCv, setSumCv] = useState();
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedDevise, setSelectedDevise] = useState(null);
    const [selectedDuDate, setSelectedDuDate] = useState(dayjs());
    const [selectedAuDate, setSelectedAuDate] = useState(dayjs());
    const [etat, setEtat] = useState(["Détaillé","Globalisé"]);
    const [selectedEtat, setSelectedEtat] = useState("Détaillé");
    const [listdevise,setlistdevise]=useState(["EUR","USD","GBP",'CAD',"CHF","GIP","SEK","NOK","JPY","SAR","KWD","AED","QAR","BHD","DKK","OMR"]);
    const [filteredItems,setFilteredItems]=useState([]);
    const [shouldPrint, setShouldPrint] = useState(false);
    const [shouldFiltre, setShouldFiltre] = useState(true);
    const [dataToRender, setDataToRender] = useState([]);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const email=localStorage.getItem("auth_email");
    const [bordereaux,setBordereaux]=useState([]);
    const [user,setuser]=useState([]);
    const [users,setusers]=useState([]);
    const [agence,setAgence]=useState({});
    const [rows, setRows] = useState([]);
    const [data2, setData2] = useState([]);
    const componentRef = useRef();
    const [selectedRow, setSelectedRow] = useState(null);
    const [info, setInfo] = useState({
        du:'',
        au:'',
        utilisateur:'',
        devise:'',
        etat:'',
        user:'',
    });

    /****************************************************************functions************************************************** */
    const handlePrintev = (e) => {
        e.preventDefault();
        axios.get(`api/info-agence`).then((res) => {
            if (res.data.status === 200) {
                setAgence(res.data.message);
                setShouldPrint(true);
            }
        });

        // Add checks to ensure variables are defined
        const du = convertDateFormat(selectedDuDate);
        const au = convertDateFormat(selectedAuDate);
        const utilisateur = selectedUser ? selectedUser.name : '--all--';
        const devise = selectedDevise ? selectedDevise : '--all--';
        const etat = selectedEtat ? selectedEtat : '';

        setInfo((prevInfo) => ({
            ...prevInfo,
            du: du,
            au: au,
            utilisateur: utilisateur,
            devise: devise,
            etat: etat,
            user:user,
        }));
    };
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "Print",
        pageStyle: `
    @page {
      size: A4;
      margin: 10mm;
    }
    /* Additional styles for the printed page can be added here */
    /* Remove the pagination and URL styles */
    @media print {
      .MuiDataGrid-footerContainer {
        display: none !important;
      }
      a {
        display: none !important;
      }
    }
  `,
        header: null, // Set header to null to remove it from the PDF
        footer: null,
        // Set footer to null to remove it from the PDF
    });
    const handleRowClick = (row) => {
        setSelectedRow(row);
    };
    useEffect(() => {
        if (shouldPrint) {
            // Call handlePrint() when shouldPrint becomes true
            handlePrint(); // This will trigger the printing action
            setShouldPrint(false); // Reset shouldPrint back to false
        }
    }, [shouldPrint]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`api/consultation-ventes-clients`);
                if (response.data.status === 200) {
                    const { message, utilisateur, utilisateurs } = response.data;
                    setBordereaux(message);
                    setuser(utilisateur);
                    setusers(utilisateurs);
                    let bor=message;
                    bor=handleDuDateChange2(selectedDuDate,bor);
                    bor=handleAuDateChange2(selectedAuDate,bor);
                    setDataToRender(bor);
                    calculateSumCv(bor);
                    const msg = bor.map((op) => {
                        return {
                            'N° bordereau': op.num_bvc,
                            'Utilisateur': op.name,
                            'Date': op.date_vc,
                            'Heure': op.heure_vc,
                            'Client': `${op.nom ? op.nom : ''} ${op.prenom ? op.prenom : ''}`,
                            'Devise': op.nom_devise,
                            'Montant': op.mt_vc,
                            'Cours': op.cours_vc,
                            'Contre Valeur': op.cv_v,
                        };
                    });
                    setRows(msg);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();

    }, []);
    const [rrows,setRrows]=useState(1);
    const [devise2, setDevise2] = useState([]);

    useEffect(() => {
        const result = dataToRender.reduce((acc, item) => {
            const { date_vc, nom_devise, mt_vc, cv_v } = item;
            const key = `${date_vc}-${nom_devise}`;

            if (acc[key]) {
                acc[key].cv_v_sum += parseFloat(cv_v);
                acc[key].mt_vc_sum += parseFloat(mt_vc);
            } else {
                acc[key] = {
                    date_vc,
                    nom_devise,
                    mt_vc_sum: parseFloat(mt_vc),
                    cv_v_sum: parseFloat(cv_v),
                };
            }

            return acc;
        }, {});

        const formattedResult = Object.values(result).map(item => ({
            ...item,
            mt_vc_sum: formatNumber(item.mt_vc_sum),
            cv_v_sum: formatNumber(item.cv_v_sum),
        }));

        // Update the state with the processed data
        setDevise2(formattedResult);
    }, [dataToRender]);

    /****************************************************************************end UseEffect********************************** */

    const renderInputs = () => {
        return (
            <Box sx={{width: "95%",marginX: "1.5%"}}>
                <Box sx={{ width: "20%" ,height: "3rem",marginTop:"0.8rem", display:'flex',
                    "& .MuiFormLabel-root":{
                        color: colors.greenAccent[300],
                    },"& .MuiFormControl-root" : {
                        marginRight:"0.5vw",
                        width:"11vw",

                    }
                }}>{user === 1 ?
                    <Autocomplete
                        size='small'
                        id="combo-box-demo"
                        options={users}
                        value={selectedUser}
                        onChange={handleUserFilterChange} // Update this line
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} label="Utilisateur" />}
                    />: null}
                    <Autocomplete
                        size='small'
                        className="Autocomplete"
                        id="combo-box-demo"
                        value={selectedDevise}
                        options={listdevise}
                        onChange={handleDeviseFilterChange}
                        renderInput={(params) => <TextField {...params} label="Devise" />}
                    />
                    <Autocomplete
                        size='small'
                        className="Autocomplete"
                        id="combo-box-demo"
                        value={selectedEtat}
                        options={etat}
                        onChange={handle2}
                        renderInput={(params) => <TextField {...params} label="Etat" />}
                        disableClearable={true}

                    />
                </Box>
                <Box display="flex" justifyContent="space-between"
                     sx={{

                         "& .MuiAutocomplete-root":{
                             marginTop:"8px"
                         },
                         "& .buttons" : {
                             color: colors.grey[200],
                             backgroundColor : colors.primary[400]
                         },
                         "& .buttons:hover" : {
                             color: colors.primary[400],
                             backgroundColor : colors.grey[200]
                         },
                         "& .span1" : {
                             marginLeft: "0.5vw",
                         },
                         "& .csss" : {
                             marginRight:"0.5vw",
                             minWidth:'13vw',
                             width:"16.75vw",
                         }
                     }}
                >
                    <Box display="flex">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker
                                    label="DU"
                                    value={selectedDuDate}
                                    onChange={handleDuDateChange}
                                    format="DD/MM/YYYY"
                                    className="csss"
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            padding: '10px 14px',
                                            fontSize: '0.875rem',
                                        },
                                    }}
                                />
                            </DemoContainer>
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker label="AU"
                                            value={selectedAuDate}
                                            onChange={handleAuDateChange}
                                            format="DD/MM/YYYY"
                                            className="csss"
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    padding: '10px 14px',
                                                    fontSize: '0.875rem',
                                                },
                                            }}

                                />
                            </DemoContainer>
                        </LocalizationProvider>
                    </Box>
                    < Box>
                        <Button className="buttons" onClick={handlePrintev}><FindInPageOutlinedIcon/><span className="span1">Aperçu</span> </Button>
                        <CSVLink data={rows} filename={nomFichier}><Button className="buttons"><FileDownloadIcon/><span className="span1">Exporter</span></Button></CSVLink>

                    </Box>

                </Box></Box>
        );
    };

    const calculateSumCv = (newValue) => {
        const newSumCv = newValue.reduce((accumulator, currentDevise) => {
            const cvAcNumber = parseFloat(currentDevise.cv_v.replace(/\s/g, '').replace(',', '.'));
            return accumulator + (isNaN(cvAcNumber) ? 0 : cvAcNumber);
        }, 0);
        setSumCv(newSumCv);
    }
    /*****************************************************filtre functions************************************************ */
//user
    const handleUserFilterChange = (event, newValue) => {
        setSelectedUser(newValue);
        let bor=bordereaux;
        bor=handleUserFilterChange2( (newValue? newValue.name:''),bor);
        bor=handleDuDateChange2(selectedDuDate,bor);
        bor=handleAuDateChange2(selectedAuDate,bor);
        bor=handleDeviseFilterChange2( selectedDevise,bor);
        setDataToRender(bor);
        calculateSumCv(bor);
        const msg = bor.map((op) => {
            return {
                'N° bordereau': op.num_bvc,
                'Utilisateur': op.name,
                'Date': op.date_vc,
                'Heure': op.heure_vc,
                'Client': `${op.nom ? op.nom : ''} ${op.prenom ? op.prenom : ''}`,
                'Devise': op.nom_devise,
                'Montant': op.mt_vc,
                'Cours': op.cours_vc,
                'Contre Valeur': op.cv_v,
            };
        });
        setRows(msg);
    }
    const handleUserFilterChange2 = (newValue,bor) => {
        let filteredItems;
        if(newValue!=''){
            filteredItems = bor.filter((item) =>
                item.name===newValue
            );
        }else{
            filteredItems = bor;
        }
        calculateSumCv(filteredItems);
        return filteredItems;
    };


//du date filtre
    const convertDateFormat = (dateString) => {

        const parsedDate = dayjs(dateString, 'MM/DD/YYYY'); // Parse the date with the original format
        return parsedDate.format('YYYY/MM/DD'); // Format the date in the desired format
    };
    const convertDateFormat2 = (dateString) => {
        const parsedDate = dayjs(dateString, 'DD/MM/YYYY'); // Parse the date with the original format
        return parsedDate.format('YYYY/MM/DD'); // Format the date in the desired format
    };
    const convertDateFormat3 = (dateString) => {

        return  dayjs(dateString, 'MM/DD/YYYY'); // Parse the date with the original format
    };

    const handleDuDateChange = (newValue) => {
        console.log(newValue);
        setSelectedDuDate(newValue);
        let bor=bordereaux;
        bor=handleUserFilterChange2( (selectedUser? selectedUser.name:''),bor);
        bor=handleDuDateChange2(newValue,bor);
        bor=handleAuDateChange2(selectedAuDate,bor);
        bor=handleDeviseFilterChange2( selectedDevise,bor);
        setDataToRender(bor);
        calculateSumCv(bor);
        const msg = bor.map((op) => {
            return {
                'N° bordereau': op.num_bvc,
                'Utilisateur': op.name,
                'Date': op.date_vc,
                'Heure': op.heure_vc,
                'Client': `${op.nom ? op.nom : ''} ${op.prenom ? op.prenom : ''}`,
                'Devise': op.nom_devise,
                'Montant': op.mt_vc,
                'Cours': op.cours_vc,
                'Contre Valeur': op.cv_v,
            };
        });
        setRows(msg);
    };
    const handleDuDateChange2 = (newValue,bor) => {
        if(newValue){
            const newV=convertDateFormat(newValue);
            const filteredItems = bor.filter((item) => {
                const parsedDate = convertDateFormat2(item.date_vc);
                return new Date(parsedDate) >= new Date(newV);
            });
            return filteredItems;
        }else{

            return bor;
        }
    }
// au date functions

    const handleAuDateChange = (newValue) => {
        setSelectedAuDate(newValue);
        let bor=bordereaux;
        bor=handleUserFilterChange2( (selectedUser? selectedUser.name:''),bor);
        bor=handleAuDateChange2(newValue,bor);
        bor=handleDuDateChange2(selectedDuDate,bor);
        bor=handleDeviseFilterChange2( selectedDevise,bor);
        setDataToRender(bor);
        calculateSumCv(bor);
        const msg = bor.map((op) => {
            return {
                'N° bordereau': op.num_bvc,
                'Utilisateur': op.name,
                'Date': op.date_vc,
                'Heure': op.heure_vc,
                'Client': `${op.nom ? op.nom : ''} ${op.prenom ? op.prenom : ''}`,
                'Devise': op.nom_devise,
                'Montant': op.mt_vc,
                'Cours': op.cours_vc,
                'Contre Valeur': op.cv_v,
            };
        });
        setRows(msg);
    };
    const handleAuDateChange2 = (newValue,bor) => {
        if(newValue){
            const newV=convertDateFormat(newValue);
            const filteredItems = bor.filter((item) => {
                const parsedDate = convertDateFormat2(item.date_vc);
                return new Date(parsedDate) <= new Date(newV);
            });
            return filteredItems;
        }else{

            return bor;
        }
    }
// devise filtre functions
    const handleDeviseFilterChange = (event, newValue) => {
        setSelectedDevise(newValue);
        let bor=bordereaux;
        bor=handleUserFilterChange2( (selectedUser? selectedUser.name:''),bor);
        bor=handleAuDateChange2(selectedAuDate,bor);
        bor=handleDuDateChange2(selectedDuDate,bor);
        bor=handleDeviseFilterChange2( newValue,bor);
        setDataToRender(bor);
        calculateSumCv(bor);
        const msg = bor.map((op) => {
            return {
                'N° bordereau': op.num_bvc,
                'Utilisateur': op.name,
                'Date': op.date_vc,
                'Heure': op.heure_vc,
                'Client': `${op.nom ? op.nom : ''} ${op.prenom ? op.prenom : ''}`,
                'Devise': op.nom_devise,
                'Montant': op.mt_vc,
                'Cours': op.cours_vc,
                'Contre Valeur': op.cv_v,
            };
        });
        setRows(msg);
    };


    const handleDeviseFilterChange2 = ( newValue,bor) => {
        if(newValue){
            const filteredItems = bor.filter((item) =>
                item.nom_devise===newValue
            );

            return filteredItems;
        }else{

            return bor;
        }


    }
// etat filtre
    const handle2 = (event, newValue) => {

        // Use a different name for the function parameter
        setSelectedEtat(newValue);
        if(newValue==="Globalisé"){
            setRrows(0);
        }else{
            setRrows(1);
        }

    };
    const nomFichier=`Consultation_des_ventes-clients-Du_${convertDateFormat(selectedDuDate)}-Au_${convertDateFormat(selectedAuDate)}-Devise_${selectedDevise ? selectedDevise : '--all--'}-Utilisateur_${selectedUser ? selectedUser : '--all--'}.csv`

    const colonnesEntete = user !== 1?[
        { id: 'num_bvc', label: 'Bordereau', align: 'left' },
        { id: 'date_achat', label: 'Date', align: 'right' },
        { id: 'heure_achat', label: 'Heure', align: 'right' },
        { id: 'prenom_nom', label: 'Client', align: 'left' },
        { id: 'nom_devise', label: 'Devise', align: 'left' },
        { id: 'mt_vc', label: 'Montant', align: 'right' },
        { id: 'cours_vc', label: 'Cours', align: 'right' },
        { id: 'cv_v', label: 'Contre Valeur', align: 'right' },
    ]:[
        { id: 'num_bac', label: 'Bordereau', align: 'left' },
        { id: 'name', label: 'Utilisateur', align: 'left' },
        { id: 'date_achat', label: 'Date', align: 'right' },
        { id: 'heure_achat', label: 'Heure', align: 'right' },
        { id: 'prenom_nom', label: 'Client', align: 'left' },
        { id: 'nom_devise', label: 'Devise', align: 'left' },
        { id: 'mt_vc', label: 'Montant', align: 'right' },
        { id: 'cours_vc', label: 'Cours', align: 'right' },
        { id: 'cv_v', label: 'Contre Valeur', align: 'right' },
    ];
    const colsToShow = user !== 1 ? [1,3, 4, 5, 7, 8, 6, 9] : [1,2,3, 4, 5, 7, 8, 6, 9];
    const colonnesEntete2 = [
        { id: 'date_achat', label: 'Date', align: 'left' },
        { id: 'nom_devise', label: 'Devise', align: 'left' },
        { id: 'mt_ac_sum', label: 'Montant', align: 'right' },
        { id: 'cv_ac_sum', label: 'Contre valeur', align: 'right' },
    ];
    const colsToShow2 = [0,1,2,3];
    return (
        <Box className="type2 container-table" sx={{
            "& .bartitle":{
                marginY : "1.5rem",
            }
        }}>
            {renderInputs()}
            <Box>
                {rrows===1 ? <Table dataToRender={dataToRender} headCells={colonnesEntete} colsToShow={colsToShow} cols={"num_bac"}/>:
                    <Table dataToRender={devise2} headCells={colonnesEntete2} colsToShow={colsToShow2} cols={"date_achat"}/>}
            </Box>
            <Box display="flex" justifyContent="space-between" >
                <Box display="flex"></Box>
                <Box  display="flex"
                      sx={{
                          "& .contrevaleur":{
                              color: colors.grey[100],
                              fontSize: "1.5rem",
                              padding:"0.5rem",
                          },
                          "& .contrev":{
                              backgroundColor:colors.primary[800],
                              color: colors.grey[100],
                              fontSize: "1.5rem",
                              marginLeft: "2rem",
                              marginRight : "3rem",
                              marginBottom: "5rem",
                              paddingX:"2.5rem",
                              paddingY:"0.5rem",
                          }
                      }}
                ><span className="contrevaleur" >Total contre valeurs : </span> <span className="contrev"  >{formatNumber(sumCv)} </span></Box>
            </Box>
            <div style={{ display: 'none' }}>
                <PDFContent data={devise2} agence={agence} data2={dataToRender} data3={info} ref={componentRef} user={user} />
            </div>
        </Box>
    );
};

export default Consultation;