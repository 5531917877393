import React from 'react';
import {Box} from "@mui/material";
import {formatNumber} from '../../../../fonctions/formatNumber'
function Index(props) {
    const { data,agence,data2,data3} = props;
    const sumCv = data.reduce((accumulator, currentDevise) => {
        return accumulator + currentDevise.cv_v;
    }, 0);
    const pageCount = 2;
    return (

        <div>
            {Array.from({length: pageCount}).map((_, index) => (
                <div className='page' key={index}>
                    <Box sx={{
                        borderBottom: '1px solid black',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: "100vw"
                    }}>
                        <Box sx={{alignItems: 'center', justifyContent: 'center', display: "flex"}}><h3
                            className="h3">BORDEREAU DE VENTE DE BILLETS DE BANQUE ETRANGERS</h3></Box>
                        <Box sx={{alignItems: 'center', justifyContent: 'center', display: "flex"}}><h3
                            className="h3">ET DE CHEQUE DE VOYAGE</h3></Box>
                        <Box sx={{alignItems: 'center', justifyContent: 'center', display: "flex"}}><h5
                            className="h5"> (Instruction Générale des Opérations de Change 3 Janvier 2022 - Articles 34
                            et 208)</h5></Box>
                    </Box>

                    <Box sx={{marginTop: "2vh"}}>
                        <Box sx={{marginBottom: "1vh", display: "flex"}}>
                            <Box><h3 className="h3"> INFORMATIONS POINT DE CHANGE </h3></Box>

                        </Box>
                        <Box sx={{marginLeft: "5vw"}}><p className="p2">Dénomination : {agence.nom_bureau}</p></Box>
                        <Box sx={{marginLeft: "5vw"}}><p className="p2">Identifiant : {agence.num_auto}</p></Box>
                    </Box>


                    <Box sx={{marginTop: "2vh"}}>
                        <Box sx={{marginBottom: "1vh", display: 'flex'}}>
                            <Box><h3 className="h3">REFERENCE BORDEREAU</h3></Box>
                            <Box sx={{ border: '1px solid black', padding:"1% 4%",marginLeft:'10%',marginTop:"-1%"}}><h3 className="h3">VENTE</h3></Box>

                        </Box>
                        <Box sx={{marginBottom: "1vh", display: 'flex'}}>
                            <Box sx={{marginLeft: "5vw"}}><p className="p2">Numéro : {data2.num_bvc}</p></Box>
                            <Box sx={{marginLeft: "5vw"}}><p className="p2">Date
                                : {new Date().getDate() < 10 ? "0" + (new Date().getDate()) : (new Date().getDate())}/{new Date().getMonth() + 1 < 10 ? "0" + (new Date().getMonth() + 1) : new Date().getMonth() + 1}/{new Date().getFullYear()}</p>
                            </Box>
                            <Box sx={{marginLeft: "5vw"}}><p className="p2">Heure : {data2.heure_vc}</p></Box>
                        </Box>
                    </Box>


                    <Box sx={{marginTop: "2vh"}}>
                        <Box sx={{marginBottom: "1vh", display: 'flex'}}>
                            <Box><h3 className="h3">INFORMATIONS ET IDENTIFICATION CLIENT</h3></Box>
                        </Box>
                        <Box sx={{marginBottom: "1vh"}}>
                            <Box sx={{marginX: "5vw", display: "flex", justifyContent: "space-between"}}>
                                <Box sx={{marginLeft: "5vw"}}><h4 className="h4">Personne physique</h4></Box>
                                <Box sx={{marginLeft: "5vw"}}><h4 className="h4">Personne morale</h4></Box>
                            </Box>
                            <Box sx={{marginLeft: "5vw"}}><p className="p2">Qualité du cédant : {data2.qualite_cl==="MR"? "MR | Marocains résidants" :
                                data2.qualite_cl==="MRE"? "MRE | Marocains résidants à l'étranger" : data2.qualite_cl==="ER"? "ER | Etrangers résidants" : "ENR | Touristes étrangers" }</p>
                            </Box>
                            <Box sx={{marginLeft: "5vw"}}><p className="p2">Nom et Prénom ou Raison Sociale
                                : {data3.prenom ? data3.prenom : ""} {data3.nom ? data3.nom : ""}</p></Box>
                            <Box sx={{marginLeft: "5vw"}}><p className="p2">Adresse
                                : {data3.adresse ? data3.adresse : ""}</p></Box>
                            <Box sx={{marginY: "1vh", display: 'flex'}}>
                                <Box><h3 className="h3">INFORMATIONS OPERATION</h3></Box>
                            </Box>

                            <Box sx={{marginLeft: "5vw"}}><p className="p2">Nature de l'Opération
                                : {data2.designation}</p></Box>
                            <Box sx={{marginLeft: "5vw"}}><p className="p2">Justificatif du Rachat
                                : {data3.num_auto ? data2.num_auto : ''}</p></Box>
                            <Box sx={{marginLeft: "5vw"}}><p className="p2">Numéro du justificatif
                                : {data3.date_auto ? data2.date_auto : ''}</p></Box>
                        </Box>
                    </Box>


                    <Box sx={{width: '90vw', marginLeft: "5vw", marginRight: "5vw"}}>
                        <table className="print-table">
                            <tr>
                                <th>Dénomination des devises</th>
                                <th>Montant en devises</th>
                                <th>Cours appliqués</th>
                                <th>Contre Valeur en DHS</th>
                            </tr>
                            {data.map((row) => (
                                <tr key={row.id}> {/* Assuming each row has a unique identifier (e.g., 'id') */}
                                    <td>{row.nom_devise}</td>
                                    <td align="right">{formatNumber(row.mt_vc)}</td>
                                    <td align="right">{parseFloat(row.cours_vc).toFixed(4)}</td>
                                    <td align="right">{formatNumber(row.cv_v)}</td>

                                </tr>
                            ))}
                        </table>
                        <Box sx={{
                            display: 'flex',
                            marginRight: "5vw",
                            alignItems: 'center',
                            marginTop: "2vh",
                            justifyContent: "end"
                        }}>
                            <Box sx={{
                                borderBottom: '1px dotted black',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                width: "16vw",
                                marginX: "2vw"
                            }}><h3>Total</h3></Box><Box sx={{
                            borderBottom: '1px dotted black',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: "16vw",
                            marginX: "2vw"
                        }}><h3>{formatNumber(sumCv)}</h3></Box>

                        </Box>

                        {/* Additional CSS styles for printing */}
                        <style>
                            {`
          .print-table {
            width: 100%;
            border-collapse: collapse;
          }

          .print-table th, .print-table td {
            border: 1px solid black;
            padding: 8px;
          }

          /* Additional styles specific for printing */
          @media print {
            .page{
              page-break-inside: avoid;
            }
            .print-table {
              page-break-inside: auto;
            }
            .footer{
              position: fixed;
              bottom: 0;
              border-top: 2px solid #73AD21;
              align-items: center;
              width:100vw;
              justify-content:center;
            }
            .print-table th, .print-table td {
              page-break-inside: avoid;
            }
            .p2{
              font-size:12px;
              color:black;
            }
          }
        `}
                        </style>
                        <Box sx={{
                            marginY: "2vh", display: "flex", justifyContent: "space-between",
                            ".p": {
                                fontSize: '16px',
                                color: 'black',
                            }
                        }}>
                            <Box sx={{marginLeft: "6vw"}}><p className="p">Signature du client</p></Box>
                            <Box sx={{marginLeft: "6vw"}}><p className="p">Cachet et signnature du point de change</p>
                            </Box>
                        </Box>
                    </Box>
                    <Box className="footer" sx={{paddingTop: '1vh'}}>
                        <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}><h5
                            className="h5">Adresse : {agence.adresse}</h5></Box>
                        <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}><Box
                            sx={{marginRight: "3vh"}}><h5 className="h5">Tél : {agence.tel}</h5></Box><Box><h5
                            className="h5">Email : {agence.email}</h5></Box></Box>
                        <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}><Box
                            sx={{marginRight: "3vh"}}><h5 className="h5">TP : {agence.tp}</h5></Box><Box
                            sx={{marginRight: "3vh"}}><h5 className="h5">IF : {agence.idfis}</h5></Box><Box
                            sx={{marginRight: "3vh"}}><h5 className="h5">RC : {agence.rc}</h5></Box>
                            <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}><h5
                                className="h5">ICE : {agence.ice}</h5></Box></Box>
                    </Box>
                </div>
            ))}
        </div>
    );
}

export default Index;