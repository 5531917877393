import React from 'react';
import {Box} from "@mui/material";
import {formatNumber}  from "../../../../fonctions/formatNumber";

function Index(props) {
    const {  data2,data3, agence } = props;

    const renderTableRows = () => {
        return (
            <tr>
                <th >Type d'opération</th>
                <th >Date</th>
                <th> Devise</th>
                <th >Montant</th>
                <th >Contre Valeur</th>
                <th>Utilisateur</th>
                <th >Caisse</th>

            </tr>
        );
    }


    const renderTableBody = () => {

        return (

            <>
                {data2.map((row) => (
                    <tr  >
                        <td>{row.type}</td>
                        <td>{row.date}</td>
                        <td>{row.nom_devise}</td>
                        <td align='right'>{row.montant}</td>
                        <td align='right'>{row.cv}</td>
                        <td>{row.name}</td>
                        <td>{row.caisse}</td>



                    </tr>
                ))}
            </>
        );
    };
    return (
        <div>
            {/* Agency information */}
            <Box sx={{
                borderBottom: '1px solid black',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%'
            }}>
                <h5 className="h5">{agence.nom_bureau}</h5>
                <h5 className="h5">{agence.num_auto}</h5>
            </Box>

            {/* Header */}
            <Box
                sx={{
                    border: '1px solid black',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '70%',
                    marginLeft: '15%',
                    marginY: '4vh',
                    borderRadius: '8px',
                    backgroundColor: '#ea5a27', // Change the background color here
                }}
            >
                <h2 className="h2">JOURNAL DES OPERATIONS DE CAISSE</h2>
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <Box sx={{marginRight: '5vw'}}>
                    <h5 className="h5">Du : {data3.du}</h5>
                </Box>
                <Box sx={{}}>
                    <h5 className="h5">Au : {data3.au}</h5>
                </Box>
            </Box>


            {/* Currency exchange data */}
            <Box className="tableau" sx={{width: '100%'}}>
                <table className="print-table">
                    {renderTableRows()}
                    {renderTableBody()}
                </table>


            </Box>

            {/* Additional CSS styles for printing */}
            <style>
                {`
            .print-table {
              margin-left:1%;
              width: 98%;
              border-collapse: collapse;
              margin-bottom: 10vh;
              z-index: 9;
            }
            .footer {
                margin-top: 4vh;
                border: 2px dotted #73AD21;
                align-items: center;
                width: 100%;
                justify-content: center;
                z-index: 9;
              }
            .print-table th,
            .print-table td {
              font-size:12px;
              border: 1px solid black;
              padding: 8px;
              z-index: 9;
            }

            /* Additional styles specific for printing */
            @media print {
              
              .print-table {
                z-index: 9;
              }
              
              .print-table th,
              .print-table td {
                page-break-inside: avoid;
                z-index: 9;
              }
              /* Ensure the footer appears on each page */
              .footer {
                page-break-inside: avoid;
                z-index: 9;

              }
              .tableau{
                margin-bottom: 10vh; /* Add margin at the bottom of the table */

                z-index: 9;

              }
            }
          `}
            </style>


            {/* Footer */}
            <Box sx={{paddingTop: '1vh',border:'2px dotted black'}}>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <h5 className="h5">Adresse : {agence.adresse}</h5>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Box sx={{marginRight: '3vh'}}>
                        <h5 className="h5">Tél : {agence.tel}</h5>
                    </Box>
                    <Box>
                        <h5 className="h5">Email : {agence.email}</h5>
                    </Box>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Box sx={{marginRight: '3vh'}}>
                        <h5 className="h5">TP : {agence.tp}</h5>
                    </Box>
                    <Box sx={{marginRight: '3vh'}}>
                        <h5 className="h5">IF : {agence.idfis}</h5>
                    </Box>
                    <Box>
                        <h5 className="h5">RC : {agence.rc}</h5>
                    </Box>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <h5 className="h5">ICE : {agence.ice}</h5>
                </Box>
            </Box>
        </div>
    );
}

export default Index;