import * as React from 'react';
import { Box, Typography, useTheme } from "@mui/material";

import { tokens } from "../../theme";
import { mockDataTeam } from "../../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";

import {Tabs,Tab,AppBar} from "@mui/material";
import Super from "../../components/super_admin";
import "../index.css";
import Logo from "../../data/logo.png";
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { Link } from 'react-router-dom';
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';

const Ventclients = () => {
  const history=useHistory();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const logoutsubmit=(e)=>{
    e.preventDefault();
    axios.post('/api/logout').then(res=>{
      if(res.data.status === 200){
      localStorage.removeItem("auth_token");
      localStorage.removeItem("auth_email");
     
      history.push('/');
      window.location.reload();

      }
    });
  }
  const [value,setValue]=React.useState(0);
  const handleTabs=(r,val)=>{
    console.warn(val)
    setValue(val)
  }
  return (
    <Box sx={{backgroundImage:'url(./../../bg-02.jpg)',}}>
    <Box sx={{margin:'4%',
      "& .MuiBox-root":{
        marginTop:"1em"
      }
    }}>
      <Box >
                <AppBar position="fixed"  style={{ height: "10vh", backgroundColor: "#ebeef0", color: "#ffffff" }}>
                <Toolbar>
                <Box >
                    <Typography variant="h6"  component="div">
                    <img src={Logo} style={{width:"18vw", marginY:"1vh" ,height:"8vh"}}/>
                    </Typography>
                </Box>
                <Box style={{position:"absolute",right:"2vw",marginY:'1vw'}}>
                    <Typography variant="h6"  component="div">
                    
                    
                    <Link>
                        </Link>
                        

                        <Link onClick={logoutsubmit}>
                        <IconButton>
                        <LogoutOutlinedIcon />
                        </IconButton></Link>
                    
                    </Typography>
                </Box>
                </Toolbar>
            </AppBar>
        </Box>
        <Box sx={{paddingTop:'3vh'}}>
          <Header  title="Super administrateur"  />
        </Box>
      <Box sx={{ width:"98%",
        "& .MuiPaper-root": {
          background: `${colors.primary[400]} !important`,
        },
        "& .MuiButtonBase-root": {
          
          padding: "5px 35px 5px 20px !important",
        },
        "& .MuiButtonBase-root:hover": {
          color: "#868dfb !important",
        },
        "& .MuiButtonBase-root.active": {
          color: "#6870fa !important",
        },
       }}>
        
        
      </Box>
        
      <Box
       
        height="70vh"
        
      >
         <Super/>
      
     
      </Box>
      
    </Box>
    </Box>
  );
  
};
const TabPanel = (props) => {
  const {children,value,index}=props;
  return (
    
      value===index && (
        <div>{children}</div>
      )
      
    
  );
};
export default Ventclients;
