import { Box } from "@mui/material";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import {Button} from "@mui/material";
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import axios from "axios";
import {useEffect, useState,useRef} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import { useReactToPrint } from "react-to-print";
import {formatNumber} from '../../../fonctions/formatNumber'
import PDFRapportJournalier from '../PDFContent/RapportJournalier/index'
import TableD from '../../../table/index'
class PDFContent extends React.Component {
    render() {
        // Extracting props for easier access
        const { data, data2,data3, agence } = this.props;

        return (
            <PDFRapportJournalier data={data} data2={data2} data3={data3} agence={agence}/>
        );
    }
}
const RapportJ = () => {


    const [page, setPage] =useState(0);
    const [rowsPerPage, setRowsPerPage] =useState(10);
    const [sumCv, setSumCv] = useState();
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedDevise, setSelectedDevise] = useState(null);
    const [selectedDuDate, setSelectedDuDate] = useState(dayjs());
    const [selectedAuDate, setSelectedAuDate] = useState(dayjs());
    const [etat, setEtat] = useState(["Détaillé","Globalisé"]);
    const [selectedEtat, setSelectedEtat] = useState("Détaillé");
    const [shouldPrint, setShouldPrint] = useState(false);
    const [dataToRender, setDataToRender] = useState([]);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const email=localStorage.getItem("auth_email");
    const [bordereaux,setBordereaux]=useState([]);
    const [user,setuser]=useState([]);
    const [users,setusers]=useState([]);
    const [agence,setAgence]=useState({});
    const [rows, setRows] = useState([]);
    const [data2, setData2] = useState([]);
    const componentRef = useRef();
    const [selectedRow, setSelectedRow] = useState(null);
    const [info, setInfo] = useState({
        du:'',
        au:'',
        utilisateur:'',
        devise:'',
        etat:'',
        user:'',
    });
    const [bool,setBool]=useState(true);
    /****************************************************************functions************************************************** */
    const handlePrintev = (e) => {
        e.preventDefault();
        axios.get(`api/info-agence`).then((res) => {
            if (res.data.status === 200) {
                setAgence(res.data.message);
                setShouldPrint(true);
            }
        });

        // Add checks to ensure variables are defined
        const du = convertDateFormat(selectedDuDate);
        const au = convertDateFormat(selectedAuDate);
        const utilisateur = selectedUser ? selectedUser.name : '--all--';
        const devise = selectedDevise ? selectedDevise : '--all--';
        const etat = selectedEtat ? selectedEtat : '';

        setInfo((prevInfo) => ({
            ...prevInfo,
            du: du,
            au: au,
            utilisateur: utilisateur,
            devise: devise,
            etat: etat,
            user:user,
        }));
    };
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "Print",
        pageStyle: `
    @page {
      size: A4;
      margin: 10mm;
    }
    /* Additional styles for the printed page can be added here */
    /* Remove the pagination and URL styles */
    @media print {
      .MuiDataGrid-footerContainer {
        display: none !important;
      }
      a {
        display: none !important;
      }
    }
  `,
        header: null, // Set header to null to remove it from the PDF
        footer: null,
        // Set footer to null to remove it from the PDF
    });
    const handleRowClick = (row) => {
        setSelectedRow(row);
    };
    useEffect(() => {
        if (shouldPrint) {
            // Call handlePrint() when shouldPrint becomes true
            handlePrint(); // This will trigger the printing action
            setShouldPrint(false); // Reset shouldPrint back to false
        }
    }, [shouldPrint]);
    /*************************************************************end functions********************************************** */
    /********************************************************************************UseEffect********************************** */

    useEffect(() => {
        const auDate=convertDateFormat2(selectedAuDate);
        const duDate=convertDateFormat2(selectedDuDate);
        console.log(auDate);
        console.log(duDate);

        const fetchData = async () => {
            try {
                const response = await axios.get(`api/rapport-journalier?du=${duDate}&au=${auDate}`);
                if (response.data.status === 200) {
                    const { message } = response.data;
                    setDataToRender(message);
                    setBool(false);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();

    }, [bool]);
    const [rrows,setRrows]=useState(1);
    const [devise2, setDevise2] = useState([]);

    useEffect(() => {
        const result = dataToRender.reduce((acc, item) => {
            const { date_achat, nom_devise, mt_ac, cv_ac } = item;
            const key = `${date_achat}-${nom_devise}`;

            if (acc[key]) {
                acc[key].cv_ac_sum += parseFloat(cv_ac);
                acc[key].mt_ac_sum += parseFloat(mt_ac);
            } else {
                acc[key] = {
                    date_achat,
                    nom_devise,
                    mt_ac_sum: parseFloat(mt_ac),
                    cv_ac_sum: parseFloat(cv_ac),
                };
            }

            return acc;
        }, {});

        const formattedResult = Object.values(result);

        // Update the state with the processed data
        setDevise2(formattedResult);
    }, [dataToRender]);

    /****************************************************************************end UseEffect********************************** */

    const renderTableRows = () => {
        return (
            <TableRow>
                <TableCell sx={{backgroundColor:"#a4a9fc"}}>Date</TableCell>
                <TableCell sx={{backgroundColor:"#a4a9fc"}}>N° bordereau</TableCell>
                <TableCell sx={{backgroundColor:"#a4a9fc"}}>Client</TableCell>

                <TableCell sx={{backgroundColor:"#a4a9fc"}}>Pièce d'identité</TableCell>
                <TableCell sx={{backgroundColor:"#a4a9fc"}}> Devise</TableCell>

                <TableCell sx={{backgroundColor:"#a4a9fc"}}>Dotation</TableCell>
                <TableCell align='right' sx={{backgroundColor:"#a4a9fc"}}>Achat</TableCell>

                <TableCell align='right' sx={{backgroundColor:"#a4a9fc"}}>Vente</TableCell>
                <TableCell align='right' sx={{backgroundColor:"#a4a9fc"}}>Cours Appliqué</TableCell>
                <TableCell align='right' sx={{backgroundColor:"#a4a9fc"}}>Contre valeur en Dhs</TableCell>
            </TableRow>
        );

    }

    const renderTableBody = () => {
        return (

            <TableBody>
                {dataToRender.map((row) => (
                    <TableRow onClick={() => handleRowClick(row)}
                              sx={{
                                  backgroundColor:
                                      selectedRow && selectedRow.num_bac === row.num_bac ? '#ccc' : 'inherit',
                              }}>
                        <TableCell>{row.date_achat}</TableCell>
                        <TableCell>{row.num_bac}</TableCell>
                        <TableCell>{row.qualite_cl}</TableCell>
                        <TableCell>{row.type_pid?row.type_pid:''} {row.n_pid?row.n_pid:''}</TableCell>
                        <TableCell>{row.nom_devise}</TableCell>
                        <TableCell>{row.dotation}</TableCell>
                        <TableCell align='right'>{formatNumber(row.mt_ac?row.mt_ac:'00')}</TableCell>
                        <TableCell align='right'>{formatNumber(row.mt_vc?row.mt_vc:'00')}</TableCell>

                        <TableCell align='right'>{parseFloat(row.cours_ac).toFixed(4)}</TableCell>
                        <TableCell align='right'>{formatNumber(row.cv_ac)}</TableCell>

                    </TableRow>
                ))}
            </TableBody>
        );
    };
    const renderInputs = () => {
        return (
            <>

                <Box display="flex" justifyContent="space-between"
                     sx={{
                         "& .MuiFormControl-root" : {
                             marginRight:"1rem",
                             width:"10rem",

                         },
                         "& .MuiAutocomplete-root":{
                             marginTop:"8px"
                         },
                         "& .buttons" : {
                             color: colors.grey[200],
                             backgroundColor : colors.primary[400],
                             margin: "0.5rem 0.5rem -0.2rem 0.5rem",
                             fontSize: "0.7rem",
                             height: "2.5rem",
                             paddingX: "1.2rem",
                             paddingY: "0.2rem"
                         },
                         "& .buttons:hover" : {
                             color: colors.primary[400],
                             backgroundColor : colors.grey[200],

                         },
                         "& .span1" : {


                             marginLeft: "0.5rem",

                         }
                     }}
                >
                    <Box display="flex">

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker
                                    label="DU"
                                    value={selectedDuDate}
                                    onChange={handleDuDateChange}
                                    format="DD/MM/YYYY"
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            padding: '10px 14px',
                                            fontSize: '0.875rem',
                                        },
                                    }}
                                />
                            </DemoContainer>
                        </LocalizationProvider>




                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker label="AU"
                                            value={selectedAuDate}
                                            onChange={handleAuDateChange}
                                            format="DD/MM/YYYY"
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    padding: '10px 14px',
                                                    fontSize: '0.875rem',
                                                },
                                            }}

                                />
                            </DemoContainer>
                        </LocalizationProvider>

                    </Box>
                    <Box
                        sx={{display:"flex",justifyContent:'center',justifyItems:'center',"& .buttons" : {
                                color: colors.grey[200],
                                backgroundColor : colors.primary[400],


                            },
                            "& .buttons:hover" : {
                                color: colors.primary[400],
                                backgroundColor : colors.grey[200],

                            },}}
                    >       <Button className="buttons" onClick={handlePrintev}><FindInPageOutlinedIcon/><span className="span1">Aperçu</span> </Button>
                    </Box>

                </Box></>
        );
    };

    /*****************************************************filtre functions************************************************ */


//du date filtre
    const convertDateFormat = (dateString) => {

        const parsedDate = dayjs(dateString, 'MM/DD/YYYY'); // Parse the date with the original format
        return parsedDate.format('YYYY/MM/DD'); // Format the date in the desired format
    };
    const convertDateFormat2 = (dateString) => {
        const parsedDate = dayjs(dateString, 'DD/MM/YYYY'); // Parse the date with the original format
        return parsedDate.format('YYYY-MM-DD'); // Format the date in the desired format
    };
    const convertDateFormat3 = (dateString) => {

        return  dayjs(dateString, 'MM/DD/YYYY'); // Parse the date with the original format
    };

    const handleDuDateChange = (newValue) => {
        setSelectedDuDate(newValue);

        setBool(true);
    };

// au date functions

    const handleAuDateChange = (newValue) => {
        setSelectedAuDate(newValue);
        setBool(true);
    };


    /**********************************END FILTRE FUNCTIONS*************************************** */
    const colonnesEntete = [
        { id: 'date_achat', label: 'Date', align: 'left' },
        { id: 'num_bac', label: 'N° bordereau', align: 'left' },
        { id: 'qualite_cl', label: 'Client', align: 'left' },
        { id: 'n_pid', label: "Pièce d'identité", align: 'left' },
        { id: 'nom_devise', label: 'Devise', align: 'left' },
        { id: 'dotation', label: 'Dotation', align: 'left' },
        { id: 'mt_ac', label: 'Achat', align: 'right' },
        { id: 'mt_vc', label: 'Vente', align: 'right' },
        { id: 'cours_ac', label: 'Cours Appliqué', align: 'right' },
        { id: 'cv_ac', label: 'Contre valeur en Dhs', align: 'right' },
    ];
    const colsToShow = [0,1,2,3,4,5,6,7,8,9]
    return (
        <Box  sx={{width: "95%",marginX: "2.5%",
            "& .bartitle":{
                marginY : "1.5rem",
            }
        }}>

            {renderInputs()}
            <Box>
                <TableD dataToRender={dataToRender} headCells={colonnesEntete} colsToShow={colsToShow} cols={"num"}/>
            </Box>

            <div style={{ display: 'none' }}>
                <PDFContent data={devise2} agence={agence} data2={dataToRender} data3={info} ref={componentRef} />
            </div>
        </Box>
    );
};

export default RapportJ;