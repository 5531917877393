import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import OpVente from '../op_vente_banque';
import DialogContent from '@mui/material/DialogContent';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
const AchatDialog = ({ open, handleClose }) => {
  return (
    <Dialog open={open} onClose={handleClose} TransitionComponent={Transition} fullScreen>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"

            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                OPERATION VENTE BANQUE
            </Typography>
          </Toolbar>
        </AppBar>
      <DialogContent sx={{paddingTop:'2px'}}>
        <OpVente sx={{ backgroundColor: '#58aee1' }}/> 
      </DialogContent>
    </Dialog>
  );
};

export default AchatDialog;