import React from 'react';
import { Box } from "@mui/material";
import './index.css';  // Import the external CSS file
import { formatNumber } from '../../../../fonctions/formatNumber';

function Index(props) {
    const { data, agence } = props;
    const dateActuelle = new Date();
    const dateFormatee = dateActuelle.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    });

    return (
        <div>
            {/* Informations de l'agence */}
            <Box className="agence-info">
                <h5 className="h5">{agence.nom_bureau}</h5>
                <h5 className="h5">{agence.num_auto}</h5>
            </Box>

            {/* En-tête */}
            <Box className="entete">
                <h5 className="h5"></h5>
            </Box>


            <Box className="center-box">
                <Box className="margin-right"></Box>
            </Box>

            {/* Données d'échange de devises */}
            <Box className="table-container">
                <table className="print-table">
                    <tr>
                        <th>Devise</th>
                        <th>Désignation</th>
                        <th>Unité</th>
                        <th>Copure</th>
                        <th>Compte Général</th>
                    </tr>

                    {data.map((ligne) => (
                        <tr key={ligne.nom_devise}>
                            <td>{ligne.nom_devise}</td>
                            <td>{ligne.designation}</td>
                            <td align='right'>{formatNumber(ligne.prix_par)}</td>
                            <td align='right'>{formatNumber(ligne.copure)}</td>
                            <td align='right'>{formatNumber(ligne.compte)}</td>
                        </tr>
                    ))}
                </table>
            </Box>

            {/* Pied de page */}
            <Box className="footer">
                <Box className="footer-box">
                    <h5 className="h5">Adresse : {agence.adresse}</h5>
                </Box>
                <Box className="footer-box">
                    <Box className="footer-margin-right">
                        <h5 className="h5">Tél : {agence.tel}</h5>
                    </Box>
                    <Box>
                        <h5 className="h5">Email : {agence.email}</h5>
                    </Box>
                </Box>
                <Box className="footer-box">
                    <Box className="footer-margin-right">
                        <h5 className="h5">TP : {agence.tp}</h5>
                    </Box>
                    <Box className="footer-margin-right">
                        <h5 className="h5">IF : {agence.idfis}</h5>
                    </Box>
                    <Box>
                        <h5 className="h5">RC : {agence.rc}</h5>
                    </Box>
                </Box>
                <Box className="footer-box">
                    <h5 className="h5">ICE : {agence.ice}</h5>
                </Box>
            </Box>
        </div>
    );
}

export default Index;
