import React from 'react';
import { Box } from "@mui/material";
import { formatNumber } from "../../../../fonctions/formatNumber";

function Index(props) {
    const { data, agence, data2 } = props;
    const montant = data.reduce((accumulator, currentDevise) => {
        return accumulator + currentDevise.cv_v;
    }, 0);
    const sumCommission = data.reduce((accumulator, currentDevise) => {
        return accumulator + currentDevise.com_vc;
    }, 0);
    const currentDate = new Date();

    const formattedDate = currentDate.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    });
    const pageCount = 2;

    return (
        <div>
            {Array.from({ length: pageCount }).map((_, index) => (
                <div className='page' key={index} style={{ pageBreakAfter: 'always' }}>
                    <Box sx={{
                        borderBottom: '1px solid black',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: "100vw"
                    }}>
                        <h5 className="h5">{agence.nom_bureau}</h5>
                        <h5 className="h5">{agence.num_auto}</h5>
                    </Box>
                    <Box
                        sx={{
                            border: '1px solid black',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '50vw',
                            marginLeft: '25vw',
                            marginY: '4vh',
                            borderRadius: '8px',
                            backgroundColor: '#ea5a27', // Change the background color here
                        }}
                    >
                        <h2 className="h2">ETAT DE VENTE BANQUE</h2>
                    </Box>
                    <Box sx={{ width: '80vw', marginBottom: '2vh', marginLeft: "7.5vw", marginRight: "7.5vw" }}>
                        <h5 className="h5">Date Vente : {formattedDate} </h5>
                        <h5 className="h5">N° Vente : {data2.num_bvc}</h5>
                        <h5 className="h5">Banque: {data2.designation}</h5>
                    </Box>
                    <Box sx={{ width: '90vw', marginLeft: "5vw", marginRight: "5vw" }}>
                        <table className="print-table">
                            <thead>
                            <tr>
                                <th>Devise</th>
                                <th>Montant en devise</th>
                                <th>Cours Vente</th>
                                <th>Contre valeur en DHS</th>
                                <th>Commission</th>
                            </tr>
                            </thead>
                            <tbody>
                            {data.map((row) => (
                                <tr key={row.nom_devise}> {/* Assuming each row has a unique identifier (e.g., 'id') */}
                                    <td>{row.nom_devise}</td>
                                    <td align="right">{formatNumber(row.mt_vc)}</td>
                                    <td align="right">{parseFloat(row.cours_vc).toFixed(4)}</td>
                                    <td align="right">{formatNumber(row.cv_v)}</td>
                                    <td align="right">{formatNumber(row.com_vc)}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        <Box sx={{
                            display: 'flex',
                            marginLeft: "30vw",
                            width: "65vw",
                            marginRight: "5vw",
                            alignItems: 'center',
                            marginTop: "2vh"
                        }}>
                            <Box sx={{
                                borderBottom: '1px dotted black',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                width: "16vw",
                                marginX: "2vw"
                            }}><h3>Total</h3></Box>
                            <Box sx={{
                                borderBottom: '1px dotted black',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                width: "16vw",
                                marginX: "2vw"
                            }}><h3>{formatNumber(montant)}</h3></Box>
                            <Box sx={{
                                borderBottom: '1px dotted black',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                width: "16vw",
                                marginX: "2vw"
                            }}><h3>{formatNumber(sumCommission)}</h3></Box>
                        </Box>

                        {/* Additional CSS styles for printing */}
                        <style>
                            {`
                                .print-table {
                                    width: 100%;
                                    border-collapse: collapse;
                                }

                                .print-table th, .print-table td {
                                    border: 1px solid black;
                                    padding: 8px;
                                }

                                @media print {
                                    .page {
                                        page-break-inside: avoid;
                                        page-break-after: always;
                                    }
                                    .print-table {
                                        page-break-inside: auto;
                                    }
                                    .footer {
                                        position: fixed;
                                        bottom: 0;
                                        border-top: 2px solid #73AD21;
                                        align-items: center;
                                        width: 100vw;
                                        justify-content: center;
                                    }
                                    .print-table th, .print-table td {
                                        page-break-inside: avoid;
                                    }
                                    .p2 {
                                        font-size: 12px;
                                        color: black;
                                    }
                                }
                            `}
                        </style>
                    </Box>
                    <Box className="footer" sx={{ paddingTop: '1vh' }}>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <h5 className="h5">Adresse : {agence.adresse}</h5>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Box sx={{ marginRight: "3vh" }}><h5 className="h5">Tél : {agence.tel}</h5></Box>
                            <Box><h5 className="h5">Email : {agence.email}</h5></Box>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Box sx={{ marginRight: "3vh" }}><h5 className="h5">TP : {agence.tp}</h5></Box>
                            <Box sx={{ marginRight: "3vh" }}><h5 className="h5">IF : {agence.idfis}</h5></Box>
                            <Box sx={{ marginRight: "3vh" }}><h5 className="h5">RC : {agence.rc}</h5></Box>
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <h5 className="h5">ICE : {agence.ice}</h5>
                            </Box>
                        </Box>
                    </Box>
                </div>
            ))}
        </div>
    );
}

export default Index;
