import React, { createContext, useContext, useState } from 'react';

// Create a context for managing the selected row
const SelectedRowContext = createContext();

// Custom hook to access the selected row context
export const useSelectedRow = () => useContext(SelectedRowContext);

// Provider component for the selected row context
export const SelectedRowProvider = ({ children }) => {
    // State to store the selected row
    const [selectedRow, setSelectedRow] = useState(null);

    // Function to update the selected row
    const updateSelectedRow = (row) => {
        setSelectedRow(row);
    };

    // JSX to provide the context value to its children
    return (
        <SelectedRowContext.Provider value={{ selectedRow, updateSelectedRow }}>
            {children}
        </SelectedRowContext.Provider>
    );
};
