import { Box } from "@mui/material";
import { DataGrid} from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataContacts } from "../../data/mockData";
import Header from "../Header";
import { useTheme } from "@mui/material";
import {Button} from "@mui/material";
import { hover } from "@testing-library/user-event/dist/hover";
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import { Padding } from "@mui/icons-material";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {useEffect, useState,useRef} from 'react';
import * as React from 'react';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import SystemUpdateAltOutlinedIcon from '@mui/icons-material/SystemUpdateAltOutlined';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import axios from "axios";
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import swal from "sweetalert";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import { Link } from 'react-router-dom';

const Statistiques = () => {
    const history=useHistory();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const users=["user1","user2"];
  const [page, setPage] =useState(0);
  const [rowsPerPage, setRowsPerPage] =useState(10);
  const [caisses1,setCaisses1]=useState([]);
  const [caisses2,setCaisses2]=useState([]);
  const [selectedCiasse,setSelectedCaisse]=useState(null);
  const [types,setTypes]=useState(["Globalisé","Détaillé"]);
  const [rows,setRows]=useState([]);
  const [rows2,setRows2]=useState([]);
  const [rows3,setRows3]=useState([]);
  const [rows4,setRows4]=useState([]);
  const [selected,setSelected]=useState({
    type:"Globalisé",
    bool:'false',
  });
 const [soldeDe,setSoldeDe]=useState();
 const [soldeAc,setSoldeAc]=useState();
 const [selectedDu, setSelectedDu] = useState(null);
 const [selectedAu, setSelectedAu] = useState(null);
 let bool2=true;
let bool=false;
const [selectedRow, setSelectedRow] = useState([]);
const [selectedRow2, setSelectedRow2] = useState([]);
const [selectedRows, setSelectedRows] = useState([]);
const [selectedRows2, setSelectedRows2] = useState([]);
const [observation,setObservation]=useState();
const [montant,setMontant]=useState();

 /**************************************************functions***************************************** */
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
 
 


const handleDuChange = (event, newValue) => {
  setSelectedDu(newValue);
  
};
const handleAuChange = (event, newValue) => {
    setSelectedAu(newValue);
    
  };
  const handleRowClick = (row) => {
    setMontant(row.montant);
    setSelectedRow(row);
  };

  const handleRowClick2 = (row) => {
    setMontant(row.montant);
    setSelectedRow2(row);
  };
  const handleObservationChange = (event) => {
    const newValue = event.target.value;
    setObservation(newValue);
  };
  const handleMontantChange = (event) => {
    const newValue = event.target.value;
    setMontant(newValue);
  };
  const handleBLeft = () => {
    if(montant){
        if(selectedAu){
            if(selectedRow2){
                if(selectedRow2.montant>=montant){
                    const da={
                        'id_devise':selectedRow2.id_devise,
                        'montant':parseFloat(montant),
                        'cv_ac':(parseFloat(selectedRow2.id_devise)/parseFloat(selectedRow2.montant))*parseFloat(montant),
                        'nom_devise':selectedRow2.nom_devise,
                    };
                    const rw=mergeRowsByDevise(rows2, da);
                    setRows2(rw);
                    const updatedStat = rows3.map((item) => {
                        if (item.id_devise === selectedRow2.id_devise) {
                          // If the id matches, create a new object with the updated value
                          return { ...item, montant:parseFloat(selectedRow2.montant)-parseFloat(montant),
                          cv_ac:parseFloat(selectedRow2.id_devise)-(parseFloat(selectedRow2.id_devise)/parseFloat(selectedRow2.montant))*parseFloat(montant), };
                        } else {
                          // For other rows, keep the existing object as-is
                          return item;
                        }
                      });
                      const filteredStat = updatedStat.filter((item) => item.montant !== 0);
                      setSelectedRow2([]);
                      setMontant("");
                    setRows3(filteredStat);
                }else{
                    swal('',"Opération interdit.",'warning');
                  }
                
            }else{
                swal('',"Opération interdit.",'warning');
              }
        }else{
            swal('',"Opération interdit.",'warning');
          }
    }else{
        swal('',"Opération interdit.",'warning');
      }
  };
  const handleBRight = () => {
    if(montant){
        if(selectedAu){
            if(selectedRow){
                if(selectedRow.montant>=montant){
                    const da={
                        'id_devise':selectedRow.id_devise,
                        'montant':parseFloat(montant),
                        'cv_ac':(parseFloat(selectedRow.id_devise)/parseFloat(selectedRow.montant))*parseFloat(montant),
                        'nom_devise':selectedRow.nom_devise,
                    };
                    const rw=mergeRowsByDevise(rows3, da);
                    setRows3(rw);
                    const updatedStat = rows2.map((item) => {
                        if (item.id_devise === selectedRow.id_devise) {
                          // If the id matches, create a new object with the updated value
                          return { ...item, montant:parseFloat(selectedRow.montant)-parseFloat(montant),
                          cv_ac:parseFloat(selectedRow.id_devise)-(parseFloat(selectedRow.id_devise)/parseFloat(selectedRow.montant))*parseFloat(montant), };
                        } else {
                          // For other rows, keep the existing object as-is
                          return item;
                        }
                      });
                      const filteredStat = updatedStat.filter((item) => item.montant !== 0);
                      setSelectedRow([]);
                      setMontant("");
                    setRows2(filteredStat);
                }else{
                    swal('',"Opération interdit.",'warning');
                  }
            }else{
                swal('',"Opération interdit.",'warning');
              }
        }else{
            swal('',"Opération interdit.",'warning');
          }
    }else{
        swal('',"Opération interdit.",'warning');
      }
  };
  const submitall=(e)=>{
  
    e.preventDefault();
    
    
    const data={
      idCaisse: selectedDu.id,
      id_beneficiaire: selectedAu.id,
      observation:observation,
      rows: rows3.map((row) => ({
        id_devise: row.id_devise,
        montant: row.montant,
        contre_valeur: row.cv_ac,
        
      })),
  
    }
    console.log(data);
   swal({
      title: '',
      text: 'Voulez vous vraiment valider cette operation ?',
      icon: 'warning',
      buttons: ['Annuler', 'Oui'],
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        // User clicked "Oui," proceed with form submission
        axios
          .post('api/passage-devise', data)
          .then((res) => {
            // ... Handle the response from the server ...
            if (res.data.status === 200) {
                history.push('/admin/gestioncaisse');
            } else if (res.data.status === 400) {
                swal(
                  '',
                  "Veuillez respecter les coupures.",
                  'warning'
                );
              } 
          })
          .catch((error) => {
            // Handle any error that occurred during the request
            console.error('Error submitting form:', error);
          });
      }
    });
    
    
    
        
  }
/***********************************************useeffect****************************************************** */
function mergeRowsByDevise(rows, newDa) {
    const updatedRows = rows.map(row => {
        if (row.id_devise === newDa.id_devise) {
            // If the id_devise already exists, update the row with the new data
            return {
                ...row,
                montant: row.montant + newDa.montant,
                cv_ac: (parseFloat(row.id_devise) / parseFloat(row.montant)) * (row.montant + newDa.montant)
            };
        } else {
            // If the id_devise doesn't exist, keep the original row
            return row;
        }
    });

    // If the id_devise is not found in the existing rows, add a new entry
    const foundIndex = updatedRows.findIndex(row => row.id_devise === newDa.id_devise);
    if (foundIndex === -1) {
        updatedRows.push(newDa);
    }

    return updatedRows;
}

  
useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`api/detail-caisse`);
        if (response.data.status === 200) {
          const { caisse,message,caisses } = response.data;
          setSelectedDu(caisse);
          setCaisses1((cai)=>[...cai,caisse]);
            const cai=caisse;
            
            setRows(message);
            setRows2(message);
            setCaisses2(caisses);

        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();

  }, []);


useEffect(() => {
  // Calculate the sum of cv_ac for each unique date_achat and nom_devise
  const result = rows4.reduce((acc, item) => {
    const { montant,nom_devise } = item;
    const key = `${nom_devise}`;

    if (acc[key]) {
      acc[key] += montant;
    } else {
      acc[key] = montant;
    }

    return acc;
  }, {});

  // Format the data into an array of objects with date_achat, nom_devise, and cv_ac_sum
  const formattedResult = Object.keys(result).map((key) => {
    const [nom_devise] = key.split("-");
    return {
      nom_devise,
      montant: result[key],
    };
  });

  // Update the state with the processed data
  setRows4(formattedResult);
}, [rows4]);
  /************************************return**************************************** */
  
 
  return (
    <Box marginTop="11vh" marginY="10vh" marginX='2vh' >
      
      
     
     <Box
      className="bartitle"
      backgroundColor={colors.primary[400]}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center', // Alignement vertical centré
        padding: '10px', // Espace autour du contenu
      }}
    >
      <Box
        textAlign="center"
        sx={{
          "& .h1": {
            color: colors.grey[500],
            margin: 0, // Supprime la marge par défaut du h1
          },
        }}
      >
        <h1 className="h1">PASSAGE DEVISE</h1>
      </Box>
      <Box sx={{marginLeft:'4vw',marginTop:'1.5vh'}}>
        <Link to='/admin/gestioncaisse'><CancelPresentationIcon /></Link>
      </Box>
    </Box>
      
        
        
       
    <Box className="type3"
      > 
      </Box>
      <Box display="flex" justifyContent="space-between"
        sx={{"& .solde":{
          marginTop:"20vh"
        }}}
      >
        <Box 
              width="35vw">
          <Box  
              marginTop="2rem"
              height="75vh"
              sx={{
              "& .MuiDataGrid-root": {
                  border: "none",
              },
              "& .MuiDataGrid-cell": {
                  borderBottom: "none",
              },
              "& .name-column--cell": {
                  color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${colors.grey[100]} !important`,
              },
              }}
          >
              <Autocomplete
                disabled
                id="combo-box-demo"
                options={caisses1}
                value={selectedDu}
                onChange={handleDuChange} // Update this line
                getOptionLabel={(option) => option.designation}
                renderInput={(params) => <TextField {...params} label="Du" />} 
                    />
              <Paper >
                <TableContainer  sx={{height:"65vh",marginTop:"3vh",backgroundColor:"#f2f0f0"}} >
                  <Table stickyHeader aria-label="sticky table" >
                    <TableHead sx={{backgroundColor:"#a4a9fc"}}>
                      <TableRow >
                        <TableCell sx={{backgroundColor:"#a4a9fc"}}>Devise</TableCell>
                        <TableCell sx={{backgroundColor:"#a4a9fc"}}>Montant</TableCell>
                      </TableRow>
                      
                    </TableHead>
                    <TableBody>
                      {rows2.map((row) => (
                        <TableRow 
                            key={row.id_devise}
                            onClick={()=>handleRowClick(row)}
                            sx={{
                            backgroundColor:
                                selectedRow && selectedRow.id_devise === row.id_devise ? '#ccc' : 'inherit',
                            }}
                        >
                          <TableCell>{row.nom_devise}</TableCell>
                          <TableCell>{parseFloat(row.montant).toFixed(2)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                
              </Paper>
      
        
    </Box>
    </Box>
    <Box width="20vw" sx={{display: "flex",
            justifyContent: "center",
            alignItems: "center"}}>
        <Box>
            <Box sx={{display: "flex",
            justifyContent: "center",marginBottom:"2vh"
                }}>
                <TextField
                whidth="80%"
                id="outlined-multiline-static"
                label="Observation"
                multiline
                rows={4}
                value={observation}
                onChange={handleObservationChange}
                />
            </Box>
        <Box sx={{display:"flex",justifyContent:"center"}}>
            <Button className="buttons"  onClick={handleBLeft} ><ArrowBackIcon sx={{whidth:"25%"}}/></Button>
            <TextField
            sx={{whidth:"50%"}}
            id="filled-multiline-flexible"
            label=""
            variant="filled"
            value={montant}
            onChange={handleMontantChange}
            type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
            />
            <Button onClick={handleBRight} className="buttons"><ArrowForwardIcon sx={{whidth:"25%"}}/></Button>
        </Box>
        <Box sx={{display:"flex",justifyContent:"center",marginTop:"10vh","& .MuiFormControl-root" : {
                  marginRight:"1rem",
                  width:"6rem",
                  
                },
                "& .MuiAutocomplete-root":{
                  marginTop:"8px"
                },
                "& .buttons" : {
                  color: colors.grey[200],
                  backgroundColor : colors.primary[700],
                  margin: "0.5rem",
                  marginTop:"1rem",
                  fontSize: "0.8rem",
                  height: "3.4rem" ,
                  paddingX:"1.5rem"
              },
              "& .buttons:hover" : {
                  color: colors.primary[400],
                  backgroundColor : colors.grey[200],
                  
                  margin: "0.5rem",
                  marginTop:"1rem",
                  fontSize: "0.9rem"
              },
              "& .span1" : {
                  
                  
                  
              }}}>
            <Button className="buttons" onClick={submitall}><span className="span1">Valider</span> </Button>
        </Box>
        </Box>
    </Box>
      <Box 
                width="35vw">
            <Box  
                marginTop="2rem"
                height="75vh"
                sx={{
                "& .MuiDataGrid-root": {
                    border: "none",
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                },
                "& .name-column--cell": {
                    color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.blueAccent[700],
                    borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${colors.grey[100]} !important`,
                },
                }}
            >
                <Autocomplete
                id="combo-box-demo"
                options={caisses2}
                value={selectedAu}
                onChange={handleAuChange} // Update this line
                getOptionLabel={(option) => option.designation}
                renderInput={(params) => <TextField {...params} label="Au" />} 
                    />
                <Paper >
                <TableContainer  sx={{height:"65vh",marginTop:"3vh",backgroundColor:"#f2f0f0"}} >
                  <Table stickyHeader aria-label="sticky table" >
                    <TableHead sx={{backgroundColor:"#a4a9fc"}}>
                      <TableRow >
                        <TableCell sx={{backgroundColor:"#a4a9fc"}}>Devise</TableCell>
                        <TableCell sx={{backgroundColor:"#a4a9fc"}}>Montant</TableCell>
                      </TableRow>
                      
                    </TableHead>
                      <TableBody>
                        {rows3.map((row) => (
                          <TableRow  key={row.id_devise}
                          onClick={()=>handleRowClick2(row)}
                          sx={{
                            backgroundColor:
                              selectedRow2 && selectedRow2.id_devise === row.id_devise ? '#ccc' : 'inherit',
                          }} >
                            <TableCell>{row.nom_devise}</TableCell>
                            <TableCell>{parseFloat(row.montant).toFixed(2)}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                  </Table>
                </TableContainer>
                
              </Paper>
        
          
          
        
      </Box>
      </Box>   
        
      </Box>
    </Box>
  );
};

export default Statistiques;
