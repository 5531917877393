import { Box, useStepContext } from "@mui/material";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import {Button} from "@mui/material";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useState } from "react";
import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import dayjs from 'dayjs';
import Table from '../../../table/index';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import axios from "axios";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';


const Consultation = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const users=["EUR","USD","GBP","CAD","CHF","GIP","SEK","NOK","JPY","SAR","KWD","AED","QAR","BHD","DKK","OMR"];
    const [selectedDuDate, setSelectedDuDate] = useState(dayjs());
    const [selectedAuDate, setSelectedAuDate] = useState(dayjs());
    const [bool, setBool] = useState(1);
    const [page, setPage] =useState(0);
    const [rowsPerPage, setRowsPerPage] =useState(10);
    const [dataToRender, setDataToRender] = useState([]);
    const [selectedDevise, setSelectedDevisee] = useState(users[0]);

    /************************************************functions**************************** */
    const convertDateFormat2 = (dateString) => {
        const parsedDate = dayjs(dateString, 'DD/MM/YYYY'); // Parse the date with the original format
        return parsedDate.format('YYYY-MM-DD'); // Format the date in the desired format
    };

    const handleDuDateChange = (newValue) => {
        setSelectedDuDate(newValue);
    }
    const handleAuDateChange = (newValue) => {
        setSelectedAuDate(newValue);
    }
    const handleDeviseChange = (event,newValue) => {
        setSelectedDevisee(newValue);
    }

    const renderTable = () => {
        if (bool === 1) {
            return (
                <Box >
                    <Table dataToRender={dataToRender} headCells={colonnesEntete} colsToShow={colsToShow} cols={"num_bac"}/>

                </Box>
            );

        }else{
            return (
                <TableRow>
                    <TableCell sx={{backgroundColor:"#a4a9fc"}}>Date</TableCell>
                    <TableCell sx={{backgroundColor:"#a4a9fc"}}>Devise</TableCell>
                    <TableCell sx={{backgroundColor:"#a4a9fc"}}>Montant</TableCell>
                    <TableCell sx={{backgroundColor:"#a4a9fc"}}>Contre valeur</TableCell>
                </TableRow>
            );
        }}
    const getInfo=(e)=>{
        e.preventDefault();
        if (selectedDevise) {
            let duu = convertDateFormat2(selectedDuDate).toString();
            let auu = convertDateFormat2(selectedAuDate).toString();
            let devisee = selectedDevise;
            axios.get(`api/devise-consultation?du=${duu}&au=${auu}&devise=${devisee}`)
                .then((res) => {
                    if (res.data.status === 200) {
                        setDataToRender(res.data.message);
                        console.log(res.data.message);
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }

    const renderGraph = () => {
        if (dataToRender && dataToRender.length !== 0) {
            const dates = dataToRender.map((op) => op.date);
            const tauxA = dataToRender.map((op) => op.tauxAchat);
            const tauxVC = dataToRender.map((op) => op.tauxVenteClient);
            const tauxVB = dataToRender.map((op) => op.tauxVenteBanque);

            const data = dates.map((date, index) => ({
                date,
                tauxA: tauxA[index],
                tauxVC: tauxVC[index],
                tauxVB: tauxVB[index],
            }));

            return (
                <Box zIndex="1" marginTop="5rem" height="75vh">
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart data={data}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip />
                            <Line type="monotone" dataKey="tauxA" stroke="#8884d8" />
                            <Line type="monotone" dataKey="tauxVC" stroke="#82ca9d" />
                            <Line type="monotone" dataKey="tauxVB" stroke="#ffc658" />
                        </LineChart>
                    </ResponsiveContainer>
                </Box>
            );
        } else {
            // Handle the case when dataToRender is empty or undefined
            return null; // or any other suitable fallback component/message
        }
    };

    const colonnesEntete = [
        { id: 'date', label: "Date", align: 'left' },
        { id: 'tauxAchat', label: 'Taux achat', align: 'left' },
        { id: 'tauxVenteClient', label: 'Taux vente client', align: 'left' },
        { id: 'tauxVenteBanque', label: 'Taux vente banque', align: 'right' }
    ];
    const colsToShow=[4,0,1, 2];
    return (
        <Box className="type2 container-table">

            <Box display="flex" justifyContent="space-between"
                 sx={{
                     "& .MuiFormControl-root" : {
                         marginRight:"1rem",
                         width:"9rem",

                     },
                     "& .MuiAutocomplete-root":{
                         marginTop:"8px"
                     },
                     "& .buttons" : {
                         color: colors.grey[200],
                         backgroundColor : colors.primary[400],
                         margin: "0.5rem 0.5rem -0.2rem 0.5rem",
                         fontSize: "0.7rem",
                         height: "2.5rem",
                         paddingX: "1.2rem",
                         paddingY: "0.2rem"
                     },
                     "& .buttons:hover" : {
                         color: colors.primary[400],
                         backgroundColor : colors.grey[200],
                     },
                     "& .span1" : {


                         marginLeft: "0.5rem",

                     }
                 }}
            >
                <Box display="flex">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                            <DatePicker
                                label="DU"
                                value={selectedDuDate}
                                onChange={handleDuDateChange}
                                format="DD/MM/YYYY"
                                sx={{
                                    '& .MuiInputBase-input': {
                                        padding: '10px 14px',
                                        fontSize: '0.875rem',
                                    },
                                }}
                            />
                        </DemoContainer>
                    </LocalizationProvider>




                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                        <DemoContainer components={['DatePicker']}>
                            <DatePicker label="AU"
                                        value={selectedAuDate}
                                        onChange={handleAuDateChange}
                                        format="DD/MM/YYYY"
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                padding: '10px 14px',
                                                fontSize: '0.875rem',
                                            },
                                        }}

                            />
                        </DemoContainer>
                    </LocalizationProvider>

                    <Autocomplete className="Autocomplete"
                                  size="small"
                                  disablePortal
                                  id="combo-box-demo"
                                  options={users}
                                  value={selectedDevise}
                                  onChange={handleDeviseChange}

                                  renderInput={(params) => <TextField {...params} label="Devise" />}
                                  disableClearable={true}
                    />
                    <Button className="buttons" onClick={getInfo}><SearchOutlinedIcon/><span className="span1">Recherche</span> </Button>

                </Box>

            </Box>
            <Box>
                {renderTable()}
            </Box>

            <Box>
                {renderGraph()}
            </Box>

        </Box>
    );
};

export default Consultation;
