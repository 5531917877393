import React from 'react';
import {Box} from "@mui/material";
import {formatNumber} from "../../../../fonctions/formatNumber";

function Index(props) {
    const { data, data2,data3, agence } = props;

    const ac = data2.reduce((accumulator, currentDevise) => {
        const numericValue = parseFloat(currentDevise.cv_ac.replace(/[^0-9.-]/g, ''));
        return accumulator + (isNaN(numericValue) ? 0 : numericValue);
    }, 0);
    const vc = data2.reduce((accumulator, currentDevise) => {
        const numericValue = parseFloat(currentDevise.cv_vc.replace(/[^0-9.-]/g, ''));
        return accumulator + (isNaN(numericValue) ? 0 : numericValue);
    }, 0);
    const vb = data2.reduce((accumulator, currentDevise) => {
        const numericValue = parseFloat(currentDevise.cv_vb.replace(/[^0-9.-]/g, ''));
        return accumulator + (isNaN(numericValue) ? 0 : numericValue);
    }, 0);

    const renderTableRows = () => {
        return (
            <tr>
                <th >Devise</th>
                <th >Achat</th>
                <th >Contre Valeur Achat</th>

                <th >Vente</th>
                <th >Contre Valeur Vente</th>

                <th >Cession</th>
                <th >Contre Valeur Cession</th>


            </tr>
        );

    }


    const renderTableBody = () => {
        return (

            <>
                {data2.map((row) => (
                    <tr  >
                        <td>{row.devise}</td>
                        <td align='right'>{row.mt_ac}</td>
                        <td align='right'>{row.cv_ac}</td>
                        <td align='right'>{row.mt_vc}</td>
                        <td align='right'>{row.cv_vc}</td>
                        <td align='right'>{row.mt_vb}</td>
                        <td align='right'>{row.cv_vb}</td>
                    </tr>
                ))}
                <tr>
                    <th >Total</th>
                    <th>Achat :</th>
                    <th align='right'>{formatNumber(ac)}</th>
                    <th>Vente :</th>

                    <th align='right' >{formatNumber(vc)}</th>
                    <th >Cession :</th>
                    <th align='right'>{formatNumber(vb)}</th>

                </tr>
            </>
        );
    };
    return (
        <div>
            {/* Agency information */}
            <Box sx={{
                borderBottom: '1px solid black',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100vw'
            }}>
                <h5 className="h5">{agence.nom_bureau}</h5>
                <h5 className="h5">{agence.num_auto}</h5>
            </Box>

            {/* Header */}
            <Box
                sx={{
                    border: '1px solid black',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '80vw',
                    marginLeft: '10vw',
                    marginY: '4vh',
                    borderRadius: '8px',
                    backgroundColor: '#ea5a27', // Change the background color here
                }}
            >
                <h2 className="h2">JOURNAL DES OPÉRATIONS DU BUREAU</h2>
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <Box sx={{marginRight: '5vw'}}>
                    <h5 className="h5">Du : {data3.du}</h5>
                </Box>
                <Box sx={{}}>
                    <h5 className="h5">Au : {data3.au}</h5>
                </Box>
            </Box>


            {/* Currency exchange data */}
            <Box sx={{width: '100%'}}>
                <table className="print-table">
                    {renderTableRows()}
                    {renderTableBody()}
                </table>


            </Box>

            {/* Additional CSS styles for printing */}
            <style>
                {`
            .print-table {
              margin-left: 1%;
              width: 98%;
              border-collapse: collapse;
            }

            .print-table th,
            .print-table td {
              font-size:12px;
              border: 1px solid black;
              padding: 8px;
              font-size: 12px;
            }
            .footer {
                margin-top: 4vh;
                border-top: 2px solid black;
                align-items: center;
                width: 100%;
                justify-content: center;
                z-index: 9;
              }
            /* Additional styles specific for printing */
            @media print {
              .print-table {
                page-break-inside: auto;
              }
              
              .print-table th,
              .print-table td {
                page-break-inside: avoid;
              }
              /* Ensure the footer appears on each page */
              .footer {
                page-break-inside: avoid;
                z-index: 9;
              }
            }
          `}
            </style>


            {/* Footer */}
            <Box className="footer" sx={{paddingTop: '1vh'}}>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <h5 className="h5">Adresse : {agence.adresse}</h5>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Box sx={{marginRight: '3vh'}}>
                        <h5 className="h5">Tél : {agence.tel}</h5>
                    </Box>
                    <Box>
                        <h5 className="h5">Email : {agence.email}</h5>
                    </Box>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Box sx={{marginRight: '3vh'}}>
                        <h5 className="h5">TP : {agence.tp}</h5>
                    </Box>
                    <Box sx={{marginRight: '3vh'}}>
                        <h5 className="h5">IF : {agence.idfis}</h5>
                    </Box>
                    <Box>
                        <h5 className="h5">RC : {agence.rc}</h5>
                    </Box>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <h5 className="h5">ICE : {agence.ice}</h5>
                </Box>
            </Box>
        </div>
    );
}

export default Index;