import { Box } from "@mui/material";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import {Button} from "@mui/material";
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {useEffect, useState,useRef} from 'react';
import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import axios from "axios";
import { useReactToPrint } from "react-to-print";
import {formatNumber} from '../../../fonctions/formatNumber'
import PDF from '../PDFContent/CaisseActuelle/index'

class PDFContent extends React.Component {
    render() {
        // Extracting props for easier access
        const { rows2,rows3, data2, agence,selected ,user,caisses} = this.props;

        return (
            <PDF rows2={rows2} rows3={rows3} data2={data2} agence={agence} selected={selected} user={user} caisses={caisses}/>
        );
    }
}

const Statistiques = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [page, setPage] =useState(0);
    const [rowsPerPage, setRowsPerPage] =useState(10);
    const [caisse,setCaisse]=useState([]);
    const [selectedCaisse,setSelectedCaisse]=useState(null);
    const [types,setTypes]=useState(["Globalisé","Détaillé"]);
    const [rows,setRows]=useState([]);
    const [rows2,setRows2]=useState([]);
    const [rows3,setRows3]=useState([]);
    const [selected,setSelected]=useState({
        type:"Globalisé",
        bool:true,
    });
    const [user, setuser] = useState();
    const [agence, setAgence] = useState([]);
    const [soldeDe,setSoldeDe]=useState();
    const [soldeAc,setSoldeAc]=useState();
    const [data2, setData2] = useState({});
    const [shouldPrint, setShouldPrint] = useState(false);
    const componentRef = useRef();

    /**************************************************functions***************************************** */
    function formatDateToDDMMYYYY(date) {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    }
    const handlePrintev= (e) => {
        e.preventDefault();
        const date=formatDateToDDMMYYYY(new Date()) ;
        setData2({
            'type':"selected.type",
            'date':date,
            'caisse':selectedCaisse? selectedCaisse.designation:'--all--',
        });

        setShouldPrint(true);
    };
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "",
        pageStyle: `
    @page {
      size: A4;
      margin: 10mm;
    }
    /* Additional styles for the printed page can be added here */
    /* Remove the pagination and URL styles */
    @media print {
      .MuiDataGrid-footerContainer {
        display: none !important;
      }
      a {
        display: none !important;
      }
    }
  `,
        header: null, // Set header to null to remove it from the PDF
        footer: null,
        // Set footer to null to remove it from the PDF
    });
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };


    const handleTypeFilterChange=(event, newValue)=>{
        setSelected((prevStete)=>({...prevStete,type: newValue}));

    }

    const renderTableRows = () => {
        if (selected.type === "Détaillé") {
            return (
                <TableRow>
                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Devise</TableCell>
                    <TableCell align='right' sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Montant</TableCell>
                    <TableCell align='right' sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Cours</TableCell>
                    <TableCell align='right' sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Contre valeur</TableCell>
                </TableRow>
            );

        }else{
            return (
                <TableRow >
                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Devise</TableCell>
                    <TableCell align='right' sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Montant</TableCell>
                </TableRow>
            );
        }}
    const [selectedRow, setSelectedRow] = useState(null);

    const handleRowClick = (row) => {
        setSelectedRow(row);
    };
    const renderTableBody = () => {
        if (selected.type === "Détaillé") {
            return (

                <TableBody>
                    {rows2.map((row) => (
                        row.montant !== 0 && row.nom_devise !== 'DHS' ? (
                            <TableRow  key={row.id} onClick={() => handleRowClick(row)}
                                       sx={{
                                           backgroundColor:
                                               selectedRow && selectedRow.id === row.id ? '#ccc' : 'inherit',
                                       }}
                            >
                                <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{row.nom_devise}</TableCell>
                                <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}} align='right'>{formatNumber(row.montant)}</TableCell>
                                <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}} align='right'>{parseFloat(row.cours_ac).toFixed(4)}</TableCell>
                                <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}} align='right'>{formatNumber(row.cv)}</TableCell>

                            </TableRow>
                        ) : null
                    ))}
                </TableBody>
            );}else{
            return (

                <TableBody>
                    {rows3.map((row) => (
                        row.montant !== 0 && row.nom_devise !== 'DHS'? (
                            <TableRow key={row.id} onClick={() => handleRowClick(row)}
                                      sx={{
                                          backgroundColor:
                                              selectedRow && selectedRow.id === row.id ? '#ccc' : 'inherit',
                                      }}
                            >
                                <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{row.nom_devise}</TableCell>
                                <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}} align='right'>{formatNumber(row.montant)}</TableCell>
                            </TableRow>
                        ) : null
                    ))}
                </TableBody>
            );
        }
    };

    const handleCaissesFilterChange = (event, newValue) => {
        setSelectedCaisse(newValue);
        let rs=rows;
        rs=handleDeviseFilterChange2( newValue,rs)
        setSoldeAc(calculateSum2(rs));
        setSoldeDe(calculateSum(rs))
        setRows2(rs);

    };


    const handleDeviseFilterChange2 = ( newValue,bor) => {
        if(newValue){
            const filteredItems = bor.filter((item) =>
                item.id_caisse===newValue.id
            );

            return filteredItems;
        }else{

            return bor;
        }


    }
    const calculateSum2=(newValue) => {
        const newSumCv = newValue.reduce((accumulator, currentDevise) => {


            return accumulator + parseFloat(currentDevise.cv_f);
        }, 0);
        return newSumCv;
    }
    const calculateSum=(newValue) => {
        const newSumCv = newValue.reduce((accumulator, currentDevise) => {
            return accumulator + parseFloat(currentDevise.cv);
        }, 0);
        return newSumCv;
    }
    /***********************************************useeffect****************************************************** */
    useEffect(() => {
        if (shouldPrint) {
            // Call handlePrint() when shouldPrint becomes true
            handlePrint(); // This will trigger the printing action
            setShouldPrint(false); // Reset shouldPrint back to false
        }
    }, [shouldPrint]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`api/solde-debut`);
                if (response.data.status === 200) {
                    const { message,message3, caisses,user } = response.data;
                    setCaisse(caisses);
                    setRows(message);
                    setSelected((prevStete)=>({...prevStete,bool:'true'}));
                    setSoldeAc(calculateSum2(message));
                    setSoldeDe(calculateSum(message));
                    setuser(user);

                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
        axios.get(`api/info-agence`).then((res) => {
            if (res.data.status === 200) {
                setAgence(res.data.message);

            }
        });
    }, []);

    useEffect(() => {
        // Calculate the sum of cv_ac for each unique nom_devise and cours_ac
        const result = rows.reduce((acc, item,index) => {
            const { montant, nom_devise, cours_ac,cv } = item;
            const key = `${nom_devise}${cours_ac}`;
            const id = `entry_${index}`;
            if (acc[key]) {
                acc[key].montant += parseFloat(montant);
                acc[key].cv += parseFloat(cv); // Assuming you want to add montant to cv
            } else {
                acc[key] = {
                    id,
                    nom_devise,
                    cours_ac,
                    montant: parseFloat(montant),
                    cv: parseFloat(cv), // Assuming you want to initialize cv with montant
                };
            }

            return acc;
        }, {});

        const formattedResult = Object.values(result);

        setRows2(formattedResult);
        setSelected((prev) => ({ ...prev, bool: false }));
    }, [rows]);


    useEffect(() => {
        const result = rows2.reduce((acc, item, index) => {
            const { montant, nom_devise } = item;
            const key = nom_devise;
            const id = `entry_${index}`;

            if (acc[key]) {
                acc[key].montant += parseFloat(montant);
            } else {
                acc[key] = {
                    id,
                    nom_devise,
                    montant: parseFloat(montant),
                };
            }

            return acc;
        }, {});

        const formattedResult = Object.keys(result).map((key) => {
            const { id, nom_devise, montant } = result[key];
            return {
                id,
                nom_devise,
                montant,
            };
        });

        setRows3(formattedResult);
        setSelected((perve) => ({ ...perve, bool: false }));

    }, [rows2]);



    /************************************return**************************************** */
    return (
        <Box m="20px" sx={{
            "& .bartitle":{
                marginY : "1.5rem",
            }
        }}>

            <Box display="flex" justifyContent="center" marginBottom="0.5rem"
                 sx={{
                     "& .MuiFormControl-root" : {
                         marginRight:"1rem",
                         width:"11rem",

                     },
                     "& .MuiAutocomplete-root":{
                         marginTop:"8px"
                     },
                     "& .buttons" : {
                         color: colors.grey[200],
                         backgroundColor : colors.primary[400],
                         margin: "0rem 0.5rem",
                         fontSize: "0.8rem",
                         height: "3.4rem" ,
                         paddingX:"1.5rem"
                     },
                     "& .buttons:hover" : {
                         color: colors.primary[400],
                         backgroundColor : colors.grey[200],

                         margin: "0.5rem",
                         fontSize: "0.9rem"
                     },
                     "& .span1" : {


                         marginLeft: "0.5rem",

                     },

                 }}
            >

                {user===1?
                    <Autocomplete className="Autocomplete"
                                  size="small"
                                  id="combo-box-demo"
                                  value={selectedCaisse}
                                  options={caisse}
                                  onChange={handleCaissesFilterChange} // Update this line
                                  getOptionLabel={(option) => option.designation}

                                  renderInput={(params) => <TextField {...params} label="Caisse" />}
                    />:null}

                <Autocomplete className="Autocomplete"
                              size="small"
                              id="combo-box-demo"
                              options={types}
                              value={selected.type}
                              onChange={handleTypeFilterChange} // Update this line
                              renderInput={(params) => <TextField {...params} label="Type d'affichage" />}
                />



            </Box>
            <Box display="flex" justifyContent="space-between"
                 sx={{"& .solde":{
                         marginTop:"20vh"
                     }}}
            >
                <Box
                    width="70%">
                    <Box
                        height="75vh"
                        sx={{
                            "& .MuiDataGrid-root": {
                                border: "none",
                            },
                            "& .MuiDataGrid-cell": {
                                borderBottom: "none",
                            },
                            "& .name-column--cell": {
                                color: colors.greenAccent[300],
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                backgroundColor: colors.blueAccent[700],
                                borderBottom: "none",
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                backgroundColor: colors.primary[400],
                            },
                            "& .MuiDataGrid-footerContainer": {
                                borderTop: "none",
                                backgroundColor: colors.blueAccent[700],
                            },
                            "& .MuiCheckbox-root": {
                                color: `${colors.greenAccent[200]} !important`,
                            },
                            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                color: `${colors.grey[100]} !important`,
                            },
                        }}
                    >
                        <h2>Solde début en devise</h2>
                        <Paper >
                            <TableContainer  sx={{height:"65vh",marginTop:"1vh",backgroundColor:"#f2f0f0"}} >
                                <Table stickyHeader aria-label="sticky table" >
                                    <TableHead sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>
                                        {renderTableRows()}

                                    </TableHead>
                                    {renderTableBody()}
                                </Table>
                            </TableContainer>

                        </Paper>





                        <Box display="flex" justifyContent="center"
                             sx={{
                                 "& .MuiFormControl-root" : {
                                     marginRight:"1rem",
                                     width:"6rem",

                                 },
                                 "& .MuiAutocomplete-root":{
                                     marginTop:"8px"
                                 },
                                 "& .buttons" : {
                                     color: colors.grey[200],
                                     backgroundColor : colors.primary[700],
                                     margin: "0.5rem",
                                     marginTop:"1rem",
                                     fontSize: "0.8rem",
                                     height: "3.4rem" ,
                                     paddingX:"1.5rem"
                                 },
                                 "& .buttons:hover" : {
                                     color: colors.primary[400],
                                     backgroundColor : colors.grey[200],

                                     margin: "0.5rem",
                                     marginTop:"1rem",
                                     fontSize: "0.9rem"
                                 },
                                 "& .span1" : {


                                     marginLeft: "0.5rem",

                                 }
                             }}
                        >
                            <Button className="buttons" onClick={handlePrintev}><FindInPageOutlinedIcon/><span className="span1">Aperçu</span> </Button>
                        </Box>
                    </Box>
                </Box>

                <Box width="25%" marginTop="20rem" sx={{}} className="solde">



                    <Box marginTop="6vh"  sx={{
                        "& .contrev":{
                            backgroundColor:colors.primary[800],
                            color: colors.grey[100],
                            fontSize: "1rem",
                            marginTop: "0",


                            marginBottom: "1rem",
                            paddingX:"1.5rem",
                            paddingY:"0.5rem",
                        },
                        "& .soldea":{
                            marginY:"2vh",              }
                    }}>
                        <Box display="flex"  justifyContent="center"><h2><span >Solde actuel en dirhams</span></h2></Box>
                        <Box  justifyContent="center" ><Box display="flex" justifyContent="center" ><h3 sx={{marginTop:"1rem",textAlign: "center"}}>solde début DHS : </h3></Box><Box display="flex" justifyContent="center"><span className="contrev"  >{formatNumber(soldeDe)} </span></Box> </Box>
                        <Box justifyContent="center"><Box display="flex" justifyContent="center"><h3>solde actuel DHS : </h3></Box><Box display="flex" justifyContent="center"> <span className="contrev"  >{formatNumber(soldeAc)} </span></Box></Box>
                    </Box>



                </Box>

            </Box>
            <div style={{ display: 'none' }}>
                <PDFContent caisses={caisse} user={user} rows2={rows2} rows3={rows3} agence={agence} data2={data2} ref={componentRef} selected={selected}/>
            </div>
        </Box>
    );
};

export default Statistiques;
