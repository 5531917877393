import * as React from 'react';
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { Tabs, Tab, AppBar } from "@mui/material";
import Information from "../../components/Parametres/InformationsBureauChange/index";
import List from "../../components/Parametres/ListeCaisses/index";
import Comptes from "../../components/Parametres/Comptes/index";
import Listbanques from "../../components/Parametres/ListeBanques/index";
import "../index.css";
import Options from "../../components/Parametres/Options/index";
import { useOrder } from "../../context/OrderContext";
import {useSelectedRow} from "../../context/SelectedRowContext";

const Gestiondevise = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {selectedRow, updateSelectedRow } = useSelectedRow();
    const [value, setValue] = React.useState(0);

    React.useEffect(() => {
        if (selectedRow === 4) {
            setValue(2);
            updateSelectedRow(null);
        }
    }, [selectedRow, updateSelectedRow]);

    const handleTabs = (event, val) => {
        setValue(val);
    };

    return (
        <Box className="gestion-devise-container">
            <Header title="PARAMÈTRES" />
            <Box>
                <AppBar position="static" style={{ backgroundColor: "#ffffff" }}>
                    <Tabs value={value} onChange={handleTabs}>
                        <Tab label="INFORMATIONS SUR LE BUREAU DE CHANGE" />
                        <Tab label="LISTE DES CAISSES" />
                        <Tab label="COMPTES" />
                        <Tab label="LISTE DES BANQUES" />
                        <Tab label="OPTIONS" />
                    </Tabs>
                </AppBar>
            </Box>

            <Box className="gestion-devise-content">
                <TabPanel value={value} index={0}><Information /></TabPanel>
                <TabPanel value={value} index={1}><List /></TabPanel>
                <TabPanel value={value} index={2}><Comptes /></TabPanel>
                <TabPanel value={value} index={3}><Listbanques /></TabPanel>
                <TabPanel value={value} index={4}><Options /></TabPanel>
            </Box>
        </Box>
    );
};

const TabPanel = (props) => {
    const { children, value, index } = props;
    return value === index && <div>{children}</div>;
};

export default Gestiondevise;
