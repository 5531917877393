import React from 'react';
import {Box} from "@mui/material";
import {formatNumber} from "../../../../fonctions/formatNumber";
function Index(props) {
    const { data, data2,data3, agence,user } = props;
    const today = new Date();

    const formatDate = (date) => `${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}/${date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1}/${date.getFullYear()}`;

    const renderTableRows = () => {
        if (data3.etat === "Détaillé") {
            return (
                <tr>
                    <th>N° bordereau</th>
                    {data3.user===1?<th>Utilisateur</th>:null}
                    <th >Date</th>
                    <th> Heure</th>
                    <th> Client</th>
                    <th >Devise</th>
                    <th >Montant</th>
                    <th >Cours</th>
                    <th>Contre Valeur</th>
                </tr>
            );

        }else{
            return (
                <tr >
                    <td>Date</td>
                    <td>Devise</td>
                    <td>Montant</td>
                    <td>Contre valeur</td>
                </tr>
            );
        }}


    const renderTableBody = () => {
        if (data3.etat === "Détaillé") {
            return (

                <>
                    {data2.map((row) => (
                        <tr  >
                            <td>{row.num_bac}</td>
                            {data3.user === 1 ? <td>{row.name}</td> : null}
                            <td>{row.date_achat}</td>
                            <td>{row.heure_achat}</td>
                            <td>{row.prenom_nom?row.prenom_nom:''}</td>

                            <td>{row.nom_devise}</td>
                            <td align='right'>{row.mt_ac}</td>
                            <td align='right'>{row.cours_ac}</td>
                            <td align='right'>{row.cv_ac}</td>


                        </tr>
                    ))}
                </>
            );}else{
            return (
                <>
                    {data.map((row) => (
                        <tr>
                            <td>{row.date_achat}</td>
                            <td>{row.nom_devise}</td>
                            <td align='right'>{formatNumber(row.mt_ac_sum)}</td>
                            <td align='right'>{formatNumber(row.cv_ac_sum)}</td>
                        </tr>
                    ))}
                </>
            );
        }
    };
    return (
        <div>
            {/* Agency information */}
            <Box sx={{
                borderBottom: '1px solid black',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%'
            }}>
                <h5 className="h5">{agence.nom_bureau}</h5>
                <h5 className="h5">{agence.num_auto}</h5>
            </Box>

            {/* Header */}
            <Box
                sx={{
                    border: '1px solid black',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '70vw',
                    marginLeft: '15vw',
                    marginY: '4vh',
                    borderRadius: '8px',
                    backgroundColor: '#ea5a27', // Change the background color here
                }}
            >
                <h2 className="h2">LISTE DES BORDEREAUX ERRONÉS</h2>
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <Box sx={{marginRight: '5vw'}}>
                    <h5 className="h5">Du : {data3.du}</h5>
                </Box>
                <Box sx={{}}>
                    <h5 className="h5">Au : {data3.au}</h5>
                </Box>
            </Box>
            {/* Date and time */}
            <Box sx={{marginY: '4vh'}}>
                <Box sx={{marginRight: '5vh'}}>
                    {user == 1 ? <h5 className="h5">Utilisateur : {data3.utilisateur}</h5> : null}
                </Box>
                <Box sx={{marginRight: '5vh'}}>
                    <h5 className="h5">Devise: {data3.devise}</h5>
                </Box>
            </Box>

            {/* Currency exchange data */}
            <Box sx={{width: '100%'}}>
                <table className="print-table">
                    {renderTableRows()}
                    {renderTableBody()}
                </table>


            </Box>

            {/* Additional CSS styles for printing */}
            <style>
                {`
            .print-table {
            margin-left:'1%';
              width: 98%;
              border-collapse: collapse;
            }

            .print-table th,
            .print-table td {
              font-size: 12px;
              border: 1px solid black;
              padding: 8px;
            }
            .footer {
              margin-top: 4vh;
              border: 2px dotted #73AD21;
              align-items: center;
              width: 100%;
              justify-content: center;
              z-index: 9;
            }
            /* Additional styles specific for printing */
            @media print {
              .print-table {
                page-break-inside: auto;
              }
              .footer {
                page-break-inside: avoid;
              }
              .print-table th,
              .print-table td {
                page-break-inside: avoid;
              }
              /* Ensure the footer appears on each page */
              
            }
          `}
            </style>


            {/* Footer */}
            <Box  sx={{paddingTop: '1vh',border:'2px dotted black',marginTop:'5%'}}>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <h5 className="h5">Adresse : {agence.adresse}</h5>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Box sx={{marginRight: '3vh'}}>
                        <h5 className="h5">Tél : {agence.tel}</h5>
                    </Box>
                    <Box>
                        <h5 className="h5">Email : {agence.email}</h5>
                    </Box>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Box sx={{marginRight: '3vh'}}>
                        <h5 className="h5">TP : {agence.tp}</h5>
                    </Box>
                    <Box sx={{marginRight: '3vh'}}>
                        <h5 className="h5">IF : {agence.idfis}</h5>
                    </Box>
                    <Box>
                        <h5 className="h5">RC : {agence.rc}</h5>
                    </Box>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <h5 className="h5">ICE : {agence.ice}</h5>
                </Box>
            </Box>
        </div>

    );
}

export default Index;