import { Box } from "@mui/material";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {useEffect, useState} from 'react';
import {Button} from "@mui/material";
import swal from "sweetalert";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AddIcon from '@mui/icons-material/Add';
import { useReactToPrint } from "react-to-print";
import React, { useRef } from 'react';
import Logo from './../../../data/bg-05.gif'
import {Link} from "react-router-dom";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import {formatNumber} from '../../../fonctions/formatNumber';
import PDFOperation from '../PDFContent/OperationVente/index'
class PDFContent extends React.Component {
    render() {
        const { data,agence,data2,data3,sumCv } = this.props;
        return (
            <PDFOperation data={data} agence={agence} data2={data2} data3={data3} sumCV={sumCv} />
        );
    }
}


const Achat = () => {
    const history=useHistory();
    const [devise,setDevise]=useState([]);
    const [devise2,setDevise2]=useState([]);
    const [inputColor, setInputColor] = useState("#000000de"); // Initial color is black
    const [de,setDe]=useState([]);
    const [type,setType]=useState(["Adulte","Mineur"]);
    const qualite=["MR | Marocains résidants", "MRE | Marocains résidants à l'étranger","ER | Etrangers résidants" ,"ENR | Touristes étrangers" ];
    const [nature,setNature]=useState(["Dotation pour voyages personnels","Dotation pour émigration à l'étranger","Dotation Départscolarité","Allocation pour mission et stage  à l'étranger"]);
    const [selectedOption, setSelectedOption] = useState(qualite[0]);
    const [pays,setpays]=useState([]);
    const [justif,setjustif]=useState(["Déclaration douanière d'importation","Bordereau d'achat","Numéro du compte en dirhams convertibles","Autre"])
    const [selectedNature,setselectedNature]=useState(nature[0]);
    const [submitValue,setSubmitValue]=useState({
        nom :'',
        prenom :'',
        adresse :'',
        nationalite :'',
        natured:"Dotation pour voyages personnels",
        typeClient:'Adulte',
        cni:'',
        num_bvc:'',
        passport:'',
        datepassport:'',
        date:'01/01/2019',
        auto:'Instruction général des opérations de change',
        pays:'',
        type_pid:'',
        n_pid:'',
        jusrechat:'',
        numjus:'',
        email: "",
        updated_at:'',
        qualite_cl:'',
        code_nop:'',
        num_dec:'',
        date_dec:'',
        Brd_ac:'',
    })
    const [listdevise,setlistdevise]=useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedRow2, setSelectedRow2] = useState(null);
    const [valider, setValider] = useState(true);

    let typep="CNI";

    const comp = () => {
        if (selectedOption === "MR | Marocains résidants") {
            submitValue.natured=nature[0];
            submitValue.nationalite="MAROC";
            typep="CNI";
            return (
                <Box sx={{
                    ".MuiInputBase-root ":{
                        paddingY:"0px",
                        fontSize:"10px",
                    }
                }}>
                    <Box sx={{display:'flex'}}>
                        <Box sx={{width:'48%'}}>
                            <Autocomplete
                                onChange={handleNatureChange}
                                value={selectedNature}
                                id="combo-box"
                                size="small"
                                options={nature}
                                sx={{marginTop:`8px`}}
                                renderInput={(params) => <TextField {...params} label="Nature Dotation" />}
                                disableClearable={true}
                            />
                            <TextField
                                disabled
                                size="small"
                                name="n_pid"
                                onChange={(e) => setSubmitValue((prevValue) => ({
                                    ...prevValue,
                                    nationalite: e.target.value,
                                }))}
                                value="MAROC"
                                sx={{ marginTop: '8px', width: '100%' }}
                                label="Nationalité"
                                variant="outlined"
                            />
                            <Autocomplete
                                onChange={(e, value) => setSubmitValue((prevValue) => ({
                                    ...prevValue,
                                    typeClient: value,
                                }))}
                                value={setSubmitValue.typeClient}
                                id="combo-box-demo"
                                options={type}
                                defaultValue="Adulte"
                                size="small"
                                sx={{marginTop:`8px`,width:"100%"}}
                                renderInput={(params) => <TextField {...params} label="Type Client" />}
                                disableClearable={true}
                            />
                            <TextField
                                onChange={(e) => setSubmitValue((prevValue) => ({
                                    ...prevValue,
                                    cni: e.target.value,
                                }))}
                                value={submitValue.cni}
                                name="nom"
                                label="N° CNI"
                                size="small"
                                sx={{ marginTop: '8px', width: '100%' }}

                                variant="outlined"
                            />
                            <Box sx={{display:'flex'}}>
                                <TextField
                                    onChange={(e) => setSubmitValue((prevValue) => ({
                                        ...prevValue,
                                        passport: e.target.value,
                                    }))}
                                    value={submitValue.passport}
                                    name="passport"
                                    label="N° Passport"
                                    size="small"
                                    sx={{
                                        marginTop: '8px',
                                        width: '47.5%',
                                        marginRight: '5%',
                                    }}

                                    variant="outlined"
                                />
                                <TextField
                                    onChange={handlePassportDateChange}
                                    value={submitValue.datepassport}
                                    id="passport-date"
                                    name="datepassport"
                                    size="small"
                                    type="date" // Use 'date' type for date input
                                    label="Date Passport"
                                    sx={{ marginTop: '8px', width: '47.5%' }}
                                    InputLabelProps={{
                                        shrink: true, // To prevent label overlap when value is entered
                                    }}
                                />
                            </Box>
                        </Box>
                        <Box sx={{width:'48%',marginLeft:'4%'}}>
                            <Box sx={{ display: 'flex' }}>
                                <TextField
                                    onChange={(e) => setSubmitValue((prevValue) => ({
                                        ...prevValue,
                                        nom: e.target.value,
                                    }))}
                                    value={submitValue.nom}
                                    id="combo-box-demo"
                                    name="nom"
                                    size="small"
                                    label="Nom"
                                    sx={{ marginTop: `8px`, width: "47.5%",marginRight:'5%' }}
                                />
                                <TextField
                                    onChange={(e) => setSubmitValue((prevValue) => ({
                                        ...prevValue,
                                        prenom: e.target.value,
                                    }))}
                                    value={submitValue.prenom}
                                    name="prenom"
                                    label="Prenom"
                                    size="small"
                                    sx={{
                                        marginTop: '8px',
                                        width: '47.5%',
                                    }}

                                    variant="outlined"
                                />
                            </Box>
                            <TextField
                                onChange={(e) => setSubmitValue((prevValue) => ({
                                    ...prevValue,
                                    adresse: e.target.value,
                                }))}
                                value={submitValue.adresse}
                                name="prenom"
                                label="Adresse"
                                size="small"
                                sx={{
                                    marginTop: '8px',
                                    width: '100%',
                                }}

                                variant="outlined"
                            />
                            <TextField
                                size="small"
                                name="num_dec4"
                                onChange={(e) => setSubmitValue((prevValue) => ({
                                    ...prevValue,
                                    auto: e.target.value,
                                }))}
                                value={setSubmitValue.auto}
                                sx={{ marginTop: '8px', width:"100%" }}
                                label="N° d'auto de l'office"
                                variant="outlined"
                                defaultValue="Instruction général des opérations de change"
                            />
                            <TextField
                                size="small"
                                name="num_dec3"
                                onChange={(e) => setSubmitValue((prevValue) => ({
                                    ...prevValue,
                                    date: e.target.value,
                                }))}
                                value={setSubmitValue.date}
                                sx={{ marginTop: '8px', width:"100%" }}
                                label="Date"
                                variant="outlined"
                                defaultValue="01/01/2019"
                            />
                        </Box>
                    </Box>
                </Box>
            );
        }else if (selectedOption === "MRE | Marocains résidants à l'étranger"){
            typep = "CNI";
            submitValue.nationalite = "";
            submitValue.natured=nature[0];
            return (
                <Box>
                    <Box sx={{ display: 'flex' }}>
                        <Box sx={{ width: '48%' }}>
                            <Autocomplete
                                onChange={handleNatureChange}
                                value={selectedNature}
                                id="combo-box"
                                size="small"
                                options={nature}
                                sx={{ marginTop: `8px` }}
                                renderInput={(params) => <TextField {...params} label="Nature Dotation" />}
                                disableClearable={true}
                            />
                            <Autocomplete
                                onChange={handleNat}
                                value={selectedNationality}
                                id="combo-box"
                                size="small"
                                options={pays}
                                sx={{ marginTop: `8px` }}
                                renderInput={(params) => <TextField {...params} label="Pays d'accueil" />}
                            />
                            <Autocomplete
                                onChange={(e, value) => setSubmitValue((prevValue) => ({
                                    ...prevValue,
                                    typeClient: value,
                                }))}
                                value={setSubmitValue.typeClient}
                                id="combo-box-demo"
                                options={type}
                                defaultValue="Adulte"
                                size="small"
                                sx={{ marginTop: `8px`, width: "100%" }}
                                renderInput={(params) => <TextField {...params} label="Type Client" />}
                                disableClearable={true}
                            />
                            <TextField
                                onChange={(e) => setSubmitValue((prevValue) => ({
                                    ...prevValue,
                                    cni: e.target.value,
                                }))}
                                value={submitValue.cni}
                                name="nom"
                                label="N° CNI"
                                size="small"
                                sx={{ marginTop: '8px', width: '100%' }}
                                variant="outlined"
                            />
                            <Box sx={{ display: 'flex' }}>
                                <TextField
                                    onChange={(e) => setSubmitValue((prevValue) => ({
                                        ...prevValue,
                                        passport: e.target.value,
                                    }))}
                                    value={submitValue.passport}
                                    name="passport"
                                    label="N° Passport"
                                    size="small"
                                    sx={{
                                        marginTop: '8px',
                                        width: '47.5%',
                                        marginRight: '5%',
                                    }}

                                    variant="outlined"
                                />
                                <TextField
                                    onChange={handlePassportDateChange}
                                    value={submitValue.datepassport}
                                    id="passport-date"
                                    name="datepassport"
                                    size="small"
                                    type="date" // Use 'date' type for date input
                                    label="Date Passport"
                                    sx={{ marginTop: '8px', width: '47.5%' }}
                                    InputLabelProps={{
                                        shrink: true, // To prevent label overlap when value is entered
                                    }}
                                />
                            </Box>
                        </Box>
                        <Box sx={{ width: '48%', marginLeft: '4%' }}>
                            <Box sx={{ display: 'flex' }}>
                                <TextField
                                    onChange={(e) => setSubmitValue((prevValue) => ({
                                        ...prevValue,
                                        nom: e.target.value,
                                    }))}
                                    value={setSubmitValue.nom}
                                    id="combo-box-demo"
                                    name="nom2"
                                    size="small"
                                    label="Nom"
                                    sx={{ marginTop: `8px`, width: "47.5%", marginRight: '5%' }}
                                />
                                <TextField
                                    onChange={(e) => setSubmitValue((prevValue) => ({
                                        ...prevValue,
                                        prenom: e.target.value,
                                    }))}
                                    value={submitValue.prenom}
                                    name="passport"
                                    label="Prenom"
                                    size="small"
                                    sx={{
                                        marginTop: '8px',
                                        width: '47.5%',
                                    }}

                                    variant="outlined"
                                />
                            </Box>
                            <TextField
                                onChange={(e) => setSubmitValue((prevValue) => ({
                                    ...prevValue,
                                    adresse: e.target.value,
                                }))}
                                value={submitValue.adresse}
                                name="prenom"
                                label="Adresse"
                                size="small"
                                sx={{
                                    marginTop: '8px',
                                    width: '100%',
                                }}

                                variant="outlined"
                            />
                            <TextField
                                size="small"
                                name="num_dec_auto" // Unique name for N° d'auto de l'office
                                onChange={(e) => setSubmitValue((prevValue) => ({
                                    ...prevValue,
                                    auto: e.target.value,
                                }))}
                                value={setSubmitValue.auto}
                                sx={{ marginTop: '8px', width:"100%" }}
                                label="N° d'auto de l'office"
                                variant="outlined"
                                defaultValue="Instruction général des opérations de change"
                            />

                            <TextField
                                size="small"
                                name="num_dec_date" // Unique name for Date
                                onChange={(e) => setSubmitValue((prevValue) => ({
                                    ...prevValue,
                                    date: e.target.value,
                                }))}
                                value={setSubmitValue.date}
                                sx={{ marginTop: '8px', width:"100%" }}
                                label="Date"
                                variant="outlined"
                                defaultValue="01/01/2019"
                            />
                            {submitValue.natured === 'Rachat' && (
                                <div>
                                    <Box sx={{ display: 'flex' }}>
                                        <TextField
                                            size="small"
                                            name="num_dec"
                                            onChange={(e) => setSubmitValue((prevValue) => ({
                                                ...prevValue,
                                                auto: e.target.value,
                                            }))}
                                            value={setSubmitValue.auto}
                                            sx={{ marginTop: '8px', width:"100%" }}
                                            label="N° d'auto de l'office"
                                            variant="outlined"
                                            defaultValue="Instruction général des opérations de change"
                                        />
                                        <TextField
                                            size="small"
                                            name="num_dec"

                                            onChange={(e) => setSubmitValue((prevValue) => ({
                                                ...prevValue,
                                                date: e.target.value,
                                            }))}
                                            value={setSubmitValue.date}
                                            sx={{ marginTop: '8px', width:"100%" }}
                                            label="Date"
                                            variant="outlined"
                                            defaultValue="01/01/2019"
                                        />
                                    </Box>
                                </div>
                            )}
                        </Box>
                    </Box>
                </Box>
            );

        }else{
            submitValue.natured=selectedNature;
            typep="CIM";
            submitValue.nationalite="";

            return(
                <Box sx={{display:'flex'}}>
                    <Box sx={{width:'48%'}}>
                        <Autocomplete
                            onChange={handleNatureChange}
                            value={selectedNature}
                            id="combo-box"
                            size="small"
                            options={nature}
                            defaultValue={nature[0]}
                            sx={{marginTop:`8px`}}
                            renderInput={(params) => <TextField {...params} label="Nature Dotation" />}
                            disableClearable={true}
                        />
                        <Autocomplete
                            onChange={handleNat}
                            value={selectedNationality}
                            id="combo-box"
                            size="small"
                            options={pays}
                            sx={{ marginTop: `8px` }}
                            renderInput={(params) => <TextField {...params} label="Pays d'accueil" />}
                        />
                        <Autocomplete
                            onChange={(e, value) => setSubmitValue((prevValue) => ({
                                ...prevValue,
                                typeClient: value,
                            }))}
                            value={setSubmitValue.typeClient}
                            id="combo-box-demo"
                            options={type}
                            defaultValue="Adulte"
                            size="small"
                            sx={{marginTop:`8px`,width:"100%"}}
                            renderInput={(params) => <TextField {...params} label="Type Client" />}
                            disableClearable={true}
                        />
                        <TextField
                            onChange={(e) => setSubmitValue((prevValue) => ({
                                ...prevValue,
                                cni: e.target.value,
                            }))}
                            value={submitValue.cni}
                            name="nom"
                            size="small"
                            sx={{ marginTop: '8px', width: '100%' }}
                            label="N° CIM"
                            variant="outlined"
                        />
                        <Box sx={{display:'flex'}}>
                            <TextField
                                onChange={(e) => setSubmitValue((prevValue) => ({
                                    ...prevValue,
                                    passport: e.target.value,
                                }))}
                                value={submitValue.passport}
                                name="passport"
                                label="N° Passport"
                                size="small"
                                sx={{
                                    marginTop: '8px',
                                    width: '47.5%',
                                    marginRight: '5%',
                                }}

                                variant="outlined"
                            />
                            <TextField
                                onChange={handlePassportDateChange}
                                value={submitValue.datepassport}
                                id="passport-date"
                                name="datepassport"
                                size="small"
                                type="date" // Use 'date' type for date input
                                label="Date Passport"
                                sx={{ marginTop: '8px', width:"47.5%" }}
                                InputLabelProps={{
                                    shrink: true, // To prevent label overlap when value is entered
                                }}
                            />
                        </Box>

                    </Box>
                    <Box sx={{width:'48%',marginLeft:'4%'}}>
                        <Box sx={{display:'flex'}}>
                            <TextField
                                onChange={(e) => setSubmitValue((prevValue) => ({
                                    ...prevValue,
                                    nom: e.target.value,
                                }))}
                                value={setSubmitValue.nom}
                                id="combo-box-demo"
                                name="nom"
                                size="small"
                                label="Nom"
                                sx={{marginTop:`8px`,width:"47.5%",marginRight:'5%'}}
                            />
                            <TextField
                                onChange={(e) => setSubmitValue((prevValue) => ({
                                    ...prevValue,
                                    prenom: e.target.value,
                                }))}
                                value={submitValue.prenom}
                                name="passport"
                                label="Prenom"
                                size="small"
                                sx={{
                                    marginTop: '8px',
                                    width: '47.5%',
                                }}

                                variant="outlined"
                            />
                        </Box>
                        <TextField
                            onChange={(e) => setSubmitValue((prevValue) => ({
                                ...prevValue,
                                adresse: e.target.value,
                            }))}
                            value={submitValue.adresse}
                            name="prenom"
                            label="Adresse"
                            size="small"
                            sx={{
                                marginTop: '8px',
                                width: '100%',
                            }}

                            variant="outlined"
                        />
                        <TextField
                            size="small"
                            name="num_dec"
                            onChange={(e) => setSubmitValue((prevValue) => ({
                                ...prevValue,
                                auto: e.target.value,
                            }))}
                            value={setSubmitValue.auto}
                            sx={{ marginTop: '8px', width:"100%" }}
                            label="N° d'auto de l'office"
                            variant="outlined"
                            defaultValue="Instruction général des opérations de change"
                        />
                        <TextField
                            size="small"
                            name="num_dec"

                            onChange={(e) => setSubmitValue((prevValue) => ({
                                ...prevValue,
                                date: e.target.value,
                            }))}
                            value={setSubmitValue.date}
                            sx={{ marginTop: '8px', width:"100%" }}
                            label="Date"
                            variant="outlined"
                            defaultValue="01/01/2019"
                        />



                        {submitValue.natured === 'Rachat' &&(
                            <div>
                                <Box sx={{display:'flex'}}>
                                    <Autocomplete
                                        onChange={(e, value) => setSubmitValue((prevValue) => ({
                                            ...prevValue,
                                            jusrechat: value,
                                        }))}
                                        value={setSubmitValue.jusrechat}
                                        id="combo-box"
                                        size="small"
                                        options={justif}
                                        sx={{marginTop:`8px`,width:'67.5%',marginRight:'5%'}}
                                        renderInput={(params) => <TextField {...params} label="Justificatif du Rachat" />}
                                        disableClearable={true}
                                    />
                                    <TextField
                                        onChange={(e) => setSubmitValue((prevValue) => ({
                                            ...prevValue,
                                            numjus: e.target.value,
                                        }))}
                                        value={setSubmitValue.numjus}
                                        id="combo-box"
                                        size="small"
                                        sx={{marginTop:`8px`,width:'37.5%'}}
                                        label="Numéro du Justificatif"
                                    />
                                </Box>
                            </div>
                        )}
                    </Box>
                </Box>
            );
        }

    };

    const handlePassportDateChange = (e) => {
        const inputDate = e.target.value;
        setSubmitValue((prevValue) => ({
            ...prevValue,
            datepassport: inputDate,
        }));
    };
    submitValue.email=localStorage.getItem("auth_email");
    const handleInput2=(e)=>{
        e.persist();
        setSubmitValue({...submitValue, [e.target.name]: e.target.value})
    }
    const handleInput3=(e,valeur)=>{
        submitValue.code_nop=valeur

    }
    const handleInput4=(e,valeur)=>{
        submitValue.nationalite=valeur

    }
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [Nationalite,setNationalite]=useState(['maroc']);
    const [nat,setNat]=useState("Nationalité");
    const [n,setN]=useState("N° CNI")
    const handleInput = (event, newValue) => {
        setSelectedOption(newValue);

    }
    const handleNatureChange = (event, newValue) => {
        setselectedNature(newValue);
        setSubmitValue((op)=>({...op,natured:newValue}));
    }
    const [selectedNationality, setSelectedNationality] = useState('');

    const handleNat = (event, newValue) => {
        setSelectedNationality(newValue);
    }
    const [selectedPays,setSelectedPays]=useState(null);
    const handlePaysChange = (event, newValue) => {
        setSelectedPays(newValue);
    }
    useEffect(() => {

        if (selectedOption === 'MR | Marocains résidants') {
            setNature([
                'Dotation pour voyages personnels',
                "Dotation pour émigration à l'étranger",
                'Dotation Départ scolarité',
                "Allocation pour mission et stage  à l'étranger"
            ]);
            setselectedNature('Dotation pour voyages personnels');
        } else if (selectedOption === "MRE | Marocains résidants à l'étranger") {

            setNature(['Dotation pour voyages personnels', 'Rachat']);
            setselectedNature('Dotation pour voyages personnels');

        } else if (selectedOption === 'ER | Etrangers résidants') {
            setselectedNature('Dotation pour voyages personnels');
            setNature(['Dotation pour voyages personnels', 'Allocation pour mission et stage à l\'étranger']);
        } else {
            setSubmitValue((op)=>({...op,natured:'Rachat'}));
            setselectedNature('Rachat');
            setNature(['Rachat']);
        }

    }, [selectedOption]);
    const [rows, setRows] = useState([]);
    const [agence,setAgence]=useState([]);
    const [coursAchat, setCoursAchat] = useState('');
    const [montant, setMontant] = useState('');
    const [contreValeur, setContreValeur] = useState('');

    const handleInsert = () => {
        if (rows.length < 5) {
        if (selectedDevise  && montant!=0 && coursVente!= 0) {
            const Devi=devise.find((row)=>row.nom_devise===selectedDevise.nom_devise);
            const existingDevise=rows.find((row)=>row.devise===selectedDevise.nom_devise);
            if(!existingDevise  ){
                const text="Le montant de "+selectedDevise.nom_devise+" en caisse est insuffisant.";
                if (parseFloat(montant)<=parseFloat(Devi.montant)) {
                    const cv = devise2.find((op) => op.nom_devise === selectedDevise.nom_devise);
                    const nbr=montant/cv.copure;
                    if(Number.isInteger(nbr)){
                        const newRow = {
                            devise: selectedDevise.nom_devise,
                            montant: montant,
                            coursAchat: cv.tauxA,
                            coursVente: coursVente,
                            contreValeur: contreValeurs,
                            prix_par:cv.prix_par,
                        };
                        setRows(prevRows => [...prevRows, newRow]);
                        setMontant(0);
                        setInputColor("#000000de");
                    }else{
                        swal('',"Veuillez respecter les coupures.",'warning');
                    }
                } else {
                    swal('',text,'warning');
                }
            }else{
                swal('',"Devise déja inséré.",'warning');
            }}
        }
        else{
            swal('', "Vous ne pouvez pas ajouter plus de 5 devises.", 'warning');
        }
    };

    const [selectedRows, setSelectedRows] = useState([]);
    const handleRowSelection = (rowName) => {
        const selectedIndex = selectedRows.indexOf(rowName);
        let newSelectedRows = [];

        if (selectedIndex === -1) {
            newSelectedRows = [...selectedRows, rowName];
        } else {
            newSelectedRows = selectedRows.filter((name) => name !== rowName);
        }

        setSelectedRows(newSelectedRows);
    };
    const handleDeleteRows = () => {
        if (selectedRow !== null) {
            swal({
                title: '',
                text: 'Voulez-vous vraiment supprimer cette ligne ?',
                icon: 'warning',
                buttons: ['Annuler', 'Oui'],
                dangerMode: true,
            }).then((confirmed) => {
                if (confirmed) {
                    const updatedRows = rows.filter((row) => row.devise!== selectedRow.devise);
                    const existingDevise = listdevise.find((row) => row.nom_devise === selectedRow.devise);
                    setSelectedDevise(existingDevise);
                    setCoursVente(parseFloat(selectedRow.coursVente).toFixed(4));
                    setMontant(selectedRow.montant);
                    setContreValeur(selectedRow.contreValeur); // Assuming setContreValeur is a state setter function
                    setSelectedRow(null);
                    setRows(updatedRows);
                }
            });
        }
    };
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        if(valider){
            axios.get(`api/detail-caisse`).then(res=>{
                if(res.data.status===200){
                    setValider(false);
                    if( (res.data.message).length<=1){
                        history.push('/espace-magchange/ventes-clients');
                    }else{
                    setDevise(res.data.message);
                    setDevise2(res.data.devise);
                    setSelectedOption((res.data.selectedQCVC).designation)
                    const py=(res.data.pays).map((op)=> op.nom_pays)
                    setpays(py)
                    const dhs = "DHS";
                    const ldevise = (res.data.message).filter((de) => de.nom_devise !== dhs);
                    setlistdevise(ldevise);
                    const rt=res.data.message;
                    const sd=rt[0];
                    const sd2=(res.data.devise).filter((de) => de.nom_devise === sd.nom_devise)[0]
                    setSelectedDevise(sd2);
                    setDe(sd2);
                    setCoursVente(parseFloat((res.data.devise).filter((de) => de.nom_devise === sd.nom_devise)[0].tauxVC).toFixed(4 ));
                    setLoading(false);
                    }
                }
            })}},[valider]);

    const sumCvAc = devise.reduce((accumulator, currentDevise) => {
        if(currentDevise.nom_devise !== "DHS"){
            return accumulator + currentDevise.cv_ac;
        }else{
            return accumulator;
        }
    }, 0);
    const sumCv = rows.reduce((accumulator, currentDevise) => {
        return accumulator +  currentDevise.contreValeur;
    }, 0);
    const [bool,setBool]=useState(false);

    const submitall=(e)=>{

        e.preventDefault();


        const data={
            type: selectedOption,
            type_pid: typep,
            natured: selectedNature,
            nationalite :selectedNationality?selectedNationality:submitValue.nationalite,
            cni: submitValue.cni,
            passport : submitValue.passport,
            datepassport: submitValue.datepassport,
            nom : submitValue.nom,
            prenom: submitValue.prenom,
            adresse: submitValue.adresse,
            typeClient: submitValue.typeClient,
            auto : submitValue.auto,
            date : submitValue.date,
            jusrechat : submitValue.jusrechat,
            numjus :submitValue.numjus,
            Brd_ac :'1',
            TypeV: "V",
            sumCv :sumCv,
            rows: rows.map((row) => ({
                devise: row.devise,
                montant: row.montant,
                coursVente: row.coursVente,
                coursAchat: row.coursAchat,
                contreValeur: row.contreValeur,
                prix_par:row.prix_par,

            })),
        }

        swal({
            title: "",
            text: "Voulez vous vraiment valider ce bordereau ?",
            icon: "warning",
            buttons: ["Annuler", "Oui"],
            dangerMode: true,
        }).then((confirmed) => {
            if (confirmed) {
                // User clicked "Oui," proceed with form submission
                axios.post('api/vente-client', data).then((res) => {
                    if(res.data.status === 410){
                        swal('',"Pour enregistrer cette opération, il est nécessaire de saisir les informations du client.",'warning');

                    }
                    else if(res.data.status === 400){
                        swal('',"Pour enregistrer cette opération, il est nécessaire de saisir les informations de l'opération de vente.",'warning');

                    }
                    else if(res.data.status === 200){

                        setSubmitValue((op)=>({...op,num_bvc:res.data.num}));
                        setBool(true);
                        setValider(true);

                    }
                })
                    .catch((error) => {
                        // Handle any error that occurred during the request
                        console.error("Error submitting form:", error);
                        swal("", "Une erreur est survenue lors de l'enregistrement.", "error");
                    });
            }

        });
        axios.get(`api/info-agence`).then((res) => {
            if (res.data.status === 200) {
                setAgence(res.data.message);

            }
        });
    }
    useEffect(() => {
        if (valider) {
            setSubmitValue(prevValue =>{
                return {
                    ...prevValue,
                    nom :'',
                    prenom :'',
                    adresse :'',
                    nationalite :'',

                    cni:'',
                    num_bvc:'',
                    passport:'',
                    datepassport:'',
                    pays:'',
                    type_pid:'',
                    n_pid:'',
                    jusrechat:'',
                    numjus:'',
                    email: "",
                    updated_at:'',
                    qualite_cl:'',
                    code_nop:'',
                    num_dec:'',
                    date_dec:'',
                    Brd_ac:'',
                }});
            setRows([]);
        }


    }, [valider]);
    const [infodevise,setInfodevise]=useState([]);
    useEffect(()=>{axios.get(`api/info-devise`).then(res=>
        {
            if(res.data.status===200){
                setInfodevise(res.data.message);

            }
        }
    );},[]);




    const [selectedDevise, setSelectedDevise] = useState({
    });
    const [coursVente,setCoursVente]=useState(parseFloat(selectedDevise.tauxVC).toFixed(4));


    // Handler to update the selectedDevise state when a devise is selected from Autocomplete
    const handleDeviseChange = (event, newValue) => {
        setSelectedDevise(newValue); // Update selectedDevise directly
        setInputColor("#000000de")
        if (!newValue) {
            changeCoursVente2();
        } else {

            changeCoursVente(newValue);
        }
    };
    const changeCoursVente2 = () => {
        setCoursVente(0);
        setDe({
            id_devise: "",
            nom_devise: "EUR",
            designation: "",
            prix_par: 1,
            copure: "",
            compte: "",
            tauxA: "",
            tauxVC: 0,
            tauxVB: ""
        });
    };
    const handleCoursChange = (event) => {
        if(parseFloat(coursVente)===0){
            swal('',"Merci de modifier le cours de vente de "+selectedDevise.nom_devise+" dans l'espace de gestion des devises.",'warning');
        }else{
            if (/^\d{0,3}(\.\d{0,4})?$/.test(event.target.value)) {
                setCoursVente(event.target.value);
            }

        }
    }
    const changeCoursVente = (selectedDevise) => {
        const cv = devise2.find((op) => op.nom_devise === selectedDevise.nom_devise);
        setDe(cv);
        const cours = cv ? cv.tauxVC : 0;
        setCoursVente(parseFloat(cours).toFixed(4));
    };
    // Handler to update the Montant state when the value changes in the "Montant" TextField
    const handleMontantChange = (event) => {
        const inputValue = event.target.value;
        if (/^\d+$/.test(inputValue) || inputValue === '') {
            setMontant(inputValue);
        }
    };


    // Calculate Contre Valeur based on the selectedDevise's tauxA and the Montant
    const contreValeurs = montant!='' ? (parseFloat(coursVente) * montant) / de.prix_par : 0;
    const handleRowClick = (row) => {
        setSelectedRow(row);
    };
    const handleRowClick2 = (row) => {
        setSelectedRow2(row);
    };
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: `
      @page {
        size: A4;
        margin: 10mm;
      }
      /* Additional styles for the printed page can be added here */
      /* Remove the pagination and URL styles */
      @media print {
        .MuiDataGrid-footerContainer {
          display: none !important;
        }
        a {
          display: none !important;
        }
      }
    `,
        header: null, // Set header to null to remove it from the PDF
        footer: null,
        // Set footer to null to remove it from the PDF
    });
    useEffect(() => {
        // Check if the status is 200 and perform the actions
        if ( bool) {

            handlePrint(); // Call handlePrint() after the state is updated
            setBool(false);
        }
    }, [bool]);
    if (loading) {
        return <div ><div style={{marginLeft:'37.5%',marginTop:"30vh"}}>
            <img style={{width:"25%",height:"40vh"}} src={Logo}/>

        </div></div>;
    }
    return (

        <Box className="type2" sx={{marginTop:'8vh','& .MuiInputBase-input': {
                height: '18px',
                fontSize:'16px',
            },
            '& .MuiInputLabel-root': {
                fontSize:'14px',

            }}}>
            <Box className="header-operation" sx={{"& .cancel-icon": {
                    marginTop: "1.2vh"
                }}}>
                <Box sx={{
                    "& .operation-title": {
                        fontSize: "1.8rem",
                        color:'#0f2479'
                    },

                }}>
                    <h2 className="operation-title">Opération vente client</h2>
                </Box>
                <Box className="cancel-icon">

                    <Link to="/espace-magchange/ventes-clients"><CancelPresentationIcon /></Link>

                </Box>

            </Box>
            <Box className="type3">
                <Box className="form1"
                     sx={{
                         "& .MuiOutlinedInput-input":{
                             heigh:"0.5rem"}
                     }}
                >
                    <Box sx={{'& .MuiInputBase-root': {
                            height: '30px',
                        }}}>
                        <h2  style={{marginBottom:'1vh', marginTop:'-2vh',fontSize: "1.4rem"}}>Informations Client</h2>

                        <Autocomplete
                            onChange={handleInput}
                            value={selectedOption}
                            id="combo-box-demo"
                            options={qualite}
                            size="small"
                            sx={{
                                marginTop: '8px',
                            }}
                            renderInput={(params) => <TextField {...params} label="Qualité Client" />}
                            disableClearable={true}
                        />



                        {comp()}

                    </Box>
                    <Box sx={{marginY:"10px"}}>
                        <h2  style={{marginBottom:'1vh', marginTop:'-1vh',fontSize: "1.4rem"}}>Opération de vente</h2>
                        <Box display="flex" sx={{marginY:"12px",width:'82%'}}>
                            <Autocomplete
                                size="small"
                                options={listdevise}
                                getOptionLabel={(option) => option.nom_devise}
                                sx={{ width: "20%" }}
                                renderInput={(params) => <TextField {...params} label="Devise" />}
                                onChange={handleDeviseChange}
                                value={selectedDevise}
                                disableClearable={true}
                            />
                            <Box sx={{marginX:'1%',width:'8%'}}>
                                <img src={require(`./../../drapeaux/${selectedDevise.nom_devise}.png`)} style={{ width: "100%", height: '35px' }} alt="Flag" />
                            </Box>
                            <TextField
                                size="small"
                                sx={{ width: "20%", marginRight: "1%" }}
                                label="Cours vente"
                                value={coursVente}
                                onChange={handleCoursChange}
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{ style: { color: inputColor } }}
                            />
                            <TextField
                                size="small"
                                sx={{ width: "20%", marginRight: "1%" }}
                                label="Montant"
                                value={montant}
                                onChange={handleMontantChange}
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{ style: { color: inputColor } }}
                            />
                            <TextField
                                size="small"
                                sx={{ width: "20%", marginRight: "1%" }}
                                label="Contre valeur"
                                value={formatNumber(contreValeurs)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{ style: { color: inputColor } }}
                                disabled
                            />

                        </Box>
                        <Box display="flex" justifyContent="space-between" sx={{
                            "& .MuiButtonBase-root" : {
                                color: colors.grey[200],
                                backgroundColor : colors.primary[400],
                                marginY: "0.5rem",
                                fontSize: "0.8rem"
                            },
                            "& .MuiButtonBase-root:hover" : {
                                color: colors.primary[400],
                                backgroundColor : colors.grey[200],

                                marginY: "0.5rem",
                                fontSize: "0.9rem"
                            },
                            "& .span1" : {




                            }}}>
                            <Box sx={{width:"82%"}}>
                                <TableContainer component={Paper} >
                                    <Table >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#e5a984" }}>Devise</TableCell>
                                                <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#e5a984" }} align="right">Montant en devise</TableCell>
                                                <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#e5a984" }} align="right">Cours apliqué</TableCell>
                                                <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#e5a984" }} align="right">Contre valeur en DHS</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row) => (
                                                <TableRow

                                                    key={row.devise}
                                                    onClick={()=>handleRowClick(row)}
                                                    sx={{
                                                        backgroundColor:
                                                            selectedRow && selectedRow.devise === row.devise ? '#ccc' : 'inherit',
                                                    }}
                                                >

                                                    <TableCell sx={{ paddingY: "0.5rem", fontSize:"0.9rem" }}>
                                                        {row.devise}
                                                    </TableCell>
                                                    <TableCell sx={{ paddingY: "0.5rem", fontSize:"0.9rem" }} align="right">{formatNumber(row.montant)}</TableCell>
                                                    <TableCell sx={{ paddingY: "0.5rem", fontSize:"0.9rem" }} align="right">{parseFloat(row.coursVente).toFixed(4)}</TableCell>
                                                    <TableCell sx={{ paddingY: "0.5rem", fontSize:"0.9rem" }} align="right">{formatNumber(row.contreValeur)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                            <Box sx={{width:"18%"}}>
                                <Box><Button onClick={handleInsert}><AddIcon/><span className="span1">Insérer</span></Button></Box>
                                <Box><Button onClick={handleDeleteRows}><CancelIcon/><span className="span1">Annuler</span></Button></Box>
                                <Box><Button  onClick={submitall}><CheckCircleOutlineIcon/><span className="span1">Valider</span></Button></Box>
                            </Box>

                        </Box>
                        <Box  display="flex"
                              sx={{justifyContent:'end',width:'82%',
                                  "& .contrevaleur":{
                                      color: colors.grey[100],
                                  },
                                  "& .contrevv":{
                                      backgroundColor:colors.primary[800],
                                      color: colors.grey[100],
                                  }
                              }}
                        ><span className="contrevaleur" >Contre valeur en DHS</span> <span className="contrevv"  >{formatNumber(sumCv)} </span></Box>

                    </Box>

                </Box>
                <Box className="table1"
                     sx={{
                         "& .MuiDataGrid-root": {
                             border: "none",
                         },
                         "& .MuiDataGrid-cell": {
                             borderBottom: "none",
                         },
                         "& .name-column--cell": {
                             color: colors.greenAccent[300],
                         },
                         "& .MuiDataGrid-columnHeaders": {
                             backgroundColor: colors.blueAccent[700],
                             borderBottom: "none",
                         },
                         "& .MuiDataGrid-virtualScroller": {
                             backgroundColor: colors.primary[400],
                         },
                         "& .MuiDataGrid-footerContainer": {
                             borderTop: "none",
                             backgroundColor: colors.blueAccent[700],
                         },
                         "& .MuiCheckbox-root": {
                             color: `${colors.greenAccent[200]} !important`,
                         },
                         "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                             color: `${colors.grey[100]} !important`,
                         },
                     }}
                >
                    <h2   className="h2">Caisse en devise</h2>
                    <TableContainer component={Paper} >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#728cef" }}>Devise</TableCell>
                                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#728cef" }}>Montant</TableCell>
                                </TableRow>
                            </TableHead>
                        </Table>
                        <div style={{ overflowY: 'auto', maxHeight: '35vh' }}>
                            <Table aria-label="simple table">
                                <TableBody>
                                    {devise.map((row, index) => (
                                        <TableRow
                                            key={index}
                                            onClick={() => handleRowClick2(row)}
                                            style={{
                                                backgroundColor:
                                                    selectedRow2 && selectedRow2.nom_devise === row.nom_devise ? '#ccc' : 'inherit',
                                            }}
                                        >
                                            <TableCell sx={{ paddingY: "0.5rem", fontSize:"0.9rem" }}>{row.nom_devise}</TableCell>
                                            <TableCell align="right" sx={{ paddingY: "0.5rem", fontSize:"0.9rem" }}>{formatNumber(row.montant)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    </TableContainer>
                    <Box display="flex" justifyContent="space-between" >

                        <Box
                            sx={{width:'100%',justifyContent: 'center', alignItems: 'center',justifyItems:'center',
                                "& .contrevaleur":{
                                    color: colors.grey[100],
                                    fontSize: "1rem",
                                    marginTop: "1rem",
                                    padding:"0.5rem",
                                },
                                "& .contrev":{
                                    backgroundColor:colors.primary[800],
                                    color: colors.grey[100],
                                    fontSize: "1.1rem",
                                    marginTop: "1rem",
                                    marginLeft: "2rem",
                                    marginRight : "3rem",
                                    marginBottom: "5rem",
                                    paddingX:"2.5rem",
                                    paddingY:"0.5rem",
                                }
                            }}
                        ><Box sx={{ marginY: '2vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <span className="contrevaleur" stylle={{width:'100%', textAlign:'center'}}>Contre valeur de devises conservées</span>
                        </Box >
                            <Box  sx={{display: 'flex', justifyContent: 'center', alignItems: 'center' }}><span className="contrev"  >{formatNumber(sumCvAc)} </span></Box></Box>
                    </Box>
                </Box>

            </Box>

            <div style={{ display: 'none' }}>
                <PDFContent data={rows} agence={agence} data2={submitValue} data3={selectedOption} sumCv={formatNumber(sumCv)}  ref={componentRef} />
            </div>


        </Box>
    );
};

export default Achat;
