import { Box } from "@mui/material";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import {Button} from "@mui/material";
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import SystemUpdateAltOutlinedIcon from '@mui/icons-material/SystemUpdateAltOutlined';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useState,useEffect,useRef } from "react";
import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import { useReactToPrint } from "react-to-print";
import axios from "axios";
import { CSVLink } from "react-csv";
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import swal from "sweetalert";
import Table from "../../../table";
import {formatNumber} from '../../../fonctions/formatNumber' ;
import PDFOperationsDhs from '../PDFContent/OperationsCaisse/index'
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
class PDFContent extends React.Component {
    render() {
        // Extracting props for easier access
        const {  data2,data3, agence } = this.props;
        return (
            <PDFOperationsDhs data2={data2} data3={data3} agence={agence}/>
        );
    }
}
const Consultation = () => {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const colors = tokens(theme.palette.mode);
    const users=["user1","user2"];
    const [dataToRender,setDataToRender]=useState([]);
    const [dataToRender2,setDataToRender2]=useState([]);
    const [user,setUser]=useState([]);
    const [caisses,setCaisses]=useState([]);
    const [selectedRow,setSelectedRow]=useState(null);

    const [page, setPage] =useState(0);
    const [rowsPerPage, setRowsPerPage] =useState(10);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedCaisse, setSelectedCaisse] = useState(null);
    const [selectedType, setSelectedType] = useState(null);
    const [selectedDuDate, setSelectedDuDate] = useState(dayjs());
    const [selectedAuDate, setSelectedAuDate] = useState(dayjs());
    const [listcaisse,setlistcaisse]=useState([]);
    const [type,setType]=useState([]);
    const componentRef = useRef();
    const [info, setInfo] = useState({
        du:'',
        au:'',
    });
    const [agence,setAgence]=useState({});
    const [shouldPrint, setShouldPrint] = useState(false);
    const [rows, setRows] = useState([]);

    /****************************************************************functions ************************************************************************ */
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handleRowClick = (row) => {
        setSelectedRow(row);
    };
    const handlePrintev = (e) => {
        e.preventDefault();
        axios.get(`api/info-agence`).then((res) => {
            if (res.data.status === 200) {
                setAgence(res.data.message);
                setShouldPrint(true);
            }
        });

        // Add checks to ensure variables are defined
        const du = convertDateFormat(selectedDuDate);
        const au = convertDateFormat(selectedAuDate);

        setInfo((prevInfo) => ({
            ...prevInfo,
            du: du,
            au: au,
            user:user,
        }));
    };
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "Print",
        pageStyle: `
    @page {
      size: A4;
      margin: 10mm;
    }
    /* Additional styles for the printed page can be added here */
    /* Remove the pagination and URL styles */
    @media print {
      .MuiDataGrid-footerContainer {
        display: none !important;
      }
      a {
        display: none !important;
      }
    }
  `,
        header: null, // Set header to null to remove it from the PDF
        footer: null,
        // Set footer to null to remove it from the PDF
    });

    useEffect(() => {
        if (shouldPrint) {
            // Call handlePrint() when shouldPrint becomes true
            handlePrint(); // This will trigger the printing action
            setShouldPrint(false); // Reset shouldPrint back to false
        }
    }, [shouldPrint]);
    /********************************************************************************UseEffect********************************** */

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`api/liste-operations-caisse`);
                if (response.data.status === 200) {
                    const { message, caisses,ur } = response.data;
                    const bor=handleDuDateChange2(selectedDuDate,message);
                    const  bor2=handleAuDateChange2(selectedAuDate,bor);
                    setUser(ur);
                    const msg = bor.map((op) => {
                        return {
                            'utilisateur':op.user_name,
                            'caisse': op.designation,
                            "Type d'opération": op.description,
                            'Date': op.date_operation,
                            'Heure': op.heure_operation,
                            'Montant': op.montant,
                            'Observation': op.observation,

                        };
                    });
                    setRows(msg);
                    setDataToRender(bor2);
                    setDataToRender2(message);
                    setlistcaisse(caisses);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();

    }, [open]);


    /*****************************************************filtre functions************************************************ */
//user
    const handleUserFilterChange = (event, newValue) => {
        setSelectedUser(newValue);
        let bor=dataToRender2;
        bor=handleUserFilterChange2( (newValue? newValue.name:''),bor);
        bor=handleDuDateChange2(selectedDuDate,bor);
        bor=handleAuDateChange2(selectedAuDate,bor);
        bor=handleCaisseFilterChange2( selectedCaisse,bor);
        setDataToRender(bor);
    }
    const handleUserFilterChange2 = (newValue,bor) => {
        let filteredItems;
        if(newValue!=''){
            filteredItems = bor.filter((item) =>
                item.name===newValue
            );
        }else{
            filteredItems = dataToRender2;
        }
        return filteredItems;
    };


//du date filtre
    const convertDateFormat = (dateString) => {

        const parsedDate = dayjs(dateString, 'MM/DD/YYYY'); // Parse the date with the original format
        return parsedDate.format('YYYY/MM/DD'); // Format the date in the desired format
    };
    const convertDateFormat2 = (dateString) => {
        const parsedDate = dayjs(dateString, 'DD/MM/YYYY'); // Parse the date with the original format
        return parsedDate.format('YYYY/MM/DD'); // Format the date in the desired format
    };
    const convertDateFormat3 = (dateString) => {

        return  dayjs(dateString, 'MM/DD/YYYY'); // Parse the date with the original format
    };

    const handleDuDateChange = (newValue) => {
        console.log(newValue);
        setSelectedDuDate(newValue);
        let bor=dataToRender2;
        bor=handleUserFilterChange2( (selectedUser? selectedUser.name:''),bor);
        bor=handleDuDateChange2(newValue,bor);
        bor=handleAuDateChange2(selectedAuDate,bor);
        bor=handleCaisseFilterChange2( selectedCaisse,bor);
        setDataToRender(bor);
        const msg = bor.map((op) => {
            return {
                'utilisateur':op.user_name,
                'caisse': op.designation,
                "Type d'opération": op.description,
                'Date': op.date_operation,
                'Heure': op.heure_operation,
                'Montant': op.montant,
                'Observation': op.observation,

            };
        });
        setRows(msg);
    };
    const handleDuDateChange2 = (newValue,bor) => {
        if(newValue){
            const newV=convertDateFormat(newValue);
            const filteredItems = bor.filter((item) => {
                const parsedDate = convertDateFormat2(item.date_operation);
                return new Date(parsedDate) >= new Date(newV);
            });
            return filteredItems;
        }else{

            return bor;
        }
    }
// au date functions

    const handleAuDateChange = (newValue) => {
        setSelectedAuDate(newValue);
        let bor=dataToRender2;
        bor=handleUserFilterChange2( (selectedUser? selectedUser.name:''),bor);
        bor=handleAuDateChange2(newValue,bor);
        bor=handleDuDateChange2(selectedDuDate,bor);
        bor=handleCaisseFilterChange2( selectedCaisse,bor);
        setDataToRender(bor);
        const msg = bor.map((op) => {
            return {
                'utilisateur':op.user_name,
                'caisse': op.designation,
                "Type d'opération": op.description,
                'Date': op.date_operation,
                'Heure': op.heure_operation,
                'Montant': op.montant,
                'Observation': op.observation,

            };
        });
        setRows(msg);
    };
    const handleAuDateChange2 = (newValue,bor) => {
        if(newValue){
            const newV=convertDateFormat(newValue);
            const filteredItems = bor.filter((item) => {
                const parsedDate = convertDateFormat2(item.date_operation);
                return new Date(parsedDate) <= new Date(newV);
            });
            return filteredItems;
        }else{

            return bor;
        }
    }
// devise filtre functions
    const handleCaisseFilterChange = (event, newValue) => {
        setSelectedCaisse(newValue);
        let bor=dataToRender2;
        bor=handleAuDateChange2(selectedAuDate,bor);
        bor=handleDuDateChange2(selectedDuDate,bor);
        bor=handleCaisseFilterChange2( newValue,bor);
        bor=handleCaisseFilterChange3( selectedType,bor);

        setDataToRender(bor);
        const msg = bor.map((op) => {
            return {
                'utilisateur':op.user_name,
                'caisse': op.designation,
                "Type d'opération": op.description,
                'Date': op.date_operation,
                'Heure': op.heure_operation,
                'Montant': op.montant,
                'Observation': op.observation,

            };
        });
        setRows(msg);
    };
    const handleBanqueFilterChangee = (event, newValue) => {
        setSelectedType(newValue);
        let bor=dataToRender2;
        bor=handleAuDateChange2(selectedAuDate,bor);
        bor=handleDuDateChange2(selectedDuDate,bor);
        bor=handleCaisseFilterChange3( newValue,bor);
        bor=handleCaisseFilterChange2( selectedCaisse,bor);

        setDataToRender(bor);
        const msg = bor.map((op) => {
            return {
                'utilisateur':op.user_name,
                'caisse': op.designation,
                "Type d'opération": op.description,
                'Date': op.date_operation,
                'Heure': op.heure_operation,
                'Montant': op.montant,
                'Observation': op.observation,

            };
        });
        setRows(msg);
    };

    const handleCaisseFilterChange2 = ( newValue,bor) => {
        if(newValue){
            const filteredItems = bor.filter((item) =>
                item.caisse===newValue.designation
            );

            return filteredItems;
        }else{

            return bor;
        }


    }
    const handleCaisseFilterChange3 = ( newValue,bor) => {
        if(newValue){
            const filteredItems = bor.filter((item) =>
                item.description===newValue.description
            );

            return filteredItems;
        }else{

            return bor;
        }


    }
    /**********************************END FILTRE FUNCTIONS*************************************** */
    /**************************************operation caisse********************************************* */
    const [devise,setDevise]=useState([]);
    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [sumCaisse, setSumCaisse] = useState(0);
    const [montant, setMontant] = useState(0);
    const [submitValue,setSubmitValue]=useState({
        montant :'',
        observation:''
    })

    const [listdevise,setlistdevise]=useState([]);

    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedDevise,setSelectedDevise]=useState(listdevise[0]);
    const handleInput = (event, newValue) => {
        setSelectedType(newValue);

    };
    const handleInput2=(e)=>{
        e.persist();
        setSubmitValue({...submitValue, [e.target.name]: e.target.value})
    }
    const sumCvAc = devise.reduce((accumulator, currentDevise) => {
        if(currentDevise.nom_devise !== "DHS"){
            return accumulator + currentDevise.cv_ac;
        }else{
            return accumulator;
        }
    }, 0);
    useEffect(()=>{axios.get(`api/operation-caisse`).then(res=>
        {
            if(res.data.status===200){
                setType(res.data.operations);
                setSumCaisse(res.data.sommeContreValeur);

            }
        }
    );},[open]);
    const contreValeurs = selectedDevise ? (selectedDevise.tauxA * montant)/selectedDevise.prix_par : 0;
    const submit=(e)=>{
        e.preventDefault();
        if(selectedType){
            const data={
                montant:parseFloat(submitValue.montant),
                observation:submitValue.observation,
                id_type:parseInt(selectedType.id),
            }


            swal({
                title: '',
                text: 'Voulez vous vraiment valider cette opération ?',
                icon: 'warning',
                buttons: ['Annuler', 'Oui'],
                dangerMode: true,
            }).then((confirmed) => {
                if (confirmed) {
                    if(submitValue.montant && selectedType.id ){
                        if(parseFloat(submitValue.montant) > parseFloat(sumCaisse) && parseInt(selectedType.id)==10){

                            swal(
                                '',
                                "Opération interdit.",
                                'warning'
                            );
                        }else{
                            axios.post('api/operation-caisse', data)
                                .then((res) => {
                                    // ... Handle the response from the server ...
                                    if (res.data.status === 200) {
                                        setOpen(false)
                                        setSubmitValue({
                                            montant :'',
                                            observation:''
                                        });
                                        setSelectedType(null);
                                    } else if (res.data.status === 400) {
                                        swal(
                                            '',
                                            "Pour enregistrer cette opération, il est nécessaire de saisir les informations d'opération.",
                                            'warning'
                                        );
                                    }
                                })
                                .catch((error) => {
                                    // Handle any error that occurred during the request
                                    console.error('Error submitting form:', error);
                                });

                        }
                    }else{
                        swal(
                            '',
                            "Pour enregistrer cette opération, il est nécessaire de saisir les informations d'opération.",
                            'warning'
                        );
                    }
                }
            });
        }
    }

    /************************************** end operation caisse********************************************* */

    const nomFichier=`ETAT_DES_OPERATIONS_DE_CAISSE-Du_${convertDateFormat(selectedDuDate)}-Au_${convertDateFormat(selectedAuDate)}.csv`
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setSubmitValue({
            montant :'',
            observation:''
        });
        setSelectedType(null);
    };
    const colonnesEntete = [
        { id: 'description', label: "Type d'opérationt", align: 'left' },
        { id: 'date_operation', label: 'Date', align: 'left' },
        { id: 'heure_operation', label: 'Heure', align: 'left' },
        { id: 'nom_devise', label: 'Devise', align: 'left' },
        { id: 'montant', label: 'Montant', align: 'right' },
        { id: 'observation', label: 'Observation', align: 'left' },
        { id: 'user_name', label: 'Utilisateur', align: 'left' },
        { id: 'designation', label: 'Caisse', align: 'left' },
    ];
    const colsToShow=[7,1,2, 3, 4, 5, 8, 6];
    return (
        <Box m="20px" sx={{
            "& .bartitle":{
                marginY : "1.5rem",
            }
        }}>

            <Box display="flex" sx={{
                "& .MuiFormControl-root" : {
                    marginRight:"0.5vw",
                    width:'14vw',

                },}}>
                <Box>
                    {user===1?<Autocomplete className="Autocomplete"
                                            size="small"
                                            id="combo-box-demo"
                                            value={selectedCaisse}
                                            getOptionLabel={(option) => option.designation}
                                            options={listcaisse}
                                            onChange={handleCaisseFilterChange}
                                            renderInput={(params) => <TextField {...params} label="Caisse" />}
                    />:null}
                </Box>
                <Box>
                    <Autocomplete className="Autocomplete"
                                  size="small"
                                  id="combo-box-demo"
                                  value={selectedType}
                                  options={type}
                                  onChange={handleBanqueFilterChangee}
                                  getOptionLabel={(option) => option.description}
                                  renderInput={(params) => <TextField {...params} label="Type Opération" />}

                    /></Box>
            </Box>
            <Box display="flex" justifyContent="space-between"
                 sx={{
                     "& .MuiFormControl-root" : {
                         marginRight:"0.5vw",
                         width:"14vw",

                     },
                     "& .MuiAutocomplete-root":{
                         marginTop:"8px"
                     },
                     "& .buttons" : {
                         color: colors.grey[200],
                         backgroundColor : colors.primary[400],
                         margin: "0.5rem 0.5rem -0.2rem 0.5rem",
                         fontSize: "0.7rem",
                         height: "2.5rem",
                         paddingX: "1.2rem",
                         paddingY: "0.2rem"
                     },
                     "& .buttons:hover" : {
                         color: colors.primary[400],
                         backgroundColor : colors.grey[200],

                     },
                     "& .span1" : {


                         marginLeft: "0.5rem",

                     },
                     "& .csss" : {


                         minWidth:'14vw',

                     }
                 }}
            >

                <Box display="flex">

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                            <DatePicker
                                label="DU"
                                value={selectedDuDate}
                                onChange={handleDuDateChange}
                                format="DD/MM/YYYY"
                                className="csss"
                                sx={{
                                    '& .MuiInputBase-input': {
                                        padding: '10px 14px',
                                        fontSize: '0.875rem',
                                    },
                                }}
                            />
                        </DemoContainer>
                    </LocalizationProvider>




                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                        <DemoContainer components={['DatePicker']}>
                            <DatePicker label="AU"
                                        value={selectedAuDate}
                                        onChange={handleAuDateChange}
                                        format="DD/MM/YYYY"
                                        className="csss"
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                padding: '10px 14px',
                                                fontSize: '0.875rem',
                                            },
                                        }}
                            />
                        </DemoContainer>
                    </LocalizationProvider>


                </Box>
                < Box>
                    <Button className="buttons" onClick={handleClickOpen}><AddCircleOutlinedIcon/><span className="span1">Nouveau</span> </Button>
                    <Button className="buttons" onClick={handlePrintev}><FindInPageOutlinedIcon/><span className="span1">Aperçu</span> </Button>
                    <CSVLink data={rows} filename={nomFichier}><Button className="buttons"><SystemUpdateAltOutlinedIcon/><span className="span1">Exporter</span></Button></CSVLink>

                </Box>

            </Box>
            <Box>
                <Table dataToRender={dataToRender} headCells={colonnesEntete} colsToShow={colsToShow} cols={"num_bac"}/>

            </Box>
            <Box display="flex" justifyContent="space-between" >
                <Box display="flex"></Box>

            </Box>


            <div style={{ display: 'none' }}>
                <PDFContent  agence={agence} data2={dataToRender} data3={info}  ref={componentRef} />
            </div>
            <Dialog

                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
                PaperProps={{
                    style: {
                        width: '70vw',
                        maxWidth: '70vw',
                        height:'90vh',
                        maxHeight:'90vh',
                    },
                }}
            >
                <AppBar sx={{ position: 'relative',bgcolor: '#0f2479' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            OPERATIONS DE CAISSE
                        </Typography>

                    </Toolbar>
                </AppBar>
                <List>
                    <Box className="type2">
                        <Box className="type3" >
                            <Box  className="form2"
                                  sx={{
                                      "& .MuiOutlinedInput-input":{
                                          }
                                  }}
                            >

                                <Box  >
                                    <h1  className= "h1">Détail opération</h1>
                                    <Box sx={{display:'flex',justifyContent:'flex-end',justifyItems:'flex-end'}}><TextField size="small" name="caisse"  value={formatNumber(sumCaisse)}  sx={{marginTop:`1.5vh` ,width:`40vh`}}  label="caisse en Dhs" variant="outlined" disabled /></Box>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker
                                                disabled
                                                label="Date"
                                                value={selectedDate}
                                                format="DD/MM/YYYY"
                                                size="small"

                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                    <Autocomplete
                                        onChange={handleInput}
                                        value={selectedType}
                                        size="small"
                                        id="combo-box-demo"
                                        options={type}
                                        sx={{marginTop:`2vh`,width:'20vw'}}
                                        getOptionLabel={(option) => option.description}

                                        renderInput={(params) => <TextField {...params} label="Type d'opération" />}
                                    />

                                    <Box><TextField size="small" sx={{marginTop:`2vh`,width:'20vw'}} name="montant"  value={submitValue.montant} onChange={handleInput2}  label="Montant" variant="outlined" type="number"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }} /></Box>
                                    <Box><TextField size="small" multiline rows={4}  sx={{marginTop:`2vh`,width:'100%'}} name="observation"  value={submitValue.cheque} onChange={handleInput2} label="Observation" variant="outlined" /></Box>
                                    <Box sx={{display:'flex',justifyContent:'center',justifyItems:'center',marginTop:'2vh',
                                        "& .MuiButtonBase-root": {
                                            color: colors.grey[200],
                                            backgroundColor: colors.primary[800],

                                        },
                                        "& .MuiButtonBase-root:hover": {
                                            color: colors.primary[400],
                                            backgroundColor: colors.grey[200],

                                        },

                                    }}><Button onClick={submit} className="buttons"   >Valider</Button></Box>

                                </Box>


                            </Box>



                        </Box>
                    </Box>
                </List>
            </Dialog>
        </Box>
    );
};

export default Consultation;
