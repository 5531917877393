import React from 'react';
import {Box} from "@mui/material";
import {formatNumber} from "../../../../fonctions/formatNumber";

function Index(props) {
    const { data,agence,data2 } = props;

    return (
        <div>
            <Box sx={{
                borderBottom: '1px solid black',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: "100vw"
            }}>
                <h5 className="h5">{agence.nom_bureau}</h5>
                <h5 className="h5">{agence.num_auto}</h5>
            </Box>
            <Box
                sx={{
                    border: '1px solid black',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '50vw',
                    marginLeft: '25vw',
                    marginY: '4vh',
                    borderRadius: '8px',
                    backgroundColor: '#ea5a27', // Change the background color here
                }}
            >
                <h2 className="h2">Statistique des ventes</h2>
            </Box>
            <Box sx={{display: "flex", justifyContent: "center"}}>

                <Box sx={{marginRight: "5vh"}}><h5 className="h5">Mois : {data2.mois}</h5></Box>
                <Box sx={{marginRight: "5vh"}}><h5 className="h5">Année: {data2.annee}</h5></Box>
            </Box>
            <Box sx={{width: '80vw', marginBottom: '2vh', marginLeft: "7.5vw", marginRight: "7.5vw"}}>

                <h5 className="h5">Utilisateur : {data2.utilisateur ? data2.utilisateur : '--all--'}</h5>
                <h5 className="h5">Devise: {data2.devise ? data2.devise : '--all--'}</h5>
            </Box>
            <Box sx={{width: '90vw', marginLeft: "5vw", marginRight: "5vw"}}>
                <table className="print-table">
                    <tr>
                        <th>Devise</th>
                        <th>Montant</th>
                        <th>Contre valeur</th>
                    </tr>
                    {data.map((row) => (
                        <tr key={row.nom_devise}> {/* Assuming each row has a unique identifier (e.g., 'id') */}
                            <td>{row.nom_devise}</td>
                            <td align="right">{formatNumber(row.mt_ac_sum)}</td>
                            <td align="right">{formatNumber(row.cv_ac_sum)}</td>
                        </tr>
                    ))}
                </table>
                <Box sx={{
                    display: 'flex',
                    marginRight: "5vw",
                    alignItems: 'center',
                    marginTop: "2vh",
                    justifyContent: "end"
                }}>
                    <Box sx={{
                        borderBottom: '1px dotted black',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: "16vw",
                        marginX: "2vw"
                    }}><h3>Total</h3></Box><Box sx={{
                    borderBottom: '1px dotted black',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: "16vw",
                    marginX: "2vw"
                }}><h3>{formatNumber(data2.sumCv)}</h3></Box>

                </Box>

                {/* Additional CSS styles for printing */}
                <style>
                    {`
          .print-table {
            width: 100%;
            border-collapse: collapse;
          }

          .print-table th, .print-table td {
            border: 1px solid black;
            padding: 8px;
          }

          /* Additional styles specific for printing */
          @media print {
            .print-table {
              page-break-inside: auto;
            }
            .footer{
              position: fixed;
              bottom: 0;
              border-top: 2px solid #73AD21;
              align-items: center;
              width:100vw;
              justify-content:center;
            }
            .print-table th, .print-table td {
              page-break-inside: avoid;
            }
          }
        `}
                </style>
            </Box>
            <Box className="footer" sx={{paddingTop: '1vh'}}>
                <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}><h5 className="h5">Adresse
                    : {agence.adresse}</h5></Box>
                <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}><Box
                    sx={{marginRight: "3vh"}}><h5 className="h5">Tél : {agence.tel}</h5></Box><Box><h5
                    className="h5">Email : {agence.email}</h5></Box></Box>
                <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}><Box
                    sx={{marginRight: "3vh"}}><h5 className="h5">TP : {agence.tp}</h5></Box><Box
                    sx={{marginRight: "3vh"}}><h5 className="h5">IF : {agence.idfis}</h5></Box><Box
                    sx={{marginRight: "3vh"}}><h5 className="h5">RC : {agence.rc}</h5></Box>
                    <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}><h5 className="h5">ICE
                        : {agence.ice}</h5></Box></Box>
            </Box>
        </div>
    );
}

export default Index;