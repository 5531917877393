import React, { useState, useEffect } from 'react';
import { TableContainer, Table, TableBody, TableRow, TableCell, Paper } from "@mui/material";
import EnhancedTableHead from './TeteTable';
import { useSelectedRow } from "../context/SelectedRowContext";
import { useOrder } from "../context/OrderContext";

function Index(props) {
    const { dataToRender, headCells, colsToShow, cols } = props;
    const { selectedRow, updateSelectedRow } = useSelectedRow();

    // État pour gérer le a
    const [order, updateOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState(cols);



    // Fonction pour trier les données
    const getComparator = (order, orderBy) => {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    };

    // Fonction pour tri stable
    const stableSort = (array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    };

    // Exemple de comparateur descendant
    const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    };

    // Fonction pour gérer le clic sur une ligne
    const handleRowClick = (row) => {
        updateSelectedRow(row);
        // Mettez ici la logique de ce que vous voulez faire lorsqu'une ligne est sélectionnée
    };

    // Fonction pour gérer le tri de la table
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        updateOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    // Filtrer les colonnes à afficher
    const filterColumns = (row) => {
        var count=-1
        return colsToShow.map((index) => {
            const keys = Object.keys(row);
            const cellValue = row[keys[index]];
            const isNumber = typeof cellValue === 'number';
            count++;
            return (
                <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}} key={keys[index]} align={headCells[count].align}>
                    {cellValue}
                </TableCell>
            );
        });
    };

    return (
        <div>
            <Paper>
                <TableContainer sx={{ height: "62vh", marginTop: "3vh", backgroundColor: "#f2f0f0" }}>
                    <Table stickyHeader aria-label="sticky table">
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            headCells={headCells}
                        />
                        <TableBody>
                            {stableSort(dataToRender, getComparator(order, orderBy)).map((row,index) => (
                                <TableRow
                                    key={row.id}
                                    onClick={() => handleRowClick(row)}
                                    sx={{
                                        backgroundColor:
                                            selectedRow && selectedRow.id === row.id ? '#bdbdbe' : 'inherit',
                                    }}
                                >
                                    {filterColumns(row)}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    );
}

export default Index;
