import React from 'react';
import { Box } from "@mui/material";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import {Button} from "@mui/material";
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import {useEffect, useState,useRef} from 'react';
import axios from "axios";
import { useReactToPrint } from "react-to-print";
import TextField from "@mui/material/TextField";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import swal from "sweetalert";
import OpVenteDialog from './../../Gestinbanque/Dialog/op_vente_dialog';
import {formatNumber} from '../../../fonctions/formatNumber'
import PDFCession from '../PDFContent/Cession/index'
import { Link } from "react-router-dom";
import Table from "../../../table";
import {useSelectedRow} from "../../../context/SelectedRowContext";

class PDFContent extends React.Component {
    render() {
        const { data,agence,data2 } = this.props;
        return (
            <PDFCession data={data} agence={agence} data2={data2}/>
        );
    }
}

const Achat = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [bordereaux, setBordereaux] = useState([]);
    const [user, setUser] = useState();
    const [sumCv, setSumCv] = useState();
    const [dataToRender, setDataToRender] = useState([]);
    const { selectedRow, updateSelectedRow } = useSelectedRow();
    const [banque,setBanque]=useState([]);
    const [agence,setAgence]=useState([]);
    const [rows, setRows] = useState([]);
    const [data2, setData2] = useState([]);
    const [search, setSearch] = useState(null);
    const history=useHistory();
    const componentRef = useRef();
    const [dialogOpen, setDialogOpen] = useState(false);
    useEffect(() => {
        updateSelectedRow(null);
    }, []);


    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    /******************************************function*************************************************** */
    const handleSearchChange = (event) => {
        const query = event.target.value;
        setSearch(query);

        // Filter the array based on the search query
        const filteredResults = bordereaux.filter((item) =>
            item.num_bvc.toLowerCase().includes(query.toLowerCase())
        );

        setDataToRender(filteredResults);
    };
    const [verCaisse,setVerCaisse]=useState();
    useEffect(() => {
        axios.get(`api/detail-caisse`).then(res => {
            if (res.data.status === 200) {
                setVerCaisse(res.data.message);
            }
        });
    }, []);
    const valideN= async (e) => {
            if (verCaisse) {
                e.preventDefault();
                if (((verCaisse ? verCaisse.length : null) === 1 && (verCaisse != [] ? verCaisse[0].nom_devise : null) === "DHS") || (verCaisse.length === 0)) {
                    swal('', "La caisse ne dispose que de dirhams, aucune autre devise.", 'warning');
                } else {
                    history.push('/espace-magchange/operation_vente_banque');
                }
            }

    }
    const handlePrintev= (e) => {

        e.preventDefault();
        if(selectedRow){
            axios.get(`api/info-agence`).then((res) => {
                if (res.data.status === 200) {
                    setAgence(res.data.message);

                }
            });
            axios.get(`api/op-v-b-p?id=${selectedRow.id}`)
                .then((res) => {
                    setRows(res.data.message);
                    setData2(res.data.message2);
                    setShouldPrint(true);
                });
        }
    };

    useEffect(() => {

        const newSumCv = dataToRender.reduce((accumulator, currentDevise) => {
            const cvAcNumber = parseFloat(currentDevise.cv_v.replace(/\s/g, '').replace(',', '.'));
            return accumulator + (isNaN(cvAcNumber) ? 0 : cvAcNumber);
        }, 0);
        // Set the calculated sumCv to state
        setSumCv(newSumCv);
    }, [dataToRender]);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: `
        @page {
          size: A4;
          margin: 10mm;
        }
        /* Additional styles for the printed page can be added here */
        /* Remove the pagination and URL styles */
        @media print {
          .MuiDataGrid-footerContainer {
            display: none !important;
          }
          a {
            display: none !important;
          }
        }
      `,
        header: null, // Set header to null to remove it from the PDF
        footer: null,
        // Set footer to null to remove it from the PDF
    });
    const [shouldPrint, setShouldPrint] = useState(false);

    useEffect(() => {
        if (shouldPrint) {
            // Call handlePrint() when shouldPrint becomes true
            handlePrint(); // This will trigger the printing action
            setShouldPrint(false); // Reset shouldPrint back to false
        }
    }, [shouldPrint]);
    /**************************************************************API******************************************/
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`api/ventes-banque`);
                if (response.data.status === 200) {
                    setBordereaux(response.data.message);

                    setDataToRender(response.data.message);
                    setUser(response.data.utilisateur);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [dialogOpen]);
    useEffect(()=>{
        axios.get(`api/op-v-b`).then(res=>{
            if(res.data.status===200){
                setBanque(res.data.banques);

            }
        })},[]);
    const colonnesEntete = user !== 1?[
        { id: 'num_bvc', label: 'Bordereau', align: 'left' },
        { id: 'date_achat', label: 'Date', align: 'right' },
        { id: 'heure_achat', label: 'Heure', align: 'right' },
        { id: 'prenom_nom', label: 'Client', align: 'left' },
        { id: 'nom_devise', label: 'Devise', align: 'left' },
        { id: 'mt_ac', label: 'Montant', align: 'right' },
        { id: 'cours_ac', label: 'Cours', align: 'right' },
        { id: 'cv_ac', label: 'Contre Valeur', align: 'right' },
    ]:[
        { id: 'num_bvc', label: 'Bordereau', align: 'left' },
        { id: 'name', label: 'Utilisateur', align: 'left' },
        { id: 'date_achat', label: 'Date', align: 'right' },
        { id: 'heure_achat', label: 'Heure', align: 'right' },
        { id: 'prenom_nom', label: 'Client', align: 'left' },
        { id: 'nom_devise', label: 'Devise', align: 'left' },
        { id: 'mt_vc', label: 'Montant', align: 'right' },
        { id: 'cours_vc', label: 'Cours', align: 'right' },
        { id: 'cv_v', label: 'Contre Valeur', align: 'right' },
    ];
    const colsToShow = user !== 1 ? [1,2, 3, 4, 5, 7, 6, 8] : [1,2, 3, 4, 5,9, 7, 6, 8];
    /*****************************************return*********************************************************9 */
    return (
        <Box className="type2 container-table" >

            <Box sx={{display:'flex',justifyContent:'space-between',marginRight: "4%",marginLeft: "2%", width: "92%",}}>
                <Box sx={{
                    "& .MuiButtonBase-root" : {
                        color: colors.grey[200],
                        backgroundColor : colors.primary[400],
                    },
                    "& .MuiButtonBase-root:hover" : {
                        color: colors.primary[400],
                        backgroundColor : colors.grey[200],
                    },
                    "& .buttons": {
                        color: colors.grey[200],
                        backgroundColor: colors.primary[400],
                        margin: "0.5rem 0.5rem -0.2rem 0.5rem",
                        fontSize: "0.7rem",
                        height: "2.5rem",
                        paddingX: "1.2rem",
                        paddingY: "0.2rem"
                    }
                }}>
                    <Button className="buttons" onClick={valideN}><AddCircleOutlinedIcon/><span className="span1">NOUVEAU</span> </Button>
                    <Button onClick={handlePrintev} className="buttons"><FindInPageOutlinedIcon/><span className="span1">Aperçu de Bordereau</span></Button>

                </Box>
                <Box sx={{}}>
                    <TextField
                        sx={{margin: "0.5rem 0 -0.2rem 0.5rem"}}
                        id="search-bar"
                        className="text"
                        label="Entrer le N° de bordereau"
                        value={search}
                        onChange={handleSearchChange}
                        variant="outlined"
                        placeholder="Search..."
                        size="small"
                    />
                </Box>
            </Box>
            <Box

                sx={{marginX: "1.5%", width: "95%",
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                    },
                }}
            >
                <Table dataToRender={dataToRender} headCells={colonnesEntete} colsToShow={colsToShow} cols={"num_bvc"}/>

            </Box>
            <Box display="flex" justifyContent="space-between" >
                <Box display="flex"></Box>
                <Box  display="flex"
                      sx={{
                          "& .contrevaleur":{
                              color: colors.grey[100],
                          },
                          "& .contrev":{
                              backgroundColor:colors.primary[800],
                              color: colors.grey[100],
                          }
                      }}
                ><span className="contrevaleur" >Total contre valeurs : </span> <span className="contrev"  >{formatNumber(sumCv)} </span></Box>
            </Box>
            <OpVenteDialog open={dialogOpen} handleClose={handleCloseDialog} />
            <div style={{ display: 'none' }}>
                <PDFContent data={rows} agence={agence} data2={data2} ref={componentRef} />
            </div>
        </Box>
    );
};

export default Achat;
