import { Box } from "@mui/material";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import {Button} from "@mui/material";
import { useState,useEffect } from "react";
import * as React from 'react';
import axios from "axios";
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import TextField from '@mui/material/TextField';

const Consultation = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [agence,setAgence]=useState({
        nom_bureau: '',
        num_auto: '',
        adresse: '',
        tp: '',
        rc: '',
        idfis: '',
        ville: '',
        tel: '',
        cnss: '',
        ice: '',
        CcompteAchat: '',
        CcompteVente: '',
        email: '',
    });

    const history=useHistory();
    const email = localStorage.getItem('auth_email');
    //backend
    useEffect(() => {
        const fetchData = async () => {
            try {

                const response = await axios.get(`api/informations-agence`);
                if (response.data.status === 200) {
                    setAgence(response.data.message);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const submit=(e)=>{
        e.preventDefault();
        const data={
            id: agence.id,
            nom_bureau: agence.nom_bureau,
            num_auto: agence.num_auto,
            adresse: agence.adresse,
            tp: agence.tp,
            rc: agence.rc,
            idfis: agence.idfis,
            ville: agence.ville,
            tel: agence.tel,
            cnss: agence.cnss,
            ice: agence.ice,
            CcompteAchat: agence.CcompteAchat,
            CcompteVente: agence.CcompteVente,
            email: agence.email,
        }
        axios.post('/api/informations-agence',data).then(res=>{

            if(res.data.status === 200){
                history.push('/espace-magchange');
            }
        });
    };

    const handleInput = (e) => {
        const { name, value } = e.target;
        setAgence((prevAgence) => ({
            ...prevAgence,
            [name]: value,
        }));
    };



//style
    const BootstrapInput = styled(InputBase)(({ theme }) => ({
        'label + &': {
            marginTop: theme.spacing(3),
        },
        '& .MuiInputBase-input': {
            borderRadius: 4,
            position: 'relative',
            backgroundColor: theme.palette.mode === 'light' ? '#F3F6F9' : '#1A2027',
            border: '1px solid',
            borderColor: theme.palette.mode === 'light' ? '#E0E3E7' : '#2D3843',
            fontSize: 16,
            padding: '10px 12px',
            transition: theme.transitions.create([
                'border-color',
                'background-color',
                'box-shadow',
            ]),
            // Use the system font instead of the default Roboto font.
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            '&:focus': {
                boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                borderColor: theme.palette.primary.main,
            },
            "& .MuiInputLabel-root":{
                fontSize:20
            }
        },
    }));

//frontend
    return (
        <Box className="type2 container-table">

            <Box >
                <Box display="flex" sx={{
                    "& .MuiFormControl-root" : {
                        marginTop:'4vh',
                        marginRight:"0.75rem",
                        width:"50rem",
                        marginBottom:"0.75rem"

                    }
                }}>
                    <TextField size="small" disabled id="outlined-basic"  name="nom_bureau" label="Raison sociale" value={agence.nom_bureau} onChange={handleInput}  variant="outlined" />

                </Box>
            </Box>


            <Box display="flex" justifyContent="space-between"
                 sx={{
                     "& .MuiFormControl-root" : {
                         marginRight:"0.75rem",
                         width:"25rem",
                         marginBottom:"0.75rem"

                     }
                 }}
            >
                <Box display="flex">
                    <TextField size="small" disabled id="outlined-basic"  name="num_auto" label="N° d'autorisation" value={agence.num_auto} onChange={handleInput}  variant="outlined" />
                </Box>
            </Box>

            <Box display="flex" justifyContent="space-between"
                 sx={{
                     "& .MuiFormControl-root" : {
                         marginRight:"0.75rem",
                         width:"50rem",
                         marginBottom:"0.75rem"

                     }
                 }}
            >
                <Box display="flex">
                    <TextField size="small" id="outlined-basic"  name="adresse" label="Adresse" value={agence.adresse} onChange={handleInput}  variant="outlined" />
                </Box>
            </Box>
            <Box display="flex"
                 sx={{
                     "& .MuiFormControl-root" : {
                         marginRight:"5rem",
                         width:"22.5rem",
                         marginBottom:"0.75rem"


                     }
                 }}
            >
                <Box display="flex">
                    <TextField size="small" id="outlined-basic"  name="ville" label="Ville" value={agence.ville} onChange={handleInput}  variant="outlined" />
                </Box>
                <Box display="flex">
                    <TextField size="small" id="outlined-basic"  name="tel" label="Télephone" value={agence.tel} onChange={handleInput}  variant="outlined" />

                </Box>
            </Box>

            <Box display="flex"
                 sx={{
                     "& .MuiFormControl-root" : {
                         marginRight:"5rem",
                         width:"22.5rem",
                         marginBottom:"0.75rem"


                     }
                 }}
            >
                <Box display="flex">
                    <TextField size="small" id="outlined-basic"  name="idfis" label="Identifiant fiscale" value={agence.idfis} onChange={handleInput}  variant="outlined" />
                </Box>
                <Box display="flex">
                    <TextField size="small" id="outlined-basic"  name="tp" label="N° TP" value={agence.tp} onChange={handleInput}  variant="outlined" />
                </Box>
            </Box>

            <Box display="flex"
                 sx={{
                     "& .MuiFormControl-root" : {
                         marginRight:"5rem",
                         width:"22.5rem",
                         marginBottom:"0.75rem"
                     }
                 }}
            >
                <Box display="flex">

                    <TextField size="small" id="outlined-basic"  name="email" label="Email" value={agence.email} onChange={handleInput}  variant="outlined" />

                </Box>
                <Box display="flex">
                    <TextField size="small" id="outlined-basic"  name="cnss" label="CNSS" value={agence.cnss} onChange={handleInput}  variant="outlined" />
                </Box>
            </Box>


            <Box display="flex"
                 sx={{
                     "& .MuiFormControl-root" : {
                         marginRight:"5rem",
                         width:"22.5rem",
                         marginBottom:"0.75rem"
                     }
                 }}
            >
                <Box display="flex">
                    <TextField size="small" id="outlined-basic"  name="ice" label="ICE" value={agence.ice} onChange={handleInput}  variant="outlined" />

                </Box>
                <Box display="flex">
                    <TextField size="small" id="outlined-basic"  name="rc" label="RC" value={agence.rc} onChange={handleInput}  variant="outlined" />

                </Box>
            </Box>


            <Box display="flex"
                 sx={{
                     "& .MuiFormControl-root" : {
                         marginRight:"5rem",
                         width:"22.5rem",
                         marginBottom:"0.75rem"
                     }
                 }}
            >
                <Box display="flex">
                    <TextField size="small" id="outlined-basic"  name="CcompteAchat" label="Code Journal d'achat" value={agence.CcompteAchat} onChange={handleInput}  variant="outlined" />
                </Box>
                <Box display="flex">
                    <TextField size="small" id="outlined-basic"  name="CcompteVente" label="Code Journal de vente" value={agence.CcompteVente} onChange={handleInput}  variant="outlined" />
                </Box>
            </Box>

            <Box display="flex"
                 sx={{
                     "& .MuiFormControl-root" : {
                         marginRight:"5rem",
                         width:"22.5rem",
                         marginBottom:"0.75rem"
                     }
                 }}
            >

            </Box>
            <Box display="flex" justifyContent="space-between"
                 sx={{
                     "& .buttons" : {
                         color: colors.grey[200],
                         backgroundColor : colors.primary[400],
                         margin: "0.5rem 0.5rem -0.2rem 0.5rem",
                         fontSize: "0.7rem",
                         height: "2.5rem",
                         paddingX: "1.2rem",
                         paddingY: "0.2rem"
                     }

                 }}
            >
                <Box display="flex"><Button className="buttons" onClick={submit}><span className="span1">Enregistrer</span> </Button></Box>
            </Box>
        </Box>
    );
};

export default Consultation;
