import { Box } from "@mui/material";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import {Button} from "@mui/material";
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import TextField from '@mui/material/TextField';
import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import {useEffect, useState,useRef} from 'react';
import axios from "axios";
import 'dayjs/locale/fr';
import dayjs from 'dayjs';
import { useReactToPrint } from "react-to-print";
import TableG from "../../../table/index";
import {formatNumber} from '../../../fonctions/formatNumber'
import PDFBenefice from '../PDFContent/BeneficeMensuel/index'
class PDFContent extends React.Component {
    render() {
        const { data,agence,data2 } = this.props;

        return (
            <PDFBenefice data={data} agence={agence} data2={data2}/>
        );
    }
}
const Statistiques = () => {
    const currentDate = new Date();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [dataToRender,setDataToRender] = useState([]);
    const [operations,setOperations]=useState([]);
    const [sumCv, setSumCv] = useState(0);
    const [selectedUser, setSelectedUser] = useState(null);
    const [devise2, setDevise2] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date().getFullYear());
    const [selectedMonth, setSelectedMonth] = useState(1);
    const [selectedDevise, setSelectedDevise] = useState(null);
    const [bool,setBool]=useState(true);
    const startYear = 2020;
    const endYear = new Date().getFullYear();
    const years = Array.from({ length: endYear - startYear + 1 }, (_, index) => endYear - index);
    const [agence,setAgence]=useState([]);
    const mois = [
        { index: '00', name: 'Exercice complet' },
        { index: '01', name: 'Janvier' },
        { index: '02', name: 'Février' },
        { index: '03', name: 'Mars' },
        { index: '04', name: 'Avril' },
        { index: '05', name: 'Mai' },
        { index: '06', name: 'Juin' },
        { index: '07', name: 'Juillet' },
        { index: '08', name: 'Août' },
        { index: '09', name: 'Septembre' },
        { index: '10', name: 'Octobre' },
        { index: '11', name: 'Novembre' },
        { index: '12', name: 'Décembre' },
    ];
    const [data2, setData2] = useState({});
    const [shouldPrint, setShouldPrint] = useState(false);

    const componentRef = useRef();

    /*****************************************************filtre functions************************************************ */
//user
    const handleUserFilterChange = (event, newValue) => {
        setSelectedUser(newValue);
        let bor=operations;
        let ur=newValue? newValue.name:'';

        bor=handleUserFilterChange2( ur,bor);
        bor=handleDateChange2(selectedDate,bor);
        bor=handleMonthChange2(selectedMonth,bor);
        bor=handleDeviseFilterChange2(selectedDevise,bor);
        setDevise2(transferdata(bor));
        calculateSumCv(transferdata(bor));
    }
    const handleUserFilterChange2 = (newValue,bor) => {
        let filteredItems;
        if(newValue!=''){
            filteredItems = bor.filter((item) =>
                item.name===newValue
            );
        }else{
            filteredItems = bor;
        }

        return filteredItems;
    };

// devise filtre functions
    const handleDeviseFilterChange = (event, newValue) => {
        setSelectedDevise(newValue);
        let bor=operations;
        bor=handleUserFilterChange2( (selectedUser?selectedUser.name:''),bor);
        bor=handleDateChange2(selectedDate,bor);
        bor=handleMonthChange2(selectedMonth,bor);
        bor=handleDeviseFilterChange2(newValue,bor);
        setDevise2(transferdata(bor));
        calculateSumCv(transferdata(bor));
    };


    const handleDeviseFilterChange2 = ( newValue,bor) => {
        if(newValue){
            const filteredItems = bor.filter((item) =>
                item.nom_devise===newValue
            );

            return filteredItems;
        }else{

            return bor;
        }


    }

//du date filtre


    const handleDateFilterChange = (event) => {
        setSelectedDate(event.target.value);
        let bor=operations;
        bor=handleMonthChange2(selectedMonth,bor);
        bor=handleDateChange2((event.target.value),bor);
        setDataToRender(bor);


    };
    const handleDateChange2 = (newValue,bor) => {
        if(newValue){
            const filteredItems = bor.filter((item) => {
                const parsedyear = new Date(item.month_year).getFullYear();
                return  parseInt(parsedyear) === parseInt(newValue);
            });
            return filteredItems;
        }else{

            return bor;
        }
    }

    const handleMonthFilterChange = (event) => {
        setSelectedMonth(event.target.value);
        let bor=operations;
        bor=handleDateChange2(selectedDate,bor);
        bor=handleMonthChange2((event.target.value),bor);
        setDataToRender(bor);
    };
    const handleMonthChange2 = (newValue, bor) => {
        const selectedMonth = parseInt(newValue);

        if (selectedMonth !== 1) {
            const filteredItems = bor.filter((item) => {
                const parsedMonth = new Date(item.month_year).getMonth() + 1;
                return parsedMonth === selectedMonth - 1;
            });
            return filteredItems;
        } else {
            return bor;
        }
    };

    /***************************************************** end filtre functions************************************************ */
    const handlePrintev= (e) => {
        e.preventDefault();
        setData2({
            'mois':mois[parseInt(selectedMonth)-1].name,
            'annee':selectedDate,
            'utilisateur':selectedUser?selectedUser.name:'--all--',
            'devise':selectedDevise,
            'sumCv':sumCv,
        });
        axios.get(`api/info-agence`).then((res) => {
            if (res.data.status === 200) {
                setAgence(res.data.message);
                setShouldPrint(true);
            }
        });

    };
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: `
      @page {
        size: A4;
        margin: 10mm;
      }
      /* Additional styles for the printed page can be added here */
      /* Remove the pagination and URL styles */
      @media print {
        .MuiDataGrid-footerContainer {
          display: none !important;
        }
        a {
          display: none !important;
        }
      }
    `,
        header: null, // Set header to null to remove it from the PDF
        footer: null,
        // Set footer to null to remove it from the PDF
    });




    let bl=false;


    const calculateSumCv = (bor) => {
        const newSumCv = bor.reduce((accumulator, currentDevise) => {
            return accumulator + currentDevise.cv_ac_sum;
        }, 0);
        setSumCv(newSumCv);
    };
    const transferdata=(data)=>{
        const result = data.reduce((acc, item) => {
            const { nom_devise, mt_ac, cv_ac, date_achat,name } = item;
            const key = `${nom_devise}`;

            if (acc[key]) {
                acc[key].cv_ac_sum += parseFloat(cv_ac); // Convert cv_ac to a number and sum
                acc[key].mt_ac_sum += parseFloat(mt_ac); // Convert mt_ac to a number and sum
            } else {
                acc[key] = {
                    name,
                    nom_devise,
                    date_achat,
                    mt_ac_sum: parseFloat(mt_ac), // Convert mt_ac to a number
                    cv_ac_sum: parseFloat(cv_ac), // Convert cv_ac to a number
                };
            }

            return acc;
        }, {});

        const formattedResult = Object.values(result);
        setBool(false);
        // Update the state with the processed data
        return formattedResult;
    }



    //****************************************backend **********************************************************************************/
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`api/benefice-mensuel`);
                if (response.data.status === 200) {
                    let data=(response.data.message)
                    let bor=handleDateChange2(selectedDate,data)
                    setOperations(data);
                    setDataToRender(bor);
                    bl=true;
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);
    useEffect(() => {
        if (shouldPrint) {
            // Call handlePrint() when shouldPrint becomes true
            handlePrint(); // This will trigger the printing action
            setShouldPrint(false); // Reset shouldPrint back to false
        }
    }, [shouldPrint]);
    /********************************************************return ************************************************************** */
    const renderChart = () => {
        if (!dataToRender || dataToRender.length === 0) {
            return <p></p>;
        }
        const data2 = dataToRender.map((op) => new Date(op.month_year).getMonth()+1);
        const data3 = dataToRender.map((op) => op.commission);
        return (
            <>
                <BarChart
                    xAxis={[
                        {
                            id: 'barDeviseAchat',
                            data: data2,
                            scaleType: 'band',
                        },
                    ]}
                    series={[
                        {
                            data: data3,
                        },
                    ]}
                    width={700}
                    height={450}
                />
            </>
        );

    };
    useEffect(() => {
        console.log(dataToRender)
    }, [dataToRender]);
    const colonnesEntete = [
        { id: 'month_year', label: 'Mois', align: 'left' },
        { id: 'cv_achat', label: 'Contre valeur achat', align: 'right' },
        { id: 'commission', label: 'Commission', align: 'right' }
    ];
    const colsToShow =  [0,2,1] ;
    return (
        <Box className="type2 container-table">

            <Box display="flex" justifyContent="center"
                 sx={{
                     "& .MuiFormControl-root" : {
                         marginRight:"1rem",
                         width:"10rem",

                     },
                     "& .MuiAutocomplete-root":{
                         marginTop:"8px"
                     },
                     "& .buttons" : {
                         color: colors.grey[200],
                         backgroundColor : colors.primary[400],
                         margin: "0.5rem",
                         fontSize: "0.8rem",
                         height: "3.4rem" ,
                         paddingX:"1.5rem"
                     },
                     "& .buttons:hover" : {
                         color: colors.primary[400],
                         backgroundColor : colors.grey[200],

                         margin: "0.5rem",
                         fontSize: "0.9rem"
                     },
                     "& .span1" : {


                         marginLeft: "0.5rem",

                     },

                 }}
            >



                <TextField
                    size="small"
                    style={{ marginTop: '0.5rem', width: '10rem' }}
                    type="year"
                    id="start"
                    name="start"
                    onChange={handleDateFilterChange}
                    select
                    SelectProps={{
                        native: true,
                    }}
                >
                    {years.map((year) => (
                        <option key={year} value={year}>
                            {year}
                        </option>
                    ))}
                </TextField>

                <TextField
                    size="small"
                    style={{ marginTop: '0.5rem', width: '12rem' }}
                    type="month"
                    id="start"
                    onChange={handleMonthFilterChange}
                    name="start"
                    select
                    SelectProps={{
                        native: true,
                    }}
                >
                    {mois.map((moisName, index) => (
                        <option key={moisName.name} value={index+1}>
                            {moisName.name}
                        </option>
                    ))}
                </TextField>




            </Box>
            <Box display="flex" justifyContent="space-between" sx={{width: "95%",marginX: "1.5%"}}>
                <Box
                    width="45%"
                    >

                    <TableG dataToRender={dataToRender} headCells={colonnesEntete} colsToShow={colsToShow} cols={"num_bac"}/>

                    <Box display="flex" justifyContent="center"
                         sx={{
                             "& .MuiFormControl-root" : {
                                 marginRight:"1rem",
                                 width:"6rem",

                             },
                             "& .MuiAutocomplete-root":{
                                 marginTop:"8px"
                             },
                             "& .buttons" : {
                                 color: colors.grey[200],
                                 backgroundColor : colors.primary[700],
                                 margin: "0.5rem",
                                 marginTop:"1rem",
                                 fontSize: "0.8rem",
                                 height: "3.4rem" ,
                                 paddingX:"1.5rem"
                             },
                             "& .buttons:hover" : {
                                 color: colors.primary[400],
                                 backgroundColor : colors.grey[200],

                                 margin: "0.5rem",
                                 marginTop:"1rem",
                                 fontSize: "0.9rem"
                             },
                             "& .span1" : {


                                 marginLeft: "0.5rem",

                             }
                         }}
                    >
                        <Button className="buttons" onClick={handlePrintev}><FindInPageOutlinedIcon/><span className="span1">Aperçu</span> </Button>
                    </Box>


                </Box>
                <Box width="50%">


                    {/*graph*/}
                    <Box sx={{display:"flex",justifyContent:"centre",whidth:'100%'}}>
                        {renderChart()}
                    </Box>




                </Box>
            </Box>
            <div style={{ display: 'none' }}>
                <PDFContent data={dataToRender} agence={agence} data2={data2} ref={componentRef} />
            </div>
        </Box>
    );
};

export default Statistiques;
