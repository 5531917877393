import * as React from 'react';
import { useState, useEffect } from 'react';
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, useTheme } from '@mui/material';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { tokens } from '../../../theme';

const Index = () => {
    const history = useHistory();
    const [comptes, setComptes] = useState([]);
    const [comptes2, setComptes2] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [user, setUser] = useState(0);
    const [search, setSearch] = useState('');

    /********************************************functions*********************************************** */
    const handleSearchChange = (event) => {
        const query = event.target.value;
        setSearch(query);

        // Filter the array based on the search query
        const filteredResults = comptes2.filter((item) =>
            item.name.toLowerCase().includes(query.toLowerCase())
        );

        setComptes(filteredResults);
    };

    const handleRowClick = (row) => {
        setSelectedRow(row);
    };

    const handleerror = (e) => {
        if (selectedRow) {
            e.preventDefault();
            const idb = selectedRow.id;
            history.push(`/espace-magchange/modifier-compte/${idb}`);
        }
    };

    /***************************************************end functions******************************************* */

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`api/liste-comptes`);
                setComptes(response.data.users);
                setComptes2(response.data.users);
                setUser(response.data.user);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    return (
        <Box className="type2 container-table">
            <Box className="bartitle" textAlign="center" marginTop="0" backgroundColor={colors.primary[400]} sx={{ "& .h1": { color: colors.grey[500] } }}>
                <h1 className="h1"></h1>
            </Box>

            <Box display="flex" justifyContent="center" 
                 sx={{
                     "& .MuiFormControl-root": {
                         marginRight: "1rem",
                         width: "11rem",
                         marginTop:"0.5rem"
                     },
                     "& .MuiAutocomplete-root": {
                         marginTop: "8px"
                     },
                     "& .buttons": {
                         color: colors.grey[200],
                         backgroundColor: colors.primary[400],
                         margin: "0.5rem 0.5rem -0.2rem 0.5rem",
                         fontSize: "0.7rem",
                         height: "2.5rem",
                         paddingX: "1.2rem",
                         paddingY: "0.2rem"
                     },
                     "& .buttons:hover": {
                         color: colors.primary[400],
                         backgroundColor: colors.grey[200],
                     },
                     "& .span1": {
                         marginLeft: "0.5rem",
                     },
                 }}
            >
                <TextField size="small" id="outlined-basic" label="Utilisateur" value={search} onChange={handleSearchChange} variant="outlined" />
                <Button className="buttons" onClick={handleerror}>
                    <DriveFileRenameOutlineOutlinedIcon /><span className="span1">Modifier</span>
                </Button>
                {user === 1 ? (
                    <Link to='/espace-magchange/ajouter-compte'>
                        <Button className="buttons">
                            <AddCircleOutlinedIcon /><span className="span1">Ajouter</span>
                        </Button>
                    </Link>
                ) : null}
            </Box>

            <Box className="type2 container-table">
                <Box marginLeft="7.5%" width="85%">
                    <Box
                        sx={{
                            "& .MuiDataGrid-root": {
                                border: "none",
                            },
                            "& .MuiDataGrid-cell": {
                                borderBottom: "none",
                            },
                            "& .name-column--cell": {
                                color: colors.greenAccent[300],
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                backgroundColor: colors.blueAccent[700],
                                borderBottom: "none",
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                backgroundColor: colors.primary[400],
                            },
                            "& .MuiDataGrid-footerContainer": {
                                borderTop: "none",
                                backgroundColor: colors.blueAccent[700],
                            },
                            "& .MuiCheckbox-root": {
                                color: `${colors.greenAccent[200]} !important`,
                            },
                            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                color: `${colors.grey[100]} !important`,
                            },
                        }}
                    >
                        <Paper>
                            <TableContainer sx={{ height: '65vh', marginTop: '3vh' }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead sx={{ backgroundColor: "#a4a9fc" }}>
                                        <TableRow>
                                            <TableCell sx={{ backgroundColor: "#a4a9fc" }}>Utilisateur</TableCell>
                                            <TableCell sx={{ backgroundColor: "#a4a9fc" }}>Utilisateur ID</TableCell>
                                            <TableCell sx={{ backgroundColor: "#a4a9fc" }}>Type utilisateur</TableCell>
                                            <TableCell sx={{ backgroundColor: "#a4a9fc" }}>Statut utilisateur</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {comptes.map((row) => (
                                            <TableRow key={row.id} onClick={() => handleRowClick(row)}
                                                      sx={{
                                                          backgroundColor:
                                                              selectedRow && selectedRow.id === row.id ? '#ccc' : 'inherit',
                                                      }}
                                            >
                                                <TableCell>{row.name}</TableCell>
                                                <TableCell>{row.email}</TableCell>
                                                <TableCell>{row.activer == 1 ? 'Activer' : 'Désactiver'}</TableCell>
                                                <TableCell>{row.admin == 1 ? 'Administration' : 'Utilisateur'}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>

                        <Box display="flex" justifyContent="center"
                             sx={{
                                 "& .MuiFormControl-root": {
                                     marginRight: "1rem",
                                     width: "6rem",
                                 },
                                 "& .MuiAutocomplete-root": {
                                     marginTop: "8px"
                                 },
                                 "& .buttons": {
                                     color: colors.grey[200],
                                     backgroundColor: colors.primary[700],
                                     margin: "0.5rem",
                                     marginTop: "1rem",
                                     fontSize: "0.8rem",
                                     height: "3.4rem",
                                     paddingX: "1.5rem"
                                 },
                                 "& .buttons:hover": {
                                     color: colors.primary[400],
                                     backgroundColor: colors.grey[200],
                                     margin: "0.5rem",
                                     marginTop: "1rem",
                                     fontSize: "0.9rem"
                                 },
                                 "& .span1": {
                                     marginLeft: "0.5rem",
                                 }
                             }}
                        >
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Index;
