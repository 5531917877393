import React from 'react';
import {Box} from "@mui/material";

function Index(props) {
    const { data, data2,data3, agence } = props;
    return (
        <div>
            {/* Agency information */}
            <Box sx={{
                borderBottom: '1px solid black',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100vw'
            }}>
                <h5 className="h5">{agence.nom_bureau}</h5>
                <h5 className="h5">{agence.num_auto}</h5>
            </Box>

            {/* Header */}
            <Box
                sx={{
                    border: '1px solid black',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '70vw',
                    marginLeft: '15vw',
                    marginY: '4vh',
                    borderRadius: '8px',
                    backgroundColor: '#ea5a27', // Change the background color here
                }}
            >
                <h2 className="h2">Rapport Client</h2>
            </Box>

            {/* Date and time */}
            <Box sx={{marginY: '4vh'}}>
                <Box sx={{marginRight: '5vh'}}>
                    <h5 className="h5">Client:</h5>
                </Box>
            </Box>

            {/* Currency exchange data */}
            <Box sx={{width: '100%', marginTop: '3vh'}}>
                <table className="print-table">
                    <tr>
                        <th>Nom</th>
                        <th>Prenom</th>
                        <th>Adresse</th>

                        <th>Nationalité</th>
                        <th>N° PID</th>

                        <th>N° PASS</th>
                        <th>Date Passport</th>
                    </tr>
                    <tr>
                        <td>{data ? data.nom : ''}</td>
                        <td>{data ? data.prenom : ''}</td>
                        <td>{data ? data.adresse : ''}</td>
                        <td>{data ? data.nationalite : ''}</td>
                        <td>{data ? data.n_pid : ''}</td>
                        <td>{data ? data.num_cni : ''}</td>
                        <td>{data ? data.date_pass : ''}</td>
                    </tr>
                </table>


            </Box>
            <Box sx={{marginY: '4vh'}}>
                <Box sx={{marginRight: '5vh'}}>
                    <h5 className="h5">Opérations client:</h5>
                </Box>
            </Box>
            <Box sx={{width: '100%', marginTop: '3vh'}}>
                <table className="print-table">
                    <tr>
                        <th>N° Bordereau</th>
                        <th>Utilisateur</th>
                        <th>Client</th>
                        <th>Date</th>

                        <th>Heure</th>
                        <th>Qualité client</th>

                        <th>Code NOP</th>
                        <th>Devise</th>
                        <th>Montant</th>
                        <th>Cours</th>
                        <th>Ctr valeur</th>
                    </tr>
                    {data2.map((row) => (
                        <tr>
                            <td>{row.num_b}</td>
                            <td>{row.name ? row.name : ''}</td>
                            <td>{row.nom ? row.nom : ''} {row.prenom ? row.prenom : ''}</td>
                            <td>{row.date ? row.date : ''}</td>
                            <td>{row.heure ? row.heure : ''}</td>
                            <td>{row.qualite_cl ? row.qualite_cl : ''}</td>
                            <td>{row.code_nop ? row.code_nop : ''}</td>
                            <td>{row.nom_devise ? row.nom_devise : ''}</td>
                            <td>{row.mt ? row.mt : ''}</td>
                            <td>{row.cours ? row.cours : ''}</td>
                            <td>{row.cv ? row.cv : ''}</td>
                        </tr>
                    ))}
                </table>


            </Box>
            {/* Additional CSS styles for printing */}
            <style>
                {`
            .print-table {
              width: 100%;
              border-collapse: collapse;
            }

            .print-table th,
            .print-table td {
              border: 1px solid black;
              padding: 8px;
              font-size:12px;
            }
            .footer {
              margin-top: 4vh;
              border: 2px dotted #73AD21;
              align-items: center;
              width: 100%;
              justify-content: center;
              z-index: 9;
            }
            /* Additional styles specific for printing */
            @media print {
              .print-table {
                page-break-inside: auto;
              }
              
              .print-table th,
              .print-table td {
                page-break-inside: avoid;
              }
              /* Ensure the footer appears on each page */
              .footer {
                page-break-inside: avoid;
                z-index: 9;
              }
            }
          `}
            </style>


            {/* Footer */}
            <Box className="footer" sx={{paddingTop: '1vh'}}>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <h5 className="h5">Adresse : {agence.adresse}</h5>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Box sx={{marginRight: '3vh'}}>
                        <h5 className="h5">Tél : {agence.tel}</h5>
                    </Box>
                    <Box>
                        <h5 className="h5">Email : {agence.email}</h5>
                    </Box>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Box sx={{marginRight: '3vh'}}>
                        <h5 className="h5">TP : {agence.tp}</h5>
                    </Box>
                    <Box sx={{marginRight: '3vh'}}>
                        <h5 className="h5">IF : {agence.idfis}</h5>
                    </Box>
                    <Box>
                        <h5 className="h5">RC : {agence.rc}</h5>
                    </Box>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <h5 className="h5">ICE : {agence.ice}</h5>
                </Box>
            </Box>
        </div>
    );
}

export default Index;