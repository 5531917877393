import { Box } from "@mui/material";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import {Button} from "@mui/material";
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import TextField from '@mui/material/TextField';
import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {useEffect, useState} from 'react';
import swal from 'sweetalert';
import axios from "axios";
const Statistiques = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [page, setPage] =useState(0);
    const [rowsPerPage, setRowsPerPage] =useState(10);
    const [caisses,setCaisses]=useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [textvalue, setTextvalue] = useState('');

    const handleRowClick = (row) => {
        setSelectedRow(row);
        setTextvalue(row.designation);
    };


    useEffect(() => {
        const fetchData = async () => {
            try {

                const response = await axios.get(`api/liste-caisses`);
                setCaisses(response.data.message);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);
    //backend
    const email = localStorage.getItem('auth_email');
    const modifier = (e) => {
        if(selectedRow){
            e.preventDefault();
            swal({
                title: '',
                text: 'Voulez-vous vraiment modifier le nom de la caisse ?',
                icon: 'warning',
                buttons: ['Annuler', 'Modifier'],
                dangerMode: true,
            }).then((willAdd) => {
                if (willAdd) {
                    const data = {
                        id: selectedRow.id,
                        designation: textvalue,
                    };
                    const cs=caisses.find(caisse=> caisse.designation===textvalue);

                    if(!cs){
                        axios.post(`/api/modifier-caisse`, data).then((res) => {
                            if (res.data.status === 200) {
                                swal('', 'Caisse modifiée avec succès !', 'success');

                                const fetchData = async () => {
                                    try {

                                        const response = await axios.get(`api/liste-caisses`);
                                        setCaisses(response.data.message);
                                        setTextvalue("");
                                        setSelectedRow(null);
                                    } catch (error) {
                                        console.error('Error fetching data:', error);
                                    }
                                };

                                fetchData();
                            }
                        });
                    }else{
                        swal('', '"La caisse existe déjà.', 'warning');
                        setTextvalue("");
                        setSelectedRow(null);

                    }
                } else {
                    // User cancelled adding caisse
                    setTextvalue("");
                    setSelectedRow(null);

                }
            });
        }
    };
    const ajouter = (e) => {
        if(textvalue){
            e.preventDefault();

            // Show SweetAlert dialog
            swal({
                title: '',
                text: 'Voulez-vous vraiment ajouter une nouvelle caisse ?',
                icon: 'warning',
                buttons: ['Annuler', 'Ajouter'],
                dangerMode: true,
            }).then((willAdd) => {
                if (willAdd) {

                    const data = {
                        designation: textvalue,
                    };
                    const cs=caisses.find(caisse=> caisse.designation===textvalue);

                    if(!cs){
                        axios.post(`/api/ajouter-caisse`, data).then((res) => {
                            if (res.data.status === 200) {
                                // Caissse added successfully
                                swal('', 'Caisse ajoutée avec succès !', 'success');

                                // Fetch updated data
                                const fetchData = async () => {
                                    try {

                                        const response = await axios.get(`api/liste-caisses`);
                                        setCaisses(response.data.message);
                                        setTextvalue("");
                                        setSelectedRow(null);
                                    } catch (error) {
                                        console.error('Error fetching data:', error);
                                    }
                                };

                                fetchData();
                            }
                        });
                    }else{
                        swal('', '"La caisse existe déjà.', 'warning');
                        setTextvalue("");
                        setSelectedRow(null);
                    }
                } else {
                    // User cancelled adding caisse
                    swal('', 'Caisse non ajoutée.', 'info');
                    setTextvalue("");
                    setSelectedRow(null);
                }
            });
        }
    };




    return (
        <Box className="type2 container-table">

            <Box display="flex" justifyContent="center" marginBottom="3rem"
                 sx={{
                     "& .MuiFormControl-root" : {
                         marginRight:"1rem",
                         width:"11rem",
                         marginTop:"0.5rem"
                     },
                     "& .MuiAutocomplete-root":{
                         marginTop:"8px"
                     },
                     "& .buttons" : {
                         color: colors.grey[200],
                         backgroundColor : colors.primary[400],
                         margin: "0.5rem 0.5rem -0.2rem 0.5rem",
                         fontSize: "0.7rem",
                         height: "2.5rem",
                         paddingX: "1.2rem",
                         paddingY: "0.2rem"
                     },
                     "& .buttons:hover" : {
                         color: colors.primary[400],
                         backgroundColor : colors.grey[200],
                     },
                     "& .span1" : {
                         marginLeft: "0.5rem",
                     },
                 }}
            >
                <TextField size="small" id="outlined-basic" value={textvalue} label="caisse" variant="outlined" onChange={(e) => setTextvalue(e.target.value)} />
                <Button className="buttons" onClick={modifier}><DriveFileRenameOutlineOutlinedIcon/><span className="span1">Modifier</span> </Button>
                <Button className="buttons" onClick={ajouter}><AddCircleOutlinedIcon/><span className="span1">Ajouter</span> </Button>
            </Box>
            <Box display="flex" justifyContent="center"
            >
                <Box
                    width="45%">
                    <Box
                        sx={{
                            "& .MuiDataGrid-root": {
                                border: "none",
                            },
                            "& .MuiDataGrid-cell": {
                                borderBottom: "none",
                            },
                            "& .name-column--cell": {
                                color: colors.greenAccent[300],
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                backgroundColor: colors.blueAccent[700],
                                borderBottom: "none",
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                backgroundColor: colors.primary[400],
                            },
                            "& .MuiDataGrid-footerContainer": {
                                borderTop: "none",
                                backgroundColor: colors.blueAccent[700],
                            },
                            "& .MuiCheckbox-root": {
                                color: `${colors.greenAccent[200]} !important`,
                            },
                            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                color: `${colors.grey[100]} !important`,
                            },
                        }}
                    >
                        <Paper >
                            <TableContainer sx={{ height: '60vh'}}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead sx={{backgroundColor:"#a4a9fc"}}>
                                        <TableRow>
                                            <TableCell sx={{backgroundColor:"#a4a9fc"}}>Designation</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {caisses.map((row) => (
                                            <TableRow key={row.id} onClick={() => handleRowClick(row)}
                                                      sx={{
                                                          backgroundColor:
                                                              selectedRow && selectedRow.id === row.id ? '#ccc' : 'inherit',
                                                      }}
                                            >
                                                <TableCell>{row.designation}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </Paper>



                        <Box display="flex" justifyContent="center"
                             sx={{
                                 "& .MuiFormControl-root" : {
                                     marginRight:"1rem",
                                     width:"6rem",

                                 },
                                 "& .MuiAutocomplete-root":{
                                     marginTop:"8px"
                                 },
                                 "& .buttons" : {
                                     color: colors.grey[200],
                                     backgroundColor : colors.primary[700],
                                     margin: "0.5rem",
                                     marginTop:"1rem",
                                     fontSize: "0.8rem",
                                     height: "3.4rem" ,
                                     paddingX:"1.5rem"
                                 },
                                 "& .buttons:hover" : {
                                     color: colors.primary[400],
                                     backgroundColor : colors.grey[200],

                                     margin: "0.5rem",
                                     marginTop:"1rem",
                                     fontSize: "0.9rem"
                                 },
                                 "& .span1" : {


                                     marginLeft: "0.5rem",

                                 }
                             }}
                        >

                        </Box>
                    </Box>
                </Box>



            </Box>
        </Box>
    );
};

export default Statistiques;
