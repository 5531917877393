import React from "react";
import { Box } from "@mui/material";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import { Button } from "@mui/material";
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import { Link } from "react-router-dom";
import axios from "axios";
import { useEffect, useState,useRef } from 'react';
import TextField from '@mui/material/TextField';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useReactToPrint } from "react-to-print";
import swal from "sweetalert";
import PDFVenteClient from '../PDFContent/VenteClient/index';

import {formatNumber} from '../../../fonctions/formatNumber'
import Table from "../../../table";
import {useSelectedRow} from "../../../context/SelectedRowContext";
class PDFContent extends React.Component {
    render() {
        const { data,agence,data2,data3} = this.props;
        return (
            <PDFVenteClient data={data} agence={agence} data2={data2} data3={data3}/>


        );
    }
}

const Achat = () => {
    const history=useHistory();
    const [bordereaux, setBordereaux] = useState([]);
    const [user, setUser] = useState();
    const [agences, setAgences] = useState([]);
    const [sumCv, setSumCv] = useState(0);
    const [dataToRender, setDataToRender] = useState([]);
    const { selectedRow, updateSelectedRow } = useSelectedRow();
    const componentRef = useRef();
    const [agence, setAgence] = useState([]);
    const [rows, setRows] = useState([]);
    const [rows2, setRows2] = useState([]);
    const [rows3, setRows3] = useState([]);
    const [shouldPrint, setShouldPrint] = useState(false);
    const [search, setSearch] = useState(null);
    useEffect(() => {
        updateSelectedRow(null);
    }, []);
    const handleSearchChange = (event) => {
        const query = event.target.value;
        setSearch(query);

        // Filter the array based on the search query
        const filteredResults = bordereaux.filter((item) =>
            item.num_bvc.toLowerCase().includes(query.toLowerCase())
        );

        setDataToRender(filteredResults);
    };

    //handles
    const handleerror=async (e) => {
        e.preventDefault();

        if (selectedRow) {
                axios.get(`api/if-bordereauver?id=${selectedRow.id_brd}`).then(res => {
                    if (res.data.status === 200) {
                        history.push(`/espace-magchange/gestion-erreur-vente/${selectedRow.id_brd}`);
                    } else {
                        swal('', "Bordereau déjà modifié.", 'warning');
                    }
                });
        }
    }
    const [droits,setDroits]=useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {

                const response = await axios.get(`api/droits-acces`);
                setDroits(response.data.droits);
                setUser(response.data.user);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const email = localStorage.getItem("auth_email");
    const [ag,setag]=useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`api/ventes-du-jour`);
                if (response.data.status === 200) {
                    setBordereaux(response.data.message);
                    setDataToRender(response.data.message);
                    setUser(response.data.utilisateur);
                    setag(response.data.agence);
                    const agn=ag.map((ac)=>ac.nom_bureau);
                    setAgences(agn);
                    const datat=response.data.message;
                    const sum=calculateSumCv(datat);
                    setSumCv(sum);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);
    const [verCaisse,setVerCaisse]=useState();
    useEffect(() => {
        axios.get(`api/detail-caisse`).then(res => {
            if (res.data.status === 200) {
                setVerCaisse(res.data.message);
            }
        });
    }, []);
    const calculateSumCv = (rows) => {
        const newSumCv = rows.reduce((accumulator, currentDevise) => {
            const cvAcNumber = parseFloat(currentDevise.contre_valeur.replace(/\s/g, '').replace(',', '.'));
            return accumulator + (isNaN(cvAcNumber) ? 0 : cvAcNumber);
        }, 0);

        return (newSumCv);
    };
    const printEv = async (e) => {
        e.preventDefault();

        if(selectedRow){
            const idb = selectedRow.id_brd;

            try {
                // Await the API calls and store the results in separate variables
                const res1 = await axios.get(`api/bordereau-vente-client?id=${idb}`);
                const res2 = await axios.get(`api/info-agence`);

                // Check the status and update the state variables accordingly
                if (res1.data.status === 200) {
                    setRows(res1.data.operation_ac);
                    setRows2(res1.data.bordereau);
                    setRows3(res1.data.client);
                }

                if (res2.data.status === 200) {
                    setAgence(res2.data.message);
                }

                // After the API calls are completed and state variables are updated, set shouldPrint to true
                setShouldPrint(true);
            } catch (error) {
                // Handle any errors from the API calls
                console.error(error);
            }
        }
    };
    useEffect(() => {
        if (shouldPrint) {
            // Call handlePrint() when shouldPrint becomes true
            handlePrint(); // This will trigger the printing action
            setShouldPrint(false); // Reset shouldPrint back to false

        }
    }, [shouldPrint]);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "",
        pageStyle: `
      @page {
        size: A4;
        margin: 10mm;
      }
      /* Additional styles for the printed page can be added here */
      /* Remove the pagination and URL styles */
      @media print {
        .MuiDataGrid-footerContainer {
          display: none !important;
        }
        a {
          display: none !important;
        }
      }
    `,
        header: null, // Set header to null to remove it from the PDF
        footer: null,
        // Set footer to null to remove it from the PDF
    });
    const colonnesEntete = user !== 1?[
        { id: 'num_bvc', label: 'Bordereau', align: 'left' },
        { id: 'date_vc', label: 'Date Vente', align: 'right' },
        { id: 'heure_vc', label: 'Heure', align: 'right' },
        { id: 'prenom_nom', label: 'Client', align: 'left' },
        { id: 'nom_devise', label: 'Devise', align: 'left' },
        { id: 'montant_vc', label: 'Montant', align: 'right' },
        { id: 'cours_vc', label: 'Cours', align: 'right' },
        { id: 'contre_valeur', label: 'Contre Valeur', align: 'right' },
    ]:[
        { id: 'num_bvc', label: 'Bordereau', align: 'left' },
        { id: 'nom_utilisateur', label: 'Utilisateur', align: 'left' },
        { id: 'date_vc', label: 'Date Vente', align: 'right' },
        { id: 'heure_vc', label: 'Heure', align: 'right' },
        { id: 'prenom_nom', label: 'Client', align: 'left' },
        { id: 'nom_devise', label: 'Devise', align: 'left' },
        { id: 'montant_vc', label: 'Montant', align: 'right' },
        { id: 'cours_vc', label: 'Cours', align: 'right' },
        { id: 'contre_valeur', label: 'Contre Valeur', align: 'right' },
    ];
    const colsToShow = user !== 1 ? [2,4, 5, 6,10,8, 7, 9] : [2,3,4, 5, 6,10,8, 7, 9];
    const valideN= async (e) => {
            if (verCaisse) {
                e.preventDefault();
                if (((verCaisse ? verCaisse.length : null) === 1 && (verCaisse != [] ? verCaisse[0].nom_devise : null) === "DHS") || (verCaisse.length === 0)) {
                    swal('', "La caisse ne dispose que de dirhams, aucune autre devise.", 'warning');
                } else {
                    history.push('/espace-magchange/operation-vente');
                }
            }
    }
    return (
        <Box className="type2 container-table" >



            <Box sx={{display:'flex',justifyContent:'space-between',marginRight: "4%",marginLeft: "2%", width: "92%",}}>
                <Box sx={{

                    "& .MuiButtonBase-root": {
                        color: colors.grey[200],
                        backgroundColor: colors.primary[400],

                    },
                    "& .MuiButtonBase-root:hover": {
                        color: colors.primary[400],
                        backgroundColor: colors.grey[200],

                    },"& .buttons": {
                        color: colors.grey[200],
                        backgroundColor: colors.primary[400],
                        margin: "0.5rem 0.5rem -0.2rem 0.5rem",
                        fontSize: "0.7rem",
                        height: "2.5rem",
                        paddingX: "1.2rem",
                        paddingY: "0.2rem"
                    }

                }}>
                    <Button className="buttons" onClick={valideN}><AddCircleOutlinedIcon /><span className="span1">NOUVEAU</span> </Button>
                    <Button className="buttons" onClick={printEv}><FindInPageOutlinedIcon /><span className="span1">Aperçu de Bordereau</span></Button>

                    {droits.find((droit) => droit.idMenu === 9) ? (<Button onClick={handleerror} className="buttons">
                        <DriveFileRenameOutlineOutlinedIcon />
                        <span className="span1">Gestion d'erreur</span>
                    </Button>):null}

                </Box>
                <Box sx={{}}>
                    <TextField
                        id="search-bar"
                        className="text"
                        label="Entrer le N° de bordereau"
                        sx={{margin: "0.5rem 0 -0.2rem 0.5rem"}}
                        value={search}
                        onChange={handleSearchChange}
                        variant="outlined"
                        placeholder="Search..."
                        size="small"
                    />
                </Box>
            </Box>
            <Box>
                <Table dataToRender={dataToRender} headCells={colonnesEntete} colsToShow={colsToShow} cols={"num_bvc"}/>


            </Box>
            <Box display="flex" justifyContent="space-between" >
                <Box display="flex"></Box>
                <Box display="flex"
                     sx={{
                         "& .contrevaleur": {
                             color: colors.grey[100],

                         },
                         "& .contrev": {
                             backgroundColor: colors.primary[800],
                             color: colors.grey[100],
                         }
                     }}
                ><span className="contrevaleur"  >Total contre valeurs : </span> <span className="contrev"  >{formatNumber(sumCv)} </span></Box>
            </Box>
            <div style={{ display: 'none' }}>
                <PDFContent data={rows} data2={rows2} data3={rows3} agence={agence} ref={componentRef} />
            </div>
        </Box>
    );
};

export default Achat;
