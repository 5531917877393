import Accueil from "../scenes/Accueil";
import Achatclients from "../scenes/achatclients";
import Venteclients from "../scenes/ventecliemts";
import Gestionbanque from "../scenes/gestionbanque";
import Statistique from "../scenes/statistique";
import Gestioncaisse from "../scenes/gestioncaisse";
import Rapports from "../scenes/rapports";
import Parametres from "../scenes/parametres";
import Gestiondevis from "../scenes/gestionDevises/index";
import Opachat from "../components/GestionAchat/OperationAchat/index";
import Geachat from "../components/GestionAchat/GestionErreurAchat/index";
import Opvente from "../components/VenteClients/OperationVenteClients/index";
import Opventeb from "../components/GestionBanque//OperationVenteBanque/index";
import Oppassdev from "../components/Gestioncaisse/op-passage-gevise";
import Oppachatdhs from "../components/Gestioncaisse/op-achat-dhs";
import OpCaisse from "../components/Gestioncaisse/op-caisse";
import Gevente from "../components/VenteClients/GestionErreurVente/index";
import AjCompte from "../components/Parametres/AjouterCompte/index";
import MdCompte from "../components/Parametres/ModifierCompte/index";

const Routes = [
  { path: "/espace-magchange", exact: true, name: 'Accueil', menu_id:0},
  { path: "/espace-magchange/accueil", exact: true, name: 'Accueil', component: Accueil , menu_id:0},
  { path: "/espace-magchange/achats-clients", exact: true, name: 'Achatclients', component: Achatclients, menu_id:1 },
  { path: "/espace-magchange/ventes-clients", exact: true, name: 'Venteclients', component: Venteclients , menu_id:2},
  { path: "/espace-magchange/gestion-banque", exact: true, name: 'Gestionbanque', component: Gestionbanque, menu_id:3},
  { path: "/espace-magchange/statistiques", exact: true, name: 'Statistique', component: Statistique, menu_id:8 },
  { path: "/espace-magchange/gestion-caisse", exact: true, name: 'Gestioncaisse', component: Gestioncaisse, menu_id:4 },
  { path: "/espace-magchange/passage-devise", exact: true, name: 'Gestioncaisse', component: Oppassdev, menu_id:4 },
  { path: "/espace-magchange/rapports", exact: true, name: 'Rapports', component: Rapports, menu_id:6 },
  { path: "/espace-magchange/gestion-devises", exact: true, name: 'Gestiondevis', component: Gestiondevis, menu_id:5 },
  { path: "/espace-magchange/parametres", exact: true, name: 'Parametres', component: Parametres, menu_id:7 },
  { path: "/espace-magchange/operation-achat", exact: true, name: 'Parametres', component: Opachat, menu_id:1 },
  { path: "/espace-magchange/gestion-erreur-achat/:id", exact: true, name: 'Parametres', component: Geachat, menu_id:1 },
  { path: "/espace-magchange/operation-vente", exact: true, name: 'Parametres', component: Opvente , menu_id:2},
  { path: "/espace-magchange/operation_vente_banque", exact: true, name: 'Parametres', component: Opventeb, menu_id:3 },
  { path: "/espace-magchange/achat-dhs", exact: true, name: 'Parametres', component: Oppachatdhs , menu_id:4},
  { path: "/espace-magchange/operation-caisse", exact: true, name: 'Parametres', component: OpCaisse , menu_id:4},
  { path: "/espace-magchange/gestion-erreur-vente/:id", exact: true, name: 'Parametres', component: Gevente, menu_id:2 },
  { path: "/espace-magchange/ajouter-compte", exact: true, name: 'Parametres', component: AjCompte, menu_id:7 },
  { path: "/espace-magchange/modifier-compte/:id", exact: true, name: 'Parametres', component: MdCompte, menu_id:7 },



];

export default Routes;
