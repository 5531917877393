import * as React from 'react';
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { Tabs, Tab, AppBar } from "@mui/material";
import Consultation from "../../components/Gestiondevise/Consultation/index";
import Coursdevise from "../../components/Gestiondevise/CoursDevises/index";
import Denominationdevise from "../../components/Gestiondevise/DenominationDevises/index";
import "./index.css";

const Gestiondevise = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [value, setValue] = React.useState(0);

    const handleTabs = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className="gestion-devise-container">
            <Header title="GESTION DE DEVISES" />

            <AppBar position="static">
                <Tabs value={value} onChange={handleTabs}>
                    <Tab label="Cours de devise" />
                    <Tab label="Consultation" />
                    <Tab label="Dénomination de devise" />
                </Tabs>
            </AppBar>

            <div className="gestion-devise-content">
                {value === 0 && <Coursdevise />}
                {value === 1 && <Consultation />}
                {value === 2 && <Denominationdevise />}
            </div>
        </div>
    );
};

export default Gestiondevise;
