import React, { createContext, useContext, useState } from 'react';

// Create a context for managing the order state
const OrderContext = createContext();

// Custom hook to access the order context
export const useOrder = () => useContext(OrderContext);

// Provider component for the order context
export const OrderProvider = ({ children }) => {
    // State to store the order
    const [order, setOrder] = useState('desc'); // Initialize order as 'desc'

    // Function to update the order
    const updateOrder = (newOrder) => {
        setOrder(newOrder);
    };

    // JSX to provide the context value to its children
    return (
        <OrderContext.Provider value={{ order, updateOrder }}>
            {children}
        </OrderContext.Provider>
    );
};
