import { Box } from "@mui/material";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import {Button} from "@mui/material";
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import axios from "axios";
import {useEffect, useState,useRef} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import { useReactToPrint } from "react-to-print";
import InputAdornment from '@mui/material/InputAdornment';
import PDFEtatComptable from '../PDFContent/EtatComptable/index'
import {formatNumber} from '../../../fonctions/formatNumber';
import TableG from "../../../table";

class PDFContent extends React.Component {
    render() {
        const { data, data2, data3, tva, agence } = this.props;

        return (
            <PDFEtatComptable data={data} data2={data2} data3={data3} tva={tva} agence={agence} />
        );
    }
}
const Consultation = () => {
    const today = new Date();

    const [sumCv, setSumCv] = useState();
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedDevise, setSelectedDevise] = useState(null);
    const [selectedDuDate, setSelectedDuDate] = useState(dayjs());
    const [selectedAuDate, setSelectedAuDate] = useState(dayjs());
    const [etat, setEtat] = useState(["Détaillé","Globalisé"]);
    const [selectedEtat, setSelectedEtat] = useState("Détaillé");
    const [listdevise,setlistdevise]=useState(["EUR","USD","GBP",'CAD',"CHF","GIP","SEK","NOK","JPY","SAR","KWD","AED","QAR","BHD","DKK","OMR"]);
    const [filteredItems,setFilteredItems]=useState([]);
    const [shouldPrint, setShouldPrint] = useState(false);
    const [shouldFiltre, setShouldFiltre] = useState(true);
    const [dataToRender, setDataToRender] = useState([]);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const email=localStorage.getItem("auth_email");
    const [bordereaux,setBordereaux]=useState([]);
    const [user,setuser]=useState([]);
    const [users,setusers]=useState([]);
    const [agence,setAgence]=useState({});
    const [rows, setRows] = useState([]);
    const [data2, setData2] = useState([]);
    const componentRef = useRef();
    const [selectedRow, setSelectedRow] = useState(null);
    const [info, setInfo] = useState({
        du:'',
        au:'',
        utilisateur:'',
        devise:'',
        etat:'',
        user:'',
    });

    /****************************************************************functions************************************************** */
    const handlePrintev = (e) => {
        e.preventDefault();
        axios.get(`api/info-agence`).then((res) => {
            if (res.data.status === 200) {
                setAgence(res.data.message);
                setShouldPrint(true);
            }
        });

        // Add checks to ensure variables are defined
        const du = convertDateFormat(selectedDuDate);
        const au = convertDateFormat(selectedAuDate);
        const utilisateur = selectedUser ? selectedUser.name : '--all--';
        const devise = selectedDevise ? selectedDevise : '--all--';
        const etat = selectedEtat ? selectedEtat : '';

        setInfo((prevInfo) => ({
            ...prevInfo,
            du: du,
            au: au,
            utilisateur: utilisateur,
            devise: devise,
            etat: etat,
            user:user,
        }));
    };
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: `
    @page {
      size: A4;
      margin: 10mm;
    }
    /* Additional styles for the printed page can be added here */
    /* Remove the pagination and URL styles */
    @media print {
      .MuiDataGrid-footerContainer {
        display: none !important;
      }
      a {
        display: none !important;
      }
    }
  `,
        header: null, // Set header to null to remove it from the PDF
        footer: null,
        // Set footer to null to remove it from the PDF
    });
    const handleRowClick = (row) => {
        setSelectedRow(row);
    };
    useEffect(() => {
        if (shouldPrint) {
            // Call handlePrint() when shouldPrint becomes true
            handlePrint(); // This will trigger the printing action
            setShouldPrint(false); // Reset shouldPrint back to false
        }
    }, [shouldPrint]);
    useEffect(() => {
        console.log(dataToRender);
    }, [dataToRender]);
    /*************************************************************end functions********************************************** */
    /********************************************************************************UseEffect********************************** */

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`api/etat-de-comptable`);
                if (response.data.status === 200) {
                    const { message } = response.data;
                    setBordereaux(message);
                    let bor=message;
                    bor=handleDuDateChange2(selectedDuDate,bor);
                    bor=handleAuDateChange2(selectedAuDate,bor);
                    setDataToRender(bor);
                    calculateSumCv(bor);
                    const msg = bor.map((op) => {
                        return {
                            'N° bordereau': op.num_bac,
                            'Utilisateur': op.name,
                            'Date': op.date_achat,
                            'Heure': op.heure_achat,
                            'Client': `${op.nom ? op.nom : ''} ${op.prenom ? op.prenom : ''}`,
                            'Devise': op.nom_devise,
                            'Montant': op.mt_ac,
                            'Cours': op.cours_ac,
                            'Contre Valeur': op.cv_ac,
                        };
                    });
                    setRows(msg);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();

    }, []);
    const [rrows,setRrows]=useState(1);
    const [devise2, setDevise2] = useState([]);

    useEffect(() => {
        let id=0;
        const result = dataToRender.reduce((acc, item) => {
            const {TypeV, nom_devise, total_mt_vc, total_cv_v, benefice_vente,benefice_client,id_devise } = item;
            const key = `${TypeV}-${nom_devise}`;

            if (acc[key]) {
                acc[key].mt_ac_sum += parseFloat(total_mt_vc);
                acc[key].cv_ac_sum += parseFloat(total_cv_v);
                acc[key].b_v_sum += parseFloat(benefice_vente);
                acc[key].b_c_sum += parseFloat(benefice_client);

            } else {
                acc[key] = {
                    id2:id++,
                    nom_devise,
                    mt_ac_sum: parseFloat(total_mt_vc),
                    cv_ac_sum: parseFloat(total_cv_v),
                    b_v_sum: parseFloat(benefice_vente),
                    b_c_sum: parseFloat(benefice_client),
                    id_devise
                };
            }

            return acc;
        }, {});
        const formattedResult = Object.values(result).map(item => ({
            ...item,
            mt_ac_sum: item.mt_ac_sum.toFixed(2),
            cv_ac_sum: item.cv_ac_sum.toFixed(2),
            b_v_sum: item.b_v_sum.toFixed(2),
            b_c_sum: item.b_c_sum.toFixed(2)
        }));
        formattedResult.sort((a, b) => a.id_devise - b.id_devise);
        // Update the state with the processed data
        setDevise2(formattedResult);
    }, [dataToRender]);

    /****************************************************************************end UseEffect********************************** */


    const [tva,setTva]=useState(0);

    const renderInputs = () => {
        return (
            <Box sx={{width: "95%",marginX: "1.5%"}}>

                <Box display="flex" justifyContent="space-between"
                     sx={{
                         "& .MuiFormControl-root" : {
                             marginRight:"1rem",
                             width:"11vw",

                         },
                         "& .MuiAutocomplete-root":{
                             marginTop:"8px"
                         },
                         "& .buttons" : {
                             color: colors.grey[200],
                             backgroundColor : colors.primary[400],
                             margin: "0.5rem 0.5rem -0.2rem 0.5rem",
                             fontSize: "0.7rem",
                             height: "2.5rem",
                             paddingX: "1.2rem",
                             paddingY: "0.2rem"
                         },
                         "& .buttons:hover" : {
                             color: colors.primary[400],
                             backgroundColor : colors.grey[200],

                         },
                         "& .csss":{
                             minWidth:'18vw',
                         },
                         "& .span1" : {


                             marginLeft: "0.5rem",

                         }
                     }}
                >
                    <Box display="flex">


                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker
                                    label="DU"
                                    value={selectedDuDate}
                                    onChange={handleDuDateChange}
                                    format="DD/MM/YYYY"
                                    className="csss"
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            padding: '10px 14px',
                                            fontSize: '0.875rem',
                                        },
                                    }}
                                />
                            </DemoContainer>
                        </LocalizationProvider>



                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker label="AU"
                                            value={selectedAuDate}
                                            onChange={handleAuDateChange}
                                            format="DD/MM/YYYY"
                                            className="csss"
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    padding: '10px 14px',
                                                    fontSize: '0.875rem',
                                                },
                                            }}
                                />
                            </DemoContainer>
                        </LocalizationProvider>

                        <Autocomplete
                            size="small"
                            className="Autocomplete"
                            id="combo-box-demo"
                            value={selectedEtat}
                            options={etat}
                            onChange={handle2}
                            renderInput={(params) => <TextField {...params} label="Etat" />}
                            disableClearable={true}
                        />
                        <TextField
                            size="small"
                            sx={{marginTop:"8px"}}
                            id="outlined-number"
                            label="TVA"
                            type="number"
                            value={tva}
                            onChange={handleChangeTva}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                        />
                    </Box>
                    < Box>
                        <Button className="buttons" onClick={handlePrintev}><FindInPageOutlinedIcon/><span className="span1">Aperçu</span> </Button>

                    </Box>

                </Box></Box>
        );
    };

    const calculateSumCv=(newValue) => {
        const newSumCv = newValue.reduce((accumulator, currentDevise) => {
            return accumulator + currentDevise.cv_ac;
        }, 0);
        setSumCv(newSumCv.toFixed(2));
    }
    /*****************************************************filtre functions************************************************ */
//user
    const handleChangeTva = event => {
        const newValue = event.target.value;
        setTva(newValue);
    };
    const handleUserFilterChange2 = (newValue,bor) => {
        let filteredItems;
        if(newValue!=''){
            filteredItems = bor.filter((item) =>
                item.name===newValue
            );
        }else{
            filteredItems = bordereaux;
        }
        calculateSumCv(filteredItems);
        return filteredItems;
    };


//du date filtre
    const convertDateFormat = (dateString) => {

        const parsedDate = dayjs(dateString, 'MM/DD/YYYY'); // Parse the date with the original format
        return parsedDate.format('YYYY/MM/DD'); // Format the date in the desired format
    };
    const convertDateFormat2 = (dateString) => {
        const parsedDate = dayjs(dateString, 'DD/MM/YYYY'); // Parse the date with the original format
        return parsedDate.format('YYYY/MM/DD'); // Format the date in the desired format
    };

    const handleDuDateChange = (newValue) => {
        console.log(newValue);
        setSelectedDuDate(newValue);
        let bor=bordereaux;
        bor=handleDuDateChange2(newValue,bor);
        bor=handleAuDateChange2(selectedAuDate,bor);
        setDataToRender(bor);
        calculateSumCv(bor);
        const msg = bor.map((op) => {
            return {
                'N° bordereau': op.num_bac,
                'Utilisateur': op.name,
                'Date': op.date_achat,
                'Heure': op.heure_achat,
                'Client': `${op.nom ? op.nom : ''} ${op.prenom ? op.prenom : ''}`,
                'Devise': op.nom_devise,
                'Montant': op.mt_ac,
                'Cours': op.cours_ac,
                'Contre Valeur': op.cv_ac,
            };
        });
        setRows(msg);
    };
    const handleDuDateChange2 = (newValue,bor) => {
        if(newValue){
            const newV=convertDateFormat(newValue);
            const filteredItems = bor.filter((item) => {
                const parsedDate = convertDateFormat2(item.date);
                return new Date(parsedDate) >= new Date(newV);
            });
            return filteredItems;
        }else{

            return bor;
        }
    }
// au date functions

    const handleAuDateChange = (newValue) => {
        setSelectedAuDate(newValue);
        let bor=bordereaux;
        bor=handleAuDateChange2(newValue,bor);
        bor=handleDuDateChange2(selectedDuDate,bor);
        setDataToRender(bor);
        calculateSumCv(bor);
        const msg = bor.map((op) => {
            return {
                'N° bordereau': op.num_bac,
                'Utilisateur': op.name,
                'Date': op.date_achat,
                'Heure': op.heure_achat,
                'Client': `${op.nom ? op.nom : ''} ${op.prenom ? op.prenom : ''}`,
                'Devise': op.nom_devise,
                'Montant': op.mt_ac,
                'Cours': op.cours_ac,
                'Contre Valeur': op.cv_ac,
            };
        });
        setRows(msg);
    };
    const handleAuDateChange2 = (newValue,bor) => {
        if(newValue){
            const newV=convertDateFormat(newValue);
            const filteredItems = bor.filter((item) => {
                const parsedDate = convertDateFormat2(item.date);
                return new Date(parsedDate) <= new Date(newV);
            });
            return filteredItems;
        }else{

            return bor;
        }
    }

    const handle2 = (event, newValue) => {

        // Use a different name for the function parameter
        setSelectedEtat(newValue);
        if(newValue==="Globalisé"){
            setRrows(0);
            setSelectedRow(null);
        }else{
            setRrows(1);
            setSelectedRow(null);

        }

    };

    const colonnesEntete = [
        { id: 'date', label: 'Date', align: 'left' },
        { id: 'num_bvc', label: 'N° bordereau', align: 'left' },
        { id: 'nom_devise', label: 'Devise', align: 'left' },
        { id: 'total_mt_vc', label: 'Montant', align: 'right' },
        { id: 'cours_ac', label: 'Cours Achat', align: 'right' },
        { id: 'cours_vc', label: 'Cours Vente', align: 'right' },
        { id: 'total_cv_v', label: 'Contre Valeur', align: 'right' },
        { id: 'benefice_vente', label: 'Benéfice vente', align: 'right' },
        { id: 'benefice_client', label: 'Benéfice vente client', align: 'right' },
    ];
    const colsToShow =  [2,3,10,6,4,5,7,9,8] ;
    const colonnesEntete2 = [
        { id: 'nom_devise', label: 'Devise', align: 'left' },
        { id: 'mt_ac_sum', label: 'Montant', align: 'right' },
        { id: 'cv_ac_sum', label: 'Contre valeur', align: 'right' },
        { id: 'b_v_sum', label: 'Benéfice vente banque', align: 'right' },
        { id: 'b_c_sum', label: 'Benéfice vente client', align: 'right' },
    ];
    const colsToShow2 = [1,2,3,4,5];
    return (
        <Box className="type2 container-table">
            {renderInputs()}
            <Box >
                {rrows===1 ? <TableG dataToRender={dataToRender} headCells={colonnesEntete} colsToShow={colsToShow} cols={"num_bac"}/>:
                    <TableG dataToRender={devise2} headCells={colonnesEntete2} colsToShow={colsToShow2} cols={"date_achat"}/>}
            </Box>
            <Box display="flex" justifyContent="space-between" >
                <Box display="flex"></Box>
            </Box>
            <div style={{ display: 'none' }}>
                <PDFContent data={devise2} agence={agence} data2={dataToRender} data3={info} tva={tva} ref={componentRef} />
            </div>
        </Box>
    );
};

export default Consultation;