import { Box } from "@mui/material";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import {Button} from "@mui/material";
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import * as React from 'react';
import axios from "axios";
import {useEffect, useState,useRef} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import dayjs from 'dayjs';
import 'dayjs/locale/fr'; // Assurez-vous d'importer la locale française si nécessaire
import { useReactToPrint } from "react-to-print";
import {formatNumber} from "../../../fonctions/formatNumber";
import PDFReleves from '../PDFContent/RelevesMensuels/index'
class PDFContent extends React.Component {
    render() {
        // Extracting props for easier access
        const { data, data2,data3, agence ,selectedReleve,selectedEtat,mois,annee } = this.props;
        return (
            <PDFReleves data={data} data2={data2} data3={data3} agence={agence} selectedReleve={selectedReleve} selectedEtat={selectedEtat} mois={mois} annee={annee}/>
        );
    }
}
const RapportJ = () => {




    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedDevise, setSelectedDevise] = useState(null);
    const [selectedDuDate, setSelectedDuDate] = useState(dayjs());
    const [selectedAuDate, setSelectedAuDate] = useState(dayjs());
    const [shouldPrint, setShouldPrint] = useState(false);
    const [dataToRender, setDataToRender] = useState([]);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [user,setuser]=useState([]);
    const [agence,setAgence]=useState({});
    const componentRef = useRef();
    const [selectedRow, setSelectedRow] = useState(null);
    const [info, setInfo] = useState({
        du:'',
        au:'',
        utilisateur:'',
        devise:'',
        etat:'',
        user:'',
    });

    const [bool,setBool]=useState(true);
    const [selectedDate, setSelectedDate] = useState(new Date().getFullYear());
    const [selectedMonth, setSelectedMonth] = useState('01');
    const startYear = 2020;
    const endYear = new Date().getFullYear();
    const years = Array.from({ length: endYear - startYear + 1 }, (_, index) => endYear - index);
    const mois = [
        { index: '01', name: 'Janvier' },
        { index: '02', name: 'Février' },
        { index: '03', name: 'Mars' },
        { index: '04', name: 'Avril' },
        { index: '05', name: 'Mai' },
        { index: '06', name: 'Juin' },
        { index: '07', name: 'Juillet' },
        { index: '08', name: 'Août' },
        { index: '09', name: 'Septembre' },
        { index: '10', name: 'Octobre' },
        { index: '11', name: 'Novembre' },
        { index: '12', name: 'Décembre' },
    ];
    const getCurrentMonth = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        return `${year}-${month}`;
    };
    const defaultValue = getCurrentMonth(); // Get the current month in "YYYY-MM" format

    const releve = [
        { index: '1', name: 'Relevé Achat' },
        { index: '2', name: 'Relevé vente Client' },
        { index: '3', name: 'Relevé vente Banque' },
        { index: '4', name: 'Etat recapitulatif' },
    ];
    const [selectedReleve,setSelectedReleve]=useState({ index: '1', name: 'Relevé Achat' });
    const etat = [
        { index: '1', name: 'Par Devise' },
        { index: '2', name: 'Par Contre Valeur' },
    ];
    const [selectedEtat,setSelectedEtat]=useState({ index: '1', name: 'Par Devise' });
    /****************************************************************functions************************************************** */
    const handlePrintev = (e) => {
        e.preventDefault();
        axios.get(`api/info-agence`).then((res) => {
            if (res.data.status === 200) {
                setAgence(res.data.message);
                setShouldPrint(true);
            }
        });

        // Add checks to ensure variables are defined
        const du = convertDateFormat(selectedDuDate);
        const au = convertDateFormat(selectedAuDate);
        const utilisateur = selectedUser ? selectedUser.name : '--all--';
        const devise = selectedDevise ? selectedDevise : '--all--';
        const etat = selectedEtat ? selectedEtat : '';

        setInfo((prevInfo) => ({
            ...prevInfo,
            du: du,
            au: au,
            utilisateur: utilisateur,
            devise: devise,
            etat: etat,
            user:user,
        }));
    };
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "Print",
        pageStyle: `
    @page {
      size: A4;
      margin: 10mm;
    }
    /* Additional styles for the printed page can be added here */
    /* Remove the pagination and URL styles */
    @media print {
      .MuiDataGrid-footerContainer {
        display: none !important;
      }
      a {
        display: none !important;
      }
    }
  `,
        header: null, // Set header to null to remove it from the PDF
        footer: null,
        // Set footer to null to remove it from the PDF
    });
    const handleRowClick = (row) => {
        setSelectedRow(row);
    };
    useEffect(() => {
        if (shouldPrint) {
            // Call handlePrint() when shouldPrint becomes true
            handlePrint(); // This will trigger the printing action
            setShouldPrint(false); // Reset shouldPrint back to false
        }
    }, [shouldPrint]);
    const handleEtatChange = (event, newValue) => {
        setSelectedEtat(newValue);
    }
    const handleReleveChange = (event, newValue) => {
        setSelectedReleve(newValue);
        console.log(newValue);

        setBool(true);
    }
    /*************************************************************end functions********************************************** */
    /********************************************************************************UseEffect********************************** */
    useEffect(() => {
        if(bool){
            const an=selectedDate;
            const mo=selectedMonth;
            const re=selectedReleve.index;

            const fetchData = async () => {
                try {
                    const response = await axios.get(`api/releves-mensuels?annee=${an}&mois=${mo}&&releve=${re}`);
                    if (response.data.status === 200) {
                        const { message } = response.data;
                        console.log(message);
                        setDataToRender(message);
                        setBool(false);
                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            };

            fetchData();
        }
    }, [bool]);


    /****************************************************************************end UseEffect********************************** */

    const renderTableRows = () => {
        if(selectedReleve.index!=='4' && selectedReleve.index!=='3' ){
            return (
                <TableRow>
                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Cédants</TableCell>
                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Code</TableCell>
                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>EUR</TableCell>

                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>USD</TableCell>
                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}> GBP</TableCell>

                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>CAD</TableCell>
                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>CHF</TableCell>

                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>GIB</TableCell>
                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>SEK</TableCell>
                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>NOK</TableCell>
                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>JPY</TableCell>
                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>SAR</TableCell>
                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>KWD</TableCell>
                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>AED</TableCell>
                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>QAR</TableCell>
                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>BHD</TableCell>
                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>DKK</TableCell>
                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>OMR</TableCell>
                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Contre valeur en Dhs</TableCell>

                </TableRow>
            );
        } else if(selectedReleve.index=='3' ){
            return (
                <TableRow>
                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Etablissement de credit et bank al maghrib</TableCell>
                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Devise</TableCell>
                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Montant en devise</TableCell>

                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Contre valeur en dirhams</TableCell>


                </TableRow>
            );
        }
        else{
            return (
                <TableRow>
                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Devise</TableCell>
                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>MONTANT EN DEVISE ACHAT</TableCell>
                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>CONTRE VALEUR EN DIRHAMS ACHAT</TableCell>
                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>MONTANT EN DEVISE VENTE</TableCell>
                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>CONTRE VALEUR EN DIRHAMS VENTE</TableCell>
                </TableRow>
            );
        }
    }


    const renderTableBody = () => {
        if( (selectedReleve.index==='1' || selectedReleve.index==='2') && selectedEtat.index==='1'){
            return (

                <TableBody>
                    {dataToRender.map((row) => (
                        <TableRow   >
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{row.designation}</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{row.code_nop}</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{formatNumber(row.mt1)}</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{formatNumber(row.mt2)}</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{formatNumber(row.mt3)}</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{formatNumber(row.mt4)}</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{formatNumber(row.mt5) }</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{formatNumber(row.mt6) }</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{formatNumber(row.mt7) }</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{formatNumber(row.mt8) }</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{formatNumber(row.mt9) }</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{formatNumber(row.mt10) }</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{formatNumber(row.mt11) }</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{formatNumber(row.mt12) }</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{formatNumber(row.mt13) }</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{formatNumber(row.mt14) }</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{formatNumber(row.mt15) }</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{formatNumber(row.mt16) }</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{formatNumber(row.cv_t) }</TableCell>


                        </TableRow>
                    ))}
                </TableBody>
            );
        }else if( (selectedReleve.index==='1' || selectedReleve.index==='2') && selectedEtat.index==='2'){
            return (

                <TableBody>
                    {dataToRender.map((row) => (
                        <TableRow   >
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{row.designation}</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{row.code_nop}</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{formatNumber(row.cv1) }</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{formatNumber(row.cv2) }</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{formatNumber(row.cv3) }</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{formatNumber(row.cv4) }</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{formatNumber(row.cv5) }</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{formatNumber(row.cv6) }</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{formatNumber(row.cv7) }</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{formatNumber(row.cv8) }</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{formatNumber(row.cv9) }</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{formatNumber(row.cv10) }</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{formatNumber(row.cv11) }</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{formatNumber(row.cv12) }</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{formatNumber(row.cv13) }</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{formatNumber(row.cv14) }</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{formatNumber(row.cv15) }</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{formatNumber(row.cv16) }</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{formatNumber(row.cv_t) }</TableCell>


                        </TableRow>
                    ))}
                </TableBody>
            );
        }else if(selectedReleve.index==='3'){
            return (

                <TableBody>
                    {dataToRender.map((row) => (
                        <TableRow   >
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{row.banque}</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{row.nom_devise}</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{formatNumber(row.mt) }</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{formatNumber(row.cv) }</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            );
        }else if(selectedReleve.index==='4'){
            return (

                <TableBody>
                    {dataToRender.map((row) => (
                        <TableRow   >
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{row.nom_devise}</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{formatNumber(row.mt_ac) }</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{formatNumber(row.cv_ac) }</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{formatNumber(row.mt_vc) }</TableCell>
                            <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{formatNumber(row.cv_v) }</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            );
        }
    };
    const renderInputs = () => {
        return (
            <>

                <Box display="flex" justifyContent="space-between"
                     sx={{
                         "& .MuiFormControl-root" : {
                             marginRight:"1rem",
                             width:"15vw",

                         },
                         "& .MuiAutocomplete-root":{
                             marginTop:"8px"
                         },
                         "& .buttons" : {
                             color: colors.grey[200],
                             backgroundColor : colors.primary[400],

                         },
                         "& .buttons:hover" : {
                             color: colors.primary[400],
                             backgroundColor : colors.grey[200],

                         },
                         "& .span1" : {


                             marginLeft: "0.5rem",

                         }
                     }}
                >
                    <Box display="flex">

                        <TextField
                            size="small"
                            style={{ marginTop: '0.5rem', width: '10vw' }}
                            type="year"
                            id="start"
                            name="start"
                            onChange={handleDateFilterChange}
                            select
                            SelectProps={{
                                native: true,
                            }}
                        >
                            {years.map((year) => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            ))}
                        </TextField>

                        <TextField
                            size="small"
                            style={{ marginTop: '0.5rem', width: '14vw' }}
                            type="month"
                            id="start"
                            onChange={handleMonthFilterChange}
                            name="start"
                            select
                            SelectProps={{
                                native: true,
                            }}
                        >
                            {mois.map((moisName, index) => (
                                <option key={moisName.name} value={index + 1}>
                                    {moisName.name}
                                </option>
                            ))}
                        </TextField>
                        <Autocomplete className="Autocomplete"
                                      size="small"
                                      id="combo-box-demo"
                                      value={selectedReleve}
                                      options={releve}
                                      onChange={handleReleveChange}
                                      getOptionLabel={(option) => option.name}
                                      renderInput={(params) => <TextField {...params} label="Relevé" />}
                                      disableClearable={true}
                        />
                        {selectedReleve.index!=='4' && selectedReleve.index!=='3'?<Autocomplete className="Autocomplete"
                                                                                                size="small"
                                                                                                id="combo-box-demo"
                                                                                                value={selectedEtat}
                                                                                                options={etat}
                                                                                                onChange={handleEtatChange} // Update this line
                                                                                                getOptionLabel={(option) => option.name}
                                                                                                renderInput={(params) => <TextField {...params} label="Type d'état" />}
                                                                                                disableClearable={true}
                        />:null}
                    </Box>
                    <Box
                        sx={{display:"flex",justifyContent:'center',justifyItems:'center',"& .buttons" : {
                                color: colors.grey[200],
                                backgroundColor : colors.primary[400],
                                marginTop:'0.5rem'
                            },
                            "& .buttons:hover" : {
                                color: colors.primary[400],
                                backgroundColor : colors.grey[200],

                            },}}
                    >       <Button className="buttons" onClick={handlePrintev}><FindInPageOutlinedIcon/><span className="span1">Aperçu</span> </Button>
                    </Box>

                </Box></>
        );
    };

    const [defaultMonth, setDefaultMonth] = useState('');

    useEffect(() => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so we add 1 and pad with '0' if necessary
        setDefaultMonth(`${year}-${month}`);
    }, []);
    /*****************************************************filtre functions************************************************ */
    const convertDateFormat = (dateString) => {

        const parsedDate = dayjs(dateString, 'MM/DD/YYYY'); // Parse the date with the original format
        return parsedDate.format('YYYY/MM/DD'); // Format the date in the desired format
    };
    const convertDateFormat2 = (dateString) => {
        const parsedDate = dayjs(dateString, 'DD/MM/YYYY'); // Parse the date with the original format
        return parsedDate.format('YYYY/MM/DD'); // Format the date in the desired format
    };
    const handleMonthFilterChange = (event) => {
        let cc=event.target.value;
        setSelectedMonth(cc);

        setBool(true);
    };

    const handleDateFilterChange = (event) => {
        setSelectedDate(event.target.value);
        setBool(true);


    };

    /**********************************END FILTRE FUNCTIONS*************************************** */









    return (
        <Box  sx={{width: "95%",marginX: "2.5%",
            "& .bartitle":{
                marginY : "1.5rem",
            }
        }}>

            {renderInputs()}
            <Box zIndex="1"
                 height="75vh"
            >
                <Paper  >
                    <TableContainer  sx={{height:"65vh",marginTop:"3vh",backgroundColor:"#f2f0f0",overflowX: "auto"}} >
                        <Table stickyHeader aria-label="sticky table" >
                            <TableHead sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>
                                {renderTableRows()}

                            </TableHead>
                            {renderTableBody()}
                        </Table>
                    </TableContainer>

                </Paper>
            </Box>

            <div style={{ display: 'none' }}>
                <PDFContent data={dataToRender} agence={agence} data2={dataToRender} data3={info} ref={componentRef} selectedReleve={selectedReleve} selectedEtat={selectedEtat} mois={selectedMonth} annee={selectedDate}/>
            </div>
        </Box>
    );
};

export default RapportJ;