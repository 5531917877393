import { useState,useEffect } from "react";
import Topbar from "../global/Topbar";
import Sidebar from "../global/Sidebar";
import { Route, Switch, Redirect } from "react-router-dom";
import routes from "../../routes/routes";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "../../theme";
import { Box, IconButton, Typography, useTheme } from "@mui/material";

import * as React from 'react';
import { styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Logo from "../../data/logo.png";
import {Link} from "react-router-dom";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useContext } from "react";
import HomeIcon from '@mui/icons-material/Home';
import BarChartIcon from '@mui/icons-material/BarChart';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import CardTravelOutlinedIcon from '@mui/icons-material/CardTravelOutlined';
const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function PersistentDrawerLeft() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [droits,setDroits]=useState([]);
  const [user,setUser]=useState({
    id: 1,
        name: "",
        email: "",
        email_verified_at: "",
        motPass: "",
        id_agence: "",
        created_at: "",
        updated_at: "",
        id_caisse: "",
        activer: "",
        admin: ""
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        
        const response = await axios.get(`api/droits-acces`);
        setDroits(response.data.droits);
        setUser(response.data.user);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
    }, []);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const [isSidebar, setIsSidebar] = useState(true);
  const history=useHistory();
  const colorMode = useContext(ColorModeContext);
  const logoutsubmit=(e)=>{
    e.preventDefault();
    axios.post('/api/logout').then(res=>{
      if(res.data.status === 200){
      localStorage.removeItem("auth_token");
      localStorage.removeItem("auth_email");
     
      history.push('/');
      window.location.reload();

      }
    });
  }
  const customStyles = {
    textDecoration: 'none', // Remove text decoration
    color: '#000', // Set the text color
    '&:hover': {
      // Apply custom hover effect
      color: '#f00', // Change the color on hover to red (you can use any color you like)
      textDecoration: 'underline', // Add underline on hover
    },
  };
  return (
    <Box sx={{ display: 'flex' }} style={{
      '& .MuiPaper-root':{
        backgroundColor: "#e7e7e8"
      }
    }}>
			  <CssBaseline />
      <AppBar position="fixed" open={open} style={{ height: "8vh", backgroundColor: "#ebeef0", color: "#ffffff" }}>
        <Toolbar>
          <Box >
            <IconButton
              color=""
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 1, ...(open && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton>
          </Box >
          <Box >
            <Typography variant="h6"  component="div">
              <img src={Logo} style={{height:"7.9vh"}}/>
            </Typography>
          </Box>
          <Box style={{position:"absolute",right:"2vw"}}>
            <Typography variant="h6"  component="div">
            
              
              <Link>
                </Link>
                {droits.find((droit) => droit.idMenu === 7) ? (

                <Link to="/espace-magchange/parametres">
                <IconButton>
                  <SettingsOutlinedIcon />
                </IconButton></Link>
                            ) : null}

                <Link onClick={logoutsubmit}>
                <IconButton>
                  <LogoutOutlinedIcon />
                </IconButton></Link>
              
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List sx={{marginY:"4vh"}}>
          <Box sx={{display:"flex",justifyContent:'center',justifyItems:'center'}}>
            <h3 style={{color:"#df5a2a",}} className="h3">{user.admin === 1 ? ' ' : 'Utilisateur'}</h3>
          </Box>
          <Box sx={{display:"flex",justifyContent:'center',justifyItems:'center'}}>
            <h2 style={{color:"#057090",fontWeight: "bold"}} className="h2">{user.name}</h2>
            </Box>
        </List>
        <Divider />
        <List>            
            <Link to={"/espace-magchange/accueil"} style={customStyles}>
              <ListItem  disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                  <HomeIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Accueil"} />
                </ListItemButton>
              </ListItem>
            </Link>
            {droits.find((droit) => droit.idMenu === 1) ? (
              <Link to={"/espace-magchange/achats-clients"} style={customStyles}>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <ArrowDownwardOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Achats Clients"} />
                  </ListItemButton>
                </ListItem>
              </Link>
            ) : null}
            {droits.find((droit) => droit.idMenu === 2) ? (
            <Link to={"/espace-magchange/ventes-clients"} style={customStyles}>
              <ListItem  disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <ArrowUpwardOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Ventes Clients"} />
                </ListItemButton>
              </ListItem>
            </Link>
            ) : null}
            {droits.find((droit) => droit.idMenu === 3) ? (
            <Link to={"/espace-magchange/gestion-banque"} style={customStyles}>
              <ListItem  disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <AccountBalanceOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Gestion Banque"} />
                </ListItemButton>
              </ListItem>
            </Link>
            ) : null}
            {droits.find((droit) => droit.idMenu === 4) ? (
            <Link to={"/espace-magchange/gestion-caisse"} style={customStyles}>
              <ListItem  disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <CardTravelOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Gestion Caisse"} />
                </ListItemButton>
              </ListItem>
            </Link>
             ) : null}
    {droits.find((droit) => droit.idMenu === 5) ? (

            <Link to={"/espace-magchange/gestion-devises"} style={customStyles}>
              <ListItem  disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                  <MonetizationOnIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Gestion Devises"} />
                </ListItemButton>
              </ListItem>
            </Link>
         ) : null}
     {droits.find((droit) => droit.idMenu === 6) ? (

            <Link to={"/espace-magchange/rapports"} style={customStyles}>
              <ListItem  disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                  <InsertDriveFileIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Rapports"} />
                </ListItemButton>
              </ListItem>
            </Link>
        ) : null}
    {droits.find((droit) => droit.idMenu === 8) ? (

            <Link to={"/espace-magchange/statistiques"} style={customStyles}>
              <ListItem  disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                  <BarChartIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Statistiques bénéfice"} />
                </ListItemButton>
              </ListItem>
            </Link>
          ) : null}

        </List>
      </Drawer>
      <Main open={open}>
      <Switch>
      {routes.map((route, idx) => {
        if (droits.find((droit) => droit.idMenu === route.menu_id)||route.menu_id===0 ) {
          return (
            route.component && (
              <Route
                key={idx}
                path={route.path}
                exact={route.exact}
                name={route.name}
                render={(props) => <route.component {...props} />}
              />
            )
          );
        }
        return null; // Return null for routes that don't match the condition
      })}
          <Redirect from="/espace-magchange" to="/espace-magchange/accueil" />
          </Switch>
            
          </Main>
    </Box>
  );
}

