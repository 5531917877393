import { Box } from "@mui/material";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import {Button} from "@mui/material";
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import axios from "axios";
import {useEffect, useState,useRef} from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import { useReactToPrint } from "react-to-print";
import PDFBordereauxErrones from '../PDFContent/BordereauxErrones/index'
import Table from "../../../table";
class PDFContent extends React.Component {
    render() {
        const { data, data2,data3, agence } = this.props;
        return (
            <PDFBordereauxErrones data={data} data2={data2} data3={data3} agence={agence}/>
        );
    }
}
const Consultation = () => {

    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedDevise, setSelectedDevise] = useState(null);
    const [selectedDuDate, setSelectedDuDate] = useState(dayjs());
    const [selectedAuDate, setSelectedAuDate] = useState(dayjs());
    const [etat, setEtat] = useState(["Détaillé","Globalisé"]);
    const [selectedEtat, setSelectedEtat] = useState("Détaillé");
    const [shouldPrint, setShouldPrint] = useState(false);
    const [shouldFiltre, setShouldFiltre] = useState(true);
    const [dataToRender, setDataToRender] = useState([]);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [bordereaux,setBordereaux]=useState([]);
    const [user,setuser]=useState([]);
    const [users,setusers]=useState([]);
    const [agence,setAgence]=useState({});
    const componentRef = useRef();
    const [selectedRow, setSelectedRow] = useState(null);
    const [info, setInfo] = useState({
        du:'',
        au:'',
        utilisateur:'',
        devise:'',
        etat:'',
        user:'',
    });

    /****************************************************************functions************************************************** */
    const handlePrintev = (e) => {
        e.preventDefault();
        axios.get(`api/info-agence`).then((res) => {
            if (res.data.status === 200) {
                setAgence(res.data.message);
                setShouldPrint(true);
            }
        });

        // Add checks to ensure variables are defined
        const du = convertDateFormat(selectedDuDate);
        const au = convertDateFormat(selectedAuDate);
        const utilisateur = selectedUser ? selectedUser.name : '--all--';
        const devise = selectedDevise ? selectedDevise : '--all--';
        const etat = selectedEtat ? selectedEtat : '';

        setInfo((prevInfo) => ({
            ...prevInfo,
            du: du,
            au: au,
            utilisateur: utilisateur,
            devise: devise,
            etat: etat,
            user:user,
        }));
    };
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: `
    @page {
      size: A4;
      margin: 10mm;
    }
    /* Additional styles for the printed page can be added here */
    /* Remove the pagination and URL styles */
    @media print {
      .MuiDataGrid-footerContainer {
        display: none !important;
      }
      a {
        display: none !important;
      }
    }
  `,
        header: null, // Set header to null to remove it from the PDF
        footer: null,
        // Set footer to null to remove it from the PDF
    });
    const handleRowClick = (row) => {
        setSelectedRow(row);
    };
    useEffect(() => {
        if (shouldPrint) {
            // Call handlePrint() when shouldPrint becomes true
            handlePrint(); // This will trigger the printing action
            setShouldPrint(false); // Reset shouldPrint back to false
        }
    }, [shouldPrint]);
    /*************************************************************end functions********************************************** */
    /********************************************************************************UseEffect********************************** */

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`api/bordereaux-ventes-errones`);
                if (response.data.status === 200) {
                    const { message, user, users} = response.data;
                    setBordereaux(message);
                    setuser(user);
                    setusers(users);
                    const msg=message;
                    let bor=message;
                    bor=handleDuDateChange2(selectedDuDate,bor);
                    bor=handleAuDateChange2(selectedAuDate,bor);
                    setDataToRender(bor);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();

    }, []);
    const [devise2, setDevise2] = useState([]);

    useEffect(() => {
        const result = dataToRender.reduce((acc, item) => {
            const { date_achat, nom_devise, mt_ac, cv_ac } = item;
            const key = `${date_achat}-${nom_devise}`;

            if (acc[key]) {
                acc[key].cv_ac_sum += parseFloat(cv_ac);
                acc[key].mt_ac_sum += parseFloat(mt_ac);
            } else {
                acc[key] = {
                    date_achat,
                    nom_devise,
                    mt_ac_sum: parseFloat(mt_ac),
                    cv_ac_sum: parseFloat(cv_ac),
                };
            }

            return acc;
        }, {});

        const formattedResult = Object.values(result);

        // Update the state with the processed data
        setDevise2(formattedResult);
    }, [dataToRender]);


    const renderInputs = () => {
        return (
            <Box sx={{width: "95%",marginX: "1.5%"}}>
                {user === 1 ? <Box sx={{ width: "20%" ,height: "3rem",marginX:"0.8rem",
                    "& .MuiFormLabel-root":{
                        color: colors.greenAccent[300],
                    }
                }}>
                    <Autocomplete
                        size="small"
                        id="combo-box-demo"
                        options={users}
                        value={selectedUser}
                        onChange={handleUserFilterChange} // Update this line
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} label="Utilisateur" />}
                    />
                </Box>: null}
                <Box display="flex" justifyContent="space-between"
                     sx={{
                         "& .MuiFormControl-root" : {
                             marginRight:"1rem",
                             width:"10rem",

                         },
                         "& .MuiAutocomplete-root":{
                         },
                         "& .buttons" : {
                             color: colors.grey[200],
                             backgroundColor : colors.primary[400],
                         },
                         "& .buttons:hover" : {
                             color: colors.primary[400],
                             backgroundColor : colors.grey[200],
                         },
                         "& .span1" : {


                             marginLeft: "0.5rem",

                         },
                         "& .csss" : {
                             fontSize: '12px',
                             minWidth:'13vw',
                             width:"17.2vw",
                         }
                     }}
                >
                    <Box display="flex">

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker
                                    label="DU"
                                    value={selectedDuDate}
                                    onChange={handleDuDateChange}
                                    format="DD/MM/YYYY"
                                    className="csss"
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            padding: '10px 14px',
                                            fontSize: '0.875rem',
                                        },
                                    }}
                                />
                            </DemoContainer>
                        </LocalizationProvider>




                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker label="AU"
                                            value={selectedAuDate}
                                            onChange={handleAuDateChange}
                                            format="DD/MM/YYYY"
                                            className="csss"
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    padding: '10px 14px',
                                                    fontSize: '0.875rem',
                                                },
                                            }}

                                />
                            </DemoContainer>
                        </LocalizationProvider>

                    </Box>
                    < Box>
                        <Button className="buttons" onClick={handlePrintev}><FindInPageOutlinedIcon/><span className="span1">Aperçu</span> </Button>

                    </Box>

                </Box></Box>
        );
    };

    /*****************************************************filtre functions************************************************ */
//user
    const handleUserFilterChange = (event, newValue) => {
        setSelectedUser(newValue);
        let bor=bordereaux;
        bor=handleUserFilterChange2( (newValue? newValue.name:''),bor);
        bor=handleDuDateChange2(selectedDuDate,bor);
        bor=handleAuDateChange2(selectedAuDate,bor);
        setDataToRender(bor);
    }
    const handleUserFilterChange2 = (newValue,bor) => {
        let filteredItems;
        if(newValue!=''){
            filteredItems = bor.filter((item) =>
                item.name===newValue
            );
        }else{
            filteredItems = bordereaux;
        }
        return filteredItems;
    };


//du date filtre
    const convertDateFormat = (dateString) => {

        const parsedDate = dayjs(dateString, 'MM/DD/YYYY'); // Parse the date with the original format
        return parsedDate.format('YYYY/MM/DD'); // Format the date in the desired format
    };
    const convertDateFormat2 = (dateString) => {
        const parsedDate = dayjs(dateString, 'DD/MM/YYYY'); // Parse the date with the original format
        return parsedDate.format('YYYY/MM/DD'); // Format the date in the desired format
    };

    const handleDuDateChange = (newValue) => {
        console.log(newValue);
        setSelectedDuDate(newValue);
        let bor=bordereaux;
        bor=handleUserFilterChange2( (selectedUser? selectedUser.name:''),bor);
        bor=handleDuDateChange2(newValue,bor);
        bor=handleAuDateChange2(selectedAuDate,bor);
        setDataToRender(bor);
    };
    const handleDuDateChange2 = (newValue,bor) => {
        if(newValue){
            const newV=convertDateFormat(newValue);
            const filteredItems = bor.filter((item) => {
                const parsedDate = convertDateFormat2(item.date_vc);
                return new Date(parsedDate) >= new Date(newV);
            });
            return filteredItems;
        }else{

            return bor;
        }
    }
    const handleAuDateChange = (newValue) => {
        setSelectedAuDate(newValue);
        let bor=bordereaux;
        bor=handleUserFilterChange2( (selectedUser? selectedUser.name:''),bor);
        bor=handleAuDateChange2(newValue,bor);
        bor=handleDuDateChange2(selectedDuDate,bor);
        setDataToRender(bor);
    };
    const handleAuDateChange2 = (newValue,bor) => {
        if(newValue){
            const newV=convertDateFormat(newValue);
            const filteredItems = bor.filter((item) => {
                const parsedDate = convertDateFormat2(item.date_vc);
                return new Date(parsedDate) <= new Date(newV);
            });
            return filteredItems;
        }else{

            return bor;
        }
    }
    const colonnesEntete = user !== 1?[
        { id: 'num_bvc', label: 'Bordereau', align: 'left' },
        { id: 'date_vc', label: 'Date vente', align: 'right' },
        { id: 'heure_vc', label: 'Heure', align: 'right' },
        { id: 'prenom_nom', label: 'Client', align: 'left' },
        { id: 'nom_devise', label: 'Devise', align: 'left' },
        { id: 'mt_vc', label: 'Montant', align: 'right' },
        { id: 'cours_vc', label: 'Cours', align: 'right' },
        { id: 'cv_v', label: 'Contre Valeur', align: 'right' },
    ]:[
        { id: 'num_bvc', label: 'Bordereau', align: 'left' },
        { id: 'name', label: 'Utilisateur', align: 'left' },
        { id: 'date_vc', label: 'Date vente', align: 'right' },
        { id: 'heure_vc', label: 'Heure', align: 'right' },
        { id: 'prenom_nom', label: 'Client', align: 'left' },
        { id: 'nom_devise', label: 'Devise', align: 'left' },
        { id: 'mt_vc', label: 'Montant', align: 'right' },
        { id: 'cours_vc', label: 'Cours', align: 'right' },
        { id: 'cv_v', label: 'Contre Valeur', align: 'right' },
    ];
    const colsToShow = user !== 1 ? [1,3, 4, 5, 7, 8, 6,9] : [1,2,3, 4, 5, 7, 8, 6,9];
    return (
        <Box className="type2 container-table">
            {renderInputs()}
            <Box >
                <Table dataToRender={dataToRender} headCells={colonnesEntete} colsToShow={colsToShow} cols={"num_bvc"}/>
            </Box>
            <Box display="flex" justifyContent="space-between" >
                <Box display="flex"></Box>
                <Box  display="flex"
                      sx={{
                          "& .contrevaleur":{
                              color: colors.grey[100],
                              fontSize: "1.5rem",
                              marginTop: "1rem",
                              padding:"0.5rem",
                          },
                          "& .contrev":{
                              backgroundColor:colors.primary[800],
                              color: colors.grey[100],
                              fontSize: "1.5rem",
                              marginTop: "1rem",
                              marginLeft: "2rem",
                              marginRight : "3rem",
                              marginBottom: "5rem",
                              paddingX:"2.5rem",
                              paddingY:"0.5rem",
                          }
                      }}
                ></Box>
            </Box>
            <div style={{ display: 'none' }}>
                <PDFContent data={devise2} agence={agence} data2={dataToRender} data3={info} ref={componentRef} />
            </div>
        </Box>
    );
};

export default Consultation;