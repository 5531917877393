import React from 'react';
import {Box} from "@mui/material";
import {formatNumber} from "../../../../fonctions/formatNumber";

function Index(props) {
    const { data, data2,data3, agence,selectedDotation,mois,annee } = props;

    const renderTableRows = () => {
        if(selectedDotation.index==='1'){
            return (
                <tr>
                    <th >Identité du bénéficiaire</th>
                    <th >Numéro de la CNI</th>
                    <th >Numéro du Passport</th>

                    <th align='right'>Montant accordé</th>
                    <th >Enfant Mineur</th>

                    <th >Date d'actrois de la dotation</th>


                </tr>
            );
        }else if(selectedDotation.index==='2'){
            return (
                <tr>
                    <th >Identité du bénéficiaire</th>
                    <th >Numéro de la CNI</th>
                    <th align='right'>Montant accordé</th>
                    <th >Date d'actroi</th>
                </tr>
            );
        }else if(selectedDotation.index==='3'){
            return (
                <tr>
                    <th >Nom et prénom de l'étudiant</th>
                    <th >Numéro du Passport</th>
                    <th >Numéro de la CNI</th>
                    <th align='right'>Montant accordé</th>
                    <th >Date d'actroi</th>
                </tr>
            );
        }else if(selectedDotation.index==='4'){
            return (
                <tr>
                    <th >Identité du bénéficiaire</th>
                    <th >Numéro de la CNI</th>
                    <th >Numéro du Passport</th>
                    <th align='right'>Montant accordé</th>
                    <th >Enfant Mineur</th>
                    <th >Date d'actrois de la dotation</th>

                </tr>
            );
        }}
    const renderTableBody = () => {
        if(selectedDotation.index==='1'){
            return (

                <>
                    {data2.map((row) => (
                        <tr   >
                            <td>{row.nom} {row.prenom}</td>
                            <td>{row.num_cni}</td>
                            <td>{row.n_pid}</td>
                            <td align='right'>{formatNumber(row.mt_ac)}</td>
                            <td>{row.mineur==1?'Oui':'Non'}</td>
                            <td>{row.date_vc}</td>
                        </tr>
                    ))}
                </>
            );
        }else if(selectedDotation.index==='2'){
            return (

                <>
                    {data2.map((row) => (
                        <tr   >
                            <td>{row.nom} {row.prenom}</td>
                            <td>{row.n_pid}</td>
                            <td align='right'>{formatNumber(row.mt_ac)}</td>
                            <td>{row.date_vc}</td>
                        </tr>
                    ))}
                </>
            );
        }else if(selectedDotation.index==='3'){
            return (

                <>
                    {data2.map((row) => (
                        <tr   >
                            <td>{row.nom} {row.prenom}</td>
                            <td>{row.n_pid}</td>
                            <td>{row.num_cni}</td>
                            <td align='right'>{formatNumber(row.mt_ac)}</td>
                            <td>{row.date_vc}</td>
                        </tr>
                    ))}
                </>
            );
        }else{
            return (

                <>
                    {data2.map((row) => (
                        <tr   >
                            <td>{row.nom} {row.prenom}</td>
                            <td>{row.num_cni}</td>
                            <td>{row.n_pid}</td>
                            <td align='right'>{formatNumber(row.mt_ac)}</td>
                            <td>{row.mineur==1?'Oui':'Non'}</td>
                            <td>{row.date_vc}</td>
                        </tr>
                    ))}
                </>
            );
        }
    };
    return (
        <div>
            {/* Agency information */}
            <Box sx={{
                borderBottom: '1px solid black',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100vw'
            }}>
                <h5 className="h5">{agence.nom_bureau}</h5>
                <h5 className="h5">{agence.num_auto}</h5>
            </Box>

            {/* Header */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '70vw',
                    marginLeft: '15vw',
                    marginY: '1vh',
                    borderRadius: '8px',
                }}
            >
                <h5 className="h5">Instruction Générale des Opérations de Change du 3 Janvier 2022</h5>

            </Box>
            <Box
                sx={{
                    border: '1px solid black',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '90%',
                    marginLeft: '5%',
                    marginBottom: '4vh',
                    borderRadius: '8px',
                    backgroundColor: '#ea5a27', // Change the background color here
                }}
            >
                {selectedDotation.index === '1' ? (<><h3 className="h3">ETAT MENSUEL DES DOTATIONS DELIVREES</h3>
                    <h3 className="h3">AU TITRE DOTATION </h3>
                    <h3 className="h3">POUR VOYAGES PERSONNELS (MAD)</h3></>) : null}
                {selectedDotation.index === '2' ? (<><h3 className="h3">ETAT MENSUEL DES DOTATIONS DELIVREES</h3>
                    <h3 className="h3">AU TITRE DOTATION POUR EMIGRATION A L'ETRANGER (MAD) </h3>
                </>) : null}
                {selectedDotation.index === '3' ? (<><h3 className="h3">ETAT MENSUEL DES DOTATIONS DELIVREES</h3>
                    <h3 className="h3">AU TITRE DOTATION DEPART SCOLARITE (MAD) </h3>
                </>) : null}
                {selectedDotation.index === '4' ? (<><h3 className="h3">ETAT MENSUEL DES DOTATIONS DELIVREES</h3>
                    <h3 className="h3">AU ALLOCATIONS POUR MISSION ET STAGE A L'ETRANGER (MAD)</h3>
                </>) : null}

            </Box>
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <Box sx={{marginRight: '5vw'}}>
                    <h5 className="h5">Mois : {mois}</h5>
                </Box>
                <Box sx={{}}>
                    <h5 className="h5">Année : {annee}</h5>
                </Box>
            </Box>


            {/* Currency exchange data */}
            <Box sx={{width: '100%'}}>
                <table className="print-table">
                    {renderTableRows()}
                    {renderTableBody()}
                </table>


            </Box>

            {/* Additional CSS styles for printing */}
            <style>
                {`
            .print-table {
              width: 100%;
              border-collapse: collapse;
              margin-bottom: 10vh;
              z-index: 9;
              font-size: 12px;
            }
            .footer {
                margin-top: 4vh;
                border: 2px dotted #73AD21;
                align-items: center;
                width: 100%;
                justify-content: center;
                z-index: 9;
              }
            .print-table th,
            .print-table td {
              border: 1px solid black;
              padding: 8px;
              z-index: 9;
            }

            /* Additional styles specific for printing */
            @media print {
              
              .print-table {
                z-index: 9;
              }
              
              .print-table th,
              .print-table td {
                page-break-inside: avoid;
                z-index: 9;
              }
              /* Ensure the footer appears on each page */
              .footer {
                page-break-inside: avoid;
                z-index: 9;

              }
              .tableau{
                margin-bottom: 10vh; /* Add margin at the bottom of the table */

                z-index: 9;

              }
            }
          `}
            </style>


            {/* Footer */}
            <Box className="footer" sx={{paddingTop: '1vh'}}>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <h5 className="h5">Adresse : {agence.adresse}</h5>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Box sx={{marginRight: '3vh'}}>
                        <h5 className="h5">Tél : {agence.tel}</h5>
                    </Box>
                    <Box>
                        <h5 className="h5">Email : {agence.email}</h5>
                    </Box>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Box sx={{marginRight: '3vh'}}>
                        <h5 className="h5">TP : {agence.tp}</h5>
                    </Box>
                    <Box sx={{marginRight: '3vh'}}>
                        <h5 className="h5">IF : {agence.idfis}</h5>
                    </Box>
                    <Box>
                        <h5 className="h5">RC : {agence.rc}</h5>
                    </Box>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <h5 className="h5">ICE : {agence.ice}</h5>
                </Box>
            </Box>
        </div>
    );
}

export default Index;