import React from 'react';
import { Box } from "@mui/material";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import Autocomplete from '@mui/material/Autocomplete';
import {useEffect, useState,useRef} from 'react';
import {Button} from "@mui/material";
import swal from "sweetalert";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import axios from "axios";
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import {Link, useParams} from 'react-router-dom';
import { useReactToPrint } from "react-to-print";
import Logo from './../../../data/bg-05.gif'
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
const formatNumber = (number) => {
    // Convert the number to a string with 2 decimal places
    const formattedNumber = Number(number).toFixed(2);

// Split the number into parts before and after the decimal point
    const [integerPart, decimalPart] = formattedNumber.split('.');

// Add thousands separators
    const integerWithSeparators = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

// Combine the formatted parts
    return `${integerWithSeparators}.${decimalPart}`;

};
class PDFContent extends React.Component {
    render() {
        const { data,agence,data2,data3} = this.props;
        const sumCv = data.reduce((accumulator, currentDevise) => {
            return accumulator + currentDevise.contreValeur;
        }, 0);
        const pageCount = 2;
        return (
            <div >
                {Array.from({ length: pageCount }).map((_, index) => (
                    <div className='page' key={index}>
                        <Box sx={{ borderBottom: '1px solid black', justifyContent:'center', alignItems: 'center' ,width:"100vw"}}>
                            <Box sx={{ alignItems: 'center',justifyContent:'center',display:"flex"}}><h3 className="h3" >BORDEREAU DE VENTE DE BILLETS DE BANQUE ETRANGERS</h3></Box>
                            <Box sx={{ alignItems: 'center',justifyContent:'center',display:"flex"}}><h3 className="h3" >ET DE CHEQUE DE VOYAGE</h3></Box>
                            <Box sx={{ alignItems: 'center',justifyContent:'center',display:"flex"}}><h5 className="h5" > (Instruction Générale des Opérations de Change  3 Janvier 2022 - Articles 34 et 208)</h5></Box>
                        </Box>

                        <Box sx={{marginTop:"2vh"}}>
                            <Box sx={{marginBottom:"1vh",display:"flex"}}>
                                <Box><h3 className="h3">INFORMATIONS POINT DE CHANGE</h3></Box>

                            </Box>
                            <Box sx={{marginLeft:"5vw"}}><p className="p2">Dénomination : {agence.nom_bureau}</p></Box>
                            <Box sx={{marginLeft:"5vw"}}><p className="p2">Identifiant : {agence.num_auto}</p></Box>
                        </Box>


                        <Box sx={{marginTop:"2vh"}}>
                            <Box sx={{marginBottom:"1vh",display:'flex'}}>
                                <Box><h3 className="h3">REFERENCE BORDEREAU</h3></Box>
                                <Box sx={{ border: '1px solid black', padding:"1% 4%",marginLeft:'10%',marginTop:"-1%"}}><h3 className="h3">VENTE</h3></Box>

                            </Box>
                            <Box sx={{marginBottom:"1vh",display:'flex'}}>
                                <Box sx={{marginLeft:"5vw"}}><p className="p2">Numéro : {data2.num_bvc}</p></Box>
                                <Box sx={{marginLeft:"5vw"}}><p className="p2">Date : {new Date().getDate()< 10?"0" + (new Date().getDate()):(new Date().getDate())}/{new Date().getMonth() + 1 < 10 ? "0" + (new Date().getMonth() + 1) : new Date().getMonth() + 1}/{new Date().getFullYear()}</p></Box>
                                <Box sx={{marginLeft:"5vw"}}><p className="p2">Heure : {new Date().getHours()}:{new Date().getMinutes()}:{new Date().getMilliseconds()}</p></Box>
                            </Box>
                        </Box>



                        <Box sx={{marginTop:"2vh"}}>
                            <Box sx={{marginBottom:"1vh",display:'flex'}}>
                                <Box><h3 className="h3">INFORMATIONS ET IDENTIFICATION CLIENT</h3></Box>
                            </Box>
                            <Box sx={{marginBottom:"1vh"}}>
                                <Box sx={{marginX:"5vw",display:"flex",justifyContent:"space-between"}}>
                                    <Box sx={{marginLeft:"5vw"}}><h4 className="h4">Personne physique</h4></Box>
                                    <Box sx={{marginLeft:"5vw"}}><h4 className="h4">Personne morale</h4></Box>
                                </Box>
                                <Box sx={{marginLeft:"5vw"}}><p className="p2">Qualité du cédant : {data2.qualite_cl==="MR"? "MR | Marocains résidants" :
                                    data2.qualite_cl==="MRE"? "MRE | Marocains résidants à l'étranger" : data2.qualite_cl==="ER"? "ER | Etrangers résidants" : "ENR | Touristes étrangers" }</p></Box>
                                <Box sx={{marginLeft:"5vw"}}><p className="p2">Nom et Prénom ou Raison Sociale : {data3.prenom?data3.prenom:""} {data3.nom?data3.nom:""}</p></Box>
                                <Box sx={{marginLeft:"5vw"}}><p className="p2">Adresse : {data3.adresse?data3.adresse:""}</p></Box>
                                <Box sx={{marginY: "1vh", display: 'flex'}}>
                                    <Box><h3 className="h3">INFORMATIONS OPERATION</h3></Box>
                                </Box>
                                {data2.qualite_cl==="MR"?<Box sx={{marginLeft:"5vw"}}><p className="p2">Identifiant et N° d'identification : {data3.n_pid}</p></Box>:null}
                                {data2.qualite_cl==="MRE"?<Box sx={{marginLeft:"5vw"}}><p className="p2">Identifiant et N° d'identification : {data3.n_pid}</p></Box>:null}
                                {data2.qualite_cl==="ER"?<Box sx={{marginLeft:"5vw"}}><p className="p2">N° CIM : {data3.n_pid}</p></Box>:null}
                                {data2.qualite_cl==="ENR"?<Box sx={{marginLeft:"5vw"}}><p className="p2">Numéro du passeport : {data3.n_pid}</p></Box>:null}

                                <Box sx={{marginLeft:"5vw"}}><p className='p2' >Nature de l'Opération : {data2.designation}</p></Box>
                                <Box sx={{marginLeft:"5vw"}}><p className="p2">N° de la déclartion douanière d'importation : {data2.num_dec}</p></Box>

                            </Box>
                        </Box>




                        <Box sx={{ width: '90vw',marginLeft:"5vw",marginRight:"5vw" }}>
                            <table className="print-table">
                                <tr>
                                    <th>Dénomination des devises</th>
                                    <th>Montant en devises</th>
                                    <th>Cours appliqués</th>
                                    <th>Contre Valeur en DHS</th>
                                </tr>
                                {data.map((row) => (
                                    <tr > {/* Assuming each row has a unique identifier (e.g., 'id') */}
                                        <td>{row.devise}</td>
                                        <td align="right">{formatNumber(row.montant)}</td>
                                        <td align="right">{parseFloat(row.coursVente).toFixed(4)}</td>
                                        <td align="right">{formatNumber(row.contreValeur)}</td>

                                    </tr>
                                ))}
                            </table>
                            <Box sx={{ display: 'flex',marginRight:"5vw",alignItems: 'center',marginTop:"2vh",justifyContent:"end"}}>
                                <Box sx={{ borderBottom: '1px dotted black', display: 'flex', flexDirection: 'column', alignItems: 'center' ,width:"16vw",marginX:"2vw"}}><h3>Total</h3></Box><Box sx={{ borderBottom: '1px dotted black', display: 'flex', flexDirection: 'column', alignItems: 'center' ,width:"16vw",marginX:"2vw"}}><h3>{formatNumber(sumCv)}</h3></Box>

                            </Box>

                            {/* Additional CSS styles for printing */}
                            <style>
                                {`
          .print-table {
            width: 100%;
            border-collapse: collapse;
          }

          .print-table th, .print-table td {
            border: 1px solid black;
            padding: 8px;
          }

          /* Additional styles specific for printing */
          @media print {
            .page{
              page-break-inside: avoid;
            }
            .print-table {
              page-break-inside: auto;
            }
            .footer{
              position: fixed;
              bottom: 0;
              border-top: 2px solid #73AD21;
              align-items: center;
              width:100vw;
              justify-content:center;
            }
            .print-table th, .print-table td {
              page-break-inside: avoid;
            }
            .p2{
              font-size:12px;
              color:black;
            }
          }
        `}
                            </style>
                            <Box sx={{marginY:"2vh",display:"flex",justifyContent:"space-between",
                                ".p":{
                                    fontSize:'16px',
                                    color:'black',
                                }
                            }}>
                                <Box sx={{marginLeft:"6vw"}}><p className="p">Signature du client</p></Box>
                                <Box sx={{marginLeft:"6vw"}}><p className="p">Cachet et signnature du point de change</p></Box>
                            </Box>
                        </Box>
                        <Box className="footer" sx={{paddingTop:'1vh'}} >
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}><h5 className="h5" >Adresse : {agence.adresse}</h5></Box>
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}><Box sx={{marginRight:"3vh"}}><h5 className="h5" >Tél : {agence.tel}</h5></Box><Box ><h5 className="h5" >Email : {agence.email}</h5></Box></Box>
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}><Box sx={{marginRight:"3vh"}}><h5 className="h5" >TP : {agence.tp}</h5></Box><Box sx={{marginRight:"3vh"}}><h5 className="h5" >IF : {agence.idfis}</h5></Box><Box sx={{marginRight:"3vh"}}><h5 className="h5" >RC : {agence.rc}</h5></Box>
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}><h5 className="h5" >ICE : {agence.ice}</h5></Box></Box>
                        </Box>
                    </div>
                ))}
            </div>
        );
    }
}
const Geachat = () => {
    const { id } = useParams();
    const history=useHistory();
    const [devise,setDevise]=useState([]);
    const [bordereau,setBordereau]=useState([]);
    const [submitValue,setSubmitValue]=useState({
        heure_achat :'',
        date_achat :'',
        num_bac :'',
        prenom :'',
        adresse :'',
        nationalite :'MAROC',
        type_pid:'',
        n_pid:'',
        email: "",
        updated_at:'',
        qualite_cl:'MR | MR | Marocains résidants',
        code_nop:'Autres',
        num_dec:'',
        date_dec:'',
        Brd_ac:'',
    })
    const componentRef = useRef();
    const [shouldPrint, setShouldPrint] = useState(false);
    const [agence, setAgence] = useState(false);

    const [operationsac,setOperationsac]=useState([]);
    const [client,setClient]=useState([]);
    submitValue.email=localStorage.getItem("auth_email");
    const handleInput2=(e)=>{
        e.persist();
        setSubmitValue({...submitValue, [e.target.name]: e.target.value})
    }
    const handleInput3 = (e, newValue) => {
        setSubmitValue({ ...submitValue, code_nop: newValue }); // Set the selected option directly
    };
    const handleInput4=(e,newValue)=>{
        setSubmitValue({ ...submitValue, nationalite: newValue });

    }
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);




    const [rows, setRows] = useState([]);

    const [montant, setMontant] = useState('');
    const [contreValeur, setContreValeur] = useState('');
    const handleInsert = () => {
        if (selectedDevise  && montant!=0) {
            const Devi=devise.find((row)=>row.nom_devise===selectedDevise.nom_devise);
            const Devi2=operationsac.find((row)=>row.nom_devise===selectedDevise.nom_devise);
            const existingDevise=rows.find((row)=>row.devise===selectedDevise.nom_devise);
            if(!existingDevise  ){
                if(Devi){
                    const text="Le montant de "+selectedDevise.nom_devise+" en caisse est insuffisant.";
                    if (parseFloat(montant)<=(parseFloat(Devi.montant)+parseFloat(Devi2?Devi2.mt_vc:0))) {
                        const cv = devise2.find((op) => op.nom_devise === selectedDevise.nom_devise);
                        const nbr=montant/cv.copure;
                        if(Number.isInteger(nbr)){
                            const newRow = {
                                devise: selectedDevise.nom_devise,
                                montant: montant,
                                coursAchat: cv.tauxA,
                                coursVente: coursVente,
                                contreValeur: contreValeurs,
                                prix_par:cv.prix_par,
                            };
                            setRows(prevRows => [...prevRows, newRow]);
                            setMontant(0);
                            setInputColor("#000000de");
                        }else{
                            swal('',"Veuillez respecter les coupures.",'warning');
                        }
                    } else {
                        swal('',text,'warning');
                    }
                }else{
                    const text="Le montant de "+selectedDevise.nom_devise+" en caisse est insuffisant.";
                    if (parseFloat(montant)<=parseFloat(Devi2.cours_vc)) {
                        const cv = devise2.find((op) => op.nom_devise === selectedDevise.nom_devise);
                        const nbr=montant/cv.copure;
                        if(Number.isInteger(nbr)){
                            const newRow = {
                                devise: selectedDevise.nom_devise,
                                montant: montant,
                                coursAchat: cv.tauxA,
                                coursVente: coursVente,
                                contreValeur: contreValeurs,
                                prix_par:cv.prix_par,
                            };
                            setRows(prevRows => [...prevRows, newRow]);
                            setMontant(0);
                            setInputColor("#000000de");
                        }else{
                            swal('',"Veuillez respecter les coupures.",'warning');
                        }
                    } else {
                        swal('',text,'warning');
                    }
                }
            }else{
                swal('',"Devise déja inséré.",'warning');
            }}

    };

    const [selectedRows, setSelectedRows] = useState([]);
    const handleRowSelection = (rowName) => {
        const selectedIndex = selectedRows.indexOf(rowName);
        let newSelectedRows = [];

        if (selectedIndex === -1) {
            newSelectedRows = [...selectedRows, rowName];
        } else {
            newSelectedRows = selectedRows.filter((name) => name !== rowName);
        }

        setSelectedRows(newSelectedRows);
    };
    const handleDeleteRows = () => {
        if (selectedRow !== null) {
            swal({
                title: '',
                text: 'Voulez-vous vraiment supprimer cette ligne ?',
                icon: 'warning',
                buttons: ['Annuler', 'Oui'],
                dangerMode: true,
            }).then((confirmed) => {
                if (confirmed) {
                    const updatedRows = rows.filter((row) => row.devise!== selectedRow.devise);
                    const existingDevise = listdevise.find((row) => row.nom_devise === selectedRow.devise);
                    setSelectedDevise(existingDevise);
                    setCoursVente(parseFloat(selectedRow.coursVente).toFixed(4));
                    setMontant(selectedRow.montant);
                    setContreValeur(selectedRow.contreValeur); // Assuming setContreValeur is a state setter function
                    setSelectedRow(null);
                    setRows(updatedRows);
                }
            });
        }
    };
    const [bool,setBool]=useState(0);
    useEffect(()=>{

        axios.get(`api/bordereau-vente-client?id=${id}`).then(res=>{
            if(res.data.status===200){
                setBordereau(res.data.bordereau);
                setOperationsac(res.data.operation_ac);
                setClient(res.data.client)
                setBool(2);
            }});

        try {
            axios.get(`api/info-agence`).then(res=>{
                if(res.data.status===200){
                    setAgence(res.data.message);

                }})

        } catch (error) {
            // Handle errors from the first API call
            console.error(error);
        }
    },[]);
    const [selectedDevise, setSelectedDevise] = useState({
        id_devise: "",
        nom_devise: "EUR",
        designation: "",
        prix_par: "",
        copure: "",
        compte: "",
        tauxA: "",
        tauxVC: "",
        tauxVB: ""
    });  const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: `
      @page {
        size: A4;
        margin: 10mm;
      }
      /* Additional styles for the printed page can be added here */
      /* Remove the pagination and URL styles */
      @media print {
        .MuiDataGrid-footerContainer {
          display: none !important;
        }
        a {
          display: none !important;
        }
      }
    `,
        header: null, // Set header to null to remove it from the PDF
        footer: null,
        // Set footer to null to remove it from the PDF
    });

    const sumCvAc = devise.reduce((accumulator, currentDevise) => {
        if(currentDevise.nom_devise !== "DHS"){
            return accumulator + currentDevise.cv_ac;
        }else{
            return accumulator;
        }
    }, 0);
    const sumCvAcWithTwoDecimals = sumCvAc.toFixed(2);
    const sumCv = rows.reduce((accumulator, currentDevise) => {
        return accumulator +  currentDevise.contreValeur;
    }, 0);
    const submitall = (e) => {
        e.preventDefault();

        const data = {
            id_bvc: bordereau.id,

            rows: rows.map((row) => ({
                devise: row.devise,
                montant: row.montant,
                coursVente: row.coursVente,

                contreValeur: row.contreValeur,
                prix_par:row.prix_par,
            })),
        };

        swal({
            title: '',
            text: 'Voulez vous vraiment valider ce bordereau ?',
            icon: 'warning',
            buttons: ['Annuler', 'Oui'],
            dangerMode: true,
        }).then((confirmed) => {
            if (confirmed) {
                // User clicked "Oui," proceed with form submission
                axios
                    .post('api/bordereau-vente-client-errone', data)
                    .then((res) => {
                        // ... Handle the response from the server ...
                        if (res.data.status === 200) {
                            // Trigger the printing action first
                            setShouldPrint(true);
                            // Navigate to the new route after printing action
                        } else if (res.data.status === 400) {
                            swal(
                                '',
                                "Pour enregistrer cette opération, il est nécessaire de saisir les informations de l'opération de vente.",
                                'warning'
                            );
                        } else {
                            swal('', 'Erreur.', 'warning');
                        }
                    })
                    .catch((error) => {
                        // Handle any error that occurred during the request
                        console.error('Error submitting form:', error);
                    });
            }
        });
    };
    useEffect(() => {
        if (shouldPrint) {
            // Call handlePrint() when shouldPrint becomes true
            handlePrint(); // This will trigger the printing action
            setShouldPrint(false); // Reset shouldPrint back to false
            history.push('/espace-magchange/ventes-clients');

        }
    }, [shouldPrint]);
    const [infodevise,setInfodevise]=useState([]);
    useEffect(()=>{axios.get(`api/info-devise`).then(res=>
        {
            if(res.data.status===200){
                setInfodevise(res.data.message);

            }
        }
    );},[]);



    const [de,setDe]=useState([]);
    const [selectedDevQC] = useState(null);
    const [inputColor, setInputColor] = useState("#000000de"); // Initial color is black
    const[coursVente,setCoursVente]=useState();
    const handleDeviseChange = (event, newValue) => {
        setSelectedDevise(newValue); // Update selectedDevise directly
        setInputColor("#000000de")
        if (newValue === null) {
            changeCoursVente2();
        } else {

            changeCoursVente(newValue);
        }
    };
    const changeCoursVente2 = () => {
        setCoursVente(0);
        setDe([]);
    };
    const handleCoursChange = (event) => {
        if(parseFloat(coursVente)===0){
            swal('',"Merci de modifier le cours de vente de "+selectedDevise.nom_devise+" dans l'espace de gestion des devises.",'warning');
        }else{
            if (/^\d{0,3}(\.\d{0,4})?$/.test(event.target.value)) {
                setCoursVente(event.target.value);
            }

        }
    }
    const changeCoursVente = (selectedDevise) => {
        const cv = devise2.find((op) => op.nom_devise === selectedDevise.nom_devise);
        setDe(cv);
        const cours = cv ? cv.tauxVC : 0;
        setCoursVente(cours);
    };
    const handleMontantChange = (event) => {
        const inputValue = event.target.value;
        if (/^\d+$/.test(inputValue) || inputValue === '') {
            setMontant(inputValue);
        }
    };

    const contreValeurs =  de.length!==0 ? (coursVente * montant) / de.prix_par : 0;
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedRow2, setSelectedRow2] = useState(null);

    const handleRowClick = (row) => {
        setSelectedRow(row);
    };
    const handleRowClick2 = (row) => {
        setSelectedRow2(row);
    };
    //style
    const BootstrapInput = styled(InputBase)(({ theme }) => ({
        'label + &': {
            marginTop: theme.spacing(2),
        },
        '& .MuiInputBase-input': {
            borderRadius: 4,
            position: 'relative',
            backgroundColor: theme.palette.mode === 'light' ? '#F3F6F9' : '#1A2027',
            border: '1px solid',
            borderColor: theme.palette.mode === 'light' ? '#E0E3E7' : '#2D3843',
            fontSize: 16,
            padding: '10px 12px',
            transition: theme.transitions.create([
                'border-color',
                'background-color',
                'box-shadow',
            ]),
            // Use the system font instead of the default Roboto font.
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            '&:focus': {
                boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                borderColor: theme.palette.primary.main,
            },
            "& .MuiInputLabel-root":{
                fontSize:20
            }
        },
    }));
    const [devise2,setDevise2]=useState([]);
    const [listdevise,setlistdevise]=useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        if (bool==2) {
            axios.get(`api/detail-caisse`).then(res=>{
                if(res.data.status===200){

                    setDevise(res.data.message);
                    setDevise2(res.data.devise);
                    const dhs = "DHS";
                    const ldevise = (res.data.devise).filter((de) => {
                        if (de.nom_devise === dhs) {
                            return false;
                        }

                        const existsInOperationsAC = operationsac.some(item => item.nom_devise === de.nom_devise);
                        const existsInStateDevise = (res.data.message).some(item => item.nom_devise === de.nom_devise);

                        return existsInOperationsAC || existsInStateDevise;
                    });
                    setlistdevise(ldevise);
                    const rt=res.data.message;
                    const sd=ldevise[0];
                    const sd2=(res.data.devise).filter((de) => de.nom_devise === sd.nom_devise)[0]
                    setSelectedDevise(sd2);
                    setDe(sd2);
                    setCoursVente(parseFloat((res.data.devise).filter((de) => de.nom_devise === sd.nom_devise)[0].tauxVC).toFixed(4 ));
                    setLoading(false);
                    setBool(0);
                }

            })}},[bool]);
    if (loading) {
        return <div ><div style={{marginLeft:'37.5%',marginTop:"30vh"}}>
            <img style={{width:"25%",height:"40vh"}} src={Logo}/>

        </div></div>;
    }
    return (

        <Box className="type2" sx={{
            marginTop:"8vh",'& .MuiInputBase-input': {
                height: '18px',
                fontSize:'16px',
            },
            '& .MuiInputLabel-root': {
                fontSize:'14px',

            }
        }}>
            <Box className="header-operation" sx={{"& .cancel-icon": {
                    marginTop: "1.2vh"
                }}}>
                <Box sx={{
                    "& .operation-title": {
                        fontSize: "1.8rem",
                        color:'#0f2479'
                    },

                }}>
                    <h2 className="operation-title">Gestion d'erreurs de vente</h2>
                </Box>
                <Box className="cancel-icon">

                    <Link to="/espace-magchange/ventes-clients"><CancelPresentationIcon /></Link>

                </Box>

            </Box>
            <Box className="type3">
                <Box  className="form1"
                      sx={{
                          "& .MuiOutlinedInput-input":{
                              heigh:"0.5rem"}
                      }}
                >

                    <Box >
                        <h2  style={{marginBottom:'1vh', marginTop:'-2vh',fontSize: "1.4rem"}}>Les informations d'ancien bordereau</h2>

                        <Box display="flex">
                            <Box display="flex" sx={{marginTop:`8px`,width:`20%`}}>
                                <FormControl variant="standard" >
                                    <InputLabel shrink htmlFor="6-input">
                                        N° Bordereau
                                    </InputLabel>
                                    <BootstrapInput disabled size="small" name="idfis" value={bordereau.num_bvc}  id="6-input" autocomplete="organization"/>
                                </FormControl>
                            </Box>
                            <Box display="flex" sx={{marginTop:`8px`,width:`20%`,marginLeft:"3%"}}>
                                <FormControl variant="standard" >
                                    <InputLabel shrink htmlFor="6-input">
                                        Client
                                    </InputLabel>
                                    <BootstrapInput disabled size="small" name="idfis" value={(client.nom===null?'':client.nom)+' '+(client.prenom===null?'':client.prenom)}  id="6-input" autocomplete="organization"/>
                                </FormControl>
                            </Box>
                            <Box display="flex" sx={{marginTop:`8px`,width:`20%`,marginLeft:"3%"}}>
                                <FormControl variant="standard" >
                                    <InputLabel shrink htmlFor="6-input">
                                        Date
                                    </InputLabel>
                                    <BootstrapInput disabled size="small" name="idfis" value={bordereau.date_vc}  id="6-input" autocomplete="organization"/>
                                </FormControl>
                            </Box>
                            <Box display="flex" sx={{marginTop:`8px`,width:`20%`,marginLeft:"3%"}}>
                                <FormControl variant="standard" >
                                    <InputLabel shrink htmlFor="6-input">
                                        Heure
                                    </InputLabel>
                                    <BootstrapInput disabled size="small" name="idfis" value={bordereau.heure_vc}  id="6-input" autocomplete="organization"/>
                                </FormControl>
                            </Box></Box>

                        <Box sx={{marginTop:"2%"}}>
                            <TableContainer component={Paper} >
                                <Table >
                                    <TableHead>
                                        <TableRow>

                                            <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#e5a984" }}>Devise</TableCell>
                                            <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#e5a984" }} align="right">Montant en devise</TableCell>
                                            <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#e5a984" }} align="right">Cours Apliqué</TableCell>
                                            <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#e5a984" }} align="right">Contre valeur en DHS</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {operationsac.map((row) => (
                                            <TableRow

                                                key={row.id}

                                            >

                                                <TableCell sx={{ paddingY: "0.5rem", fontSize:"0.9rem" }}>
                                                    {row.nom_devise}
                                                </TableCell>
                                                <TableCell sx={{ paddingY: "0.5rem", fontSize:"0.9rem" }} align="right">{formatNumber(row.mt_vc)}</TableCell>
                                                <TableCell sx={{ paddingY: "0.5rem", fontSize:"0.9rem" }} align="right">{parseFloat(row.cours_vc).toFixed(4)}</TableCell>
                                                <TableCell sx={{ paddingY: "0.5rem", fontSize:"0.9rem" }} align="right">{formatNumber(row.cv_v)}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                    <Box sx={{marginY:"1%"}}>
                        <h2  style={{marginBottom:'1vh',fontSize: "1.4rem"}}>Opération de vente</h2>
                        <Box display="flex" sx={{marginY:"1%",width:'82%'}}>
                            <Autocomplete
                                size="small"
                                options={listdevise}
                                getOptionLabel={(option) => option.nom_devise}
                                sx={{ width: "20%" }}
                                renderInput={(params) => <TextField {...params} label="Devise" />}
                                onChange={handleDeviseChange}
                                value={selectedDevise}
                                disableClearable
                            />
                            <Box sx={{marginX:'1%',width:'8%'}}>
                                <img src={require(`./../../drapeaux/${selectedDevise.nom_devise!="DHS"?selectedDevise.nom_devise:listdevise[0].nom_devise}.png`)} style={{ width: "100%", height: '35px' }} alt="Flag" />
                            </Box>
                            <TextField
                                size="small"
                                sx={{ width: "20%", marginRight: "1%" }}
                                label="Cours vente"
                                value={coursVente}
                                onChange={handleCoursChange}
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{ style: { color: inputColor } }}
                            />

                            <TextField
                                size="small"
                                sx={{ width: "20%", marginRight: "1%" }}
                                label="Montant"
                                value={montant}
                                onChange={handleMontantChange}
                                type="number"

                            />
                            <TextField
                                size="small"
                                sx={{ width: "20%", marginRight: "1%" }}
                                label="Contre valeur"
                                value={formatNumber(contreValeurs)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{ style: { color: inputColor } }}
                                disabled
                            />

                        </Box>
                        <Box display="flex" justifyContent="space-between" sx={{
                            "& .MuiButtonBase-root" : {
                                color: colors.grey[200],
                                backgroundColor : colors.primary[400],
                                marginY: "0.5em",
                                fontSize: "0.8em"
                            },
                            "& .MuiButtonBase-root:hover" : {
                                color: colors.primary[400],
                                backgroundColor : colors.grey[200],

                                marginY: "0.5em",
                                fontSize: "0.9em"
                            },
                            "& .span1" : {




                            }}}>
                            <Box sx={{width:"82%"}}>
                                <TableContainer component={Paper} >
                                    <Table >
                                        <TableHead>
                                            <TableRow>

                                                <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#e5a984" }}>Devise</TableCell>
                                                <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#e5a984" }} align="right">Montant en devise</TableCell>
                                                <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#e5a984" }} align="right">Cours Apliqué</TableCell>
                                                <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#e5a984" }} align="right">Contre valeur en DHS</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row) => (
                                                <TableRow

                                                    key={row.devise}
                                                    onClick={()=>handleRowClick(row)}
                                                    sx={{
                                                        backgroundColor:
                                                            selectedRow && selectedRow.devise === row.devise ? '#ccc' : 'inherit',
                                                    }}
                                                >

                                                    <TableCell sx={{ paddingY: "0.5rem", fontSize:"0.9rem" }}>
                                                        {row.devise}
                                                    </TableCell>
                                                    <TableCell sx={{ paddingY: "0.5rem", fontSize:"0.9rem" }} align="right">{formatNumber(row.montant)}</TableCell>
                                                    <TableCell sx={{ paddingY: "0.5rem", fontSize:"0.9rem" }} align="right">{parseFloat(row.coursVente).toFixed(4)}</TableCell>
                                                    <TableCell sx={{ paddingY: "0.5rem", fontSize:"0.9rem" }} align="right">{formatNumber(row.contreValeur)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                            <Box sx={{width:"18%"}}>
                                <Box><Button onClick={handleInsert}><AddIcon/><span className="span1">Insérer</span></Button></Box>
                                <Box><Button onClick={handleDeleteRows}><CancelIcon/><span className="span1">Annuler</span></Button></Box>
                                <Box><Button  onClick={submitall}><CheckCircleOutlineIcon/><span className="span1">Valider</span></Button></Box>
                            </Box>

                        </Box>
                        <Box  display="flex"
                              sx={{justifyContent:'end',width:'82%',
                                  "& .contrevaleur":{
                                      color: colors.grey[100],
                                  },
                                  "& .contrevv":{
                                      backgroundColor:colors.primary[800],
                                      color: colors.grey[100],
                                  }
                              }}
                        ><span className="contrevaleur" >Contre valeur en DHS</span> <span className="contrevv"  >{formatNumber(sumCv)} </span></Box>

                    </Box>

                </Box>
                <Box className="table1"
                     sx={{
                         "& .MuiDataGrid-root": {
                             border: "none",
                         },
                         "& .MuiDataGrid-cell": {
                             borderBottom: "none",
                         },
                         "& .name-column--cell": {
                             color: colors.greenAccent[300],
                         },
                         "& .MuiDataGrid-columnHeaders": {
                             backgroundColor: colors.blueAccent[700],
                             borderBottom: "none",
                         },
                         "& .MuiDataGrid-virtualScroller": {
                             backgroundColor: colors.primary[400],
                         },
                         "& .MuiDataGrid-footerContainer": {
                             borderTop: "none",
                             backgroundColor: colors.blueAccent[700],
                         },
                         "& .MuiCheckbox-root": {
                             color: `${colors.greenAccent[200]} !important`,
                         },
                         "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                             color: `${colors.grey[100]} !important`,
                         },
                     }}
                >
                    <h2   className="h2">Caisse en devise</h2>
                    <TableContainer component={Paper} >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#728cef" }}>Devise</TableCell>
                                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#728cef" }}>Montant</TableCell>
                                </TableRow>
                            </TableHead>
                        </Table>
                        <div style={{ overflowY: 'auto', maxHeight: '35vh' }}>
                            <Table aria-label="simple table">
                                <TableBody>
                                    {devise.map((row, index) => (
                                        <TableRow
                                            key={index}
                                            onClick={() => handleRowClick2(row)}
                                            style={{
                                                backgroundColor:
                                                    selectedRow2 && selectedRow2.nom_devise === row.nom_devise ? '#ccc' : 'inherit',
                                            }}
                                        >
                                            <TableCell sx={{ paddingY: "0.5rem", fontSize:"0.9rem" }}>{row.nom_devise}</TableCell>
                                            <TableCell sx={{ paddingY: "0.5rem", fontSize:"0.9rem" }} align="right">{formatNumber(row.montant)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    </TableContainer>
                    <Box display="flex" justifyContent="space-between" >

                        <Box
                            sx={{width:'100%',justifyContent: 'center', alignItems: 'center',justifyItems:'center',
                                "& .contrevaleur":{
                                    color: colors.grey[100],
                                    fontSize: "1rem",
                                    marginTop: "1rem",
                                    padding:"0.5rem",
                                },
                                "& .contrev":{
                                    backgroundColor:colors.primary[800],
                                    color: colors.grey[100],
                                    fontSize: "1.1rem",
                                    marginTop: "1rem",
                                    marginLeft: "2rem",
                                    marginRight : "3rem",
                                    marginBottom: "5rem",
                                    paddingX:"2.5rem",
                                    paddingY:"0.5rem",
                                }
                            }}
                        ><Box sx={{ marginY: '2vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <span className="contrevaleur" stylle={{width:'100%', textAlign:'center'}}>Contre valeur de devises conservées</span>
                        </Box >
                            <Box  sx={{display: 'flex', justifyContent: 'center', alignItems: 'center' }}><span className="contrev"  >{formatNumber(sumCvAc)} </span></Box></Box>
                    </Box>
                </Box>

            </Box>
            <div style={{ display: 'none' }}>
                <PDFContent data={rows} data2={bordereau} data3={client} agence={agence} ref={componentRef} />
            </div>
        </Box>



    );
};

export default Geachat;
