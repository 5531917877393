import { Box } from "@mui/material";
import { DataGrid} from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataContacts } from "../../data/mockData";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import Autocomplete from '@mui/material/Autocomplete';
import {useEffect, useState,useRef} from 'react';
import {Button} from "@mui/material";
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import swal from "sweetalert";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import axios from "axios";
import { number } from "yup";
import { useReactToPrint } from "react-to-print";
import React from "react";
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Link } from 'react-router-dom';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
const Achat = () => {
    const componentRef = useRef();
    const history=useHistory();
    const [devise,setDevise]=useState([]);
    const [type_pid,setType_pid]=useState([]);
    const [submitValue,setSubmitValue]=useState({
      montant :'',
      cheque:''
    })
    const [selectedQC,setSelectedQC]=useState([]);
    const [pays,setPays]=useState([]);
    const [listdevise,setlistdevise]=useState([]);
    const handleInput2=(e)=>{
      e.persist();
      setSubmitValue({...submitValue, [e.target.name]: e.target.value})
    } 
     const theme = useTheme();
     const colors = tokens(theme.palette.mode);
     const [banque, setBanque] = useState([]);
     const [nature,setNature]=useState(["Autres","Règlement d'exportations de marchandises","Gains obtenus dans le cadre de compétitions sportives,jeux,prix littéraires,artistique ou scientifiq","Rétrocession"]);
    const [selectedBanque, setselectedBanque] = useState({});
    const [Nationalite,setNationalite]=useState(['MAROC']);
    const [nat,setNat]=useState("Nationalité");
     let countrynames=pays.map((country) => country.nom_pays);;
    const [n,setN]=useState("N° CNI")
    const [bool,setBool]=useState(false);
    const [agence,setAgence]=useState([]);
    const [shouldPrint, setShouldPrint] = useState(false);
    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [sumCaisse, setSumCaisse] = useState(0);
    const [montant, setMontant] = useState(0);

    
    
    const handleInput = (event, newValue) => {
       setselectedBanque(newValue);
      
    };
    
  const [rows, setRows] = useState([]);
  
  
  const handleInsert = () => {
    if(montant){
    if (selectedDevise) {
      const existingDevise=rows.find((row)=>row.devise===selectedDevise.nom_devise);
      if(!existingDevise){
        const nbr=montant/selectedDevise.copure;
        if(Number.isInteger(nbr)){
        const newRow = {
          devise: selectedDevise.nom_devise,
          montant: montant,
          coursAchat: selectedDevise.tauxA,
          contreValeur: contreValeurs,
        };
        setRows((prevRows) => [...prevRows, newRow]);
      }else{
        swal('',"Veuillez respecter les coupures.",'warning');
      }
    }else{
      swal('',"Devise déja inséré.",'warning');
    }
    }
  }
  };
  
  const [selectedRows, setSelectedRows] = useState([]);
  const handleRowSelection = (rowName) => {
    const selectedIndex = selectedRows.indexOf(rowName);
    let newSelectedRows = [];

    if (selectedIndex === -1) {
      newSelectedRows = [...selectedRows, rowName];
    } else {
      newSelectedRows = selectedRows.filter((name) => name !== rowName);
    }

    setSelectedRows(newSelectedRows);
  };
  const handleDeleteRows = () => {
    // Filter out the selected rows and update the rows state
    const updatedRows = rows.filter((row) => row.devise!== selectedRow.devise);
    // Clear the selected rows
    setSelectedRows([]);
    // Update the rows state with the filtered rows
    setRows(updatedRows);
  };
  
  const [selectedDevise,setSelectedDevise]=useState(listdevise[0]);

  const sumCvAc = devise.reduce((accumulator, currentDevise) => {
    if(currentDevise.nom_devise !== "DHS"){
    return accumulator + currentDevise.cv_ac;
  }else{
    return accumulator;
  }
  }, 0);
  const sumCvAcWithTwoDecimals = sumCvAc.toFixed(2);
  const sumCv = rows.reduce((accumulator, currentDevise) => {
    return accumulator +  currentDevise.contreValeur;
  }, 0);
const submitall=(e)=>{
  
  e.preventDefault();
  
  const data={
    montant:parseFloat(submitValue.montant),
    cheque:submitValue.cheque,
    id_banque:selectedBanque.id,
  }
  console.log(data);
  swal({
    title: '',
    text: 'Voulez vous vraiment valider cette opération ?',
    icon: 'warning',
    buttons: ['Annuler', 'Oui'],
    dangerMode: true,
  }).then((confirmed) => {
    if (confirmed) {
      // User clicked "Oui," proceed with form submission
      axios
        .post('api/achat-dhs-post', data)
        .then((res) => {
          // ... Handle the response from the server ...
          if (res.data.status === 200) {
            history.push('/admin/gestioncaisse');

          } else if (res.data.status === 400) {
            swal(
              '',
              "Pour enregistrer cette opération, il est nécessaire de saisir les informations d'opération.",
              'warning'
            );
          } 
        })
        .catch((error) => {
          // Handle any error that occurred during the request
          console.error('Error submitting form:', error);
        });
    }
  });

  
  
      
}
const [infodevise,setInfodevise]=useState([]);
useEffect(()=>{axios.get(`api/achat-dhs-info`).then(res=>
  {
    if(res.data.status===200){
      setBanque(res.data.banques);
      setselectedBanque(res.data.selectedBanque);
      setSumCaisse(res.data.sum);
      
    }
  }
  );},[]);


  
  const [selectedDevQC] = useState(null);
  

  // Handler to update the selectedDevise state when a devise is selected from Autocomplete
  const handleDeviseChange = (event, newValue) => {
    setSelectedDevise(newValue);
  };

  // Handler to update the Montant state when the value changes in the "Montant" TextField
  const handleMontantChange = (event) => {
    setMontant(event.target.value);
  };

  // Calculate Contre Valeur based on the selectedDevise's tauxA and the Montant
  const contreValeurs = selectedDevise ? (selectedDevise.tauxA * montant)/selectedDevise.prix_par : 0;
  const [selectedRow, setSelectedRow] = useState(null);
  const handleRowClick = (row) => {
    setSelectedRow(row);
  };



  
  return (
    
    <Box className="type2" sx={{marginTop:"11vh"}}>
      

<Box
        className="bartitle"
        backgroundColor={colors.primary[400]}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center', // Alignement vertical centré
          padding: '10px', // Espace autour du contenu
        }}
      >
        <Box
          textAlign="center"
          sx={{
            "& .h1": {
              color: colors.grey[500],
              margin: 0, // Supprime la marge par défaut du h1
            },
          }}
        >
          <h1 className="h1">CREATION DES ACHATS DE DHS</h1>
        </Box>
        <Box sx={{marginLeft:'4vw',marginTop:'1.5vh'}}>
          <Link to='/admin/gestioncaisse'><CancelPresentationIcon /></Link>
        </Box>
      </Box>



      <Box className="type3">
        <Box  className="form2"
         sx={{
            "& .MuiOutlinedInput-input":{
            heigh:"0.5rem"}
        }}
         >
          
            <Box >
                <h1  className= "h1">Detail opération</h1>
                <Box sx={{display:'flex',justifyContent:'flex-end',justifyItems:'flex-end'}}><TextField size="small" name="caisse"  value={parseFloat(sumCaisse).toFixed(2)}  sx={{marginTop:`1.5vh` ,width:`40vh`}}  label="caisse en Dhs" variant="outlined" /></Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                        <DatePicker
                        disabled
                        label="Date"
                        value={selectedDate}
                        format="DD/MM/YYYY"
                        size="small"
                        />
                    </DemoContainer>
                </LocalizationProvider>
                <Autocomplete
                    onChange={handleInput}
                    value={selectedBanque}
                    size="small"
                    id="combo-box-demo"
                    options={banque}
                    sx={{marginTop:`4vh`}}
                    getOptionLabel={(option) => option.designation}

                    renderInput={(params) => <TextField {...params} label="Banque" />}
                />

                <Box><TextField size="small" sx={{marginTop:`4vh`,width:'50%'}} name="cheque"  value={submitValue.cheque} onChange={handleInput2} label="N° de chéque" variant="outlined" /></Box>
                <Box><TextField size="small" sx={{marginTop:`4vh`,width:'50%'}} name="montant"  value={submitValue.montant} onChange={handleInput2}  label="Montant" variant="outlined" type="number"
                      InputLabelProps={{
                        shrink: true,
                      }} /></Box>

                <Box sx={{display:'flex',justifyContent:'center',justifyItems:'center',marginTop:'4vh',
                 "& .MuiButtonBase-root": {
                    color: colors.grey[200],
                    backgroundColor: colors.primary[800],
        
                  },
                  "& .MuiButtonBase-root:hover": {
                    color: colors.primary[400],
                    backgroundColor: colors.grey[200],
        
                  },
            
            }}><Button onClick={submitall} className="buttons"   >Valider</Button></Box>
            </Box>
            
            
        </Box>
        
       
       
      </Box>
      </Box>
       
      
    
  );
};

export default Achat;
