import { Box } from "@mui/material";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import Autocomplete from '@mui/material/Autocomplete';
import {useEffect, useState,useRef} from 'react';
import {Button} from "@mui/material";
import swal from "sweetalert";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import axios from "axios";
import { useReactToPrint } from "react-to-print";
import React from "react";
import Logo from './../../../data/bg-05.gif'
import {formatNumber} from './../../../fonctions/formatNumber'
import PDFOperationAchat from '../PDFContent/OperationAchat/index';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import {Link} from "react-router-dom";
class PDFContent extends React.Component {
    render() {
        const { data,agence,data2,data3,sumCv } = this.props;
        return (
            <PDFOperationAchat data={data} agence={agence} data2={data2} data3={data3} sumCv={sumCv}/>
        );
    }
}
const Achat = () => {
    const componentRef = useRef();
    const history=useHistory();
    const [devise,setDevise]=useState([]);
    const [type_pid,setType_pid]=useState([]);
    const [submitValue,setSubmitValue]=useState({
        nom :'',
        prenom :'',
        adresse :'',
        nationalite :'MAROC',
        type_pid:'',
        n_pid:'',
        email: "",
        updated_at:'',
        qualite_cl:'MR | MR | Marocains résidants',
        code_nop:'Autres',
        num_dec:'',
        date_dec:'',
        Brd_ac:'',
        num_bac:''
    })
    const [selectedQC,setSelectedQC]=useState([]);
    const [pays,setPays]=useState([]);
    const [listdevise,setlistdevise]=useState([]);
    const [loading, setLoading] = useState(true);

    const handleInput2=(e)=>{
        e.persist();
        setSubmitValue({...submitValue, [e.target.name]: e.target.value})
    }
    const handleInput3 = (e, newValue) => {
        setSubmitValue({ ...submitValue, code_nop: newValue }); // Set the selected option directly
    };
    const handleInput4=(e,newValue)=>{
        setSubmitValue({ ...submitValue, nationalite: newValue });

    }
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const qualite=["MR | Marocains résidants", "MRE | Marocains résidants à l'étranger","ER | Etrangers résidants" ,"ENR | Touristes étrangers" ];
    const [nature,setNature]=useState(["Autres","Règlement d'exportations de marchandises","Gains obtenus dans le cadre de compétitions sportives,jeux,prix littéraires,artistique ou scientifiq","Rétrocession"]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [Nationalite,setNationalite]=useState(['MAROC']);
    const [nat,setNat]=useState("Nationalité");
    let countrynames=pays.map((country) => country.nom_pays);;
    const [n,setN]=useState("N° CNI")
    const [bool,setBool]=useState(false);
    const [agence,setAgence]=useState([]);
    const [valider, setValider] = useState(true);
    const [validerOp, setValiderOp] = useState(false);
    const [shouldPrint, setShouldPrint] = useState(false);
    const [inputColor, setInputColor] = useState("#000000de"); // Initial color is black

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "",
        pageStyle: `
        @page {
          size: A4;
          margin: 10mm;
        }
        /* Additional styles for the printed page can be added here */
        /* Remove the pagination and URL styles */
        @media print {
          .MuiDataGrid-footerContainer {
            display: none !important;
          }
          a {
            display: none !important;
          }
        }
      `,
        header: null, // Set header to null to remove it from the PDF
        footer: null,
        // Set footer to null to remove it from the PDF
    });
    useEffect(() => {
        if (shouldPrint) {
            // Call handlePrint() when shouldPrint becomes true
            handlePrint(); // This will trigger the printing action
            setShouldPrint(false); // Reset shouldPrint back to false
            // history.push('/admin/achatclients');
            setValider(true);
            setRows([]);
            setSubmitValue(prevValue => {
                return {
                    ...prevValue,
                    nom: '',
                    prenom: '',
                    adresse: '',
                    type_pid: '',
                    n_pid: '',
                    email: '',
                    updated_at: '',
                    num_dec: '',
                    date_dec: '',
                    Brd_ac: '',
                    num_bac: ''
                };
            });


        }
    }, [shouldPrint]);
    useEffect(()=>{
        if (bool) {
            if (selectedOption === 'MR | Marocains résidants') {
                setNature(["Autres", "Règlement d'exportations de marchandises", "Gains obtenus dans le cadre de compétitions sportives,jeux,prix littéraires,artistique ou scientifique", "Rétrocession"]);
                setNationalite(['MAROC']);
                setNat("Nationalité");
                setN("N° CNI");
                setType_pid("CNI");
                setSubmitValue({
                    ...submitValue,
                    code_nop: "Autres",
                    nationalite: "MAROC"
                });
            } else if (selectedOption === "MRE | Marocains résidants à l'étranger") {
                setNature(["Marocains résidants à l'étranger"]);
                setNationalite(countrynames);
                setNat("Pays d'accueil");
                setN("N° CNI");
                setType_pid("CNI");
                setSubmitValue({
                    ...submitValue,
                    code_nop: "Marocains résidants à l'étranger",
                    nationalite: ""
                });
            } else if (selectedOption === "ER | Etrangers résidants") {
                setNature(["Etrangers résidants"]);
                setNationalite(countrynames);
                setNat("Nationalité");
                setN("N° CIM");
                setType_pid("CIM");
                setSubmitValue({
                    ...submitValue,
                    code_nop: "Etrangers résidants",
                    nationalite: ""
                });
            } else {
                setNature(["Touristes étrangers"]);
                setNationalite(countrynames);
                setNat("Nationalité");
                setType_pid("PASS");
                setN("N° Passport");
                setSubmitValue({
                    ...submitValue,
                    code_nop: "Touristes étrangers",
                    nationalite: ""
                });
            }
            setBool(false)
        }
    },[bool]);
    const handleInput = (event, newValue) => {
        countrynames = pays.map((country) => country.nom_pays);
        setSelectedOption(newValue);
        setBool(true);

    };

    const [rows, setRows] = useState([]);

    const [coursAchat, setCoursAchat] = useState('');
    const [montant, setMontant] = useState('');
    const [contreValeur, setContreValeur] = useState('');
    const handleInsert = () => {
        if (rows.length < 5) {
        if(montant  && montant!=0){
            if (selectedDevise && coursAchat!=0) {
                const existingDevise=rows.find((row)=>row.devise===selectedDevise.nom_devise);
                const Devi=devise.find((row)=>row.nom_devise==='DHS')?devise.find((row)=>row.nom_devise==='DHS'):0;
                if(parseFloat(contreValeurs)<=parseFloat(Devi.montant)){
                    if(!existingDevise){
                        const nbr=montant/selectedDevise.copure;
                        if(Number.isInteger(nbr)){
                            const newRow = {
                                devise: selectedDevise.nom_devise,
                                montant: montant,
                                coursAchat: coursAchat,
                                contreValeur: contreValeurs,
                            };
                            setContreValeur(0);
                            setMontant(0);
                            setRows((prevRows) => [...prevRows, newRow]);
                            setInputColor("#000000de");

                        }else{
                            swal('',"Veuillez respecter les coupures.",'warning');
                        }
                    }else{
                        swal('',"Devise déja inséré.",'warning');
                    }
                }else{
                    swal('',"Alimenter votre caisse.",'warning');
                }
            }
        }}
        else{
            swal('', "Vous ne pouvez pas ajouter plus de 5 devises.", 'warning');
        }


    };

    const [selectedRows, setSelectedRows] = useState(null);
    const handleRowSelection = (rowName) => {
        const selectedIndex = selectedRows.indexOf(rowName);
        let newSelectedRows = [];

        if (selectedIndex === -1) {
            newSelectedRows = [...selectedRows, rowName];
        } else {
            newSelectedRows = selectedRows.filter((name) => name !== rowName);
        }

        setSelectedRows(newSelectedRows);
    };
    const handleDeleteRows = () => {
        if (selectedRow !== null) {
            swal({
                title: '',
                text: 'Voulez-vous vraiment supprimer cette ligne ?',
                icon: 'warning',
                buttons: ['Annuler', 'Oui'],
                dangerMode: true,
            }).then((confirmed) => {
                if (confirmed) {
                    const updatedRows = rows.filter((row) => row.devise !== selectedRow.devise);
                    const existingDevise = listdevise.find((row) => row.nom_devise === selectedRow.devise);
                    setSelectedDevise(existingDevise);
                    setCoursAchat(parseFloat(selectedRow.coursAchat).toFixed(4));
                    setMontant(selectedRow.montant);
                    setContreValeur(selectedRow.contreValeur); // Assuming setContreValeur is a state setter function
                    setSelectedRow(null);
                    setRows(updatedRows);
                }
            });
        }
    };

    const [selectedDevise,setSelectedDevise]=useState({
        id_devise: "",
        nom_devise: "EUR",
        designation: "",
        prix_par: "",
        copure: "",
        compte: "",
        tauxA: "",
        tauxVC: "",
        tauxVB: ""
    });

    useEffect(()=>{
        if(valider){
            axios.get(`api/detail-caisse`).then(res=>{
                if(res.data.status===200){
                    setDevise(res.data.message);
                    setSelectedQC(res.data.selectedQC);
                    setPays(res.data.pays);
                    setlistdevise(res.data.devise);
                    const sd=(res.data.devise).find((op)=>op.nom_devise==="EUR");
                    setSelectedDevise(sd);
                    setCoursAchat(parseFloat(sd.tauxA).toFixed(4));
                    setLoading(false);
                    setValider(false);
                }

            })}},[valider]);

    const sumCvAc = devise.reduce((accumulator, currentDevise) => {
        if(currentDevise.nom_devise !== "DHS"){
            return accumulator + currentDevise.cv_ac;
        }else{
            return accumulator;
        }
    }, 0);
    const sumCvAcWithTwoDecimals = sumCvAc.toFixed(2);
    const sumCv = rows.reduce((accumulator, currentDevise) => {
        return accumulator +  currentDevise.contreValeur;
    }, 0);
    const submitall = (e) => {
        e.preventDefault();

        if (validerOp) {
            return; // If already submitting, do nothing
        }

        setValiderOp(true);

        const data = {
            qualite_cl: selectedOption,
            n_pid: submitValue.n_pid,
            nationalite: submitValue.nationalite,
            nom: submitValue.nom,
            prenom: submitValue.prenom,
            adresse: submitValue.adresse,
            email: submitValue.email,
            code_nop: submitValue.code_nop,
            num_dec: submitValue.num_dec,
            date_dec: submitValue.date_dec,
            type_pid: type_pid,
            Brd_ac: '1',
            sumCv: sumCv,
            rows: rows.map((row) => ({
                devise: row.devise,
                montant: row.montant,
                coursAchat: row.coursAchat,
                contreValeur: row.contreValeur,
            })),
        };

        swal({
            title: '',
            text: 'Voulez-vous vraiment valider ce bordereau ?',
            icon: 'warning',
            buttons: ['Annuler', 'Oui'],
            dangerMode: true,
        }).then((confirmed) => {
            if (confirmed) {
                // User clicked "Oui," proceed with form submission
                axios
                    .post('api/achat-client', data)
                    .then((res) => {
                        // Handle the response from the server
                        if (res.data.status === 200) {
                            setSubmitValue((op) => ({ ...op, num_bac: res.data.message }));
                            setShouldPrint(true);
                        } else if (res.data.status === 410) {
                            swal(
                                '',
                                "Pour enregistrer cette opération, il est nécessaire de saisir les informations du client.",
                                'warning'
                            );
                        } else if (res.data.status === 400) {
                            swal(
                                '',
                                "L'enregistrement de l'opération est impossible.",
                                'warning'
                            );
                        } else {
                            swal('', 'Erreur.', 'warning');
                        }
                    })
                    .catch((error) => {
                        // Handle any error that occurred during the request
                        console.error('Error submitting form:', error);
                    })
                    .finally(() => {
                        setValiderOp(false);
                    });
            } else {
                setValiderOp(false); // User clicked "Annuler"
            }
        });

        axios.get(`api/info-agence`).then((res) => {
            if (res.data.status === 200) {
                setAgence(res.data.message);
            }
        });
    };
    const [infodevise,setInfodevise]=useState([]);
    useEffect(()=>{axios.get(`api/info-devise`).then(res=>
        {
            if(res.data.status===200){
                setInfodevise(res.data.message);

            }
        }
    );},[]);
    useEffect(()=>{axios.get(`api/qualite-client-ac`).then(res=>
        {
            if(res.data.status===200){
                setSelectedOption(res.data.message);
                setBool(true);
            }
        }
    );},[]);



    const [selectedDevQC] = useState(null);


    // Handler to update the selectedDevise state when a devise is selected from Autocomplete
    const handleDeviseChange = (event, newValue) => {
        setSelectedDevise(newValue);
        setInputColor("#000000de");
        setCoursAchat(parseFloat(newValue.tauxA).toFixed(4));
    };

    const handleMontantChange = (event) => {
        const inputValue = event.target.value;
        if (/^\d+$/.test(inputValue) || inputValue === '') {
            setMontant(inputValue);
        }
    };

    // Calculate Contre Valeur based on the selectedDevise's tauxA and the Montant
    const contreValeurs = selectedDevise ? (coursAchat * montant)/selectedDevise.prix_par : 0;
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedRow2, setSelectedRow2] = useState(null);

    const handleRowClick = (row) => {
        setSelectedRow(row);
    };
    const handleRowClick2 = (row) => {
        setSelectedRow2(row);
    };
    const handleCoursChange = (event) => {
        if(parseFloat(coursAchat)===0){
            swal('',"Merci de modifier le cours d'achat de "+selectedDevise.nom_devise+" dans l'espace de gestion des devises.",'warning');
        }else{
            if (/^\d{0,3}(\.\d{0,4})?$/.test(event.target.value)) {
                setCoursAchat(event.target.value);
            }

        }

    }

    if (loading) {
        return <div ><div style={{marginLeft:'37.5%',marginTop:"30vh"}}>
            <img style={{width:"25%",height:"40vh"}} src={Logo}/>

        </div></div>;
    }


    return (

        <Box  className="type2" sx={{marginTop: '8vh','& .MuiInputBase-input': {
                height: '18px',
                fontSize:'16px',
            },
            '& .MuiInputLabel-root': {
                fontSize:'14px',

            }
        }}>
            <Box className="header-operation" sx={{"& .cancel-icon": {
                    marginTop: "1.2vh"
                }}}>
                <Box sx={{
                    "& .operation-title": {
                        fontSize: "1.8rem",
                        color:'#0f2479'
                    },

                }}>
                    <h2 className="operation-title">Opération d'achat client</h2>
                </Box>
                <Box className="cancel-icon">

                     <Link to="/espace-magchange/achats-clients"><CancelPresentationIcon /></Link>

                </Box>

            </Box>
            <Box className="type3">
                <Box  className="form1"
                      sx={{
                          "& .MuiOutlinedInput-input":{
                              heigh:"0.5rem"}
                      }}
                >

                    <Box >

                        <h2   style={{marginBottom:'1vh', marginTop:'-1vh',fontSize: "1.4rem"}}>Informations client</h2>
                        <Box sx={{display:'flex'}} >
                            <Box sx={{width:'48%'}}>

                                <Autocomplete
                                    onChange={handleInput}
                                    value={selectedOption}
                                    size="small"
                                    id="combo-box-demo"
                                    options={qualite}
                                    sx={{marginTop:`8px`}}
                                    renderInput={(params) => <TextField {...params} label="Qualité client" />}
                                    disableClearable={true}
                                />
                                <Autocomplete
                                    name="code_nop"
                                    size="small"
                                    id="combo-box-demo"
                                    options={nature}
                                    value={submitValue.code_nop}
                                    onChange={handleInput3}
                                    sx={{ marginTop: '8px' }}
                                    renderInput={(params) => <TextField {...params} label="Nature opération" />}
                                    disableClearable={true}
                                />

                                <Autocomplete
                                    onChange={handleInput4}
                                    name="nationalite"
                                    size="small"
                                    value={submitValue.nationalite}
                                    id="combo-box-demo"
                                    options={Nationalite}
                                    defaultValue={Nationalite[0]}
                                    sx={{marginTop:`8px`}}
                                    renderInput={(params) => <TextField {...params} label={nat} />}
                                    disableClearable={true}
                                />
                                <Box><TextField size="small" name="n_pid"  value={submitValue.n_pid} onChange={handleInput2} sx={{marginTop:`8px` ,width:`90%`}}  label={n} variant="outlined" /></Box>
                            </Box>
                            <Box sx={{width:'48%',marginLeft:'4%'}}>
                                <Box><TextField size="small" value={submitValue.nom} name="nom" onChange={handleInput2} sx={{marginTop:`8px` ,width:`100%`}}  label="Nom" variant="outlined" /></Box>
                                <Box><TextField size="small" value={submitValue.prenom} name="prenom" onChange={handleInput2} sx={{marginTop:`8px`,width:`100%`}}  label="Prénom" variant="outlined" /></Box>
                                <Box><TextField size="small" value={submitValue.adresse} name="adresse" onChange={handleInput2} sx={{marginTop:`8px`,width:`100%`}}  label="Adresse" variant="outlined" /></Box>
                                <Box sx={{display:'flex'}}>
                                    <Box sx={{width:'58%'}}><TextField size="small" value={submitValue.num_dec} name="num_dec" onChange={handleInput2} sx={{marginTop:`8px`,width:`100%`}} label="N° déclaration" variant="outlined" /></Box>
                                    <Box sx={{width:'40%',marginLeft:'2%'}}><TextField size="small" value={submitValue.date_dec} name="date_dec" onChange={handleInput2} sx={{marginTop:`8px`,width:`100%`}}  label="Date déclaration" variant="outlined" /></Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{marginY:"12px"}}>
                        <h2  className="h2" style={{marginBottom:'1vh',marginTop:'-1vh',fontSize:'1.5rem'}}>Opération d'achat</h2>
                        <Box display="flex" sx={{marginY:"12px",width:'82%'}}>
                            <Autocomplete
                                size="small"
                                options={listdevise}
                                getOptionLabel={(option) => option.nom_devise}
                                sx={{  width: "20%" }}
                                renderInput={(params) => <TextField {...params} label="Devise" />}
                                onChange={handleDeviseChange}
                                value={selectedDevise}

                                disableClearable={true}
                            />
                            <Box sx={{marginX:'1%',width:'8%'}}>
                                <img src={require(`./../../drapeaux/${selectedDevise.nom_devise}.png`)} style={{ width: "100%", height: '35px' }} alt="Flag" />
                            </Box>
                            <TextField
                                size="small"
                                sx={{ width: "20%", marginRight: "1%" }}
                                label="Cours achat"
                                onChange={handleCoursChange}
                                value={coursAchat}
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{ style: { color: inputColor } }}
                            />
                            <TextField
                                size="small"
                                sx={{ width: "20%", marginRight: "1%" }}
                                label="Montant"
                                value={montant}
                                type="number"
                                onChange={handleMontantChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{ style: { color: inputColor } }}
                            />
                            <TextField
                                size="small"
                                sx={{ width: "20%", marginRight: "13%" }}
                                label="Contre valeur"
                                value={formatNumber(contreValeurs)}
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{ style: { color: inputColor } }}
                            />

                        </Box>
                        <Box display="flex" justifyContent="space-between" sx={{
                            "& .MuiButtonBase-root" : {
                                color: colors.grey[200],
                                backgroundColor : colors.primary[400],
                                marginY: "0.5em",
                                fontSize: "0.8em"
                            },
                            "& .MuiButtonBase-root:hover" : {
                                color: colors.primary[400],
                                backgroundColor : colors.grey[200],

                                marginY: "0.5em",
                                fontSize: "0.8em"
                            },
                            "& .span1" : {




                            }}}>
                            <Box sx={{width:"82%"}}>
                                <TableContainer component={Paper} >
                                    <Table >
                                        <TableHead>
                                            <TableRow>

                                                <TableCell sx={{ paddingY: "0.5rem", fontSize:"0.9rem",backgroundColor:"#e5a984" }}>Devise</TableCell>
                                                <TableCell align="right" sx={{ paddingY: "0.5rem", fontSize:"0.9rem" ,backgroundColor:"#e5a984"}}>Montant en devise</TableCell>
                                                <TableCell align="right" sx={{ paddingY: "0.5rem", fontSize:"0.9rem" ,backgroundColor:"#e5a984"}}>Cours appliqué</TableCell>
                                                <TableCell align="right" sx={{ paddingY: "0.5rem", fontSize:"0.9rem" ,backgroundColor:"#e5a984"}}>Contre valeur en DHS</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row) => (
                                                <TableRow

                                                    key={row.devise}
                                                    onClick={()=>handleRowClick(row)}
                                                    sx={{
                                                        backgroundColor:
                                                            selectedRow && selectedRow.devise === row.devise ? '#ccc' : 'inherit',
                                                    }}
                                                >

                                                    <TableCell sx={{ paddingY: "0.5rem", fontSize:"0.9rem" }}>
                                                        {row.devise}
                                                    </TableCell>
                                                    <TableCell align="right" sx={{ paddingY: "0.5rem", fontSize:"0.9rem" }}>{formatNumber(row.montant)}</TableCell>
                                                    <TableCell align="right" sx={{ paddingY: "0.5rem", fontSize:"0.9rem" }}>{parseFloat(row.coursAchat).toFixed(4)}</TableCell>
                                                    <TableCell align="right" sx={{ paddingY: "0.5rem", fontSize:"0.9rem" }}>{formatNumber(row.contreValeur)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                            <Box sx={{width:"18%"}}>
                                <Box><Button onClick={handleInsert}><AddIcon/><span className="span1">Insérer</span></Button></Box>
                                <Box><Button onClick={handleDeleteRows}><CancelIcon/><span className="span1">Annuler</span></Button></Box>
                                <Box><Button  onClick={submitall}><CheckCircleOutlineIcon/><span className="span1">Valider</span></Button></Box>
                            </Box>

                        </Box>
                        <Box  display="flex"
                              sx={{justifyContent:'end',width:'82%',
                                  "& .contrevaleur":{
                                      color: colors.grey[100],
                                  },
                                  "& .contrevv":{
                                      backgroundColor:colors.primary[800],
                                      color: colors.grey[100],
                                  }
                              }}
                        ><span className="contrevaleur" >Contre valeur en DHS</span> <span className="contrevv"  >{formatNumber(sumCv)} </span></Box>

                    </Box>

                </Box>
                <Box className="table1"
                     sx={{
                         "& .MuiDataGrid-root": {
                             border: "none",
                         },
                         "& .MuiDataGrid-cell": {
                             borderBottom: "none",
                         },
                         "& .name-column--cell": {
                             color: colors.greenAccent[300],
                         },
                         "& .MuiDataGrid-columnHeaders": {
                             backgroundColor: colors.blueAccent[700],
                             borderBottom: "none",
                         },
                         "& .MuiDataGrid-virtualScroller": {
                             backgroundColor: colors.primary[400],
                         },
                         "& .MuiDataGrid-footerContainer": {
                             borderTop: "none",
                             backgroundColor: colors.blueAccent[700],
                         },
                         "& .MuiCheckbox-root": {
                             color: `${colors.greenAccent[200]} !important`,
                         },
                         "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                             color: `${colors.grey[100]} !important`,
                         },
                     }}
                >
                    <h2   className="h2">Caisse en devise</h2>
                    <TableContainer component={Paper} >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#728cef" }}>Devise</TableCell>
                                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem", backgroundColor:"#728cef" }}>Montant</TableCell>
                                </TableRow>
                            </TableHead>
                        </Table>
                        <div style={{ overflowY: 'auto', maxHeight: '35vh' }}>
                            <Table aria-label="simple table">
                                <TableBody>
                                    {devise.map((row, index) => (
                                        <TableRow
                                            key={index}
                                            onClick={() => handleRowClick2(row)}
                                            style={{
                                                backgroundColor:
                                                    selectedRow2 && selectedRow2.nom_devise === row.nom_devise ? '#ccc' : 'inherit',
                                            }}
                                        >
                                            <TableCell sx={{ paddingY: "0.5rem", fontSize:"0.9rem" }}>{row.nom_devise}</TableCell>
                                            <TableCell align="right" sx={{ paddingY: "0.5rem", fontSize:"0.9rem"}}>{formatNumber(row.montant)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    </TableContainer>
                    <Box display="flex" justifyContent="space-between" >

                        <Box
                            sx={{width:'100%',justifyContent: 'center', alignItems: 'center',justifyItems:'center',
                                "& .contrevaleur":{
                                    color: colors.grey[100],
                                    fontSize: "1rem",
                                    marginTop: "1rem",
                                    padding:"0.5rem",
                                },
                                "& .contrev":{
                                    backgroundColor:colors.primary[800],
                                    color: colors.grey[100],
                                    fontSize: "1.1rem",
                                    marginTop: "1rem",
                                    marginLeft: "2rem",
                                    marginRight : "3rem",
                                    marginBottom: "5rem",
                                    paddingX:"2.5rem",
                                    paddingY:"0.5rem",
                                }
                            }}
                        ><Box sx={{  display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <span className="contrevaleur" stylle={{width:'100%', textAlign:'center'}}>Contre valeur de devises conservées</span>
                        </Box >
                            <Box  sx={{display: 'flex', justifyContent: 'center', alignItems: 'center' }}><span className="contrev"  >{formatNumber(sumCvAc)} </span></Box></Box>
                    </Box>
                </Box>

            </Box>
            <div style={{ display: 'none' }}>
                <PDFContent data={rows} agence={agence} data2={submitValue} data3={selectedOption} sumCv={formatNumber(sumCv)}  ref={componentRef} />
            </div>
        </Box>



    );
};

export default Achat;
