import React from 'react';
import { Box } from "@mui/material";
import './index.css';

function Index(props) {
    const { data, agence } = props;

    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    });

    return (
        <div className="body-container">
            {/* Information sur l'agence */}
            <Box className="info-agence">
                <h5 className="h5">{agence.nom_bureau}</h5>
                <h5 className="h5">{agence.num_auto}</h5>
            </Box>

            {/* En-tête */}
            <Box className="header">
                <h5 className="h5"></h5>
            </Box>

            {/* Conteneur des cours des devises */}
            <Box className="cours-devises">
                <h3 className="h3">LES COURS DES DEVISES</h3>
            </Box>

            {/* Date */}
            <Box className="date-container">
                <h5 className="h5">{formattedDate}</h5>
            </Box>

            {/* Données des devises */}
            <Box className="donnees-devises">
                <table className="print-table">
                    <thead>
                    <tr>
                        <th>Nom devise</th>
                        <th>Taux achat</th>
                        <th>Taux vente client</th>
                        <th>Taux vente banque</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data.map((row, index) => (
                        <tr key={index}>
                            <td>{row.nom_devise}</td>
                            <td align='right'>{parseFloat(row.tauxA).toFixed(4)}</td>
                            <td align='right'>{parseFloat(row.tauxVC).toFixed(4)}</td>
                            <td align='right'>{parseFloat(row.tauxVB).toFixed(4)}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </Box>

            {/* Pied de page */}
            <Box className="footer">
                <Box className="info">
                    <h5 className="h5">Adresse : {agence.adresse}</h5>
                </Box>
                <Box className="info">
                    <h5 className="h5">Tél : {agence.tel}</h5>
                    <h5 className="h5">Email : {agence.email}</h5>
                </Box>
                <Box className="info">
                    <h5 className="h5">TP : {agence.tp}</h5>
                    <h5 className="h5">IF : {agence.idfis}</h5>
                    <h5 className="h5">RC : {agence.rc}</h5>
                </Box>
                <Box className="info">
                    <h5 className="h5">ICE : {agence.ice}</h5>
                </Box>
            </Box>
        </div>
    );
}

export default Index;
