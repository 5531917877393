import React from 'react';
import {Box} from "@mui/material";
import {formatNumber} from "../../../../fonctions/formatNumber";

function Index(props) {
    const { data, data2,data3, agence ,selectedReleve,selectedEtat,mois,annee } = props;
    const renderTableRows = () => {
        if(selectedReleve.index!=='4' && selectedReleve.index!=='3' ){
            return (
                <tr>
                    <th >Cédants</th>
                    <th >Code</th>
                    <th >EUR</th>

                    <th >USD</th>
                    <th > GBP</th>

                    <th >CAD</th>
                    <th >CHF</th>

                    <th >GIB</th>
                    <th >SEK</th>
                    <th >NOK</th>
                    <th >JPY</th>
                    <th >SAR</th>
                    <th >KWD</th>
                    <th >AED</th>
                    <th >QAR</th>
                    <th >BHD</th>
                    <th >DKK</th>
                    <th >OMR</th>
                    <th >Contre valeur en Dhs</th>

                </tr>
            );
        } else if(selectedReleve.index=='3' ){
            return (
                <tr>
                    <th >Etablissement de credit et bank al maghrib</th>
                    <th >Devise</th>
                    <th >Montant en devise</th>

                    <th >Contre valeur en dirhams</th>


                </tr>
            );
        }
        else{
            return (
                <tr>
                    <th >Devise</th>
                    <th >MONTANT EN DEVISE ACHAT</th>
                    <th >CONTRE VALEUR EN DIRHAMS ACHAT</th>
                    <th >MONTANT EN DEVISE VENTE</th>
                    <th >CONTRE VALEUR EN DIRHAMS VENTE</th>
                </tr>
            );
        }}


    const renderTableBody = () => {
        if ((selectedReleve.index === '1' || selectedReleve.index === '2') && selectedEtat.index === '1') {
            return (
                <tbody>
                {data2.map((row, index) => (
                    <tr key={index}>
                        <td>{row.designation}</td>
                        <td>{row.code_nop}</td>
                        <td>{formatNumber(row.mt1) }</td>
                        <td>{formatNumber(row.mt2) }</td>
                        <td>{formatNumber(row.mt3) }</td>
                        <td>{formatNumber(row.mt4) }</td>
                        <td>{formatNumber(row.mt5) }</td>
                        <td>{formatNumber(row.mt6) }</td>
                        <td>{formatNumber(row.mt7) }</td>
                        <td>{formatNumber(row.mt8) }</td>
                        <td>{formatNumber(row.mt9) }</td>
                        <td>{formatNumber(row.mt10) }</td>
                        <td>{formatNumber(row.mt11) }</td>
                        <td>{formatNumber(row.mt12) }</td>
                        <td>{formatNumber(row.mt13) }</td>
                        <td>{formatNumber(row.mt14) }</td>
                        <td>{formatNumber(row.mt15) }</td>
                        <td>{formatNumber(row.mt16) }</td>
                        <td>{formatNumber(row.cv_t) }</td>
                    </tr>
                ))}
                </tbody>
            );
        } else if ((selectedReleve.index === '1' || selectedReleve.index === '2') && selectedEtat.index === '2') {
            return (
                <tbody>
                {data2.map((row, index) => (
                    <tr key={index}>
                        <td>{row.designation}</td>
                        <td>{row.code_nop}</td>
                        <td>{formatNumber(row.cv1) }</td>
                        <td>{formatNumber(row.cv2) }</td>
                        <td>{formatNumber(row.cv3) }</td>
                        <td>{formatNumber(row.cv4) }</td>
                        <td>{formatNumber(row.cv5) }</td>
                        <td>{formatNumber(row.cv6) }</td>
                        <td>{formatNumber(row.cv7) }</td>
                        <td>{formatNumber(row.cv8) }</td>
                        <td>{formatNumber(row.cv9) }</td>
                        <td>{formatNumber(row.cv10) }</td>
                        <td>{formatNumber(row.cv11) }</td>
                        <td>{formatNumber(row.cv12) }</td>
                        <td>{formatNumber(row.cv13) }</td>
                        <td>{formatNumber(row.cv14) }</td>
                        <td>{formatNumber(row.cv15) }</td>
                        <td>{formatNumber(row.cv16) }</td>
                        <td>{formatNumber(row.cv_t) }</td>
                    </tr>
                ))}
                </tbody>
            );
        } else if (selectedReleve.index === '3') {
            return (
                <tbody>
                {data2.map((row, index) => (
                    <tr key={index}>
                        <td>{row.banque}</td>
                        <td>{row.nom_devise}</td>
                        <td>{formatNumber(row.mt) }</td>
                        <td>{formatNumber(row.cv) }</td>
                    </tr>
                ))}
                </tbody>
            );
        } else if (selectedReleve.index === '4') {
            return (
                <tbody>
                {data2.map((row, index) => (
                    <tr key={index}>
                        <td>{row.nom_devise}</td>
                        <td>{formatNumber(row.mt_ac) }</td>
                        <td>{formatNumber(row.cv_ac) }</td>
                        <td>{formatNumber(row.mt_vc) }</td>
                        <td>{formatNumber(row.cv_v) }</td>
                    </tr>
                ))}
                </tbody>
            );
        } else {
            return null; // Return null for cases when no data should be displayed
        }
    };
    return (
        <div>
            {/* Agency information */}
            <Box sx={{
                borderBottom: '1px solid black',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%'
            }}>
                <h5 className="h5">{agence.nom_bureau}</h5>
                <h5 className="h5">{agence.num_auto}</h5>
            </Box>
            {/* Header */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '70%',
                    marginLeft: '15%',
                    marginY: '1vh',

                }}
            >
                <h5 className="h5">Instruction Générale des Opérations de Change du 3 Janvier 2022</h5>
            </Box>
            {/* Header */}
            {/* Header */}
            <Box
                sx={{
                    border: '1px solid black',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '90%',
                    marginLeft: '5%',
                    marginBottom: '4vh',
                    borderRadius: '8px',
                    backgroundColor: '#ea5a27', // Change the background color here
                }}
            >
                {selectedReleve.index === '1' ? <><h3 className="h3">RELEVE MENSUEL RECAPITULATIF DES ACHATS DE
                    BILLETS</h3>
                    <h3 className="h3">DE BANQUE ETRANGERS ET DE CHEQUE DE VOYAGE</h3></> : null}
                {selectedReleve.index === '2' ? <><h3 className="h3">RELEVE MENSUEL RECAPITULATIF DES VENTES </h3>
                    <h3 className="h3">DE BILLETS DE BANQUE ETRANGERS ET DE CHEQUE DE VOYAGE</h3></> : null}
                {selectedReleve.index === '3' ? <><h3 className="h3">OPERATIONS SUR BILLETS DE BANQUE ETRANGERS
                    RELEVE </h3>
                    <h3 className="h3">MENSUEL DES VENTES AUX ETABLISSEMENTS DE CREDIT</h3></> : null}
                {selectedReleve.index === '4' ? <><h3 className="h3">ETAT RECAPITULATIF DES OPERATIONS SUR BILLETS </h3>
                    <h3 className="h3">DE BANQUE ETRANGERS</h3></> : null}

            </Box>
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <Box sx={{marginRight: '5vw'}}>
                    <h5 className="h5">Mois : {mois}</h5>
                </Box>
                <Box sx={{}}>
                    <h5 className="h5">Année : {annee}</h5>
                </Box>
            </Box>

            {/* Currency exchange data */}
            <Box sx={{width: '100%'}}>
                <table className="print-table">
                    {renderTableRows()}
                    {renderTableBody()}
                </table>


            </Box>

            {/* Additional CSS styles for printing */}
            <style>
                {`
            .print-table {
              width: 100%;
              border-collapse: collapse;
            }

            .print-table th,
            .print-table td {
              border: 1px solid black;
              padding: 8px;
              font-size:10px;
            }

            /* Additional styles specific for printing */
            @media print {
              .print-table {
                page-break-inside: auto;
              }
              .footer {
                position: fixed;
                bottom: 0;
                border-top: 2px solid #73AD21;
                align-items: center;
                width: 100vw;
                justify-content: center;
              }
              .print-table th,
              .print-table td {
                page-break-inside: avoid;
              }
              /* Ensure the footer appears on each page */
              .footer {
                page: footer;
              }
              .rotate-table {
                transform: rotate(90deg);
                transform-origin: left top;
                white-space: nowrap;
                position: absolute;
                right: 0;
              }
            }
          `}
            </style>


            {/* Footer */}
            <Box className="footer" sx={{paddingTop: '1vh'}}>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <h5 className="h5">Adresse : {agence.adresse}</h5>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Box sx={{marginRight: '3vh'}}>
                        <h5 className="h5">Tél : {agence.tel}</h5>
                    </Box>
                    <Box>
                        <h5 className="h5">Email : {agence.email}</h5>
                    </Box>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Box sx={{marginRight: '3vh'}}>
                        <h5 className="h5">TP : {agence.tp}</h5>
                    </Box>
                    <Box sx={{marginRight: '3vh'}}>
                        <h5 className="h5">IF : {agence.idfis}</h5>
                    </Box>
                    <Box>
                        <h5 className="h5">RC : {agence.rc}</h5>
                    </Box>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <h5 className="h5">ICE : {agence.ice}</h5>
                </Box>
            </Box>
        </div>
    );
}

export default Index;