import { Box } from "@mui/material";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import {Button} from "@mui/material";
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import axios from "axios";
import {useEffect, useState,useRef} from 'react';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import { useReactToPrint } from "react-to-print";
import TableG from "../../../table";
import {formatNumber} from "../../../fonctions/formatNumber";
import PDFJournalOperations from '../PDFContent/JournalOperations/index'
class PDFContent extends React.Component {
    render() {
        const { data, data2,data3, agence } = this.props;

        return (
            <PDFJournalOperations data={data} data2={data2} data3={data3} agence={agence}/>
        );
    }
}
const RapportJ = () => {
    const today = new Date();


    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedDevise, setSelectedDevise] = useState(null);
    const [selectedDuDate, setSelectedDuDate] = useState(dayjs());
    const [selectedAuDate, setSelectedAuDate] = useState(dayjs());
    const [selectedEtat, setSelectedEtat] = useState("Détaillé");
    const [shouldPrint, setShouldPrint] = useState(false);
    const [dataToRender, setDataToRender] = useState([]);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [user,setuser]=useState([]);
    const [agence,setAgence]=useState({});
    const componentRef = useRef();
    const [selectedRow, setSelectedRow] = useState(null);
    const [info, setInfo] = useState({
        du:'',
        au:'',
        utilisateur:'',
        devise:'',
        etat:'',
        user:'',
    });
    const [bool,setBool]=useState(true);
    /****************************************************************functions************************************************** */
    const handlePrintev = (e) => {
        e.preventDefault();
        axios.get(`api/info-agence`).then((res) => {
            if (res.data.status === 200) {
                setAgence(res.data.message);
                setShouldPrint(true);
            }
        });

        // Add checks to ensure variables are defined
        const du = convertDateFormat(selectedDuDate);
        const au = convertDateFormat(selectedAuDate);
        const utilisateur = selectedUser ? selectedUser.name : '--all--';
        const devise = selectedDevise ? selectedDevise : '--all--';
        const etat = selectedEtat ? selectedEtat : '';

        setInfo((prevInfo) => ({
            ...prevInfo,
            du: du,
            au: au,
            utilisateur: utilisateur,
            devise: devise,
            etat: etat,
            user:user,
        }));
    };
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: `
    @page {
      size: A4;
      margin: 10mm;
    }
    /* Additional styles for the printed page can be added here */
    /* Remove the pagination and URL styles */
    @media print {
      .MuiDataGrid-footerContainer {
        display: none !important;
      }
      a {
        display: none !important;
      }
    }
  `,
        header: null, // Set header to null to remove it from the PDF
        footer: null,
        // Set footer to null to remove it from the PDF
    });
    const handleRowClick = (row) => {
        setSelectedRow(row);
    };
    useEffect(() => {
        if (shouldPrint) {
            // Call handlePrint() when shouldPrint becomes true
            handlePrint(); // This will trigger the printing action
            setShouldPrint(false); // Reset shouldPrint back to false
        }
    }, [shouldPrint]);
    /*************************************************************end functions********************************************** */
    /********************************************************************************UseEffect********************************** */

    useEffect(() => {
        const auDate=convertDateFormat2(selectedAuDate);
        const duDate=convertDateFormat2(selectedDuDate);
        console.log(auDate);
        console.log(duDate);

        const fetchData = async () => {
            try {
                const response = await axios.get(`api/journal-operations?du=${duDate}&au=${auDate}`);
                if (response.data.status === 200) {
                    const { message } = response.data;
                    console.log(message);
                    setDataToRender(message);
                    setBool(false);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();

    }, [bool]);
    const [rrows,setRrows]=useState(1);
    const [devise2, setDevise2] = useState([]);

    useEffect(() => {
        const result = dataToRender.reduce((acc, item) => {
            const { date_achat, nom_devise, mt_ac, cv_ac } = item;
            const key = `${date_achat}-${nom_devise}`;

            if (acc[key]) {
                acc[key].cv_ac_sum += parseFloat(cv_ac);
                acc[key].mt_ac_sum += parseFloat(mt_ac);
            } else {
                acc[key] = {
                    date_achat,
                    nom_devise,
                    mt_ac_sum: parseFloat(mt_ac),
                    cv_ac_sum: parseFloat(cv_ac),
                };
            }

            return acc;
        }, {});

        const formattedResult = Object.values(result);

        // Update the state with the processed data
        setDevise2(formattedResult);
    }, [dataToRender]);

    /****************************************************************************end UseEffect********************************** */

    const renderTableRows = () => {
        return (
            <TableRow>
                <TableCell sx={{backgroundColor:"#a4a9fc"}}>Devise</TableCell>
                <TableCell sx={{backgroundColor:"#a4a9fc"}}>Achat</TableCell>
                <TableCell align='center' sx={{backgroundColor:"#a4a9fc"}}>Contre Valeur Achat</TableCell>

                <TableCell align='center' sx={{backgroundColor:"#a4a9fc"}}>Vente</TableCell>
                <TableCell align='center' sx={{backgroundColor:"#a4a9fc"}}>Contre Valeur Vente</TableCell>

                <TableCell align='center' sx={{backgroundColor:"#a4a9fc"}}>Cession</TableCell>
                <TableCell align='center' sx={{backgroundColor:"#a4a9fc"}}>Contre Valeur Cession</TableCell>


            </TableRow>
        );

    }


    const renderTableBody = () => {
        return (

            <TableBody>
                {dataToRender.map((row) => (
                    <TableRow  onClick={() => handleRowClick(row)}
                               sx={{
                                   backgroundColor:
                                       selectedRow && selectedRow.devise === row.devise ? '#ccc' : 'inherit',
                               }} >
                        <TableCell>{row.devise}</TableCell>
                        <TableCell align='right'>{formatNumber(row.mt_ac)}</TableCell>
                        <TableCell align='right'>{formatNumber(row.cv_ac)}</TableCell>
                        <TableCell align='right'>{formatNumber(row.mt_vc)}</TableCell>
                        <TableCell align='right'>{formatNumber(row.cv_vc)}</TableCell>
                        <TableCell align='right'>{formatNumber(row.mt_vb)}</TableCell>
                        <TableCell align='right'>{formatNumber(row.cv_vb)}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        );
    };
    const renderInputs = () => {
        return (
            <>

                <Box display="flex" justifyContent="space-between" width="100%"
                     sx={{
                         "& .MuiFormControl-root" : {
                             marginRight:"1rem",
                             width:"5rem",

                         },
                         "& .MuiAutocomplete-root":{
                             marginTop:"8px"
                         },
                         "& .csss":{
                             minWidth:'18vw',
                         },
                         "& .buttons" : {
                             color: colors.grey[200],
                             backgroundColor : colors.primary[400],
                             margin: "0.5rem 0.5rem -0.2rem 0.5rem",
                             fontSize: "0.7rem",
                             height: "2.5rem",
                             paddingX: "1.2rem",
                             paddingY: "0.2rem"
                         },
                         "& .buttons:hover" : {
                             color: colors.primary[400],
                             backgroundColor : colors.grey[200],
                         },
                         "& .span1" : {


                             marginLeft: "0.5rem",

                         }
                     }}
                >
                    <Box display="flex" width="100%">

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker
                                    label="DU"
                                    value={selectedDuDate}
                                    onChange={handleDuDateChange}
                                    format="DD/MM/YYYY"
                                    className="csss"
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            padding: '10px 14px',
                                            fontSize: '0.875rem',
                                        },
                                    }}
                                />
                            </DemoContainer>
                        </LocalizationProvider>




                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker label="AU"
                                            value={selectedAuDate}
                                            onChange={handleAuDateChange}
                                            format="DD/MM/YYYY"
                                            className="csss"
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    padding: '10px 14px',
                                                    fontSize: '0.875rem',
                                                },
                                            }}
                                />
                            </DemoContainer>
                        </LocalizationProvider>

                    </Box>
                    <Box>       <Button className="buttons" onClick={handlePrintev}><FindInPageOutlinedIcon/><span className="span1">Aperçu</span> </Button>
                    </Box>

                </Box>  </>
        );
    };


    /*****************************************************filtre functions************************************************ */


//du date filtre
    const convertDateFormat = (dateString) => {

        const parsedDate = dayjs(dateString, 'MM/DD/YYYY'); // Parse the date with the original format
        return parsedDate.format('YYYY/MM/DD'); // Format the date in the desired format
    };
    const convertDateFormat2 = (dateString) => {
        const parsedDate = dayjs(dateString, 'DD/MM/YYYY'); // Parse the date with the original format
        return parsedDate.format('YYYY-MM-DD'); // Format the date in the desired format
    };

    const handleDuDateChange = (newValue) => {
        setSelectedDuDate(newValue);

        setBool(true);
    };

// au date functions

    const handleAuDateChange = (newValue) => {
        setSelectedAuDate(newValue);
        setBool(true);
    };


    /**********************************END FILTRE FUNCTIONS*************************************** */








    const colonnesEntete = [
        { id: 'devise', label: 'Devise', align: 'left' },
        { id: 'mt_ac', label: 'Achat', align: 'right' },
        { id: 'cv_ac', label: 'Contre valeur achat', align: 'right' },
        { id: 'mt_vc', label: 'Vente', align: 'right' },
        { id: 'cv_vc', label: 'Contre valeur vente', align: 'right' },
        { id: 'mt_vb', label: 'Cession', align: 'right' },
        { id: 'cv_vb', label: 'Contre valeur cession', align: 'right' }
    ];
    const colsToShow =  [0,1,2,3,4,5,6] ;
    return (
        <Box className="type2 container-table">

            {renderInputs()}
            <Box >
                <TableG dataToRender={dataToRender} headCells={colonnesEntete} colsToShow={colsToShow} cols={"num_bac"}/>

            </Box>

            <div style={{ display: 'none' }}>
                <PDFContent data={devise2} agence={agence} data2={dataToRender} data3={info} ref={componentRef} />
            </div>
        </Box>
    );
};

export default RapportJ;