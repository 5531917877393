import React from 'react';
import { Box } from "@mui/material";
import { formatNumber } from "../../../../fonctions/formatNumber";

function Index(props) {
    const { data, agence, data2, data3, sumCv } = props;
    const pageCount = 2;

    const today = new Date();
    const formatDate = (date) => `${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}/${date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1}/${date.getFullYear()}`;
    const formatTime = (date) => `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

    return (
        <div>
            {Array.from({ length: pageCount }).map((_, index) => (
                <div className='page' key={index}>
                    <Box sx={{ borderBottom: '1px solid black', justifyContent: 'center', alignItems: 'center', width: "100vw" }}>
                        <Box sx={{ alignItems: 'center', justifyContent: 'center', display: "flex" }}>
                            <h3 className="h3">BORDEREAU D'ACHAT DE BILLETS DE BANQUE ETRANGERS</h3>
                        </Box>
                        <Box sx={{ alignItems: 'center', justifyContent: 'center', display: "flex" }}>
                            <h3 className="h3">ET DE CHEQUE DE VOYAGE</h3>
                        </Box>
                        <Box sx={{ alignItems: 'center', justifyContent: 'center', display: "flex" }}>
                            <p className="p2">(Instruction Générale des Opérations de Change 3 Janvier 2022 - Articles 34 et 208)</p>
                        </Box>
                    </Box>

                    <Box sx={{ marginTop: "2vh" }}>
                        <Box sx={{ marginBottom: "1vh", display: "flex" }}>
                            <Box><h3 className="h3">INFORMATIONS POINT DE CHANGE</h3></Box>
                        </Box>
                        <Box sx={{ marginLeft: "5vw" }}><p className="p2">Dénomination : {agence.nom_bureau}</p></Box>
                        <Box sx={{ marginLeft: "5vw" }}><p className="p2">Identifiant : {agence.num_auto}</p></Box>
                    </Box>

                    <Box sx={{ marginTop: "2vh" }}>
                        <Box sx={{ marginBottom: "1vh", display: 'flex' }}>
                            <Box><h3 className="h3">REFERENCE BORDEREAU</h3></Box>
                            <Box sx={{ border: '1px solid black', padding:"1% 4%",marginLeft:'10%',marginTop:"-1%"}}><h3 className="h3">ACHAT</h3></Box>
                        </Box>
                        <Box sx={{ marginBottom: "1vh", display: 'flex' }}>
                            <Box sx={{ marginLeft: "5vw" }}><p className="p2">Numéro : {data2.num_bac}</p></Box>
                            <Box sx={{ marginLeft: "5vw" }}><p className="p2">Date : {formatDate(today)}</p></Box>
                            <Box sx={{ marginLeft: "5vw" }}><p className="p2">Heure : {formatTime(today)}</p></Box>
                        </Box>
                    </Box>

                    <Box sx={{ marginTop: "2vh" }}>
                        <Box sx={{ marginBottom: "1vh", display: 'flex' }}>
                            <Box><h3 className="h3">INFORMATIONS ET IDENTIFICATION CLIENT</h3></Box>
                        </Box>
                        <Box sx={{ marginBottom: "1vh" }}>
                            <Box sx={{ marginX: "5vw", display: "flex", justifyContent: "space-between" }}>
                                <Box sx={{ marginLeft: "5vw" }}><h4 className="h4">Personne physique</h4></Box>
                                <Box sx={{ marginLeft: "5vw" }}><h4 className="h4">Personne morale</h4></Box>
                            </Box>
                            <Box sx={{ marginLeft: "5vw" }}><p className="p2">Qualité du cédant : {data3}</p></Box>
                            <Box sx={{ marginLeft: "5vw" }}><p className="p2">Nom et Prénom ou Raison Sociale : {data2.prenom ? data2.prenom : ""} {data2.nom ? data2.nom : ""}</p></Box>
                            <Box sx={{ marginLeft: "5vw" }}><p className="p2">Adresse : {data2.adresse ? data2.adresse : ""}</p></Box>
                            {data3 === "ENR | Touristes étrangers" ? <Box sx={{ marginLeft: "5vw" }}><p className="p2">Numéro du passeport : {data2.n_pid}</p></Box>
                            :<Box sx={{ marginLeft: "5vw" }}><p className="p2">Numéro du passeport :</p></Box>}
                            {data3 === "MR | Marocains résidants" || data3 === "MRE | Marocains résidants à l'étranger" ?
                                <Box sx={{ marginLeft: "5vw" }}><p className="p2">Identifiant et N° d'identification : CNI {data2.n_pid}</p></Box> :
                                 data3 === "ER | Etrangers résidants" ? <Box sx={{ marginLeft: "5vw" }}><p className="p2">Identifiant et N° d'identification : CIM {data2.n_pid}</p></Box>:
                                <Box sx={{ marginLeft: "5vw" }}><p className="p2">Identifiant et N° d'identification : </p></Box>}
                            <Box sx={{ marginLeft: "5vw" }}><p className="p2">Nature de l'Opération : {data2.code_nop}</p></Box>
                            <Box sx={{ marginLeft: "5vw" }}><p className="p2">N° de la déclaration douanière d'importation : {data2.num_dec}</p></Box>
                        </Box>
                    </Box>

                    <Box sx={{ width: '90vw', marginLeft: "5vw", marginRight: "5vw" }}>
                        <table className="print-table">
                            <thead>
                            <tr>
                                <th>Dénomination des devises</th>
                                <th>Montant en devises</th>
                                <th>Cours appliqués</th>
                                <th>Contre Valeur en DHS</th>
                            </tr>
                            </thead>
                            <tbody>
                            {data.map((row) => (
                                <tr key={row.devise}>
                                    <td>{row.devise}</td>
                                    <td align="right">{formatNumber(row.montant)}</td>
                                    <td align="right">{parseFloat(row.coursAchat).toFixed(4)}</td>
                                    <td align="right">{formatNumber(row.contreValeur)}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        <Box sx={{ display: 'flex', marginRight: "5vw", alignItems: 'center', marginTop: "2vh", justifyContent: "end" }}>
                            <Box sx={{ borderBottom: '1px dotted black', display: 'flex', flexDirection: 'column', alignItems: 'center', width: "16vw", marginX: "2vw" }}><h3>Total</h3></Box>
                            <Box sx={{ borderBottom: '1px dotted black', display: 'flex', flexDirection: 'column', alignItems: 'center', width: "16vw", marginLeft: "2vw" }}><h3>{sumCv}</h3></Box>
                        </Box>

                        <style>
                            {`
                                .print-table {
                                    width: 100%;
                                    border-collapse: collapse;
                                }

                                .print-table th, .print-table td {
                                    border: 1px solid black;
                                    padding: 8px;
                                }

                                @media print {
                                    .page {
                                        page-break-inside: avoid;
                                    }
                                    .print-table {
                                        page-break-inside: auto;
                                    }
                                    .footer {
                                        position: fixed;
                                        bottom: 0;
                                        border-top: 2px solid #73AD21;
                                        align-items: center;
                                        width: 100vw;
                                        justify-content: center;
                                    }
                                    .print-table th, .print-table td {
                                        page-break-inside: avoid;
                                    }
                                }
                                .p2{
                                  font-size:12px;
                                  color:black;
                                }
                            `}
                        </style>

                        <Box sx={{ marginY: "2vh", display: "flex", justifyContent: "space-between" ,".p": {
                                fontSize: '16px',
                                color: 'black',
                            }}}>
                            <Box sx={{ marginLeft: "6vw" }}><p className="p" >Signature du client</p></Box>
                            <Box sx={{ marginLeft: "6vw" }}><p className="p">Cachet et signature du point de change</p></Box>
                        </Box>
                    </Box>
                    <Box className="footer" sx={{ paddingTop: '1vh' }}>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}><p className="p2">Adresse : {agence.adresse}</p></Box>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Box sx={{ marginRight: "3vh" }}><p className="p2">Tél : {agence.tel}</p></Box>
                            <Box><p className="p2">Email : {agence.email}</p></Box>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Box sx={{ marginRight: "3vh" }}><p className="p2">TP : {agence.tp}</p></Box>
                            <Box sx={{ marginRight: "3vh" }}><p className="p2">IF : {agence.idfis}</p></Box>
                            <Box sx={{ marginRight: "3vh" }}><p className="p2">RC : {agence.rc}</p></Box>
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}><p className="p2">ICE : {agence.ice}</p></Box>
                        </Box>
                    </Box>
                </div>
            ))}
        </div>
    );
}

export default Index;
