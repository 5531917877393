import * as React from 'react';
import { Box, Typography, useTheme } from "@mui/material";

import { tokens } from "../../theme";
import Header from "../../components/Header";

import {Tabs,Tab,AppBar} from "@mui/material";
import Achat from "../../components/VenteClients/VenteClient/index";
import Consultation from "../../components/VenteClients/ConsultationVentes/index";
import Bordereauxerrones from "../../components/VenteClients/BordereauxErrones/index";
import Statistiques from "../../components/VenteClients/Statistiques/index";
import "../index.css";

const Ventclients = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  
  const [value,setValue]=React.useState(0);
  const handleTabs=(r,val)=>{
    console.warn(val)
    setValue(val)
  }
  return (
    <Box  className="gestion-devise-container">
      <Header title="VENTES CLIENTS"  />
      <Box >
        <AppBar position='static' backgroundColor="#fffff" >
          <Tabs value={value} onChange={handleTabs} >
            <Tab label="Vente" />
            <Tab label="Consultation"/>
            <Tab label="Bordereaux erronés"/>
            <Tab label="Statistiques"/>
          </Tabs>
        </AppBar>
        
      </Box>
        
      <Box className="gestion-devise-content">
         
      <TabPanel value={value} index={0}><Achat/></TabPanel>
      <TabPanel value={value} index={1}><Consultation/></TabPanel>
      <TabPanel value={value} index={2}><Bordereauxerrones/></TabPanel>
      <TabPanel value={value} index={3}><Statistiques/></TabPanel>
     
      </Box>
      
    </Box>
  );
  
};
const TabPanel = (props) => {
  const {children,value,index}=props;
  return (
    
      value===index && (
        <div>{children}</div>
      )
      
    
  );
};
export default Ventclients;
