import React, { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import axios from "axios";
import Main from "./scenes/main";

function AdminPrivateRoute({ verification, user, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ props, location }) =>
        parseFloat(verification) === 200 && (parseFloat(user) === 1|| parseFloat(user) === 0)? (
          <Main {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: location } }} />
        )
      }
    />
  );
}

export default AdminPrivateRoute;
