import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { useEffect,useState } from "react";
import axios from "axios";
import "../index.css";
import Logo from './../../data/logo.png'
const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [agence, setAgence] = useState({
    nom_bureau:'',
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`api/info-agence`);
        if (response.data.status === 200) {
          setAgence(response.data.message);
          setLoading(false);

        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  if (loading) {
    return <div ><div style={{marginLeft:'20vw',marginTop:"25vh",width:"60vw",height:"50vh"}}>
                <img style={{width:"50vw",height:"40vh",marginLeft:'5vw',marginTop:"5vh"}} src={Logo}/>
      
    </div></div>;
  }
  return (
    <Box className="type1">
      <Box  sx={{justifyContent:'space-between',display:'flex',    backgroundColor: "#ebeef0",paddingX:"3%",paddingY:"0.5vh",marginBottom:"4vh" }}>
        <Box><h1 style={{color:"#df5a2a",fontSize:'1.8vw'}}>Société: <span style={{color:"#057090",fontWeight: "bold",fontSize:'3vw'}}>{agence.nom_bureau}</span></h1></Box>
        <Box><h1 style={{color:"#df5a2a",fontSize:'1.8vw'}}>Autorisation N°: <span style={{color:"#057090",fontWeight: "bold",fontSize:'3vw'}}>{agence.num_auto}</span></h1></Box>

      </Box>
      <Box sx={{width:"90%",marginLeft:'5%',height:'60vh'}}>
        <img style={{width:"100%",height:"100%"}} src="./../../logo.png"/>
      </Box>
    </Box>
  );
};

export default Dashboard;
