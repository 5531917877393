import React from 'react';
import {Box} from "@mui/material";
import {formatNumber} from "../../../../fonctions/formatNumber";

function Index(props) {
    const { data, data2,data3, agence } = props;
    const renderTableRows = () => {
        return (
            <tr>
                <td >Date</td>
                <td >N° bordereau</td>
                <td >Client</td>

                <td >Pièce d'identité</td>
                <td > Devise</td>

                <td >Dotation</td>
                <td >Achat</td>

                <td >Vente</td>
                <td >Cours Appliqué</td>
                <td >Contre valeur en Dhs</td>

            </tr>
        );

    }


    const renderTableBody = () => {
        return (

            <>
                {data2.map((row) => (
                    <tr  >
                        <td>{row.date_achat}</td>
                        <td>{row.num_bac}</td>
                        <td>{row.qualite_cl}</td>
                        <td>{row.type_pid?row.type_pid:''} {row.n_pid?row.n_pid:''}</td>
                        <td>{row.nom_devise}</td>
                        <td>{row.dotation}</td>
                        <td align='right'>{formatNumber(row.mt_ac?row.mt_ac:'00')}</td>
                        <td align='right'>{formatNumber(row.mt_vc?row.mt_vc:'00')}</td>

                        <td align='right'>{parseFloat(row.cours_ac).toFixed(4)}</td>
                        <td align='right'>{formatNumber(row.cv_ac)}</td>


                    </tr>
                ))}
            </>
        );
    };
    return (
        <div>
            {/* Agency information */}
            <Box sx={{
                borderBottom: '1px solid black',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%'
            }}>
                <h5 className="h5">{agence.nom_bureau}</h5>
                <h5 className="h5">{agence.num_auto}</h5>
            </Box>
            {/* Header */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '70%',
                    marginLeft: '15%',
                    marginY: '1vh',

                }}
            >
                <h5 className="h5">Instruction Générale des Opérations de Change du 3 Janvier 2022</h5>
            </Box>
            {/* Header */}
            <Box
                sx={{
                    border: '1px solid black',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '70%',
                    marginLeft: '15%',
                    marginBottom: '4vh',
                    borderRadius: '8px',
                    backgroundColor: '#ea5a27', // Change the background color here
                }}
            >
                <h2 className="h2">JOURNAL DES OPERATIONS DE CHANGE</h2>
                <h2 className="h2">TRAITEES AVEC LA CLIENTELE</h2>
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <Box sx={{marginRight: '5vw'}}>
                    <h5 className="h5">Du : {data3.du}</h5>
                </Box>
                <Box sx={{}}>
                    <h5 className="h5">Au : {data3.au}</h5>
                </Box>
            </Box>


            {/* Currency exchange data */}
            <Box sx={{width: '100vw'}}>
                <table className="print-table">
                    {renderTableRows()}
                    {renderTableBody()}
                </table>


            </Box>

            {/* Additional CSS styles for printing */}
            <style>
                {`
            .print-table {
              width: 100%;
              border-collapse: collapse;
            }

            .print-table th,
            .print-table td {
              border: 1px solid black;
              padding: 8px;
              font-size: 12px;
            }
            .footer {
              margin-top: 4vh;
              border: 2px dotted #73AD21;
              align-items: center;
              width: 100%;
              justify-content: center;
              z-index: 9;
            }
            /* Additional styles specific for printing */
            @media print {
              .print-table {
                page-break-inside: auto;
              }
              
              .print-table th,
              .print-table td {
                page-break-inside: avoid;
              }
              /* Ensure the footer appears on each page */
              .footer {
                page-break-inside: avoid;
                z-index: 9;
              }
            }
          `}
            </style>


            {/* Footer */}
            <Box className="footer" sx={{paddingTop: '1vh'}}>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <h5 className="h5">Adresse : {agence.adresse}</h5>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Box sx={{marginRight: '3vh'}}>
                        <h5 className="h5">Tél : {agence.tel}</h5>
                    </Box>
                    <Box>
                        <h5 className="h5">Email : {agence.email}</h5>
                    </Box>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Box sx={{marginRight: '3vh'}}>
                        <h5 className="h5">TP : {agence.tp}</h5>
                    </Box>
                    <Box sx={{marginRight: '3vh'}}>
                        <h5 className="h5">IF : {agence.idfis}</h5>
                    </Box>
                    <Box>
                        <h5 className="h5">RC : {agence.rc}</h5>
                    </Box>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <h5 className="h5">ICE : {agence.ice}</h5>
                </Box>
            </Box>
        </div>

    );
}

export default Index;