import React from "react";
import axios from "axios";
import "../../assets/css/util.css";
import "../../assets/css/main.css";
import "../../assets/fonts/font-awesome-4.7.0/css/font-awesome.min.css";
import "../../assets/fonts/iconic/css/material-design-iconic-font.min.css";
import { useState } from 'react';
import { useHistory } from "react-router-dom";
import swal from "sweetalert";

const Login = () => {
    const [loginInput, setLogin] = useState({
        email2: "",
        password2: "",
    });
    const history = useHistory();

    const handleInput = (e) => {
        e.persist();
        setLogin({ ...loginInput, [e.target.name]: e.target.value });
    }

    const loginSubmit = (e) => {
        e.preventDefault();
        const data = {
            email: loginInput.email2,
            password: loginInput.password2,
        }
        axios.get('/sanctum/csrf-cookie').then(response => {
            axios.post('/api/login', data).then(res => {
                if (res.data.status === 200) {
                    localStorage.setItem("auth_token", res.data.token);
                    history.push('/admin');
                    window.location.reload();
                } else if (res.data.status === 202) {
                    localStorage.setItem("auth_token", res.data.token);
                    localStorage.setItem("auth_email", res.data.email);
                    history.push('/super_admin');
                    window.location.reload();
                } else if (res.data.status === 401) {
                    swal('', "Mot de passe ou utilisateur ID incorrect.", 'warning');
                } else {
                    setLogin({ ...loginInput, error_list: res.data.validationerror });
                }
            });
        });
    }

    return (
        <>
            <div className="limiter">
                <div className="container-login100" style={{ backgroundImage: 'url("./bg-01.jpg")' }}>
                    <div className="wrap-login100">
                        <form onSubmit={loginSubmit} className="login100-form validate-form">
                            <span className="login100-form-logo">
                                <img src="./logo.png" width="220px" height="160px" style={{ marginLeft: '-20px' }} alt="Logo" />
                            </span>

                            <span className="login100-form-title p-b-34 p-t-27">
                                IDENTIFICATION
                            </span>

                            <div className="wrap-input100 validate-input" data-validate="Enter username">
                                <input className="input100" type="text" name="email2" onChange={handleInput} value={loginInput.email2} placeholder="Utilisateur" />
                                <span className="focus-input100" data-placeholder="&#xf207;"></span>
                            </div>

                            <div className="wrap-input100 validate-input" data-validate="Enter password">
                                <input className="input100" type="password" name="password2" onChange={handleInput} value={loginInput.password2} placeholder="Mot de passe" />
                                <span className="focus-input100" data-placeholder="&#xf191;"></span>
                            </div>

                            <div className="container-login100-form-btn">
                                <input type="submit" value=" Connexion" className="login100-form-btn" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <footer style={{
                zIndex: 100,
                textAlign: 'center',
                padding: '10px',
                position: 'absolute',
                bottom: '0',
                width: '100%',
                marginTop: '20px' // Adds spacing between the form and the footer
            }}>
                <p>Copyright © 2024 by MagChange.</p>
            </footer>
        </>
    );
}

export default Login;
