import { Box } from "@mui/material";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import {Button} from "@mui/material";
import TextField from '@mui/material/TextField';
import * as React from 'react';
import {useEffect, useState} from 'react';
import swal from 'sweetalert';
import axios from "axios";
import Autocomplete from '@mui/material/Autocomplete';

const Statistiques = () => {
    const [info,setInfo]=useState({
        achat: '',
        vente: '',
        banque: '',
        NbrdAc: '',
        NbrdVc: '',
        NbrdVb: '',
        fifo:'',
    });
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [page, setPage] =useState(0);
    const [rowsPerPage, setRowsPerPage] =useState(10);
    const [bool,setBool]=useState(1);
    const [selectedRow, setSelectedRow] = useState(null);
    const [textvalue, setTextvalue] = useState('');
    const [Banques, setBanques] = useState([]);
    const [Qualites, setQualites] = useState([]);

    useEffect(() => {
        if(bool===1){
            const fetchData = async () => {
                try {

                    const response = await axios.get(`api/options`);
                    setInfo(response.data.message);
                    const qts=(response.data.Qualites);
                    const Qs=qts.map((op)=>op.designation);
                    setQualites(Qs);
                    const bq=(response.data.banques);

                    const Bqs=bq.map((op)=>op.banque);
                    setBanques(Bqs);

                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            };

            fetchData();
            setBool(0);
        }
    }, [bool]);
    //backend

    const handleInput = (e) => {
        const { name, value } = e.target;
        setInfo((previnfo) => ({
            ...previnfo,
            [name]: value,
        }));
    };

    const submit=(e)=>{
        e.preventDefault();

        swal({
            title: '',
            text: 'Voulez vous vraiment valider cette opération ?',
            icon: 'warning',
            buttons: ['Annuler', 'Oui'],
            dangerMode: true,
        }).then((confirmed) => {
            if (confirmed) {
                if(info.motdpasse===info.confirme){
                    axios
                        .post('api/modifier-options', info)
                        .then((res) => {
                            // ... Handle the response from the server ...
                            if (res.data.status === 200) {
                                setBool(1);
                            }
                        })
                        .catch((error) => {
                            // Handle any error that occurred during the request
                            console.error('Error submitting form:', error);
                        });
                }else{
                    swal(
                        '',
                        "Les mots de passe saisis ne correspandent pas.",
                        'warning'
                    );
                }
            }
        });




    }



    return (
        <Box  sx={{
            paddingY:"4vh", width:"60%",marginLeft:"20%",
            "& .bartitle":{
                marginY : "1.5rem",
            }
        }}>
            <Box sx={{marginBottom:"2vh"}}>
                <Box >
                    <h2>Achat :</h2>
                </Box>
                <Box sx={{ width:"100%",paddingX:"4vw",paddingY:"2vh",backgroundColor:"#a4a9fc"}}>
                    <Autocomplete
                        sx={{width:'100%'}}
                        id="combo-box-demo"
                        size="small"
                        value={info.achat}
                        options={Qualites}
                        onChange={(event, newValue) => {
                            setInfo((previnfo) => ({
                                ...previnfo,
                                achat: newValue,
                            }));
                        }}
                        renderInput={(params) => <TextField {...params} label="Qualité Client" />}
                        disableClearable={true}

                    />
                </Box>
            </Box>
            <Box sx={{marginBottom:"2vh"}}>
                <Box >
                    <h2>Vente Client :</h2>
                </Box>
                <Box sx={{ width:"100%",paddingX:"4vw",paddingY:"2vh",backgroundColor:"#a4a9fc"}}>
                    <Autocomplete
                        sx={{width:'100%'}}
                        id="combo-box-demo"
                        size="small"
                        value={info.vente}
                        options={Qualites}
                        onChange={(event, newValue) => {
                            setInfo((previnfo) => ({
                                ...previnfo,
                                vente: newValue,
                            }));
                        }}
                        renderInput={(params) => <TextField {...params} label="Qualité Client" />}
                        disableClearable={true}

                    />
                </Box>
            </Box>
            <Box sx={{marginBottom:"2vh"}}>
                <Box >
                    <h2>Achat Banque:</h2>
                </Box>
                <Box sx={{ width:"100%",paddingX:"4vw",paddingY:"2vh",backgroundColor:"#a4a9fc"}}>
                    <Autocomplete
                        sx={{width:'100%'}}
                        id="combo-box-demo"
                        size="small"
                        value={info.banque}
                        options={Banques}
                        onChange={(event, newValue) => {
                            setInfo((previnfo) => ({
                                ...previnfo,
                                banque: newValue,
                            }));
                        }}
                        renderInput={(params) => <TextField {...params} label="Banque" />}
                        disableClearable={true}

                    />
                </Box>
            </Box>
            <Box sx={{marginBottom:"2vh"}}>
                <Box >
                    <h2>Méthode de Vente :</h2>
                </Box>
                <Box sx={{ width:"100%",paddingX:"4vw",paddingY:"2vh",backgroundColor:"#a4a9fc"}}>
                    <Autocomplete
                        sx={{ width: '100%' }}
                        id="combo-box-demo"
                        size="small"
                        value={info.fifo === "true" ? "FIFO" : "Cours achat trier décroissants"}
                        options={["Cours achat trier décroissants", "FIFO"]}
                        onChange={(event, newValue) => {
                            setInfo((previnfo) => ({
                                ...previnfo,
                                fifo: newValue=== "FIFO" ? "true" : "false",
                            }));
                        }}
                        renderInput={(params) => <TextField {...params} label="Méthode" />}
                        disableClearable={true}

                    />

                </Box>
            </Box>
            <Box sx={{marginBottom:"4vh"}}>
                <Box >
                    <h2>Les numéros de départ de bordereaux :</h2>
                </Box>
                <Box sx={{ width:"100%",paddingX:"5%",paddingY:"2%",backgroundColor:"#a4a9fc",display:'flex'}}>
                    <Box sx={{marginY:'4vh',marginLeft:'3%',width:'28%'}}><TextField size="small" name="NbrdAc" type="number" value={info.NbrdAc} onChange={handleInput}  sx={{width:'100%'}}  label="Achat" variant="outlined" /></Box>
                    <Box sx={{marginY:'4vh',marginLeft:'3%',width:'28%'}}><TextField size="small" name="NbrdVc" type="number" value={info.NbrdVc} onChange={handleInput}  sx={{width:'100%'}}  label="Vente" variant="outlined" /></Box>
                    <Box sx={{marginY:'4vh',marginLeft:'3%',width:'28%'}}><TextField size="small" name="NbrdVb" type="number" value={info.NbrdVb} onChange={handleInput}  sx={{width:'100%'}}  label="Cession" variant="outlined" /></Box>

                </Box>
            </Box>
            <Box display="flex" justifyContent="center"
                 sx={{
                     "& .MuiFormControl-root" : {
                         marginRight:"1rem",
                         width:"11rem",

                     },
                     "& .MuiAutocomplete-root":{
                         marginTop:"8px"
                     },
                     "& .buttons" : {
                         color: colors.grey[200],
                         backgroundColor : colors.primary[800],
                         margin: "0.5rem",
                         fontSize: "0.8rem",
                         height: "3.4rem" ,
                         paddingX:"1.5rem"
                     },
                     "& .buttons:hover" : {
                         color: colors.primary[400],
                         backgroundColor : colors.grey[200],

                         margin: "0.5rem",
                         fontSize: "0.9rem"
                     },
                     "& .span1" : {


                         marginLeft: "0.5rem",

                     },

                 }}
            >
                <Box display="flex"><Button className="buttons" onClick={submit}><span className="span1">Enregistrer</span> </Button></Box>
            </Box>
        </Box>
    );
};

export default Statistiques;
