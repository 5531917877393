import React from 'react';
import {Box} from "@mui/material";
import {formatNumber} from "../../../../fonctions/formatNumber";

function Index(props) {
    const { data,agence,data2,cours } = props;
    const sumcontrevaleur = () => {
        let sum = 0;
        data.map((op) => {
            sum += op.contreValeur;
        });
        return sum;
    };
    const sumCommission2 = () => {
        let sum = 0;
        data.map((op2) => {
            sum+=sumCommission(op2.devise);
            return sum;
        })
        return sum;
    };
    const sumCommission = (devise) => {
        let sum = 0;
        let courV;
        let prix_par;
        data.map((op2) => {
            if(devise==op2.devise){
                courV=op2.coursVente;
                prix_par=op2.prix_par;
            }
        });
        cours.map((op2) => {
            if(devise==op2.devise){
                sum+=((op2.montant)*(courV)-(op2.montant)*(op2.cours_ac))/prix_par;

            }
        })
        return sum;
    };
    const pageCount = 2;
    return (
        <div>

            <Box sx={{
                borderBottom: '1px solid black',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: "100vw"
            }}>
                <h5 className="h5">{agence.nom_bureau}</h5>
                <h5 className="h5">{agence.num_auto}</h5>
            </Box>
            <Box
                sx={{
                    border: '1px solid black',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '50vw',
                    marginLeft: '25vw',
                    marginY: '4vh',
                    borderRadius: '8px',
                    backgroundColor: '#ea5a27', // Change the background color here
                }}
            >
                <h2 className="h2">ETAT DE VENTE BANQUE</h2>
            </Box>
            <Box sx={{width: '80vw', marginBottom: '2vh', marginLeft: "7.5vw", marginRight: "7.5vw"}}>
                <h5 className="h5">Date Vente : {data2.date} </h5>
                <h5 className="h5">N° Vente : {data2.num}</h5>
                <h5 className="h5">Banque: {data2.banque}</h5>
            </Box>
            <Box sx={{width: '90vw', marginLeft: "5vw", marginRight: "5vw"}}>
                <table className="print-table">
                    <tr>
                        <th>Devise</th>
                        <th>Montant en devise</th>
                        <th>Cours Vente</th>
                        <th>Contre valeur en DHS</th>
                        <th>Commission</th>
                    </tr>
                    {data.map((row) => (
                        <tr key={row.devise}> {/* Assuming each row has a unique identifier (e.g., 'id') */}
                            <td>{row.devise}</td>
                            <td align="right">{formatNumber(row.montant)}</td>
                            <td align="right">{parseFloat(row.coursVente).toFixed(4)}</td>
                            <td align="right">{formatNumber(row.contreValeur)}</td>
                            <td align="right">{formatNumber(sumCommission(row.devise))}</td>
                        </tr>
                    ))}
                </table>
                <Box sx={{
                    display: 'flex',
                    marginLeft: "30vw",
                    width: "65vw",
                    marginRight: "5vw",
                    alignItems: 'center',
                    marginTop: "2vh"
                }}>
                    <Box sx={{
                        borderBottom: '1px dotted black',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: "16vw",
                        marginX: "2vw"
                    }}><h3>Total</h3></Box><Box sx={{
                    borderBottom: '1px dotted black',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: "16vw",
                    marginX: "2vw"
                }}><h3>{formatNumber(sumcontrevaleur())}</h3></Box><Box sx={{
                    borderBottom: '1px dotted black',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: "16vw",
                    marginX: "2vw"
                }}><h3>{formatNumber(sumCommission2())}</h3></Box>

                </Box>

                {/* Additional CSS styles for printing */}
                <style>
                    {`
          .print-table {
            width: 100%;
            border-collapse: collapse;
          }

          .print-table th, .print-table td {
            border: 1px solid black;
            padding: 8px;
          }

          /* Additional styles specific for printing */
          @media print {
            .print-table {
              page-break-inside: auto;
            }
            .footer{
              position: fixed;
              bottom: 0;
              border-top: 2px solid #73AD21;
              align-items: center;
              width:100vw;
              justify-content:center;
            }
            .print-table th, .print-table td {
              page-break-inside: avoid;
            }
          }
        `}
                </style>
            </Box>
            <Box className="footer" sx={{paddingTop: '1vh'}}>
                <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}><h5 className="h5">Adresse
                    : {agence.adresse}</h5></Box>
                <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}><Box
                    sx={{marginRight: "3vh"}}><h5 className="h5">Tél : {agence.tel}</h5></Box><Box><h5
                    className="h5">Email : {agence.email}</h5></Box></Box>
                <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}><Box
                    sx={{marginRight: "3vh"}}><h5 className="h5">TP : {agence.tp}</h5></Box><Box
                    sx={{marginRight: "3vh"}}><h5 className="h5">IF : {agence.idfis}</h5></Box><Box
                    sx={{marginRight: "3vh"}}><h5 className="h5">RC : {agence.rc}</h5></Box>
                    <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}><h5 className="h5">ICE
                        : {agence.ice}</h5></Box></Box>
            </Box>

        </div>
    );
}

export default Index;