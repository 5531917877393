import React, { useState, useEffect, useRef, forwardRef } from 'react';
import { Box, Button } from '@mui/material';
import { useTheme } from '@mui/material';
import { tokens } from "../../../theme";
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import axios from "axios";
import { useReactToPrint } from "react-to-print";
import PDFCoursDevises from '../PDFContent/CoursDevises/index';
import Table from '../../../table/index';
import { useSelectedRow } from "../../../context/SelectedRowContext";
import EditDialog from '../Dialogs/CoursDevises/index'; // Importer le nouveau composant de dialogue

const colonnesEntete = [
    { id: 'nom_devise', label: 'Nom devise', align: 'left' },
    { id: 'tauxA', label: 'Taux achat', align: 'right' },
    { id: 'tauxVC', label: 'Taux vente client', align: 'right' },
    { id: 'tauxVB', label: 'Taux vente banque', align: 'right' },
];

const ContenuPDF = forwardRef((props, ref) => {
    const { data, agence } = props;
    return (
        <div ref={ref}>
            <PDFCoursDevises data={data} agence={agence} />
        </div>
    );
});

const Index = () => {
    const composantRef = useRef();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [donneesAVisualiser, setDonneesAVisualiser] = useState([]);
    const { selectedRow, updateSelectedRow } = useSelectedRow();
    const [ouvrirDialog, setOuvrirDialog] = useState(false);
    const [lignes, setLignes] = useState([]);
    const [agence, setAgence] = useState({});
    const [bool, setBool] = useState(1);
    const [devise, setDevise] = useState();
    const [valeurSoumise, setValeurSoumise] = useState();
    const [imprimer, setImprimer] = useState(false);

    useEffect(() => {
        updateSelectedRow(null);
    }, []);

    // Gestion de l'impression
    const gererImpressionEvenement = (e) => {
        e.preventDefault();
        axios.get(`api/info-agence`).then((res) => {
            if (res.data.status === 200) {
                setAgence(res.data.message);
                setImprimer(true);
            }
        });
    };

    const gererImpression = useReactToPrint({
        content: () => composantRef.current,
        documentTitle: "Imprimer",
        pageStyle: `
      @page {
        size: A4;
        margin: 10mm;
      }
      @media print {
        .MuiDataGrid-footerContainer {
          display: none !important;
        }
        a {
          display: none !important;
        }
      }
    `,
        header: null,
        footer: null,
    });

    useEffect(() => {
        if (imprimer) {
            gererImpression();
            setImprimer(false);
        }
    }, [imprimer, gererImpression]);

    // Ouvrir le dialogue de modification
    const gererOuvrirDialog = () => {
        if (selectedRow) {
            setOuvrirDialog(true);
        }
    };

    const gererFermerDialog = () => {
        setOuvrirDialog(false);
    };

    // Valider la modification
    const validerModification = (devise, valeurSoumise) => {
        let idd = devise.id;
        const miseAJour = {
            id: idd,
            tauxA: valeurSoumise.tauxA,
            tauxVC: valeurSoumise.tauxVC,
            tauxVB: valeurSoumise.tauxVB,
        };
        const resultats = lignes.filter((op) => op.id !== idd);
        resultats.push(miseAJour);
        setLignes(resultats);
        const data = {
            donnees: resultats.map((ligne) => ({
                id: ligne.id,
                tauxA: ligne.tauxA,
                tauxVC: ligne.tauxVC,
                tauxVB: ligne.tauxVB,
            })),
        };
        axios.post("api/cours-devises", data).then((res) => {
            if (res.data.status === 200) {
                setBool(1);
            } else {
                // Gérer l'erreur
            }
            setOuvrirDialog(false);
        });
    };

    useEffect(() => {
        if (bool) {
            axios.get(`api/cours-devises`).then((res) => {
                if (res.data.status === 200) {
                    setDonneesAVisualiser(res.data.cours_devises);
                }
            });
            setBool(0);
        }
    }, [bool]);

    // useEffect(() => {
    //     if (selectedRow) {
    //         setDevise(selectedRow);
    //         setValeurSoumise({
    //             tauxA: selectedRow.tauxA,
    //             tauxVC: selectedRow.tauxVC,
    //             tauxVB: selectedRow.tauxVB,
    //         });
    //     }
    // }, [selectedRow]);

    return (
        <Box className='container-table' sx={{ "& .bartitle": { marginY: "1.5rem" } }}>
            <Box display="flex" justifyContent="space-between" sx={{
                "& .MuiFormControl-root": { marginRight: "1rem", width: "6rem" },
                "& .MuiAutocomplete-root": { marginTop: "5px" },
                "& .buttons": {
                    color: colors.grey[200],
                    backgroundColor: colors.primary[400],
                    margin: "0.5rem 0.5rem -0.2rem 0.5rem",
                    fontSize: "0.7rem",
                    height: "2.5rem",
                    paddingX: "1.2rem",
                    paddingY: "0.2rem"
                },
                "& .buttons:hover": { color: colors.primary[400], backgroundColor: colors.grey[200] },
                "& .span1": { marginLeft: "0.5rem" }
            }}>
                <Box display="flex">
                    {/* Vos autres boutons ou contenu */}
                </Box>
                <Box>
                    <Button className="buttons" onClick={gererOuvrirDialog}>
                        <DriveFileRenameOutlineOutlinedIcon /><span className="span1">Modifier</span>
                    </Button>
                    <EditDialog
                        open={ouvrirDialog}
                        handleClose={gererFermerDialog}
                        handleValide={validerModification}
                        dataToRender={donneesAVisualiser}
                        selectedRow={selectedRow}
                        updateSelectedRow={updateSelectedRow}
                    />
                    <Button className="buttons" onClick={gererImpressionEvenement}>
                        <FindInPageOutlinedIcon /><span className="span1">Aperçu</span>
                    </Button>
                </Box>
            </Box>
            <Box>
                <Table dataToRender={donneesAVisualiser} headCells={colonnesEntete} colsToShow={[1, 6, 7, 8]} />
            </Box>
            <div style={{ display: 'none' }}>
                <ContenuPDF data={donneesAVisualiser} agence={agence} ref={composantRef} />
            </div>
        </Box>
    );
};

export default Index;
