import { Box } from "@mui/material";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {useEffect, useState,useRef} from 'react';
import {Button} from "@mui/material";
import swal from "sweetalert";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import React from "react";
import { Link } from 'react-router-dom';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import {useSelectedRow} from "../../../context/SelectedRowContext";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 300,
        },
    },
};
let ab=null;
const menu = [
    'Achat Client',
    'Vente Client',
    'Cession banque',
    'Caisse',
    'Gestion de devise',
    'Rapports',
    'Paramètres',
    'Statistique bénéfice',
    'Gestion Erreur',
    'Caisses utilisateurs',
    'Passage de devise',
    'Achat de dirhams',
];
const description = [
    'Alimentation',
    'Entrée',
    'Sortie',
];
function getStyles(name, selectedMenu, theme) {
    return {
        fontWeight:
            selectedMenu.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}
const Achat = (props) => {

    const history=useHistory();
    const [submitValue,setSubmitValue]=useState({
        montant :'',
        cheque:''
    })

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [caisses,setCaisses]=useState([]);
    const { selectedRow, updateSelectedRow } = useSelectedRow();
    const [selectedMenu, setSelectedMenu] = useState([]);
    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedMenu(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const [selectedValue, setSelectedValue] = useState([]);
    const handleChange2 = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedValue(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const etat=['Activer','Désactiver'];
    const statut=['Utilisateur','Administration'];
    const [info,setInfo]=useState({
        nomC: '',
        email: '',
        motdpasse: '',
        confirme: '',
        etat: '',
        statut: '',
        caisse: '',

    });
    const handleInput = (e) => {
        const { name, value } = e.target;
        setInfo((previnfo) => ({
            ...previnfo,
            [name]: value,
        }));
    };

    useEffect(() => {
        const fetchData = async () => {
            try {

                const response = await axios.get(`api/liste-caisses`);
                const cs=response.data.message;
                const cs2=cs.map((op)=>op.designation)
                setCaisses(cs2);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);
    const submit=(e)=>{
        e.preventDefault();
        const data={
            id:props.match.params.id,
            nomC: info.nomC,
            email: info.email,
            motdpasse: info.motdpasse,
            etat: info.etat,
            statut: info.statut,
            caisse: info.caisse,
            menu: selectedMenu,
            desCaisse:selectedValue,
        }
        swal({
            title: '',
            text: 'Voulez vous vraiment valider cette opération ?',
            icon: 'warning',
            buttons: ['Annuler', 'Oui'],
            dangerMode: true,
        }).then((confirmed) => {
            if (confirmed) {
                if(info.motdpasse===info.confirme){
                    axios
                        .post('api/modifier-compte', data)
                        .then((res) => {
                            // ... Handle the response from the server ...
                            if (res.data.status === 200) {
                                history.push('/espace-magchange/parametres');

                            } else if (res.data.status === 400) {
                                const firstErrorMessage = Object.values(res.data.validationerror)[0][0];
                                swal(
                                    '',
                                    firstErrorMessage || "Pour enregistrer cette opération, il est nécessaire de saisir toutes les informations.",
                                    'warning'
                                );
                            }else if(res.data.status === 450){
                                console.log("res.data.ver");
                                console.log(res.data.ver);
                                swal(
                                    '',
                                    "L'utilisateur ID existe déjà.",
                                    'warning'
                                );
                            }
                            else  {
                                swal('', 'Erreur.', 'warning');
                            }
                        })
                        .catch((error) => {
                            // Handle any error that occurred during the request
                            console.error('Error submitting form:', error);
                        });
                }else{
                    swal(
                        '',
                        "Les mots de passe saisis ne correspandent pas.",
                        'warning'
                    );
                }
            }
        });




    }
    const [user,setUser]=useState(0);
    const [user2,setUser2]=useState(0);

    useEffect(()=>{
        const id_compte=props.match.params.id;
        updateSelectedRow(4);
        axios.get(`api/compte-selectionner?id=${id_compte}`).then(res=>{
            if(res.data.status===200){
                const droitsA=res.data.droitsA;
                const droitsO=res.data.droitsO;
                const menu2=droitsA.map((op)=>op.Menu);
                setSelectedMenu(menu2);
                const description2=droitsO.map((op)=>op.description);
                setSelectedValue(description2);
                setUser(res.data.user);
                setUser2(res.data.admin);
                setInfo({
                    nomC: (res.data.user).name,
                    email: (res.data.user).email,
                    motdpasse: (res.data.user).motPass,
                    confirme: "",
                    etat: (res.data.user).activer===1?'Activer':'Désactiver',
                    statut: (res.data.user).admin===1?'Administration':'Utilisateur',
                    caisse: (res.data.user).designation,

                });
            }});


    },[]);
    return (

        <Box className="type2" sx={{marginTop:"6vh"}}>


            <Box
                className="bartitle"
                backgroundColor={colors.primary[400]}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center', // Alignement vertical centré
                    padding: '2px', // Espace autour du contenu
                }}
            >
                <Box
                    textAlign="center"
                    sx={{
                        "& .h1": {
                            color: colors.grey[500],
                            margin: 0,
                            fontSize:"1.75rem",
                        },
                    }}
                >
                    <h1 className="h1">GESTION D'UTILISATEUR</h1>
                </Box>
                <Box sx={{marginLeft:'4vw',marginTop:'1.5vh'}}>
                    <Link to='/espace-magchange/parametres'><CancelPresentationIcon /></Link>
                </Box>
            </Box>



            <Box sx={{}}>
                <Box
                    sx={{
                        backgroundColor:'rgb(243, 240, 238)',
                        width:'90%',marginLeft:'5%',padding:'1% 4%',display:'flex',
                        "& .MuiOutlinedInput-input":{
                            heigh:"0.5rem"}
                    }}
                >

                    <Box sx={{width:'30%',border:'1px solid black',borderRadius:'5px',padding:'2vh'}} >
                        <h3  className= "h3" style={{marginBottom:'2vh'}}>Les informations de l'utilisateur :</h3>
                        <Box sx={{marginY:'2vh'}}><TextField size="small" name="nomC" value={info.nomC} onChange={handleInput}  sx={{width:'100%'}}  label="Nom complet" variant="outlined" /></Box>
                        <Box sx={{marginY:'2vh'}}><TextField size="small" name="email" value={info.email} onChange={handleInput} sx={{width:'100%'}}  label="Utilisateur ID" variant="outlined" /></Box>
                        <Box sx={{marginY:'2vh'}}><TextField size="small" type="password" name="motdpasse"  value={info.motdpasse} onChange={handleInput} sx={{width:'100%'}}   label="Mot de passe (8 caractères, maj., min., spéciaux)" variant="outlined" /></Box>
                        <Box sx={{marginY:'2vh'}}><TextField size="small" type="password" name="confirme"  value={info.confirme} onChange={handleInput} sx={{width:'100%'}}   label="Confirmer M.passe" variant="outlined" /></Box>
                        <Box sx={{marginY:'2vh'}}>
                            <Autocomplete
                                sx={{width:'100%'}}
                                id="combo-box-demo"
                                size="small"
                                options={etat}
                                value={info.etat}
                                onChange={(event, newValue) => {
                                    setInfo((previnfo) => ({
                                        ...previnfo,
                                        etat: newValue,
                                    }));
                                }}
                                renderInput={(params) => <TextField {...params} label="Etat Compte" />}
                                disabled={user2 === 0}
                            />
                        </Box>
                        <Box sx={{marginY:'2vh'}}>
                            <Autocomplete
                                sx={{ width: '100%' }}
                                id="st"
                                size="small"
                                options={statut}
                                value={info.statut}
                                onChange={(event, newValue) => {
                                    setInfo((previnfo) => ({
                                        ...previnfo,
                                        statut: newValue,
                                    }));
                                    if (newValue === 'Administration') {
                                        setSelectedMenu(menu);
                                        setSelectedValue(description);
                                    }
                                }}
                                renderInput={(params) => <TextField {...params} label="Statut compte" />}
                                disabled={user2 === 0}
                            />

                        </Box>
                        <Box sx={{marginY:'2vh'}}>
                            <Autocomplete

                                sx={{width:'100%'}}
                                id="combo-box-demo"
                                size="small"
                                options={caisses}
                                value={info.caisse}
                                onChange={(event, newValue) => {
                                    setInfo((previnfo) => ({
                                        ...previnfo,
                                        caisse: newValue,
                                    }));

                                }}
                                renderInput={(params) => <TextField {...params} label="Caisse" />}
                                disabled={user2 === 0}
                            />
                        </Box>

                    </Box>
                    <Box sx={{width:'30%',border:'1px solid black',borderRadius:'5px',padding:'2vh',marginLeft:'3vw'}} >
                        <h3  className= "h3" style={{marginBottom:'2vh'}}>Menu de l'application :</h3>
                        <Box sx={{marginY:'2vh'}}>
                            <FormControl sx={{  width: '100%' }}>
                                <InputLabel id="demo-multiple-chip-label">Menu</InputLabel>
                                <Select
                                    {...ab}
                                    labelId="demo-multiple-chip-label"
                                    id="demo-multiple-chip"
                                    multiple
                                    disabled={user2 === 0}
                                    value={selectedMenu}
                                    onChange={handleChange}
                                    input={<OutlinedInput id="select-multiple-chip" label="Menu" />}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((value) => (
                                                <Chip key={value} label={value} />
                                            ))}
                                        </Box>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    {menu.map((name) => (
                                        <MenuItem
                                            key={name}
                                            value={name}
                                            style={getStyles(name, selectedMenu, theme)}
                                        >
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>

                    </Box>
                    <Box sx={{width:'30%',border:'1px solid black',borderRadius:'5px',padding:'2vh',marginLeft:'3vw'}} >
                        <h3  className= "h3" style={{marginBottom:'2vh'}}>Les opérations de la caisse :</h3>
                        <Box sx={{marginY:'2vh'}}>
                            <FormControl sx={{width: '100%'}}>
                                <InputLabel id="demo-multiple-chip-label">Description</InputLabel>
                                <Select
                                    {...ab}
                                    disabled={user2 === 0}
                                    labelId="demo-multiple-chip-label"
                                    id="demo-multiple-chip"
                                    multiple
                                    value={selectedValue}
                                    onChange={handleChange2}
                                    input={<OutlinedInput id="select-multiple-chip" label="Menu" />}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((value) => (
                                                <Chip key={value} label={value} />
                                            ))}
                                        </Box>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    {description.map((name) => (
                                        <MenuItem
                                            key={name}
                                            value={name}
                                            style={getStyles(name, selectedValue, theme)}
                                        >
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>

                    </Box>
                </Box>



            </Box>
            <Box display="flex" justifyContent="center"
                 sx={{
                     "& .MuiFormControl-root" : {
                         marginRight:"1rem",
                         width:"11rem",

                     },
                     "& .MuiAutocomplete-root":{
                         marginTop:"8px"
                     },
                     "& .buttons" : {
                         color: colors.grey[200],
                         backgroundColor : colors.primary[800],
                         margin: "0.5rem",
                         fontSize: "0.8rem",
                         height: "3.4rem" ,
                         paddingX:"1.5rem"
                     },
                     "& .buttons:hover" : {
                         color: colors.primary[400],
                         backgroundColor : colors.grey[200],

                         margin: "0.5rem",
                         fontSize: "0.9rem"
                     },
                     "& .span1" : {


                         marginLeft: "0.5rem",

                     },

                 }}
            >
                <Box display="flex"><Button className="buttons" onClick={submit}><span className="span1">Enregistrer</span> </Button></Box>
            </Box>
        </Box>



    );
};

export default Achat;
