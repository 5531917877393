
export const formatNumber = (number) => {
    // Convert the number to a string with 2 decimal places
    const formattedNumber = Number(number).toFixed(2);

// Split the number into parts before and after the decimal point
    const [integerPart, decimalPart] = formattedNumber.split('.');

// Add thousands separators
    const integerWithSeparators = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

// Combine the formatted parts
    return `${integerWithSeparators}.${decimalPart}`;

};