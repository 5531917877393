import * as React from 'react';
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import {Tabs,Tab,AppBar} from "@mui/material";
import Cession from "../../components/GestionBanque/Cession/index";
import Consultation from "../../components/GestionBanque/Consultation/index";
import Statistiques from "../../components/GestionBanque/Statistique/index";
import '../index.css';
const Gestionbanque = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [value,setValue]=React.useState(0);
  const handleTabs=(r,val)=>{
    console.warn(val)
    setValue(val)
  }
  return (
    <Box className="gestion-devise-container">
      <Header title="GESTION BANQUE"  />
      <Box>
        <AppBar position='static' backgroundColor="#fffff"    >
          <Tabs value={value} onChange={handleTabs} >
            <Tab label="Cession" />
            <Tab label="Consultation"/>
            <Tab label="Statistique"/>
            
          </Tabs>
        </AppBar>
        
      </Box>
        
      <Box className="gestion-devise-content">
         
      <TabPanel value={value} index={0}><Cession/></TabPanel>
      <TabPanel value={value} index={1}><Consultation/></TabPanel>
      <TabPanel value={value} index={2}><Statistiques/></TabPanel>
     
      </Box>
      
    </Box>
  );
  
};
const TabPanel = (props) => {
  const {children,value,index}=props;
  return (
    
      value===index && (
        <div>{children}</div>
      )
      
    
  );
};
export default Gestionbanque;
