import { Box } from "@mui/material";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import {Button} from "@mui/material";
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import SystemUpdateAltOutlinedIcon from '@mui/icons-material/SystemUpdateAltOutlined';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useState,useEffect,useRef } from "react";
import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import { useReactToPrint } from "react-to-print";
import axios from "axios";
import { CSVLink } from "react-csv";
import Table from "../../../table";
import PDFJournalCaisse from "../PDFContent/JournalCaisse/index"
class PDFContent extends React.Component {
    render() {
        const {  data2,data3, agence } = this.props;


        return (
            <PDFJournalCaisse data2={data2} data3={data3} agence={agence}/>
        );
    }
}
const Consultation = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [dataToRender,setDataToRender]=useState([]);
    const [dataToRender2,setDataToRender2]=useState([]);
    const [user,setUser]=useState([]);
    const [caisses,setCaisses]=useState([]);
    const [selectedRow,setSelectedRow]=useState(null);

    const [page, setPage] =useState(0);
    const [rowsPerPage, setRowsPerPage] =useState(10);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedCaisse, setSelectedCaisse] = useState(null);
    const [selectedType, setSelectedType] = useState(null);
    const [selectedDuDate, setSelectedDuDate] = useState(dayjs());
    const [selectedAuDate, setSelectedAuDate] = useState(dayjs());
    const [listcaisse,setlistcaisse]=useState([]);
    const [type,setType]=useState([]);
    const componentRef = useRef();
    const [info, setInfo] = useState({
        du:'',
        au:'',
    });
    const [agence,setAgence]=useState({});
    const [shouldPrint, setShouldPrint] = useState(false);
    const [rows, setRows] = useState([]);

    /****************************************************************functions ************************************************************************ */
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handleRowClick = (row) => {
        setSelectedRow(row);
    };
    const handlePrintev = (e) => {
        e.preventDefault();
        axios.get(`api/info-agence`).then((res) => {
            if (res.data.status === 200) {
                setAgence(res.data.message);
                setShouldPrint(true);
            }
        });

        // Add checks to ensure variables are defined
        const du = convertDateFormat(selectedDuDate);
        const au = convertDateFormat(selectedAuDate);

        setInfo((prevInfo) => ({
            ...prevInfo,
            du: du,
            au: au,
            user:user,
        }));
    };
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "Print",
        pageStyle: `
    @page {
      size: A4;
      margin: 10mm;
    }
    /* Additional styles for the printed page can be added here */
    /* Remove the pagination and URL styles */
    @media print {
      .MuiDataGrid-footerContainer {
        display: none !important;
      }
      a {
        display: none !important;
      }
    }
  `,
        header: null, // Set header to null to remove it from the PDF
        footer: null,
        // Set footer to null to remove it from the PDF
    });

    useEffect(() => {
        if (shouldPrint) {
            // Call handlePrint() when shouldPrint becomes true
            handlePrint(); // This will trigger the printing action
            setShouldPrint(false); // Reset shouldPrint back to false
        }
    }, [shouldPrint]);
    /********************************************************************************UseEffect********************************** */

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`api/journal-caisse`);
                if (response.data.status === 200) {
                    const { message,ur,caisse } = response.data;
                    const bor=handleDuDateChange2(selectedDuDate,message);
                    const  bor2=handleAuDateChange2(selectedAuDate,bor);
                    if(ur===1){
                        setCaisses(caisse);
                    }

                    setUser(ur);

                    const msg = bor.map((op) => {
                        return {
                            "Type d'opération":op.type,
                            'Date': op.date,
                            "Devise": op.nom_devise,
                            'Montant': op.montant,
                            'Contre Valeur': op.cv,
                            'Utilisateur': op.name,
                            'Caisse': op.caisse,

                        };
                    });
                    setRows(msg);
                    setDataToRender(bor2);
                    setDataToRender2(message);
                    setlistcaisse(caisses);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();

    }, []);


    /*****************************************************filtre functions************************************************ */
//user
    const handleUserFilterChange = (event, newValue) => {
        setSelectedUser(newValue);
        let bor=dataToRender2;
        bor=handleUserFilterChange2( (newValue? newValue.name:''),bor);
        bor=handleDuDateChange2(selectedDuDate,bor);
        bor=handleAuDateChange2(selectedAuDate,bor);
        bor=handleCaisseFilterChange2( selectedCaisse,bor);
        setDataToRender(bor);
    }
    const handleUserFilterChange2 = (newValue,bor) => {
        let filteredItems;
        if(newValue!=''){
            filteredItems = bor.filter((item) =>
                item.name===newValue
            );
        }else{
            filteredItems = dataToRender2;
        }
        return filteredItems;
    };


//du date filtre
    const convertDateFormat = (dateString) => {

        const parsedDate = dayjs(dateString, 'MM/DD/YYYY'); // Parse the date with the original format
        return parsedDate.format('YYYY/MM/DD'); // Format the date in the desired format
    };
    const convertDateFormat2 = (dateString) => {
        const parsedDate = dayjs(dateString, 'DD/MM/YYYY'); // Parse the date with the original format
        return parsedDate.format('YYYY/MM/DD'); // Format the date in the desired format
    };
    const convertDateFormat3 = (dateString) => {

        return  dayjs(dateString, 'MM/DD/YYYY'); // Parse the date with the original format
    };

    const handleDuDateChange = (newValue) => {
        console.log(newValue);
        setSelectedDuDate(newValue);
        let bor=dataToRender2;
        bor=handleDuDateChange2(newValue,bor);
        bor=handleAuDateChange2(selectedAuDate,bor);
        bor=handleCaisseFilterChange2( selectedCaisse,bor);
        setDataToRender(bor);
        const msg = bor.map((op) => {
            return {
                "Type d'opération":op.type,
                'Date': op.date,
                "Devise": op.nom_devise,
                'Montant': op.montant,
                'Contre Valeur': op.cv,
                'Utilisateur': op.name,
                'Caisse': op.caisse,

            };
        });
        setRows(msg);
    };
    const handleDuDateChange2 = (newValue,bor) => {
        if(newValue){
            const newV=convertDateFormat(newValue);
            const filteredItems = bor.filter((item) => {
                const parsedDate = convertDateFormat2(item.date);
                return new Date(parsedDate) >= new Date(newV);
            });
            return filteredItems;
        }else{

            return bor;
        }
    }
// au date functions

    const handleAuDateChange = (newValue) => {
        setSelectedAuDate(newValue);
        let bor=dataToRender2;
        bor=handleAuDateChange2(newValue,bor);
        bor=handleDuDateChange2(selectedDuDate,bor);
        bor=handleCaisseFilterChange2( selectedCaisse,bor);
        setDataToRender(bor);
        const msg = bor.map((op) => {
            return {
                "Type d'opération":op.type,
                'Date': op.date,
                "Devise": op.nom_devise,
                'Montant': op.montant,
                'Contre Valeur': op.cv,
                'Utilisateur': op.name,
                'Caisse': op.caisse,

            };
        });
        setRows(msg);
    };
    const handleAuDateChange2 = (newValue,bor) => {
        if(newValue){
            const newV=convertDateFormat(newValue);
            const filteredItems = bor.filter((item) => {
                const parsedDate = convertDateFormat2(item.date);
                return new Date(parsedDate) <= new Date(newV);
            });
            return filteredItems;
        }else{

            return bor;
        }
    }
// devise filtre functions
    const handleCaisseFilterChange = (event, newValue) => {
        setSelectedCaisse(newValue);
        let bor=dataToRender2;
        bor=handleAuDateChange2(selectedAuDate,bor);
        bor=handleDuDateChange2(selectedDuDate,bor);
        bor=handleCaisseFilterChange2( newValue,bor);

        setDataToRender(bor);
        const msg = bor.map((op) => {
            return {
                "Type d'opération":op.type,
                'Date': op.date,
                "Devise": op.nom_devise,
                'Montant': op.montant,
                'Contre Valeur': op.cv,
                'Utilisateur': op.name,
                'Caisse': op.caisse,

            };
        });
        setRows(msg);
    };
    const handleBanqueFilterChangee = (event, newValue) => {
        setSelectedType(newValue);
        let bor=dataToRender2;
        bor=handleAuDateChange2(selectedAuDate,bor);
        bor=handleDuDateChange2(selectedDuDate,bor);
        bor=handleCaisseFilterChange2( selectedCaisse,bor);

        setDataToRender(bor);
        const msg = bor.map((op) => {
            return {
                "Type d'opération":op.type,
                'Date': op.date,
                "Devise": op.nom_devise,
                'Montant': op.montant,
                'Contre Valeur': op.cv,
                'Utilisateur': op.name,
                'Caisse': op.caisse,

            };
        });
        setRows(msg);
    };

    const handleCaisseFilterChange2 = ( newValue,bor) => {
        if(newValue){
            const filteredItems = bor.filter((item) =>
                item.caisse===newValue.designation
            );

            return filteredItems;
        }else{

            return bor;
        }


    }
    const handleCaisseFilterChange3 = ( newValue,bor) => {
        if(newValue){
            const filteredItems = bor.filter((item) =>
                item.description===newValue.description
            );

            return filteredItems;
        }else{

            return bor;
        }


    }
    /**********************************END FILTRE FUNCTIONS*************************************** */
    const nomFichier=`JOURNAL_DES_OPERATIONS_DE_CAISSE-Du_${convertDateFormat(selectedDuDate)}-Au_${convertDateFormat(selectedAuDate)}.csv`
    const colonnesEntete = [
        { id: 'type', label: "Type d'opération", align: 'left' },
        { id: 'date', label: 'Date', align: 'left' },
        { id: 'nom_devise', label: 'Devise', align: 'left' },
        { id: 'montant', label: 'Montant', align: 'right' },
        { id: 'cv', label: 'Contre valeur', align: 'right' },
        { id: 'name', label: 'Utilisateur', align: 'center' },
        { id: 'caisse', label: 'Caisse', align: 'left' }
    ];
    const colsToShow=[0,1, 2, 5, 6, 3, 4];
    return (
        <Box m="20px" sx={{
            "& .bartitle":{
                marginY : "1.5rem",
            }
        }}>


            <Box display="flex" justifyContent="space-between"
                 sx={{
                     "& .MuiFormControl-root" : {
                         marginRight:"1rem",
                         width:"14vw",

                     },
                     "& .MuiAutocomplete-root":{
                         marginTop:"8px"
                     },
                     "& .buttons" : {
                         color: colors.grey[200],
                         backgroundColor : colors.primary[400],
                         margin: "0.5rem 0.5rem -0.2rem 0.5rem",
                         fontSize: "0.7rem",
                         height: "2.5rem",
                         paddingX: "1.2rem",
                         paddingY: "0.2rem"
                     },
                     "& .buttons:hover" : {
                         color: colors.primary[400],
                         backgroundColor : colors.grey[200],

                     },
                     "& .span1" : {


                         marginLeft: "0.5rem",

                     },
                     "& .csss" : {


                         minWidth:'14vw',

                     }
                 }}
            >
                <Box display="flex">
                    {user===1?<Autocomplete className="Autocomplete"
                                            size="small"
                                            id="combo-box-demo"
                                            value={selectedCaisse}
                                            getOptionLabel={(option) => option.designation}
                                            options={caisses}
                                            onChange={handleCaisseFilterChange}
                                            renderInput={(params) => <TextField {...params} label="Caisse" />}
                    />:null}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                            <DatePicker
                                label="DU"
                                value={selectedDuDate}
                                onChange={handleDuDateChange}
                                format="DD/MM/YYYY"
                                className="csss"
                                sx={{
                                    '& .MuiInputBase-input': {
                                        padding: '10px 14px',
                                        fontSize: '0.875rem',
                                    },
                                }}
                            />
                        </DemoContainer>
                    </LocalizationProvider>




                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                        <DemoContainer components={['DatePicker']}>
                            <DatePicker label="AU"
                                        value={selectedAuDate}
                                        onChange={handleAuDateChange}
                                        format="DD/MM/YYYY"
                                        className="csss"
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                padding: '10px 14px',
                                                fontSize: '0.875rem',
                                            },
                                        }}
                            />
                        </DemoContainer>
                    </LocalizationProvider>


                </Box>
                < Box>
                    <Button className="buttons" onClick={handlePrintev}><FindInPageOutlinedIcon/><span className="span1">Aperçu</span> </Button>
                    <CSVLink data={rows} filename={nomFichier}><Button className="buttons"><SystemUpdateAltOutlinedIcon/><span className="span1">Exporter</span></Button></CSVLink>

                </Box>

            </Box>
            <Box>
                <Table dataToRender={dataToRender} headCells={colonnesEntete} colsToShow={colsToShow} cols={"num_bac"}/>

            </Box>
            <Box display="flex" justifyContent="space-between" >
                <Box display="flex"></Box>

            </Box>
            <div style={{ display: 'none' }}>
                <PDFContent  agence={agence} data2={dataToRender} data3={info}  ref={componentRef} />
            </div>
        </Box>
    );
};

export default Consultation;
