import React from 'react';
import {Box} from "@mui/material";
import {formatNumber} from "../../../../fonctions/formatNumber";

function Index(props) {
    const { data,agence,data2 } = props;
    const cv_t = data.reduce((accumulator, currentDevise) => {
        return accumulator +  currentDevise.cv_achat;
    }, 0);
    const com_t = data.reduce((accumulator, currentDevise) => {
        return accumulator +  currentDevise.commission;
    }, 0);
    return (
        <div>
            <Box sx={{
                borderBottom: '1px solid black',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: "100vw"
            }}>
                <h5 className="h5">{agence.nom_bureau}</h5>
                <h5 className="h5">{agence.num_auto}</h5>
            </Box>
            <Box
                sx={{
                    border: '1px solid black',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '70vw',
                    marginLeft: '15vw',
                    marginY: '4vh',
                    borderRadius: '8px',
                    backgroundColor: '#ea5a27', // Change the background color here
                }}
            >
                <h2 className="h2">STATISTIQUES DES BÉNÉFICES</h2>
            </Box>
            <Box sx={{display: "flex", justifyContent: "center"}}>

                <Box sx={{marginRight: "5vh"}}><h5 className="h5">Mois : {data2.mois}</h5></Box>
                <Box sx={{marginRight: "5vh"}}><h5 className="h5">Année: {data2.annee}</h5></Box>
            </Box>

            <Box sx={{width: '90vw', marginLeft: "5vw", marginRight: "5vw"}}>
                <table className="print-table">
                    <tr>
                        <th>Mois</th>
                        <th>Contre valeur achat</th>
                        <th>Commission</th>
                    </tr>
                    {data.map((row) => (
                        <tr> {/* Assuming each row has a unique identifier (e.g., 'id') */}
                            <td>{new Date(row.month_year).getMonth() + 1}</td>
                            <td align="right">{formatNumber(row.cv_achat)}</td>
                            <td align="right">{formatNumber(row.commission)}</td>
                        </tr>
                    ))}
                    <tr>
                        <th>Total</th>
                        <th align="right">{formatNumber(cv_t)}</th>
                        <th align="right">{formatNumber(com_t)}</th>
                    </tr>
                </table>


                {/* Additional CSS styles for printing */}
                <style>
                    {`
          .print-table {
            width: 100%;
            border-collapse: collapse;
          }

          .print-table th, .print-table td {
            border: 1px solid black;
            padding: 8px;
          }

          /* Additional styles specific for printing */
          @media print {
            .print-table {
              page-break-inside: auto;
            }
            .footer{
              position: fixed;
              bottom: 0;
              border-top: 2px solid #73AD21;
              align-items: center;
              width:100vw;
              justify-content:center;
            }
            .print-table th, .print-table td {
              page-break-inside: avoid;
              text-align: right;

            }
          }
        `}
                </style>
            </Box>
            <Box className="footer" sx={{paddingTop: '1vh'}}>
                <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}><h5 className="h5">Adresse
                    : {agence.adresse}</h5></Box>
                <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}><Box
                    sx={{marginRight: "3vh"}}><h5 className="h5">Tél : {agence.tel}</h5></Box><Box><h5
                    className="h5">Email : {agence.email}</h5></Box></Box>
                <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}><Box
                    sx={{marginRight: "3vh"}}><h5 className="h5">TP : {agence.tp}</h5></Box><Box
                    sx={{marginRight: "3vh"}}><h5 className="h5">IF : {agence.idfis}</h5></Box><Box><h5
                    className="h5">RC : {agence.rc}</h5></Box>
                    <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}><h5 className="h5">ICE
                        : {agence.ice}</h5></Box></Box>
            </Box>
        </div>
    );
}

export default Index;