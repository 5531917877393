import * as React from 'react';
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import {Tabs,Tab,AppBar} from "@mui/material";
import Rapportj from "../../components/Rapports/RapportJournalier/index";
import Dotation from "../../components/Rapports/Dotation/index";
import Releves from "../../components/Rapports/RelevesMensuels/index";
import Recherche from "../../components/Rapports/Recherche/index";
import "../index.css";


const Gestiondevise = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [value,setValue]=React.useState(0);
  const handleTabs=(r,val)=>{
    console.warn(val)
    setValue(val)
  }
  return (
    <Box className="gestion-devise-container">
      <Header title="RAPPORTS"  />
      <Box >
        <AppBar position='static'>
          <Tabs value={value} onChange={handleTabs} >
            <Tab label="Rapports Journalier" />
            <Tab label="Relevés Mensuels"/>
            <Tab label="Dotations"/>
            <Tab label="Historique"/>
            <Tab label="Recherche"/>
            
          </Tabs>
        </AppBar>
        
      </Box>
        
      <Box className="gestion-devise-content">
      <TabPanel value={value} index={0}><Rapportj/></TabPanel>
      <TabPanel value={value} index={1}><Releves/></TabPanel>
      <TabPanel value={value} index={2}><Dotation/></TabPanel>
      <TabPanel value={value} index={4}><Recherche/></TabPanel>
      </Box>
      
    </Box>
  );
  
};
const TabPanel = (props) => {
  const {children,value,index}=props;
  return (
    
      value===index && (
        <div>{children}</div>
      )
      
    
  );
};
export default Gestiondevise;
