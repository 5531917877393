import { Box } from "@mui/material";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import {Button} from "@mui/material";
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import {useEffect, useState,useRef} from 'react';
import axios from "axios";
import { LineChart } from '@mui/x-charts/LineChart';
import { PieChart } from '@mui/x-charts/PieChart';
import 'dayjs/locale/fr';
import { useReactToPrint } from "react-to-print";
import {formatNumber} from './../../../fonctions/formatNumber';
import PDFStatique from './../PDFContent/Statistique/index'
import Table from '../../../table/index'
import dayjs from "dayjs";
import {useSelectedRow} from "../../../context/SelectedRowContext";

class PDFContent extends React.Component {
    render() {
        const { data,agence,data2,user } = this.props;
        return (
            <PDFStatique data={data} agence={agence} data2={data2} user={user}/>
        );
    }
}
const colonnesEntete = [
    { id: 'nom_devise', label: 'Devise', align: 'left' },
    { id: 'mt_ac_sum', label: 'Montant', align: 'right' },
    { id: 'cv_ac_sum', label: 'Contre valeur', align: 'right' }
];
const Statistiques = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [dataToRender,setDataToRender] = useState([]);
    const [operations,setOperations]=useState([]);
    const [user,setUser]=useState([]);
    const [users,setusers]=useState([]);
    const [sumCv, setSumCv] = useState(0);
    const [selectedUser, setSelectedUser] = useState(null);
    const [listdevise,setlistdevise]=useState(["EUR","USD","GBP",'CAD',"CHF","GIP","SEK","NOK","JPY","SAR","KWD","AED","QAR","BHD","DKK","OMR"]);
    const [filteredItems,setFilteredItems]=useState([]);
    const [devise2, setDevise2] = useState([]);
    const [devise3, setDevise3] = useState([]);
    const [selectedType, setSelectedType] = useState("Colonne");
    const [type, setType] = useState(["Colonne","Tarte","Ligne"]);
    const [cchart,setCchart]=useState(1);
    const [selectedDate, setSelectedDate] = useState(new Date().getFullYear());
    const [selectedMonth, setSelectedMonth] = useState(1);
    const [selectedDevise, setSelectedDevise] = useState(null);
    const [bool,setBool]=useState(true);
    const startYear = 2020;
    const endYear = new Date().getFullYear();
    const years = Array.from({ length: endYear - startYear + 1 }, (_, index) => endYear - index);
    const [agence,setAgence]=useState([]);
    const { selectedRow, updateSelectedRow } = useSelectedRow();
    const mois = [
        { index: '00', name: 'Exercice complet' },
        { index: '01', name: 'Janvier' },
        { index: '02', name: 'Février' },
        { index: '03', name: 'Mars' },
        { index: '04', name: 'Avril' },
        { index: '05', name: 'Mai' },
        { index: '06', name: 'Juin' },
        { index: '07', name: 'Juillet' },
        { index: '08', name: 'Août' },
        { index: '09', name: 'Septembre' },
        { index: '10', name: 'Octobre' },
        { index: '11', name: 'Novembre' },
        { index: '12', name: 'Décembre' },
    ];
    const [data2, setData2] = useState({});
    const [shouldPrint, setShouldPrint] = useState(false);

    const componentRef = useRef();
    useEffect(() => {
        updateSelectedRow(null);
    }, []);
    /*****************************************************filtre functions************************************************ */
//user
    const handleUserFilterChange = (event, newValue) => {
        setSelectedUser(newValue);
        let bor=operations;
        let ur=newValue? newValue.name:'';

        bor=handleUserFilterChange2( ur,bor);
        bor=handleDateChange2(selectedDate,bor);
        bor=handleMonthChange2(selectedMonth,bor);
        bor=handleDeviseFilterChange2(selectedDevise,bor);
        setDevise2(transferdata(bor));
        calculateSumCv(transferdata(bor));
    }
    const handleUserFilterChange2 = (newValue,bor) => {
        let filteredItems;
        if(newValue!=''){
            filteredItems = bor.filter((item) =>
                item.name===newValue
            );
        }else{
            filteredItems = bor;
        }

        return filteredItems;
    };

// devise filtre functions
    const handleDeviseFilterChange = (event, newValue) => {
        setSelectedDevise(newValue);
        let bor=operations;
        bor=handleUserFilterChange2( (selectedUser?selectedUser.name:''),bor);
        bor=handleDateChange2(selectedDate,bor);
        bor=handleMonthChange2(selectedMonth,bor);
        bor=handleDeviseFilterChange2(newValue,bor);
        setDevise2(transferdata(bor));
        calculateSumCv(transferdata(bor));
    };


    const handleDeviseFilterChange2 = ( newValue,bor) => {
        if(newValue){
            const filteredItems = bor.filter((item) =>
                item.nom_devise===newValue
            );

            return filteredItems;
        }else{

            return bor;
        }


    }


    const handleDateFilterChange = (event) => {
        setSelectedDate(event.target.value);
        let bor=operations;
        bor=handleUserFilterChange2( (selectedUser?selectedUser.name:''),bor);
        bor=handleDateChange2((event.target.value),bor);
        bor=handleDeviseFilterChange2(selectedDevise,bor);
        setDevise2(transferdata(bor));
        calculateSumCv(transferdata(bor));


    };
    const convertDateFormat2 = (dateString) => {
        const parsedDate = dayjs(dateString, 'DD/MM/YYYY'); // Parse the date with the original format
        return parsedDate.format('YYYY/MM/DD'); // Format the date in the desired format
    };
    const convertDateFormat3 = (dateString) => {
        let parsedDate = dayjs(dateString, 'DD/MM/YYYY');
        const date=new Date(parsedDate).getMonth()
        return date; // Format the date in the desired format
    };
    const handleDateChange2 = (newValue,bor) => {
        if(newValue){
            const filteredItems = bor.filter((item) => {
                const parsedDate = convertDateFormat2(item.date_achat);
                const parsedyear=new Date(parsedDate).getFullYear();
                return  parseInt(parsedyear) === parseInt(newValue);
            });
            return filteredItems;
        }else{

            return bor;
        }
    }

    const handleMonthFilterChange = (event) => {
        setSelectedMonth(event.target.value);
        let bor=operations;
        bor=handleUserFilterChange2( (selectedUser?selectedUser.name:''),bor);
        bor=handleDateChange2(selectedDate,bor);
        bor=handleMonthChange2((event.target.value),bor);
        bor=handleDeviseFilterChange2(selectedDevise,bor);
        setDevise2(transferdata(bor));
        calculateSumCv(transferdata(bor));
    };
    const handleMonthChange2 = (newValue,bor) => {
        if(parseInt(newValue)!==1){
            const filteredItems = bor.filter((item) => {
                const parsedDate = convertDateFormat3(item.date_achat);


                return  parseInt(parsedDate+1)=== parseInt(newValue)-1;
            });
            return filteredItems;
        }else{

            return bor;
        }
    }
    /***************************************************** end filtre functions************************************************ */
    const handlePrintev= (e) => {
        e.preventDefault();
        setData2({
            'mois':mois[parseInt(selectedMonth)-1].name,
            'annee':selectedDate,
            'utilisateur':selectedUser?selectedUser.name:'--all--',
            'devise':selectedDevise,
            'sumCv':sumCv,
        });
        axios.get(`api/info-agence`).then((res) => {
            if (res.data.status === 200) {
                setAgence(res.data.message);
                setShouldPrint(true);
            }
        });

    };
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "numBvc",
        pageStyle: `
      @page {
        size: A4;
        margin: 10mm;
      }
      /* Additional styles for the printed page can be added here */
      /* Remove the pagination and URL styles */
      @media print {
        .MuiDataGrid-footerContainer {
          display: none !important;
        }
        a {
          display: none !important;
        }
      }
    `,
        header: null, // Set header to null to remove it from the PDF
        footer: null,
        // Set footer to null to remove it from the PDF
    });
    const handleType = (event, newValue) => {
        console.log('done')
        setSelectedType(newValue);
        if(newValue==="Colonne"){
            setCchart(1);
        }else if(newValue==="Tarte"){
            setCchart(2);
        }else{
            setCchart(3);
        }
    }
    const renderChart = () => {
        const data2 = devise2.map((op) => op.nom_devise);
        const data3 = devise2.map((op) => op.mt_ac_sum);

        // Check if devise2 is empty or undefined
        if (!devise2 || devise2.length === 0) {
            return null; // Return null or any fallback JSX if data is not available
        }

        // If devise2 is not empty, create the data1 array
        const data1 = devise2.map((op, index) => ({
            id: index + 1,
            value: op.cv_ac_sum,
            label: op.nom_devise,
        }));

        if (cchart === 1) {
            return (
                <>
                    <BarChart
                        xAxis={[
                            {
                                id: 'barDeviseAchat',
                                data: data2,
                                scaleType: 'band',
                            },
                        ]}
                        series={[
                            {
                                data: data3,
                            },
                        ]}
                        width={700}
                        height={450}
                    />
                </>
            );
        } else if (cchart === 2) {
            return (
                <>
                    <PieChart
                        series={[
                            {
                                data: data1,
                            },
                        ]}
                        width={700}
                        height={450}
                    />
                </>
            );
        } else if (cchart === 3) {
            return (
                <>
                    <LineChart
                        width={700}
                        height={450}
                        series={[
                            { data: data3 },
                        ]}
                        xAxis={[{ scaleType: 'point', data: data2 }]}
                    />
                </>
            );
        }
    };

    const calculateSumCv = (bor) => {
        const newSumCv = bor.reduce((accumulator, currentDevise) => {
            return accumulator + currentDevise.cv_ac_sum;
        }, 0);
        setSumCv(newSumCv);
    };
    const transferdata=(data)=>{
        const result = data.reduce((acc, item) => {
            const { id,nom_devise, mt_ac, cv_ac, date_achat,name } = item;
            const key = `${nom_devise}`;

            if (acc[key]) {
                acc[key].cv_ac_sum += parseFloat(cv_ac); // Convert cv_ac to a number and sum
                acc[key].mt_ac_sum += parseFloat(mt_ac); // Convert mt_ac to a number and sum
            } else {
                acc[key] = {
                    id,
                    name,
                    nom_devise,
                    date_achat,
                    mt_ac_sum: parseFloat(mt_ac), // Convert mt_ac to a number
                    cv_ac_sum: parseFloat(cv_ac), // Convert cv_ac to a number
                };
            }

            return acc;
        }, {});
        const formattedResult2 = Object.values(result).map(item => ({
            ...item,
            mt_ac_sum: formatNumber(item.mt_ac_sum),
            cv_ac_sum: formatNumber(item.cv_ac_sum),
        }));
        setDevise3(formattedResult2);
        const formattedResult = Object.values(result);
        setBool(false);
        // Update the state with the processed data
        return formattedResult;
    }

    useEffect(() => {
        let bor=handleDateChange2(selectedDate,dataToRender)
        setDevise2(transferdata(bor));
        calculateSumCv(transferdata(dataToRender));

    }, [dataToRender]);

    //****************************************backend **********************************************************************************/
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`api/statistiques-achats`);
                if (response.data.status === 200) {
                    let data=(response.data.message)
                    setOperations(data);
                    setDataToRender(data);
                    setusers(response.data.users);
                    setUser(response.data.user);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);
    useEffect(() => {
        if (shouldPrint) {
            // Call handlePrint() when shouldPrint becomes true
            handlePrint(); // This will trigger the printing action
            setShouldPrint(false); // Reset shouldPrint back to false
        }
    }, [shouldPrint]);
    /********************************************************return ************************************************************** */


    return (
        <Box className="type2">
            <Box display="flex" justifyContent="center" marginBottom="3rem"
                 sx={{
                     "& .MuiFormControl-root" : {
                         marginRight:"1rem",
                         width:"10rem",

                     },
                     "& .MuiAutocomplete-root":{
                         marginTop:"8px"
                     },
                     "& .buttons" : {
                         color: colors.grey[200],
                         backgroundColor : colors.primary[400],
                         margin: "0.5rem",
                         fontSize: "0.8rem",
                         height: "3.4rem" ,
                         paddingX:"1.5rem"
                     },
                     "& .buttons:hover" : {
                         color: colors.primary[400],
                         backgroundColor : colors.grey[200],

                         margin: "0.5rem",
                         fontSize: "0.9rem"
                     },
                     "& .span1" : {


                         marginLeft: "0.5rem",

                     },

                 }}
            >


                {user === 1 ?
                    <Autocomplete
                        size="small"
                        id="combo-box-demo"
                        options={users}
                        value={selectedUser}
                        onChange={handleUserFilterChange} // Update this line
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} label="Utilisateur" />}
                    />: null}
                <TextField
                    style={{ marginTop: '0.5rem', width: '10rem' }}
                    type="year"
                    size="small"
                    id="start"
                    name="start"
                    onChange={handleDateFilterChange}
                    select
                    SelectProps={{
                        native: true,
                    }}
                >
                    {years.map((year) => (
                        <option key={year} value={year}>
                            {year}
                        </option>
                    ))}
                </TextField>

                <TextField
                    size="small"
                    style={{ marginTop: '0.5rem', width: '12rem' }}
                    type="month"
                    id="start"
                    onChange={handleMonthFilterChange}
                    name="start"
                    select
                    SelectProps={{
                        native: true,
                    }}
                >
                    {mois.map((moisName, index) => (
                        <option key={moisName.name} value={index+1}>
                            {moisName.name}
                        </option>
                    ))}
                </TextField>
                <Autocomplete className="Autocomplete"
                              size="small"
                              id="combo-box-demo"
                              value={selectedDevise}
                              options={listdevise}
                              onChange={handleDeviseFilterChange}
                              renderInput={(params) => <TextField {...params} label="Devise" />}
                />



            </Box>
            <Box display="flex" justifyContent="space-between" sx={{width: "95%",marginX: "1.5%"}}>
                <Box
                    height="75vh"
                    width="45%"
                    marginTop='-7vh'
                >
                    <Table dataToRender={devise3} headCells={colonnesEntete} colsToShow={[2,4,5]} />
                    <Box justifyContent="space-between">



                        <Box  display="flex" justifyContent="end"
                              sx={{
                                  "& .contrevaleur":{
                                      color: colors.grey[100],
                                      fontSize: "1.2rem",
                                  },
                                  "& .contrev":{
                                      backgroundColor:colors.primary[800],
                                      color: colors.grey[100],
                                      fontSize: "1.2rem",
                                      marginLeft: "0.2rem",
                                      marginBottom: "0.5rem",
                                      paddingX:"1rem",
                                      paddingY:"0.5rem",
                                  }
                              }}
                        ><span className="contrevaleur" >Total contre valeurs : </span> <span className="contrev"  >{formatNumber(sumCv)} </span></Box>
                        <Box  justifyContent="center" display="flex"
                              sx={{
                                  "& .MuiFormControl-root" : {
                                      marginRight:"1rem",
                                      width:"6rem",

                                  },
                                  "& .MuiAutocomplete-root":{
                                      marginTop:"8px"
                                  },
                                  "& .buttons" : {
                                      color: colors.grey[200],
                                      backgroundColor : colors.primary[700],
                                      margin: "0.5rem",
                                      fontSize: "0.8rem",
                                      height: "3.4rem" ,
                                      paddingX:"1.5rem"
                                  },
                                  "& .buttons:hover" : {
                                      color: colors.primary[400],
                                      backgroundColor : colors.grey[200],

                                      margin: "0.5rem",
                                      fontSize: "0.9rem"
                                  },
                                  "& .span1" : {


                                      marginLeft: "0.5rem",

                                  }
                              }}
                        >
                            <Button className="buttons"  onClick={handlePrintev}><FindInPageOutlinedIcon/><span className="span1">Aperçu</span> </Button>
                        </Box>
                    </Box>

                </Box>
                <Box width="50%" m="-3.5vh 0 0 0">
                    <Box sx={{display:'flex',justifyContent:'center'}}>
                        <Autocomplete className="Autocomplete"
                                      size="small"
                                      id="combo-box-demo"
                                      sx={{width:'50%'}}
                                      value={selectedType}
                                      options={type}
                                      onChange={handleType}
                                      renderInput={(params) => <TextField {...params} label="Type graphique" />}
                        />
                    </Box>

                    {/*graph*/}
                    <Box sx={{display:"flex",justifyContent:"centre",whidth:'100%',marginTop:"-2vh"}}>
                        {renderChart()}
                    </Box>




                </Box>
            </Box>
            <div style={{ display: 'none' }}>
                <PDFContent data={devise2} agence={agence} data2={data2} ref={componentRef} user={user}/>
            </div>
        </Box>
    );
};

export default Statistiques;
