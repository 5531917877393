import { Box } from "@mui/material";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {useEffect, useState,useRef} from 'react';
import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import axios from "axios";
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {formatNumber} from '../../../fonctions/formatNumber'
const Statistiques = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const users=["user1","user2"];
    const [page, setPage] =useState(0);
    const [rowsPerPage, setRowsPerPage] =useState(10);
    const [ciasse,setCaisse]=useState([]);
    const [selectedCiasse,setSelectedCaisse]=useState(null);
    const [types,setTypes]=useState(["Globalisé","Détaillé"]);
    const [rows,setRows]=useState([]);
    const [rows2,setRows2]=useState([]);
    const [rows3,setRows3]=useState([]);
    const [rows4,setRows4]=useState([]);
    const [selected,setSelected]=useState({
        type:"Globalisé",
        bool:'false',
    });
    const [shouldPrint,setShouldPrint]=useState(true);

    const [user,setuser]=useState();
    const [soldeAc,setSoldeAc]=useState();
    const [selectedDate, setSelectedDate] = useState(dayjs());

    /**************************************************functions***************************************** */
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const convertDateFormat = (dateString) => {
        const parsedDate = dayjs(dateString, 'DD/MM/YYYY'); // Parse the date with the original format
        return parsedDate.format('DD/MM/YYYY');
    };
    useEffect(() => {
        const fetchData = async () => { // Define an async function
            try {
                const response = await axios.get(`api/consultation-caisse?date=${convertDateFormat(selectedDate)}`);
                if (response.data.status === 200) {
                    const { message2, message3, caisses, user } = response.data;
                    setCaisse(caisses);
                    setRows(message2);
                    setRows2(message2);
                    setRows3(message3);
                    setRows4(message3);
                    setuser(user);
                    setShouldPrint(false);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData(); // Call the async function

    }, [shouldPrint]);

    const handleDateChange = async ( newValue) => {
        setSelected((prevState) => ({ ...prevState, type: newValue }));
        setSelectedDate(newValue)
        setShouldPrint(true);
    };


    const handleCiassesFilterChange = (event, newValue) => {
        setSelectedCaisse(newValue);
        let rs1=rows;
        rs1=handleCiassesFilterChange2( newValue,rs1)
        setRows2(rs1);
        let rs2=rows3;
        rs2=handleCiassesFilterChange2( newValue,rs2)
        setRows4(rs2);
    };


    const handleCiassesFilterChange2 = ( newValue,bor) => {
        if(newValue){
            const filteredItems = bor.filter((item) =>
                item.id_caisse===newValue.id
            );

            return filteredItems;
        }else{

            return bor;
        }


    }
    const calculateSum=(newValue) => {
        const newSumCv = newValue.reduce((accumulator, currentDevise) => {
            return accumulator + currentDevise.cv;
        }, 0);
        return newSumCv;
    }
    /***********************************************useeffect****************************************************** */
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`api/solde-debut`);
                if (response.data.status === 200) {
                    const { message2,message3, caisses } = response.data;
                    setCaisse(caisses);
                    setRows(message2);
                    setRows2(message2);
                    setRows3(message3);
                    setRows4(message3);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);


    useEffect(() => {
        if (rows2) {
            const result = rows2.reduce((acc, item) => {
                const { montant, nom_devise } = item;
                const key = `${nom_devise}`;

                if (acc[key]) {
                    acc[key] += montant;
                } else {
                    acc[key] = montant;
                }

                return acc;
            }, {});

            const formattedResult = Object.keys(result).map((key, index) => {
                const [nom_devise] = key.split("-");
                const id = `entry_${index}`;
                return {
                    id,
                    nom_devise,
                    montant: result[key],
                };
            });

            setRows2(formattedResult);
        }
    }, [rows2]);


    useEffect(() => {
        if (rows4) {
            const result = rows4.reduce((acc, item) => {
                const { montant, nom_devise } = item;
                const key = `${nom_devise}`;

                if (acc[key]) {
                    acc[key] += montant;
                } else {
                    acc[key] = montant;
                }

                return acc;
            }, {});

            const formattedResult = Object.keys(result).map((key, index) => {
                const [nom_devise] = key.split("-");
                const id = `entry_${index}`;
                return {
                    id,
                    nom_devise,
                    montant: result[key],
                };
            });

            setRows4(formattedResult);
        }
    }, [rows4]);

    /************************************return**************************************** */

    const [selectedRow, setSelectedRow] = useState(null);

    const handleRowClick = (row) => {
        setSelectedRow(row);
    };
    return (
        <Box m="20px" sx={{
            "& .bartitle":{
                marginY : "1.5rem",
            }
        }}>

            <Box display="flex" justifyContent="center" marginBottom="0.5rem"
                 sx={{
                     "& .MuiFormControl-root" : {
                         marginRight:"1rem",
                         width:"11rem",

                     },
                     "& .MuiAutocomplete-root":{
                         marginTop:"8px"
                     },
                     "& .buttons" : {
                         color: colors.grey[200],
                         backgroundColor : colors.primary[400],
                         margin: "0.5rem",
                         fontSize: "0.8rem",
                         height: "3.4rem" ,
                         paddingX:"1.5rem"
                     },
                     "& .buttons:hover" : {
                         color: colors.primary[400],
                         backgroundColor : colors.grey[200],

                         margin: "0.5rem",
                         fontSize: "0.9rem"
                     },
                     "& .span1" : {


                         marginLeft: "0.5rem",

                     },

                 }}
            >


                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                        <DatePicker
                            label="Date"
                            value={selectedDate}
                            onChange={handleDateChange}
                            format="DD/MM/YYYY"
                            sx={{
                                '& .MuiInputBase-input': {
                                    padding: '10px 14px',
                                    fontSize: '0.875rem',
                                },
                            }}
                        />
                    </DemoContainer>
                </LocalizationProvider>

                {user===1?<Autocomplete className="Autocomplete"
                                        size="small"
                                        id="combo-box-demo"
                                        value={selectedCiasse}
                                        options={ciasse}
                                        onChange={handleCiassesFilterChange} // Update this line
                                        getOptionLabel={(option) => option.designation}

                                        renderInput={(params) => <TextField {...params} label="Caisse" />}
                />:null}



            </Box>
            <Box display="flex" justifyContent="space-between"
                 sx={{"& .solde":{
                         marginTop:"20vh"
                     }}}
            >
                <Box
                    width="45%">
                    <Box
                        height="75vh"
                        sx={{
                            "& .MuiDataGrid-root": {
                                border: "none",
                            },
                            "& .MuiDataGrid-cell": {
                                borderBottom: "none",
                            },
                            "& .name-column--cell": {
                                color: colors.greenAccent[300],
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                backgroundColor: colors.blueAccent[700],
                                borderBottom: "none",
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                backgroundColor: colors.primary[400],
                            },
                            "& .MuiDataGrid-footerContainer": {
                                borderTop: "none",
                                backgroundColor: colors.blueAccent[700],
                            },
                            "& .MuiCheckbox-root": {
                                color: `${colors.greenAccent[200]} !important`,
                            },
                            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                color: `${colors.grey[100]} !important`,
                            },
                        }}
                    >
                        <h2>Solde début en devise</h2>
                        <Paper >
                            <TableContainer  sx={{height:"65vh",marginTop:"1vh",backgroundColor:"#f2f0f0"}} >
                                <Table stickyHeader aria-label="sticky table" >
                                    <TableHead sx={{backgroundColor:"#a4a9fc"}}>
                                        <TableRow >
                                            <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Devise</TableCell>
                                            <TableCell align="right" sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Montant</TableCell>
                                        </TableRow>

                                    </TableHead>
                                    <TableBody>
                                        {rows2 && Array.isArray(rows2) && rows2.map((row) => (
                                            row.montant !== 0 ? (
                                                <TableRow  key={row.id} onClick={() => handleRowClick(row)}
                                                           sx={{
                                                               backgroundColor:
                                                                   selectedRow && selectedRow.id === row.id ? '#ccc' : 'inherit',
                                                           }}>
                                                    <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{row.nom_devise}</TableCell>
                                                    <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}} align="right">{formatNumber(row.montant)}</TableCell>
                                                </TableRow>
                                            ) : null
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </Paper>



                        <Box display="flex" justifyContent="center"
                             sx={{
                                 "& .MuiFormControl-root" : {
                                     marginRight:"1rem",
                                     width:"6rem",
                                 },
                                 "& .MuiAutocomplete-root":{
                                     marginTop:"8px"
                                 },
                                 "& .buttons" : {
                                     color: colors.grey[200],
                                     backgroundColor : colors.primary[700],
                                     margin: "0.5rem",
                                     marginTop:"1rem",
                                     fontSize: "0.8rem",
                                     height: "3.4rem" ,
                                     paddingX:"1.5rem"
                                 },
                                 "& .buttons:hover" : {
                                     color: colors.primary[400],
                                     backgroundColor : colors.grey[200],

                                     margin: "0.5rem",
                                     marginTop:"1rem",
                                     fontSize: "0.9rem"
                                 },
                                 "& .span1" : {
                                     marginLeft: "0.5rem",
                                 }
                             }}
                        >
                        </Box>
                    </Box>
                </Box>
                <Box
                    width="45%">
                    <Box
                        height="75vh"
                        sx={{
                            "& .MuiDataGrid-root": {
                                border: "none",
                            },
                            "& .MuiDataGrid-cell": {
                                borderBottom: "none",
                            },
                            "& .name-column--cell": {
                                color: colors.greenAccent[300],
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                backgroundColor: colors.blueAccent[700],
                                borderBottom: "none",
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                backgroundColor: colors.primary[400],
                            },
                            "& .MuiDataGrid-footerContainer": {
                                borderTop: "none",
                                backgroundColor: colors.blueAccent[700],
                            },
                            "& .MuiCheckbox-root": {
                                color: `${colors.greenAccent[200]} !important`,
                            },
                            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                color: `${colors.grey[100]} !important`,
                            },
                        }}
                    >
                        <h2>Solde fin en devise</h2>
                        <Paper >
                            <TableContainer  sx={{height:"65vh",marginTop:"1vh",backgroundColor:"#f2f0f0"}} >
                                <Table stickyHeader aria-label="sticky table" >
                                    <TableHead sx={{backgroundColor:"#a4a9fc"}}>
                                        <TableRow >
                                            <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Devise</TableCell>
                                            <TableCell align="right" sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#a4a9fc" }}>Montant</TableCell>
                                        </TableRow>

                                    </TableHead>
                                    <TableBody>
                                        {rows4 && Array.isArray(rows4) && rows4.map((row) => (
                                            row.montant !== 0 ? (
                                                <TableRow  key={row.id} onClick={() => handleRowClick(row)}
                                                           sx={{
                                                               backgroundColor:
                                                                   selectedRow && selectedRow.id === row.id ? '#ccc' : 'inherit',
                                                           }}>
                                                    <TableCell sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{row.nom_devise}</TableCell>
                                                    <TableCell align="right" sx={{ paddingY: "0.5rem" ,fontSize:"0.9rem"}}>{formatNumber(row.montant)}</TableCell>
                                                </TableRow>
                                            ) : null
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </Paper>



                        <Box display="flex" justifyContent="center"
                             sx={{
                                 "& .MuiFormControl-root" : {
                                     marginRight:"1rem",
                                     width:"6rem",

                                 },
                                 "& .MuiAutocomplete-root":{
                                     marginTop:"8px"
                                 },
                                 "& .buttons" : {
                                     color: colors.grey[200],
                                     backgroundColor : colors.primary[700],
                                     margin: "0.5rem",
                                     marginTop:"1rem",
                                     fontSize: "0.8rem",
                                     height: "3.4rem" ,
                                     paddingX:"1.5rem"
                                 },
                                 "& .buttons:hover" : {
                                     color: colors.primary[400],
                                     backgroundColor : colors.grey[200],

                                     margin: "0.5rem",
                                     marginTop:"1rem",
                                     fontSize: "0.9rem"
                                 },
                                 "& .span1" : {


                                     marginLeft: "0.5rem",

                                 }
                             }}
                        >
                        </Box>
                    </Box>
                </Box>

            </Box>
        </Box>
    );
};

export default Statistiques;
