import * as React from 'react';
import { Box, Typography, useTheme } from "@mui/material";

import { tokens } from "../../theme";
import Header from "../../components/Header";

import {Tabs,Tab,AppBar} from "@mui/material";
import Consultation from "../../components/GestionCaisses/Consultation/index";
import Passagedevise from "../../components/GestionCaisses/PassageDevise/index";
import Operationsdecaisse from "../../components/GestionCaisses/OperationsCaisse/index";
import Journaldecaisse from "../../components/GestionCaisses/JournalCaisse/index";
import Caisseactuelle from "../../components/GestionCaisses/CaisseActuelle/index";
import Achatdedirhams from "../../components/GestionCaisses/AchatDirhams";
import "../index.css";
import axios from "axios";
import { useState,useEffect } from "react";

const Gestionbanque = () => {
  const [droits,setDroits]=useState([]);
  const [user,setUser]=useState({
    id: 1,
        name: "",
        email: "",
        email_verified_at: "",
        motPass: "",
        id_agence: "",
        created_at: "",
        updated_at: "",
        id_caisse: "",
        activer: "",
        admin: ""
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        
        const response = await axios.get(`api/droits-acces`);
        setDroits(response.data.droits);
        setUser(response.data.user);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
    }, []);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [value,setValue]=React.useState(0);
  const handleTabs=(r,val)=>{
    console.warn(val)
    setValue(val)
  }

  const Gestionbanque = () => {
    // Determine the indices based on the droits.find conditions
    let ind=1;
    const passageDeviseIndex = droits.find((droit) => droit.idMenu === 11) ? ++ind : -1;
    const achatDirhamsIndex = droits.find((droit) => droit.idMenu === 12) ? ++ind : -1;

    return (
      <Box className="gestion-devise-content">
        <TabPanel value={value} index={0}>
          <Caisseactuelle />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Consultation />
        </TabPanel>
        {passageDeviseIndex !== -1 && (
          <TabPanel value={value} index={passageDeviseIndex}>
            <Passagedevise />
          </TabPanel>
        )}
        {achatDirhamsIndex !== -1 && (
          <TabPanel value={value} index={achatDirhamsIndex}>
            <Achatdedirhams />
          </TabPanel>
        )}
        <TabPanel value={value} index={++ind}>
          <Operationsdecaisse />
        </TabPanel>
        <TabPanel value={value} index={++ind}>
          <Journaldecaisse />
        </TabPanel>
      </Box>
    );
  };
  
  return (
    <Box className="gestion-devise-container">
      <Header title="GESTION DE CAISSE"  />
      <Box >
        <AppBar position='static'  >
          <Tabs value={value} onChange={handleTabs} >
            <Tab label="Caisse Actuelle" />
            <Tab label="Consultation"/>
            {droits.find((droit) => droit.idMenu === 11) ? (<Tab label="Passage devise"/>) : null}
            {droits.find((droit) => droit.idMenu === 12) ? (<Tab label="Achat de dirhams"/>) : null}
            <Tab label="Operations de caisse"/>
            <Tab label="Journal de caisse"/>
            
          </Tabs>
        </AppBar>
        
      </Box>
        
      <Box className="gestion-devise-content">
      {Gestionbanque()}
      
     
      </Box>
      
    </Box>
  );
  
};
const TabPanel = (props) => {
  const {children,value,index}=props;
  return (
    
      value===index && (
        <div>{children}</div>
      )
      
    
  );
};
export default Gestionbanque;
