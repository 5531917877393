import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import axios from "axios";
import Connexion from "./scenes/Login";
import AdminPrivateRoute from './AdminPrivateRoute';
import SuperAdmin from './scenes/superAdmin';
import Logo from './data/logo.png';

axios.defaults.baseURL = "https://api.changemaroc.com/";
axios.defaults.headers.post['Content-Type'] = "application/json";
axios.defaults.headers.post['Accept'] = "application/json";

axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem("auth_token");
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
});

const callApisDaily = async () => {
    try {
        await axios.get('api/cours');
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

const handleBeforeUnload = (event) => {
    // Display a confirmation dialog to the user
    event.preventDefault();
    event.returnValue = 'Are you sure you want to leave? Your session may be lost.';
};

function App() {
    const [verification, setVerification] = useState(null);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [currentDate, setCurrentDate] = useState(new Date());
    //
    // useEffect(() => {
    //     const intervalId = setInterval(() => {
    //         const now = new Date();
    //         setCurrentDate(now);
    //
    //         if (
    //             now.getHours() === 20 &&
    //             now.getMinutes() >= 27 &&
    //             now.getSeconds() === 0 &&
    //             now.getMilliseconds() === 0
    //         ) {
    //             callApisDaily();
    //         }
    //     }, 1000);
    //
    //     return () => clearInterval(intervalId);
    // }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`api/checkingAuthenticated`);
                setVerification(response.data.status);
                setUser(response.data.us);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    // useEffect(() => {
    //     let timeoutId;
    //
    //     const resetTimer = () => {
    //         clearTimeout(timeoutId);
    //         timeoutId = setTimeout(() => {
    //             axios.post('api/logout').then(() => {
    //                 localStorage.removeItem("auth_token");
    //                 window.location.reload(); // Reload to reflect logout
    //             }).catch(error => {
    //                 console.error('Error logging out:', error);
    //             });
    //         }, 30 * 1000); // Adjust the timeout as needed
    //     };
    //
    //     const handleActivity = () => {
    //         resetTimer();
    //     };
    //
    //     const handleVisibilityChange = () => {
    //         if (document.visibilityState === 'hidden') {
    //             resetTimer();
    //         } else {
    //             clearTimeout(timeoutId);
    //         }
    //     };
    //
    //     window.addEventListener('mousemove', handleActivity);
    //     window.addEventListener('keydown', handleActivity);
    //     document.addEventListener('visibilitychange', handleVisibilityChange);
    //
    //     resetTimer(); // Start the timer initially
    //
    //     return () => {
    //         clearTimeout(timeoutId);
    //         window.removeEventListener('mousemove', handleActivity);
    //         window.removeEventListener('keydown', handleActivity);
    //         document.removeEventListener('visibilitychange', handleVisibilityChange);
    //     };
    // }, []);

    if (loading) {
        return (
            <div>
                <div style={{ marginLeft: '20vw', marginTop: "25vh", width: "60vw", height: "50vh" }}>
                    <img style={{ width: "50vw", height: "40vh", marginLeft: '5vw', marginTop: "5vh" }} src={Logo} />
                </div>
            </div>
        );
    }

    return (
        <div className="app">
            <Router>
                <Switch>
                    <AdminPrivateRoute path="/espace-magchange" verification={verification} user={user} />
                    <Route path="/super_admin">
                        {parseFloat(verification) === 200 && parseFloat(user) === 2 ? <SuperAdmin /> : <Connexion />}
                    </Route>
                    <Route path="/">
                        {parseFloat(verification) === 200 && (parseFloat(user) === 1 || parseFloat(user) === 0) ? <Redirect to="/espace-magchange" /> : parseFloat(verification) === 200 && parseFloat(user) === 2 ? <Redirect to="/super_admin" /> : <Connexion />}
                    </Route>
                </Switch>
            </Router>
        </div>
    );
}

export default App;
