import React from 'react';
import { Box } from "@mui/material";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import Autocomplete from '@mui/material/Autocomplete';
import {useEffect, useState,useRef} from 'react';
import {Button} from "@mui/material";
import swal from "sweetalert";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import axios from "axios";
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { useReactToPrint } from "react-to-print";
import Logo from './../../../data/bg-05.gif'
import {Link, useParams} from 'react-router-dom';
import {formatNumber} from '../../../fonctions/formatNumber';
import PDFGestionErreurAchat from '../PDFContent/GestionErreurAchat/index'
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
class PDFContent extends React.Component {
    render() {
        const { data,agence,data2,data3} = this.props;

        return (
            <PDFGestionErreurAchat data={data} agence={agence} data2={data2} data3={data3}/>
        );
    }
}
const Geachat = () => {
    const { id } = useParams();
    const history=useHistory();
    const [inputColor, setInputColor] = useState("#000000de"); // Initial color is black
    const [devise,setDevise]=useState([]);
    const [bordereau,setBordereau]=useState([]);
    const [submitValue,setSubmitValue]=useState({
        heure_achat :'',
        date_achat :'',
        num_bac :'',
        prenom :'',
        adresse :'',
        nationalite :'MAROC',
        type_pid:'',
        n_pid:'',
        email: "",
        updated_at:'',
        qualite_cl:'MR | MR | Marocains résidants',
        code_nop:'Autres',
        num_dec:'',
        date_dec:'',
        Brd_ac:'',
    })
    const componentRef = useRef();
    const [shouldPrint, setShouldPrint] = useState(false);
    const [agence, setAgence] = useState(false);
    const [coursAchat, setCoursAchat] = useState('');
    const [operationsac,setOperationsac]=useState([]);
    const [client,setClient]=useState([]);
    submitValue.email=localStorage.getItem("auth_email");
    const handleInput2=(e)=>{
        e.persist();
        setSubmitValue({...submitValue, [e.target.name]: e.target.value})
    }
    const handleInput3 = (e, newValue) => {
        setSubmitValue({ ...submitValue, code_nop: newValue }); // Set the selected option directly
    };
    const handleInput4=(e,newValue)=>{
        setSubmitValue({ ...submitValue, nationalite: newValue });

    }
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);




    const [rows, setRows] = useState([]);

    const [montant, setMontant] = useState('');
    const [contreValeur, setContreValeur] = useState('');

    const sumCv2 = operationsac.reduce((accumulator, currentDevise) => {
        return accumulator + currentDevise.cv_ac;
    }, 0);
    const handleInsert = () => {
        if (montant  && montant!=0 && coursAchat!=0) {
            const existingDevise=rows.find((row)=>row.devise===selectedDevise.nom_devise);
            const ancienDevise=operationsac.find((row)=>row.nom_devise===selectedDevise.nom_devise);
            const deviseCaisse=devise.find((row)=>row.nom_devise===selectedDevise.nom_devise);

            const Devi=devise.find((row)=>row.nom_devise==='DHS');
            if(parseFloat(deviseCaisse?deviseCaisse.montant:0)<(parseFloat(ancienDevise?ancienDevise.mt_ac:0)-parseFloat(montant))){
                const text="Le montant de "+ selectedDevise.nom_devise+ " en caisse est insuffisant."
                swal('',text,'warning');
            }else{
                if(parseFloat(contreValeurs)<=(parseFloat(Devi.montant)+sumCv2-parseFloat(sumCv))){
                    if(!existingDevise){
                        const nbr=montant/selectedDevise.copure;
                        if(Number.isInteger(nbr)){
                            const newRow = {
                                devise: selectedDevise.nom_devise,
                                montant: montant,
                                coursAchat: coursAchat,
                                contreValeur: contreValeurs,
                            };
                            setContreValeur(0);
                            setMontant(0);
                            setRows((prevRows) => [...prevRows, newRow]);
                            setInputColor("#000000de");

                        }else{
                            swal('',"Veuillez respecter les coupures.",'warning');
                        }
                    }else{
                        swal('',"Devise déja inséré.",'warning');
                    }
                }else{
                    swal('',"Alimenter votre caisse.",'warning');
                }
            }
        }

    };

    const [selectedRow2, setSelectedRow2] = useState([]);
    const handleDeleteRows = () => {
        if (selectedRow !== null) {
            swal({
                title: '',
                text: 'Voulez-vous vraiment supprimer cette ligne ?',
                icon: 'warning',
                buttons: ['Annuler', 'Oui'],
                dangerMode: true,
            }).then((confirmed) => {
                if (confirmed) {
                    const updatedRows = rows.filter((row) => row.devise !== selectedRow.devise);
                    const existingDevise = listdevise.find((row) => row.nom_devise === selectedRow.devise);
                    setSelectedDevise(existingDevise);
                    setCoursAchat(parseFloat(selectedRow.coursAchat).toFixed(4));
                    setMontant(selectedRow.montant);
                    setContreValeur(selectedRow.contreValeur); // Assuming setContreValeur is a state setter function
                    setSelectedRow(null);
                    setRows(updatedRows);


                }
            });
        }
    };

    useEffect(()=>{

        axios.get(`api/modifier-bordereau-achat?id=${id}`).then(res=>{
            if(res.data.status===200){
                setBordereau(res.data.bordereauAchat);
                setOperationsac(res.data.operationsAchat);
                setClient(res.data.client)
            }});

        try {
            axios.get(`api/info-agence`).then(res=>{
                if(res.data.status===200){
                    setAgence(res.data.message);

                }})

        } catch (error) {
            // Handle errors from the first API call
            console.error(error);
        }
    },[]);
    const [selectedDevise,setSelectedDevise]=useState({
        id_devise: "",
        nom_devise: "EUR",
        designation: "",
        prix_par: "",
        copure: "",
        compte: "",
        tauxA: "",
        tauxVC: "",
        tauxVB: ""
    });
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: `
      @page {
        size: A4;
        margin: 10mm;
      }
      /* Additional styles for the printed page can be added here */
      /* Remove the pagination and URL styles */
      @media print {
        .MuiDataGrid-footerContainer {
          display: none !important;
        }
        a {
          display: none !important;
        }
      }
    `,
        header: null, // Set header to null to remove it from the PDF
        footer: null,
        // Set footer to null to remove it from the PDF
    });

    const sumCvAc = devise.reduce((accumulator, currentDevise) => {
        if(currentDevise.nom_devise !== "DHS"){
            return accumulator + currentDevise.cv_ac;
        }else{
            return accumulator;
        }
    }, 0);
    const sumCvAcWithTwoDecimals = sumCvAc.toFixed(2);
    const sumCv = rows.reduce((accumulator, currentDevise) => {
        return accumulator +  currentDevise.contreValeur;
    }, 0);
    const [validerOp, setValiderOp] = useState(false);

    const submitall = (e) => {
        e.preventDefault();
        if (validerOp) {
            return; // If already submitting, do nothing
        }

        setValiderOp(true);
        const data = {
            id_bac: bordereau.id,
            rows2: operationsac.map((row) => ({
                devise: row.nom_devise,
                mt_ac: row.mt_ac,
                cours_ac: row.cours_ac,
            })),
            rows: rows.map((row) => ({
                devise: row.devise,
                montant: row.montant,
                coursAchat: row.coursAchat,
                contreValeur: row.contreValeur,
            })),
        };


        swal({
            title: '',
            text: 'Voulez vous vraiment valider ce bordereau ?',
            icon: 'warning',
            buttons: ['Annuler', 'Oui'],
            dangerMode: true,
        }).then((confirmed) => {
            if (confirmed) {
                // User clicked "Oui," proceed with form submission
                axios
                    .post('api/modifier-bordereau-achat', data)
                    .then((res) => {
                        // ... Handle the response from the server ...
                        if (res.data.status === 200) {
                            // Trigger the printing action first
                            setShouldPrint(true);

                            // Navigate to the new route after printing action
                        } else if (res.data.status === 400) {
                            swal(
                                '',
                                "Pour enregistrer cette opération, il est nécessaire de saisir les informations de l'opération d'achat.",
                                'warning'
                            );
                        } else {
                            swal('', 'Erreur.', 'warning');
                        }
                    })
                    .catch((error) => {
                        // Handle any error that occurred during the request
                        console.error('Error submitting form:', error);
                    }).finally(() => {
                    setValiderOp(false);
                });
            }else {
                setValiderOp(false); // User clicked "Annuler"
            }
        });
    };
    const [infodevise, setInfodevise] = useState([]);
    const [selectedDevQC, setSelectedDevQC] = useState(null);
    useEffect(() => {
        if (shouldPrint) {
            // Call handlePrint() when shouldPrint becomes true
            handlePrint(); // This will trigger the printing action
            history.push('/espace-magchange/achats-clients')
            setShouldPrint(false); // Reset shouldPrint back to false
        }
    }, [shouldPrint, handlePrint, history]);
    useEffect(() => {
        axios.get('api/info-devise').then((res) => {
            if (res.data.status === 200) {
                setInfodevise(res.data.message);
            }
        });
    }, []);

    // Handler to update the selectedDevise state when a devise is selected from Autocomplete
    const handleDeviseChange = (event, newValue) => {
        setInputColor("#000000de");
        setSelectedDevise(newValue);
        setCoursAchat(parseFloat(newValue.tauxA).toFixed(4));
    };

    // Handler to update the Montant state when the value changes in the "Montant" TextField
    const handleMontantChange = (event) => {
        const inputValue = event.target.value;
        if (/^\d+$/.test(inputValue) || inputValue === '') {
            setMontant(inputValue);
        }
    };

    // Calculate Contre Valeur based on the selectedDevise's tauxA and the Montant
    const contreValeurs = selectedDevise ? (coursAchat * montant)/selectedDevise.prix_par : 0;
    const [selectedRow, setSelectedRow] = useState(null);
    const handleRowClick = (row) => {
        setSelectedRow(row);
    };
    const handleRowClick2 = (row) => {
        setSelectedRow2(row);
    };
    //style
    const BootstrapInput = styled(InputBase)(({ theme }) => ({
        'label + &': {
            marginTop: theme.spacing(2),
        },
        '& .MuiInputBase-input': {
            borderRadius: 4,
            position: 'relative',
            backgroundColor: theme.palette.mode === 'light' ? '#F3F6F9' : '#1A2027',
            border: '1px solid',
            borderColor: theme.palette.mode === 'light' ? '#E0E3E7' : '#2D3843',
            fontSize: 16,
            padding: '10px 12px',
            transition: theme.transitions.create([
                'border-color',
                'background-color',
                'box-shadow',
            ]),
            // Use the system font instead of the default Roboto font.
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            '&:focus': {
                boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                borderColor: theme.palette.primary.main,
            },
            "& .MuiInputLabel-root":{
                fontSize:20
            }
        },
    }));
    const [selectedQC,setSelectedQC]=useState([]);
    const [listdevise,setlistdevise]=useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        axios.get(`api/detail-caisse`).then(res=>{
            if(res.data.status===200){
                setDevise(res.data.message);
                setSelectedQC(res.data.selectedQC);
                setlistdevise(res.data.devise)
                const sd=(res.data.devise).find((op)=>op.nom_devise==="EUR");
                setSelectedDevise(sd);
                setCoursAchat(parseFloat(sd.tauxA).toFixed(4));

                setLoading(false);

            }
        })},[]);
    if (loading) {
        return <div ><div style={{marginLeft:'37.5%',marginTop:"30vh"}}>
            <img style={{width:"25%",height:"40vh"}} src={Logo}/>

        </div></div>;
    }
    const handleCoursChange = (event) => {
        if(parseFloat(coursAchat)===0){
            swal('',"Merci de modifier le cours d'achat de "+selectedDevise.nom_devise+" dans l'espace de gestion des devises.",'warning');
        }else{
            if (/^\d{0,3}(\.\d{0,4})?$/.test(event.target.value)) {
                setCoursAchat(event.target.value);
            }

        }
    }
    return (

        <Box className="type2" sx={{marginTop: '8vh',
            '& .MuiInputBase-input': {
                height: '18px',
                fontSize:'16px',
            },
            '& .MuiInputLabel-root': {
                fontSize:'14px',

            },
            '& .header-operation': {
                display:"flex", justifyContent:'space-between', padding:"0.1vh 15% 0.1vh 15%",
                backgroundColor:"rgb(243, 240, 238)",
                margin:"-2vh 9% 0.5vh 9%"

            }
        }}>
            <Box className="header-operation" sx={{"& .cancel-icon": {
                    marginTop: "1.2vh"
                }}}>
                <Box sx={{
                    "& .operation-title": {
                        fontSize: "1.8rem",
                        color:'#0f2479'
                    },

                }}>
                    <h2 className="operation-title">Gestion d'erreur d'achat</h2>
                </Box>
                <Box className="cancel-icon">

                    <Link to="/espace-magchange/achats-clients"><CancelPresentationIcon /></Link>

                </Box>

            </Box>
            <Box className="type3">
                <Box  className="form1"
                      sx={{
                          "& .MuiOutlinedInput-input":{
                              heigh:"0.5rem"}
                      }}
                >

                    <Box >
                        <h2  style={{marginBottom:'1vh', marginTop:'-1vh',fontSize: "1.4rem"}}>Les informations d'ancien bordereau</h2>

                        <Box display="flex">
                            <Box display="flex" sx={{width:`20%`}}>
                                <FormControl variant="standard" >
                                    <InputLabel shrink htmlFor="6-input">
                                        N° Bordereau
                                    </InputLabel>
                                    <BootstrapInput disabled size="small" name="idfis" value={bordereau.num_bac}  id="6-input" autocomplete="organization"/>
                                </FormControl>
                            </Box>
                            <Box display="flex" sx={{width:`25%`,marginLeft:"3%"}}>
                                <FormControl variant="standard" >
                                    <InputLabel shrink htmlFor="6-input">
                                        Client
                                    </InputLabel>
                                    <BootstrapInput disabled size="small" name="idfis" value={(client.nom===null?'':client.nom)+' '+(client.prenom===null?'':client.prenom)}  id="6-input" autocomplete="organization"/>
                                </FormControl>
                            </Box>
                            <Box display="flex" sx={{width:`20%`,marginLeft:"3%"}}>
                                <FormControl variant="standard" >
                                    <InputLabel shrink htmlFor="6-input">
                                        Date
                                    </InputLabel>
                                    <BootstrapInput disabled size="small" name="idfis" value={bordereau.date_achat}  id="6-input" autocomplete="organization"/>
                                </FormControl>
                            </Box>
                            <Box display="flex" sx={{width:`20%`,marginLeft:"3%"}}>
                                <FormControl variant="standard" >
                                    <InputLabel shrink htmlFor="6-input">
                                        Heure
                                    </InputLabel>
                                    <BootstrapInput disabled size="small" name="idfis" value={bordereau.heure_achat}  id="6-input" autocomplete="organization"/>
                                </FormControl>
                            </Box></Box>

                        <Box sx={{marginTop:"1vh"}}>
                            <TableContainer component={Paper} >
                                <Table>
                                    <TableHead>
                                        <TableRow>

                                            <TableCell sx={{
                                                paddingY: "0.8rem",
                                                fontSize: "0.9rem",
                                                backgroundColor: "#e5a984"
                                            }}>Devise</TableCell>
                                            <TableCell align="right" sx={{
                                                paddingY: "0.8rem",
                                                fontSize: "0.9rem",
                                                backgroundColor: "#e5a984"
                                            }}>Montant en devise</TableCell>
                                            <TableCell align="right" sx={{
                                                paddingY: "0.8rem",
                                                fontSize: "0.9rem",
                                                backgroundColor: "#e5a984"
                                            }}>Cours Appliqué</TableCell>
                                            <TableCell align="right" sx={{
                                                paddingY: "0.8rem",
                                                fontSize: "0.9rem",
                                                backgroundColor: "#e5a984"
                                            }}>Contre valeur en DHS</TableCell>
                                        </TableRow>
                                    </TableHead>
                                        <TableBody >
                                            {operationsac.map((row) => (
                                                <TableRow

                                                    key={row.id}

                                                >

                                                    <TableCell sx={{paddingY: "0.5rem", fontSize: "0.9rem"}}>
                                                        {row.nom_devise}
                                                    </TableCell>
                                                    <TableCell align="right" sx={{
                                                        paddingY: "0.5rem",
                                                        fontSize: "0.9rem"
                                                    }}>{formatNumber(row.mt_ac)}</TableCell>
                                                    <TableCell align="right" sx={{
                                                        paddingY: "0.5rem",
                                                        fontSize: "0.9rem"
                                                    }}>{parseFloat(row.cours_ac).toFixed(4)}</TableCell>
                                                    <TableCell align="right" sx={{
                                                        paddingY: "0.5rem",
                                                        fontSize: "0.9rem"
                                                    }}>{formatNumber(row.cv_ac)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                    <Box sx={{marginY:"1%"}}>
                        <h2   style={{marginBottom:'1vh', marginTop:'-1vh',fontSize: "1.4rem"}}>Modification</h2>
                        <Box display="flex" sx={{marginY:"1%",width:'82%'}}>
                            <Autocomplete
                                size="small"
                                options={listdevise}
                                getOptionLabel={(option) => option.nom_devise}
                                sx={{ width: "20%" }}
                                renderInput={(params) => <TextField {...params} label="Devise" />}
                                onChange={handleDeviseChange}
                                value={selectedDevise}
                                disableClearable
                            />
                            <Box sx={{marginX:'1%',width:'8%'}}>
                                <img src={require(`./../../drapeaux/${selectedDevise.nom_devise}.png`)} style={{ width: "100%", height: '35px' }} alt="Flag" />
                            </Box>
                            <TextField
                                size="small"
                                sx={{ width: "20%", marginRight: "1%" }}
                                label="Cours achat"
                                onChange={handleCoursChange}
                                value={coursAchat}
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{ style: { color: inputColor } }}
                            />
                            <TextField
                                size="small"
                                sx={{ width: "20%", marginRight: "1%" }}
                                label="Montant"
                                value={montant}
                                type="number"
                                onChange={handleMontantChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{ style: { color: inputColor } }}
                            />
                            <TextField
                                size="small"
                                sx={{ width: "20%", marginRight: "1%" }}
                                label="Contre valeur"
                                value={formatNumber(contreValeurs)}
                                disabled
                            />

                        </Box>
                        <Box display="flex" justifyContent="space-between" sx={{
                            "& .MuiButtonBase-root" : {
                                color: colors.grey[200],
                                backgroundColor : colors.primary[400],
                                marginY: "0.5em",
                                fontSize: "0.8em"
                            },
                            "& .MuiButtonBase-root:hover" : {
                                color: colors.primary[400],
                                backgroundColor : colors.grey[200],

                                marginY: "0.5em",
                                fontSize: "0.9em"
                            },
                            "& .span1" : {




                            }}}>
                            <Box sx={{width:"82%"}}>
                                <TableContainer component={Paper} >
                                    <Table >
                                        <TableHead>
                                            <TableRow>

                                                <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem" ,backgroundColor:"#e5a984"}}>Devise</TableCell>
                                                <TableCell align="right" sx={{ paddingY: "0.8rem", fontSize:"0.9rem" ,backgroundColor:"#e5a984"}}>Montant en devise</TableCell>
                                                <TableCell align="right" sx={{ paddingY: "0.8rem", fontSize:"0.9rem" ,backgroundColor:"#e5a984"}}>Cours Appliqué</TableCell>
                                                <TableCell align="right" sx={{ paddingY: "0.8rem", fontSize:"0.9rem" ,backgroundColor:"#e5a984"}}>Contre valeur en DHS</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row) => (
                                                <TableRow

                                                    key={row.devise}
                                                    onClick={()=>handleRowClick(row)}
                                                    sx={{
                                                        backgroundColor:
                                                            selectedRow && selectedRow.devise === row.devise ? '#ccc' : 'inherit',
                                                    }}
                                                >

                                                    <TableCell sx={{ paddingY: "0.5rem", fontSize:"0.9rem" }}>
                                                        {row.devise}
                                                    </TableCell>
                                                    <TableCell align="right" sx={{ paddingY: "0.5rem", fontSize:"0.9rem" }}>{formatNumber(row.montant)}</TableCell>
                                                    <TableCell align="right" sx={{ paddingY: "0.5rem", fontSize:"0.9rem" }}>{parseFloat(row.coursAchat).toFixed(4)}</TableCell>
                                                    <TableCell align="right" sx={{ paddingY: "0.5rem", fontSize:"0.9rem" }}>{formatNumber(row.contreValeur)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                            <Box sx={{width:"18%"}}>
                                <Box><Button onClick={handleInsert}><AddIcon/><span className="span1">Insérer</span></Button></Box>
                                <Box><Button onClick={handleDeleteRows}><CancelIcon/><span className="span1">Annuler</span></Button></Box>
                                <Box><Button  onClick={submitall}><CheckCircleOutlineIcon/><span className="span1">Valider</span></Button></Box>
                            </Box>

                        </Box>
                        <Box  display="flex"
                              sx={{justifyContent:'end',width:'82%',
                                  "& .contrevaleur":{
                                      color: colors.grey[100],
                                  },
                                  "& .contrevv":{
                                      backgroundColor:colors.primary[800],
                                      color: colors.grey[100],
                                  }
                              }}
                        ><span className="contrevaleur" >Contre valeur en DHS</span> <span className="contrevv"  >{formatNumber(sumCv)} </span></Box>

                    </Box>

                </Box>
                <Box className="table1"
                     sx={{
                         "& .MuiDataGrid-root": {
                             border: "none",
                         },
                         "& .MuiDataGrid-cell": {
                             borderBottom: "none",
                         },
                         "& .name-column--cell": {
                             color: colors.greenAccent[300],
                         },
                         "& .MuiDataGrid-columnHeaders": {
                             backgroundColor: colors.blueAccent[700],
                             borderBottom: "none",
                         },
                         "& .MuiDataGrid-virtualScroller": {
                             backgroundColor: colors.primary[400],
                         },
                         "& .MuiDataGrid-footerContainer": {
                             borderTop: "none",
                             backgroundColor: colors.blueAccent[700],
                         },
                         "& .MuiCheckbox-root": {
                             color: `${colors.greenAccent[200]} !important`,
                         },
                         "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                             color: `${colors.grey[100]} !important`,
                         },
                     }}
                >
                    <h2   className="h2">Caisse en devise</h2>
                    <TableContainer component={Paper} >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#728cef" }}>Devise</TableCell>
                                    <TableCell sx={{ paddingY: "0.8rem", fontSize:"0.9rem",backgroundColor:"#728cef" }}>Montant</TableCell>
                                </TableRow>
                            </TableHead>
                        </Table>
                        <div style={{ overflowY: 'auto', maxHeight: '35vh' }}>
                            <Table aria-label="simple table">
                                <TableBody>
                                    {devise.map((row, index) => (
                                        <TableRow
                                            key={index}
                                            onClick={() => handleRowClick2(row)}
                                            style={{
                                                backgroundColor:
                                                    selectedRow2 && selectedRow2.nom_devise === row.nom_devise ? '#ccc' : 'inherit',
                                            }}
                                        >
                                            <TableCell sx={{ paddingY: "0.5rem", fontSize:"0.9rem" }}>{row.nom_devise}</TableCell>
                                            <TableCell align="right" sx={{ paddingY: "0.5rem", fontSize:"0.9rem" }}>{formatNumber(row.montant)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    </TableContainer>
                    <Box display="flex" justifyContent="space-between" >

                        <Box
                            sx={{width:'100%',justifyContent: 'center', alignItems: 'center',justifyItems:'center',
                                "& .contrevaleur":{
                                    color: colors.grey[100],
                                    fontSize: "1rem",
                                    marginTop: "1rem",
                                    padding:"0.5rem",
                                },
                                "& .contrev":{
                                    backgroundColor:colors.primary[800],
                                    color: colors.grey[100],
                                    fontSize: "1.1rem",
                                    marginLeft: "2rem",
                                    marginRight : "3rem",
                                    marginBottom: "5rem",
                                    paddingX:"2.5rem",
                                    paddingY:"0.5rem",
                                }
                            }}
                        ><Box sx={{ marginY: '2vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <span className="contrevaleur" stylle={{width:'100%', textAlign:'center'}}>Contre valeur de devises conservées</span>
                        </Box >
                            <Box  sx={{display: 'flex', justifyContent: 'center', alignItems: 'center' }}><span className="contrev"  >{formatNumber(sumCvAc)} </span></Box></Box>
                    </Box>
                </Box>

            </Box>
            <div style={{ display: 'none' }}>
                <PDFContent data={rows} data2={bordereau} data3={client} agence={agence} ref={componentRef} />
            </div>
        </Box>



    );
};

export default Geachat;
